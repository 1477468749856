import React from "react";
import { Grid, Box, Typography, Divider } from "@material-ui/core";
import useStyles from "../styles/pages/_NewsStyles";
import LatestNews from "../components/LatestNews";
import newsData from "../data/news";
import HomeSVGR from "../smart-assets/SVGRs/HomeSVGR";
import useLanguage from "../hooks/useLanguage";
import HTMLReactParser from 'html-react-parser';
import { useHistory } from "react-router-dom";

function _News(props) {
	const newsId = Number(props.match.params.newsId);
	const news = newsData.find(entry => entry.id === newsId);
	const classes = useStyles(props);
	const history = useHistory();

	const { key: languageKey, universal: { months } } = useLanguage();

	return (
		<Grid item container xs={11} sm={11} md={10} className={classes.contentContainerGrid} justify="center">
			<Grid item sm={12} lg={7} className={classes.newsContainer}>
				<Box className={classes.newsHeader}>
					<Box className={classes.homeSVGRContainer} onClick={() => history.push(`/`)}>
						<HomeSVGR />
					</Box>
					<Grid container className={classes.titleAndDateContainer} alignItems="center" justify="center">
						<Grid item xs={12} sm={12}>
							<Typography variant="body1" className={classes.title}>
								{news.title[languageKey]}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography variant="body1" className={classes.date}>
								{`${news.date.getDate()} ${months[news.date.getMonth()]}, ${news.date.getFullYear()}`}
							</Typography>
						</Grid>
					</Grid>
				</Box>
				<Box className={classes.news}>
					<Box className={classes.imageContainer}>
						<img src={news.images[languageKey].l.default} alt="" className={classes.image} />
					</Box>
					<Box className={classes.contents}>


						<Typography variant="body1" className={classes.description}>
							{HTMLReactParser(news.description[languageKey])}
						</Typography>
					</Box>
				</Box>
			</Grid>
			<Grid item container xs={1} justify="center" alignItems="center">
				<Divider variant="middle" orientation="vertical" className={classes.verticalDivider} />
			</Grid>
			<Grid item xs={12} lg={4} className={classes.latestNewsContainer}>
				<LatestNews news={newsData} lg={12} perPage={5} />
			</Grid>
		</Grid >
	);
}

export default _News;
