import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 450,
        borderRadius: 6,
        background: '#171f32',
        padding: '37px 32px',
        outline: 'none',
        [theme.breakpoints.down(500)]: {
            width: 'calc(100% - 20px)'
        },
    },
    closeSVGR: {
        position: 'absolute',
        top: 20,
        right: 20,
        cursor: 'pointer',
        '& path': {
            transition: 'fill 250ms ease-in-out',
        },
        "&:hover path": {
            fill: '#c7d0da',
        }
    },
    title: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 'normal',
        fontSize: 21,
        lineHeight: '39px',
        textAlign: 'left',
        color: '#c7d0da',
    },
    titleContainer: {
        marginBottom: 30,
    },
    statusText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '22px',
        textAlign: 'center',
        color: '#197163',
        maxWidth: '90%',
        margin: 'auto',
    },
    statusVGR: {
        margin: 'auto',
        marginBottom: 20,
    },
    contentContainer: {
        marginTop: 35,
        marginBottom: 56
    },
    buttonText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        textDecoration: 'underline',
        fontSize: 14,
        textAlign: 'center',
        color: '#a6b5d9',
        cursor: 'pointer'
    }
}));

export default useStyles;
