import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import useStyles from "../styles/components/FAQSectionPickerItemStyles";
import classNames from "classnames";

const FAQSectionPicker = (props) => {
    const classes = useStyles(props);
    const { title, SVGR, onClick, isSelected } = props;

    return (
        <Grid container className={classNames(classes.container, isSelected ? classes.containerSelected : null)} onClick={onClick}>
            <Box className={classes.SVGRContainer}>
                <SVGR />
            </Box>
            <Typography className={classes.title}>
                {title}
            </Typography>
        </Grid>
    );
};

export default FAQSectionPicker;
