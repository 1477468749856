import React from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "../styles/components/StepProgressStyles";
import classNames from 'classnames';

const StepProgress = (props) => {
    const classes = useStyles(props);
    const { steps, currentStep, setCurrentStep, canReachStep, maxReached, setMaxReached } = props;

    const handleClick = async (stepNumber) => {
            const canReach = await canReachStep(stepNumber);
            if(canReach){
                setCurrentStep(stepNumber)
                if(stepNumber > maxReached){
                    setMaxReached(stepNumber);
                }
            }

    }

    return (
        <Box className={classes.container}>
            {Array(steps).fill().map((NONE, i) => {
                return (<React.Fragment key={i}>
                    <Box className={classNames(classes.step, 
                    (i + 1) < currentStep ? classes.isPassed : null, 
                    (i) < maxReached ? classes.isPassed : null, 
                        (i + 1) === currentStep ? classes.isCurrent : null)} onClick={() => handleClick(i + 1)}>
                        <Typography variant="body1" className={classes.stepText}>
                            {i + 1}
                        </Typography>
                    </Box>
                    {i !== steps - 1 && <hr className={classNames(classes.lineBetweenSteps, (i + 1) < currentStep ? classes.isPassed : null)} />}
                </React.Fragment>)
            })}
        </Box>
    );
};

export default StepProgress;
