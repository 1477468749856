import * as React from "react"

function SearchSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            viewBox="0 0 16 16"
            {...props}
        >
            <path
                d="M12.515 10.634l3.095 3.1a1.332 1.332 0 01-1.884 1.883l-3.1-3.1a6.832 6.832 0 111.884-1.883zm-.845-3.8a4.83 4.83 0 10-4.83 4.833 4.834 4.834 0 004.83-4.831z"
                fill="#1f2637"
                fillRule="evenodd"
                data-name="Interface-Essential / Search / search-1"
            />
        </svg>
    )
}

export default SearchSVGR
