import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "calc(100% + 20px)",
		marginLeft: -10,
		// background: "blue",
		// [theme.breakpoints.down(theme.breakpoints.values.md)]: {
		// 	'& $newsContainer:nth-of-type(even)': {
		// 		paddingRight: 0,
		// 	},
		// 	'& $newsContainer:nth-of-type(odd)': {
		// 		paddingLeft: 0,
		// 	},
		// 	'& $newsContainer': {
		// 		padding: '10px 10px',
		// 		// backgroundColor: 'red'
		// 	}
		// },
		// [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
		// 	'& $newsContainer': {
		// 		padding: '10px 0',
		// 	}
		// },
		// [theme.breakpoints.up(theme.breakpoints.values.md)]: {
		// 	'& $newsContainer:nth-of-type(even)': {
		// 		paddingLeft: 10,
		// 		paddingRight: 10,
		// 	},
		// 	'& $newsContainer:nth-of-type(3n)': {
		// 		paddingLeft: 10,
		// 	},
		// },
		minHeight: "100%",
	},
	news: {
		boxSizing: "border-box",
		// margin: "10px 0",
		borderRadius: 6,
		background: "#181f2e",
		boxShadow: "0px 3px 2px #161c2a",
		cursor: "pointer",
		display: "flex",
		flexDirection: "column",
		[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
			maxWidth: 493,
			height: "100%",
			minHeight: 450,
		}
	},
	imageContainer: {
		borderRadius: "6px 6px 0px 0px",
		width: "100%",
		paddingTop: `${270 / 493 * 100}%`,
		position: "relative",
		"&:before": {
			content: "' '",
			backgroundColor: "#151B284D",
			borderRadius: "6px 6px 0px 0px",
			backgroundPosition: "left",
			position: "absolute",
			left: 0,
			zIndex: 1,
			top: 0,
			width: "100%" /*width of the css background*/,
			height: "100%",
		},
		maxHeight: 'calc(100% / 6 * 4)',
	},
	image: {
		width: "100%",
		objectFit: "cover",
		borderRadius: "6px 6px 0px 0px",
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
	},
	contents: {
		boxSizing: "border-box",
		padding: '20px 24px',
		display: "flex",
		flexDirection: "column",
		height: '100%',
		// justifyContent: "center",
	},
	date: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 12,
		lineHeight: "22px",
		textAlign: "left",
		color: "#6b7a9e",
	},
	title: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 16,
		lineHeight: "25px",
		textAlign: "left",
		color: "#c7d0da",
		// marginBottom: 15,
	},
	shortDescription: {
		fontFamily: "var(--font-family-2)",
		fontWeight: "normal",
		fontSize: 14,
		lineHeight: "27px",
		textAlign: "left",
		color: "#6b7a9e",
		display: '-webkit-box',
		'-webkit-line-clamp': 3,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		margin: 'auto 0',
	},
	newsContainer: {
		padding: 10,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			margin: 'auto'
		},
	}
}));

export default useStyles;
