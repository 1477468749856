import * as React from "react"

function ErrorStatusSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={70}
            height={70}
            viewBox="0 0 70 70"
            {...props}
        >
            <g data-name="Group 1180">
                <g data-name="Group 1179">
                    <path
                        data-name="Path 72"
                        d="M35 0a35 35 0 1035 35A34.98 34.98 0 0035 0zm0 64.531A29.531 29.531 0 1164.531 35 29.515 29.515 0 0135 64.531z"
                        fill="#cb3352"
                    />
                </g>
            </g>
            <g data-name="Group 1182">
                <g data-name="Group 1181">
                    <path
                        data-name="Path 73"
                        d="M47.094 43.188L38.906 35l8.188-8.188a2.761 2.761 0 10-3.905-3.905l-8.188 8.188-8.188-8.188a2.761 2.761 0 10-3.905 3.905L31.096 35l-8.188 8.188a2.761 2.761 0 103.905 3.905l8.188-8.188 8.188 8.188a2.761 2.761 0 103.905-3.905z"
                        fill="#cb3352"
                    />
                </g>
            </g>
        </svg>
    )
}

export default ErrorStatusSVGR
