import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import useStyles from "../styles/components/PinRecoveryStyles";
import useLanguage from "../hooks/useLanguage";


function ChangePassword(props) {
    const classes = useStyles(props);
    const { pages: { dashboard: { profile: { pinRecovery: STRINGS } } } } = useLanguage();


    return (
        <Grid item container xs={12} className={classes.container} alignItems="flex-start">
            <Grid item xs={12} className={classes.informationLine}>
                <hr />
                <Typography variant="body1" className={classes.informationLineText}>
                    {STRINGS.title}
                </Typography>
                <hr />
            </Grid >
            <Grid item xs={12} className={classes.descriptionContainer}>
                <Typography variant="body1" className={classes.descriptionText}>
                    {STRINGS.description}
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
                <Button 
                // onClick={requestPinRecovery} 
                className={classes.button}>
                    <Typography className={classes.buttonText}>
                        {STRINGS.requestPin}
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    );
}

export default ChangePassword;
