import * as React from "react"

function NoLocationsSelected(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={76}
            height={114}
            viewBox="0 0 76 114"
            {...props}
        >
            <path
                d="M0 38a38 38 0 0176 0c0 16.691-23.75 57.124-33.953 73.734A4.7 4.7 0 0138 114a4.752 4.752 0 01-4.047-2.261C23.75 95.119 0 54.673 0 38zm21.375 0A16.625 16.625 0 1038 21.375 16.625 16.625 0 0021.375 38z"
                fill="#343e57"
                fillRule="evenodd"
                data-name="Maps-Navigation / Pins / pin"
            />
        </svg>
    )
}

export default NoLocationsSelected
