import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  contentContainerGrid: {
    width: "auto",
    margin: "auto",
    marginTop: -6,
    "& > div:last-of-type *:last-child": {
      // marginTop: 36,
      marginBottom: 13,
    },
    "& > div:first-of-type": {
      margin: 0,
    },

    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      maxWidth: '90rem',
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      maxWidth: "53.75rem",
    }
  },
  title: {
    fontFamily: "var(--font-family-1)",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "28px",
    textAlign: "left",
    color: "#677aa7",
    marginBottom: 26,
    // "&:first-of-type": {
    // 	background: "red",
    // },
  },
  underline: {
    fontFamily: "var(--font-family-1)",
    textDecoration:'underline',
    cursor:'pointer',
    marginBottom:0,
    marginTop:30
  },
  end:{
    color: "#a6b5d9",
    fontWeight:"bold",
    fontSize:12
  },
  policy: {
    fontFamily: "var(--font-family-3)",
    fontWeight: "normal",
    fontSize: 16,
    textAlign: "left",
    color: "#a6b5d9",
    "p,span,strong": {
      color: "#a6b5d9" ,
      margin:0,
      padding:0
    },
  },

}));

export default useStyles;
