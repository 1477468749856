import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        cursor: 'pointer',
        minWidth: 31,
        minHeight: 50,
        width: 31,
        height: 50,
        borderRadius: '1000px',
        // backgroundColor: '#f1c157',
        display: "flex",
        // alignItems: "center",
        justifyContent: "center",
        // boxShadow: '0px 2px 6px rgba(47, 53, 70, 0.4)',
        '& svg': {
            minWidth: 31,
            minHeight: 50,
            width: 31,
            height: 50,
        },
        marginTop: -51,
        marginLeft: -(31 / 2),
        '&:hover $markerInfo': {
            display: 'block'
        }
    },
    text: {
        position: 'absolute',
        fontFamily: "\"LBet STD MT\"",
        fontWeight: 500,
        fontSize: 19,
        lineHeight: "25px",
        textAlign: "center",
        color: "#67728c",
        marginTop: 2.5,
        // top: -51 + 3,
    },
    markerInfo: {
        zIndex: 100,
        cursor: 'default',
        display: "none",
        position: 'absolute',
        width: 270,
        background: '#161c2a',
        bottom: 55,
        borderRadius: 6,
        border: '1px solid #546283',
        color: '#6b7a9e',
        boxSizing: 'border-box',
        padding: 5,
    },
    city: {
        fontFamily: "var(--font-family-1)",
        fontSize: 20,
    },
    address: {
        fontFamily: "var(--font-family-1)",
        fontSize: 14,
    },
    phone: {
        fontFamily: "var(--font-family-1)",
        fontSize: 18,
    },
    workingHours: {
        fontFamily: "var(--font-family-1)",
        fontSize: 14,
    }
}));

export default useStyles;
