import React, { useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import useStyles from "../styles/components/LatestNewsStyles";
import PervPageArrowSVGR from "../smart-assets/SVGRs/PervPageArrowSVGR";
import NextPageArrowSVGR from "../smart-assets/SVGRs/NextPageArrowSVGR";
import { useHistory } from "react-router-dom";
import useLanguage from "../hooks/useLanguage";

const LatestNews = (props) => {
    const classes = useStyles(props);
    const { news } = props;
    const perPage = props.perPage ? props.perPage : 3;
    const [currentPage, setCurrentPage] = useState(1);
    const history = useHistory();

    const { key: languageKey, routeKey, universal: { months }, globalComponents: { latestNews } } = useLanguage();

    const getPage = (page) => {
        const thisPage = news.slice((currentPage - 1) * perPage, perPage * page);
        return thisPage;
    }

    const getPageCount = () => {
        return Math.ceil(news.length / perPage);
    }

    const nextPage = () => {
        if (currentPage !== getPageCount()) {
            setCurrentPage(currentPage + 1);
        }
    }

    const pervPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    return (
        <Grid container className={classes.container}>
            <Grid xs={12} item className={classes.sectionTitleContainer}>
                <Typography variant="h2" className={classes.sectionTitle}>
                    {latestNews.headerText}
                </Typography>
            </Grid>

            {getPage(currentPage).map((entry) => (
                <Grid sm={props.sm ? props.sm : 6} md={props.md ? props.md : 4} lg={props.lg ? props.lg : 4} item className={classes.newsContainer} key={entry.id} onClick={() => history.push(`/${routeKey}/news/${entry.id}`)}>
                    <Box className={classes.news}>
                        <Box className={classes.imageContainer}>
                            <img src={entry.images[languageKey].s.default} alt="" className={classes.image} />
                        </Box>
                        <Box className={classes.contents}>
                            <Typography variant="body1" className={classes.date}>
                                {`${entry.date.getDate()} ${months[entry.date.getMonth()]}, ${entry.date.getFullYear()}`}
                            </Typography>
                            <Typography variant="body1" className={classes.title}>
                                {entry.title[languageKey]}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            ))}
            <Grid xs={12} item container direction="row" alignItems="center" justify="flex-end" className={classes.pagination}>
                <Typography variant="body1" className={classes.currentPage}>
                    {currentPage < 10 ? '0' : null}{currentPage}
                </Typography>
                <Typography variant="body1" className={classes.lastPageAndSlash}>
                    /{getPageCount() < 10 ? '0' : null}{getPageCount()}
                </Typography>
                <PervPageArrowSVGR className={classes.pervPageArrow} onClick={pervPage} />
                <NextPageArrowSVGR className={classes.nextPageArrow} onClick={nextPage} />
            </Grid>
        </Grid>
    );
};

export default LatestNews;
