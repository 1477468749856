import * as React from "react"

function DeleteBinSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={22}
            height={24}
            viewBox="0 0 22 24"
            {...props}
        >
            <g
                fill="#ad7171"
                fillRule="evenodd"
                data-name="Interface-Essential / Delete / bin"
            >
                <path d="M18.5 7.5h-15A.5.5 0 003 8v14a2 2 0 002 2h12a2 2 0 002-2V8a.5.5 0 00-.5-.5zm-9.25 13a.75.75 0 01-1.5 0v-9a.75.75 0 011.5 0zm4.25.75a.753.753 0 00.75-.75v-9a.75.75 0 00-1.5 0v9a.753.753 0 00.75.75zM16.25 4H21a1 1 0 010 2H1a1 1 0 010-2h4.75a.281.281 0 00.177-.07A.256.256 0 006 3.75V2.5A2.5 2.5 0 018.5 0h5A2.5 2.5 0 0116 2.5v1.25a.249.249 0 00.25.25zM8 2.5v1.25a.249.249 0 00.25.25h5.5a.249.249 0 00.25-.25V2.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5z" />
            </g>
        </svg>
    )
}

export default DeleteBinSVGR
