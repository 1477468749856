import * as React from "react"

function CardSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={39}
            height={27}
            viewBox="0 0 39 27"
            {...props}
        >
            <g
                fill="#546283"
                data-name="Money-Payments-Finance / Credit-Card-Payments / credit-card-1"
            >
                <path
                    d="M3.391 27A3.373 3.373 0 010 23.656V9.445a.419.419 0 01.424-.418h38.152a.419.419 0 01.424.418v14.211A3.373 3.373 0 0135.609 27zm29.674-7.106h-5.087a1.254 1.254 0 100 2.508h5.087a1.254 1.254 0 100-2.508zM6.782 13.206h13.566a1.254 1.254 0 110 2.508H6.783a1.254 1.254 0 110-2.508zm5.087 6.688H6.783a1.254 1.254 0 100 2.508h5.087a1.254 1.254 0 100-2.508z"
                    fillRule="evenodd"
                />
                <path d="M39 3.439A3.42 3.42 0 0035.609 0H3.391A3.42 3.42 0 000 3.439v2.579a.425.425 0 00.424.43h38.152a.425.425 0 00.424-.43z" />
            </g>
        </svg>
    )
}

export default CardSVGR
