import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	containerGrid: {
		backgroundColor: "#161C2A",
		minHeight: "100vh",
		// minWidth: theme.breakpoints.values.sm,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			background: 'linear-gradient(#161c2a 0%, #07090e 100%)'
		},
	},
	contentContainerGrid: {
		width: "100%",
		margin: "auto",
		// padding: '30px',
		// marginTop: 50,
		// marginBottom: 50,
	},
	welcomeBannerContainer: {
		height: 350,
		marginBottom: 50,
	},
	headerContainer: {
		width: "100%",
		'& > div': {
			[theme.breakpoints.up(theme.breakpoints.values.md)]: {
				maxWidth: '95rem',
				margin: '0 auto',
			},
		},
		margin: '0 auto',
	},
	footerContainer: {
		width: "100%",
		'& > div': {
			[theme.breakpoints.up(theme.breakpoints.values.md)]: {
				maxWidth: '95rem',
				margin: '0 auto',
			},
		},
		margin: '0 auto',
	},
	footerCover: {
		width: '100%',
		backgroundColor: "#141927",
		marginTop: 50,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			backgroundColor: 'transparent'
		},
	},
	headerCover: {
		width: '100%',
		backgroundColor: "transparent",
		borderBottom: "2px solid #1A2131",
		marginBottom: 50,
	}
}));

export default useStyles;
