export default {
    key:2,
    title:{
        Geo: `„ენ ბი სი“- ს საგადახდო მომსახურების ხელშეკრულება`,
        Eng: `„ენ ბი სი“- ს საგადახდო მომსახურების ხელშეკრულება`,
        Rus: `„ენ ბი სი“- ს საგადახდო მომსახურების ხელშეკრულება`,
    },
    endDate:{
        Geo: `ცვლილებები ძალაშია 14.03.2023-დან`,
        Eng: `ცვლილებები ძალაშია 14.03.2023-დან`,
        Rus: `ცვლილებები ძალაშია 14.03.2023-დან`,
    },
    content:{
        Geo: `
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>გთხოვთ, საგადახდო მომსახურებით სარგებლობამდე ხელშეკრულების სავალდებულო</strong></span></span></span></p>
    
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>ხასიათის გათვალისწინებით ყურადღებით გაეცნოთ წინამდებარე პირობებს!</strong></span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>&bdquo;ენ ბი სი&ldquo;- ს საგადახდო მომსახურების ხელშეკრულება</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>+995 32 2 193 293 | </strong><a href="http://www.nbc.ge/" style="color:#954f72; text-decoration:underline"><strong>www.nbc.ge</strong></a><strong> , </strong><a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline"><strong>www.leadercard.ge</strong></a><strong> / | info@nbc.ge</strong></span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1. ზოგადი დებულებანი</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.1. წინამდებარე ხელშეკრულება მოიცავს მომხმარებლების მიერ ენბისის ელექტრონული საგადახდო მომსახურებით სარგებლობის სტანდარტულ და სპეციალურ პირობებს/წესებს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.2. კონკრეტული საგადახდო მომსახურების მიღებისას შესაძლოა მოქმედებდეს სპეციალური პირობები/წესები, რომლის შესახებაც მომხმარებელი ინფორმაციას იღებს კონკრეტული მომსახურების მიღების პროცესში;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.3. ხელშეკრულების პირობები ძალაში შედის მას შემდეგ, რაც მომხმარებელი განაცხადით ან/და კანონმდებლობით ნებადართული და ენბისისთვის მისაღები სხვა (დისტანციური მომსახურება) ფორმით, დაადასტურებს თანხმობას პირობებზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.4. წინამდებარე საგადახდო მომსახურებით სარგებლობის სტანდარტული და სპეციალური პირობები/წესები, მათ შორის ნებისმიერი დანართი მომხმარებლის განაცხადთან ერთად წარმოადგენს ენბისისა და მომხმარებელს შორის გაფორმებულ ხელშეკრულებას.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.5. ენბისი უფლებამოსილია საკუთარი შეხედულებისამებრ არ დაუშვას, ან სხვა სახით შევზღუდოს კონკრეტული მომხმარებლისთვის მომსახურების მიღება ასევე, იტოვებს უფლებას,</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >უარი თქვას კონკრეტული მომხმარებლის შემთხვევაში, კონკრეტულ გადახდასთან დაკავშირებით, ეროვნული ბანკის მიერ საგადახდო მომსახურების პროვაიდერისთვის დადგენილი სტანდარტების შესაბამისად.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.6. ვებ-გვერდზე რეგისტრაციისას ინფორმაციის მოწოდება ხდება მომხმარებლის სურვილით,</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >რაც ნიშნავს, რომ ენბისის აქვს უფლება შეინახოს მომხმარებლის მიერ მოწოდებული ინფორმაცია ტრანზაქციების, ისტორიის შექმნის და ვებ-გვერდის ფუნქციონირების სხვა მიზნებისთვის.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>2. ტერმინთა განმარტება</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >წინამდებარე ხელშეკრულებაში გამოყენებულ ტერმინებს, ამ &bdquo;ხელშეკრულების &ldquo;მიზნებისთვის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >აქვს შემდეგი მნიშვნელობა (თუ &bdquo;ხელშეკრულებით &ldquo;სხვა რამ არ არის განსაზღვრული, ან მისი კონტექსტიდან სხვა რამ არ გამომდინარეობს):</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.1. <strong>საგადახდო მომსახურების პროვაიდერი (პროვაიდერი)</strong> - შპს &bdquo;ენ ბი სი &ldquo;, საიდენტიფიკაციო</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ნომერი:405150063, საქართველოს ეროვნულ ბანკში რეგისტრაციის N 0053-9004, სათაო ოფისის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მისამართი: ონიაშვილის ქ. N1, კომერციული ფართი N5 თბილისი, საქართველო; ვებ-გვერდის მისამართი: <a href="http://www.nbc.ge/" style="color:#954f72; text-decoration:underline">www.nbc.ge</a> , <a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline">www.leadercard.ge</a> , ცხელი ხაზი: +995 32 2 193 293, მომხმარებელს უწევს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ელექტრონულ საგადახდო მომსახურებას &ldquo;საგადახდო სისტემისა და საგადახდო მომსახურების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >შესახებ&rdquo; საქართველოს კანონისა და სხვა ნორმატიული აქტებით განსაზღვრული პირობების შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.2. <strong>ავტორიზაცია</strong> - კანონმდებლობითა და ენბისის მიერ დადგენილი წესებისა და პროცედურების შესაბამისად მომხმარებლის მიერ გაცემული თანხმობა ოპერაციის განხორციელებაზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.3. <strong>ამონაწერი</strong> - ინფორმაცია მომხმარებლის მიერ ჩატარებული ოპერაციის შესახებ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.4. <strong>არაპერსონიფიცირებული საგადახდო ბარათი</strong> &ndash; Visa/Mastercard ან/და სხვა სისტემის საგადახდო ბარათი, რომელზეც არ არის ასახული ბარათის მფლობელის სახელი ან/და გვარი.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.5. <strong>განაცხადი </strong>- მომხმარებლის მიმართვა პროვაიდერს (ენბისის მიერ დადგენილი ფორმით</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მატერიალური დოკუმენტის, ელექტრონული ან სხვა მსგავსი საშუალებების გამოყენებით</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომხმარებლისთვის მიწოდებული ინფორმაცია, რომელსაც დადგენილი ფორმით ეთანხმება/არ ეთანხმება მომხმარებელი), რომ განაცხადშივე განსაზღვრული პირობების შესაბამისად, განახორციელოს ან/და შეაჩეროს/შეწყვიტოს საგადახდო მომსახურება;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.6. <strong>დავალება</strong> -მომხმარებლის მიერ საგადახდო მომსახურების ხელშეკრულების ფარგლებში</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >განხორციელებული ნებისმიერი დავალება ან/და მითითება, რომელსაც მომხმარებელი აძლევს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პროვაიდერს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.7. <strong>დისტანციური მომსახურება</strong> - ენბისის მიერ დადგენილი პროცედურების შესაბამისად, მოსახურების ცენტრში მიუსვლელად დისტანციურად მომსახურების მიღება, განაცხადის გაკეთება, სატელეფონო, SMS შეტყობინების დადასტურების, მომხმარებლის პროფილიდან და</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სხვადასხვა დისტანციური/ელექტრონული საკომუნიკაციო არხების საშუალებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.8. <strong>ელექტრონული საფულე</strong> - პირის უნიკალური ვირტუალური ანგარიში, რომელიც დაკავშირებულია პირის იდენტიფიცირებასთან. საფულეში განთავსებულია ელექტრონული</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ფული, რომელიც იმართება მომხმარებლის მიერ ენბისის დადგენილი წესების შესაბამისად და</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მოიცავს საფულის წვდომის რეკვიზიტებს, ავტორიზაციისთვის საჭირო რეკვიზიტებს, რომელიც</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ცნობილია მხოლოდ საფულის მფლობელისთვის და შეიცავს შემდეგ მონაცემებს: მომხმარებლის მიმდინარე ნაშთს, გადახდების ისტორიას და სხვა ინფორმაციას, რომელიც განახორციელა მომხმარებელმა საფულედან;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.9. <strong>ელექტრონული ფული</strong> - ენბისის მიერ მომხმარებლისგან გადახდის ოპერაციების განხორციელების მიზნით ფულადი სახსრების ექვივალენტური ღირებულება, რომელიც შენახულია საგადახდო ინსტრუმენტებზე და რომელიც აღიარებულია გადახდის საშუალებად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.10. <strong>ვებგვერდი</strong> - <a href="http://www.nbc.ge/" style="color:#954f72; text-decoration:underline">www.nbc.ge</a> , <a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline">www.leadercard.ge</a> საშუალებას აძლევს ენბისის მომხმარებლებს დისტანციურად შეავსონ განაცხადი ან/და მიიღონ სასურველი მომსახურება, სადაც განთავსებულია პროვაიდერის სათაო ოფისის მისამართი, ელექტრონული ფოსტის მისამართი, ტელეფონის ნომერი, მომსახურების ცენტრების მისამართები და სხვა ინფორმაცია, რაც მნიშვნელოვანია მხარეთა შორის სახელშეკრულებო ურთიერთობის პროცესში;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.11. <strong>კანონმდებლობა</strong> &ndash; საქართველოს მოქმედი საკანონმდებლო და კანონქვემდებარე ნორმატიული აქტები და საქართველოს ნორმატიულ აქტთა სისტემაში მოქცეული საერთაშორისო ხელშეკრულებები და შეთანხმებები;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.12.<strong> მომხმარებელი </strong>- სრულწლოვანი ქმედუნარიანი საქართველოს რეზიდენტი ფიზიკური პირი, რომელიც ეთანხმება წინამდებარე ხელშეკრულებით დადგენილ წესებსა და პირობებს,</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >აკმაყოფილებს კანონმდებლობით და ხელშეკრულებით გათვალისწინებულ მოთხოვნებს. ამასთანავე, რომლის იდენტიფიკაცია/ვერიფიკაცია ენბისიმ განახორციელა, უშუალოდ ან/და დისტანციური მომსახურების საშუალებით წარდგენილი განცხადების საფუძველზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.13.<strong> მომსახურება </strong>- ენბისი კისრულობს ვალდებულებას, ხელშეკრულების საფუძველზე გაუწიოს მომხმარებელს მომსახურება წარდგენილი განცხადების საფუძველზე, რეალურ ან/და</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დისტანციურ რეჟიმში ხელშეკრულებით გათვალისწინებული წესითა და პირობით საგადახდო</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომსახურება, რომელიც მოიცავს მომხმარებლის ელექტრონული საფულის გახსნასა და განკარგვას, ფულადი სახსრების ან/და მათი ეკვივალენტი ელექტრონული ფულის გატანას/გადარიცხვას, მომხმარებლის საგადახდო ბარათით მომსახურებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.14. <strong>მხარე/მხარეები</strong> - ენბისი და მომხმარებელი ერთობლივად ან ცალ-ცალკე, კონტექსტის შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.15. <strong>ოპერაცია</strong> - ენბისის(პროვაიდერის) მიერ მომხმარებლის დავალებით განხორციელებული</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ნებისმიერი სახის საგადახდო მომსახურება;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.16. <strong>პარტნიორი ორგანიზაცია</strong> - პარტნიორი ორგანიზაციების და პარტნიორების შესახებ მონაცემები განთავსებულია ვებგვერდზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.17. <strong>საგადახდო ბარათი (შემდგომში &ndash; ბარათი)</strong> &ndash; ენბისის მიერ გამოშვებული საგადახდო ინსტრუმენტი (პლასტიკური ბარათი), რომელიც დაკავშირებულია მომხმარებლის ელექტრონულ საფულესთან, რომლის საშუალებითაც ხდება მომსახურების საფასურის გადახდა, ნაღდი ფულის მიღება, ხელშეკრულებით გათვალისწინებული წესების/პირობების და</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >საქართველოს კანონმდებლობით ნებადართული სხვა ოპერაციების შესრულება;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.18. <strong>საკომისიო</strong> - ენბისის მიერ, მომხმარებლისთვის, ნებისმიერი საგადახდო მომსახურებით სარგებლობისათვის დაწესებული გადასახადი/მომსახურების საფასური, რომლის ოდენობა და</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გადახდის პირობები განისაზღვრება დანართით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.19. <strong>სამუშაო დღე</strong> - დღე, შაბათის, კვირის ასევე კანონმდებლობით განსაზღვრული უქმე დღეების გარდა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.20. <strong>ფორს-მაჟორი </strong>&ndash; ხელშეკრულების მხარეები თავისუფლდებიან პასუხისმგებლობისაგან მათმიერ ნაკისრი ვალდებულებების სრული ან ნაწილობრივი შეუსრულებლობისათვის დაუძლეველი ძალის მოქმედების შემთხვევაში, რომლის დროსაც შეუძლებელია ხელშეკრულებით გათვალისწინებული ვალდებულებების შესრულება. &bdquo;დაუძლეველი ძალის&ldquo;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ქვეშ იგულისხმება გარემოებები, რომლებიც არ არსებობდნენ ხელშეკრულების დადების დროს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >და რომელთა დადგომა, თუ ზემოქმედება მხარეებს არ შეეძლოთ თავიდან აეცილებინათ და გადაელახათ. დაუძლეველი ძალის გარემოებებს მიეკუთვნება: სტიქიური უბედურებები, აფეთქებები (ტერორისტული თუ დივერსიული აქტები), სახელმწიფო ორგანოების ისეთი აქტების მიღება, რაც არსებითად აფერხებს ხელშეკრულების მხარეთა მიერ ნაკისრი ვალდებულებების შესრულებას, ასევე ელექტროენერგიის მიწოდების შეწყვეტა, მოწყობილობების მწყობრიდან გამოსვლა, პროგრამული უზრუნველყოფის დაზიანება, სისტემური ან და ქსელის ისეთი ხარვეზი, დაზიანება, შეფერხება, შეზღუდვა რაც ლოკაციაზე შეუძლებელს ხდის მომსახურების გაწევას ან ენბისისთვის გადაულახავი და მისი კონტროლისაგან დამოუკიდებელი სხვა გარემოებები.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>3. მომსახურების მიღება</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.1.საგადახდო მომსახურების პროვაიდერის საზედამხედველო ორგანოს წარმოადგენს საქართველოს ეროვნული ბანკი, ვებ გვერდის მისამართი: <a href="http://www.nbg.ge/" style="color:#954f72; text-decoration:underline">www.nbg.ge</a> .</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.2. საზედამხეველო ორგანო არ არის პასუხისმგებელი, საგადახდო მომსახურების პროვაიდერის მიერ ნაკისრი ვალდებულებების არაჯეროვან შესრულებაზე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.3. ხელშეკრულების საფუძველზე ენბისი იღებს ვალდებულებას, გაუხსნას მომხმარებელს ელექტრონული საფულის ანგარიში და გაუწიოს მას აღნიშნულ ანგარიშთან დაკავშირებული საგადახდო მომსახურება, უზრუნველყოს ანგარიშზე ფულადი სახსრების ჩარიცხვა და მის შესრულებასთან დაკავშირებული ოპერაციები, გადახდები, საგადახდო ბარათის ან სხვა საგადახდო ინსტრუმენტის გამოყენებით, გადარიცხვის მუდმივი დავალების ჩათვლით, მომხმარებლის კუთვნილი თანხის ფარგლებში;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.4. ენბისი იღებს ვალდებულებას გაუწიოს მომხმარებელს საგადახდო მომსახურება, რის სანაცვლოდაც მომხმარებელი ვალდებულია შეასრულოს ხელშეკრულებით განსაზღვრული პირობები და გადაიხადოს საკომისიო (ასეთის არსებობის შემთხვევაში);</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.5. ენბისი საგადახდო მომსახურებას ახორციელებს მხოლოდ საქართველოს ტერიტორიაზე. ელექტრონულ საფულეს გააჩნია ელექტრონული ფულის ანგარიში ერთ ვალუტაში და ყველა შემთხვევაში - ლარში;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.6. მომხმარებელი იღებს ვალდებულებას, პერიოდულად გაეცნოს ვებგვერდზე განთავსებულ ინფორმაციას ცვლილებების და დამატებების შესახებ. მომხმარებელი უფლებამოსილია მოიძიოს ინფორმაცია ხელშეკრულების პირობების შესახებ ხელშეკრულების მოქმედების განმავლობაში, ენბისის (პროვაიდერის) ვებ გვერდზე &bdquo;პირადი პროფილი &ldquo;-ს საშუალებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.7. ხელშეკრულებით განსაზღვრული საგადახდო პროდუქტით მომსახურების მიღების ან/და სარგებლობის შეწყვეტის მიზნით, მომხმარებელი უფლებამოსილია მიმართოს განაცხადით ენბისის უშუალოდ მომსახურების ცენტრში მისვლით (დისტანციური მომსახურების არხების საშუალებით ასეთის არსებობის შემთხვევაში). ენბისის მიერ განაცხადის განხილვა მოხდება იმ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პირობით, თუ მომხმარებლის მიერ განაცხადის შევსების დროს დაკმაყოფილებული იქნება ხელშეკრულებით გათვალისწინებული ყველა პირობა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.8. ენბისი დავალებას მიიღებს მხოლოდ იმ შემთხვევაში, თუ მისი გაცემა მოხდა მომხმარებლის მიერ რეგისტრირებული იდენტიფიკატორების გამოყენების მეშვეობით და უფლებამოსილია, შეამოწმოს დავალების ნამდვილობა, ასევე მომხმარებლისგან მოითხოვოს დამატებითი ინფორმაციის/დოკუმენტის წარდგენა, თუ ეს აუცილებელია დავალების შესასრულებლად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.9. საგადახდო დავალების მიღების დროდ ითვლება მომენტი, როდესაც ენბისი მიიღებს საგადახდო დავალებას. იმ შემთხვევაში, თუ საგადახდო დავალება ინიცირებულია არასამუშაო</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დღეს, იგი მიღებულად ჩაითვლება მომდევნო სამუშაო დღეს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.10. ენბისი საგადახდო დავალების შესრულებას უზრუნველყოფს საგადახდო დავალების წარდგენიდან 2 (ორი) სამუშაო დღის ვადაში, გარდა კანონმდებლობით გათვალისწინებული შემთხვევებისა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.11. გადარიცხვის ოპერაციის შესახებ დავალება, მისი სათანადოდ შესრულებისათვის, აუცილებელია მოიცავდეს საქართველოს ეროვნული ბანკის პრეზიდენტის 2015 წლის 22 იანვარის №8/04 გადახდის ოპერაციის შესრულების წესის დამტკიცების შესახებ ბრძანებით დადგენილ რეკვიზიტებს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12. ენბისი უფლებამოსილია არ მიიღოს შესასრულებლად დავალება ან/და უარი განაცხადოს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დავალების შესრულებაზე:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.1. თუ დავალება არ შეესაბამება კანონმდებლობით დადგენილ მოთხოვნებს ან/და ენბისის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დადგენილ წესებსა და პროცედურებს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.2. თუ დავალება შეიცავს არაზუსტ ინფორმაციას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.3. თუ დავალების შესრულებისათვის ანგარიშზე არსებული თანხა, საკომისიოს ჩათვლით, არ არის საკმარისი;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.4. ოპერაცია გადააჭარბებს ენბისის მიერ დაწესებულ ლიმიტს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.5. არსებობს ეჭვი, რომ მომხმარებლის მხრიდან ადგილი აქვს თაღლითობას ან/და უკანონო</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ქმედებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.6. თუ არსებობს სხვა ისეთი ხარვეზი/გარემოება, რომელიც შეუძლებელს ხდის დავალების შესრულებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.13. მომხმარებლის მოთხოვნის საფუძველზე ენბისი იღებს ყველა ზომას, რათა დროულად, გონივრულ ვადაში, შეაჩეროს ან/და გააუქმოს ნებისმიერი დავალების განხორციელება, თუ კანონმდებლობით სხვა რამ არ არის გათვალისწინებული. ენბისი არ კისრუბლობს პასუხისმგებლობას აღნიშნული მოთხოვნის დაუკმაყოფილებლობის შემთხვევაში, თუ მას არ მიუღია შესაბამისი შეტყობინება და ამგვარი შეჩერება ან გაუქმება შეუძლებელია;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.14. მიღებული დავალების შესრულებისათვის ენბისი უფლებამოსილია მომხმარებელს მოსთხოვოს დავალების ერთჯერადი კოდით დადასტურება (ე.წ. OTP ავტორიზაცია) იმ ოპერაციებისათვის, რომლებიც კანონმდებლობით გათვალისწინებული წესით საჭიროებს დამატებით დადასტურებას. მომხმარებელი ერთჯერად კოდს იღებს მის მიერ განაცხადში დაფიქსირებული მობილური ტელეფონის ნომრის ან/და მხარეთა შორის შეთანხმებული სხვა მოწყობილობის გამოყენებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.15. იმ შემთხვევაში, თუ ოპერაციის შესრულებისას ნებისმიერი მიზეზით წარმოიშვა გადახარჯვა, მომხმარებელი ვალდებულია, არაუგვიანეს მეორე დღისა უზრუნველყოს გადახარჯვის აღმოფხვრა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.16. მომხმარებელი ვალდებულია გადაიხადოს საკომისიო (ასეთის არსებობის შემთხვევაში) გადახდის დროისათვის არსებული ენბისის მიერ დადგენილი ტარიფების შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.17. მომხმარებელი სრულად აგებს პასუხს მის მიერ ენბისისთვის საგადახდო დავალებით მოწოდებულ რეკვიზიტების სისწორესა და სისრულეზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.18. ენბისი უფლებამოსილია, მის მიერ დადგენილი ტარიფების შესაბამისად მომხმარებლის ელექტრონული საფულიდან, მომხმარებლის დამატებითი თანხმობის გარეშე ჩამოჭრას/დაბლოკოს საკომისიო და მოახდინოს მოსახურებით გათვალისწინებული საკომისიოს დაფარვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.19. მომსახურების მისაღებად მომხმარებელი ვალდებულია:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.19.1. ყურადღებით გაეცნოს ხელშეკრულებას (წინამდებარე პირობებს);</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.19.2. შეავსოს ან/და ხელი მოაწეროს (მათ შორის ელექტრონულად) განაცხადს (ასეთის არსებობის შემთხვევაში) ან/და სხვა სახის დოკუმენტს, რომელიც საჭიროა შესაბამისი მომსახურების მისაღებად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.19.3. ენბისისთვის წარდგენილ/გაგზავნილ უფლებამოსილების დამადასტურებელ დოკუმენტსა ან/და ინფორმაციაში განხოციელებული ნებისმიერი სახის ცვლილების შესახებ (მათ შორის, ტელეფონის ნომრის) შეატყობინოს ენბისის აღნიშნული გარემოებების დადგომიდან უმოკლეს ვადაში, წინააღმდეგ შემთხვევაში ენბისი არ იქნება პასუხისმგებელი ცვლილების შეტყობინების მიღებამდე განხორციელებული ქმედებების შედეგად დამდგარ ზიანზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.20. დეტალების შესახებ, მომხმარებელს ინფორმაციის მიღება შეუძლია როგორც ადგილზე ენბისის მომსახურების ცენტრებში, ასევე ცხელი ხაზისა და დისტანციური მომსახურების საშუალებით. ოპერაციის ტიპიდან გამომდინარე, მომხმარებელზე შეიძლება გაიცეს ამონაწერი,</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ცნობა, ქვითარი ან/და სხვა დოკუმენტი ენბისის მიერ განსაზღვრული ფორმით და პირობებით.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.21. მომხმარებელი პასუხისმგებელია მოპარული ან დაკარგული საგადახდო ინსტრუმენტით ან უკანონო გამოყენებით გამოწვეული, განხორციელებული არაავტორიზებული ოპერაციის შედეგად წარმოშობილი ზიანისთვის კანონმდებლობით დადგენილი ოდენობისა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.22. მომხმარებელი ვალდებულია დაიცვას საგადახდო ინსტრუმენტის უსაფრთხოების ზომები ენბისის მითითების და ხელშეკრულების დანართი N1-ის შესაბამისად, უზრუნველყოს ამ ანგარიშის პერსონიფიცირებული საშუალებების დაცვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.23. მომხმარებელი ვალდებულა საგადახდო ინსტრუმენტის (ანგარიშის, კოდის, პაროლის და სხვა გადახდის განხორციელებისათვის აუცილებელი მონაცემების) დაკარგვის, მოპარვის ან უკანონო გამოყენების ფაქტის აღმოჩენის შემთხვევაში მისი აღმოჩენიდან უმოკლეს ვადაში შეატყობინოს ენბისის ამის თაობაზე. წინააღმდეგ შემთხვევაში მომხმარებელი სრულად იქნება</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პასუხისმგებელი განზრახ და დაუდევრობით გამოწვეულ ნებისმიერ ზიანზე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.24. ენბისი საგადახდო ინსტრუმენტის (ანგარიშის, კოდის, პაროლის და სხვა გადახდისგანხორციელებისათვის აუცილებელი მონაცემების) დაკარგვის, მოპარვის, უკანონო გამოყენების ფაქტის აღმოჩენის ან/და შეტყობინების მიღებისთანავე დაუყოვნებლივ უზრუნველვყოფს დაცვითი ზომების გატარებას.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>4.ელექტრონული საფულე</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.1. ელექტრონული ფულის ელექტრონული საფულის ანგარიშზე აღრიცხვას და მომხმარებლისთვის ხელმისაწვდომობას, ენბისი უზრუნველყოფს მომხმარებლის მიერ მის ელექტრონულ საფულეზე ფულადი სახსრების განთავსებისთანავე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.2. ელექტრონულ საფულეზე ელექტრონული ფულის განთავსება/გადარიცხვა შესაძლებელიაგანხორციელდეს პარტნიორი ორგანიზაციის საშუალებით, ენბისის მიერ მომხმარებლისთვისშეთავაზებული სხვა მეთოდებით რომელსაც პროვაიდერი ხელმისაწვდომს გახდის მომხმარებლისთვის;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.3 ენბისის უფლება აქვს, ნებისმიერ დროს გააუქმოს ან დაამატოს ელექტრონული საფულის ანგარიშზე თანხის შეტანის საშუალებები. ინფორმაცია ელექტრონული საფულის ბალანსის შევსების მეთოდების თაობაზე ხელმისაწვდომი იქნება პროვაიდერის ვებ-გვერდზე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.4. ელექტრონული საფულიდან შეიძლება განხორციელდეს კომუნალური გადასახადების გადახდა პარტნიორ ორგანიზაციებში და ასევე გადახდები QR კოდის მეშვეობით პარტნიორ კომპანიებში;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.5. ელექტრონულ საფულეზე ელექტრონული ფულის აღრიცხვა ან/და ელექტრონულ საფულეზე არსებული ელექტორნული ფულის განკარგვა ხორციელდება მომხმარებლის დავალების საფუძველზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.6. დავალების მიღების დროდ ითვლება მომენტი, როდესაც ენბისი მიიღებს დავალებას, მიუხედავად მისი ინიცირების თარიღისა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.7. იმ შემთხვევაში თუ დავალება ინიცირებულია არასამუშაო დღეს, იგი მიღებულად ჩაითვლებამომდევნო სამუშაო დღეს. ამასთან, სამუშაო დღის 18:00 სთ-ის შემდეგ წარმოდგენილი დავალებები, მიღებულად ჩაითვლება მომდევნო სამუშაო დღეს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.8. გადარიცხვის ოპერაციის შესახებ დავალება, მისი სათანადოდ შესრულებისათვის, აუცილებელია მოიცავდეს კანონმდებლობით დადგენილ რეკვიზიტებს ან/და ენბისის მიერ დამატებით განსაზღვრულ სხვა რეკვიზიტებს დავალების ტიპიდან გამომდინარე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9. ენბისი უფლებამოსილია არ მიიღოს შესასრულებლად დავალება და უარი განაცხადოს დავალების შესრულებაზე შემდეგ შემთხვევაში:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.1. დავალება არ შეესაბამება კანონმდებლობით დადგენილ მოთხოვნებს ან და ენბისის მიერ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დადგენილ წესებსა და პროცედურებს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.2. დავალება შეიცავს არაზუსტ ინფორმაციას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.3. დავალების შესრულებისათვის ელექტრონულ საფულეზე არსებული თავისუფალი ელექტრონული ფულის მოცულობა, საკომისიოს ჩათვლით, არ არის საკმარისი დავალების შესასრულებლად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.4. ოპერაცია აჭარბებს ენბისის მიერ დაწესებულ ლიმიტებს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.5. არსებობს ეჭვი, რომ ადგილი აქვს თაღლითობას ან უკანონო ქმედებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.6. არსებობს სხვა ისეთი გარემოება რომელიც შეუძლებელს ხდის დავალების შესრულებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.10. მომხმარებელი პასუხისმგებელია ყველა ოპერაციაზე, რომელიც განხორციელდა ელექტრონული საფულის საშუალებით, ელექტრონული საფულის დახურვამდე ან დახურვის შემდეგ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.11. ელექტრონული საფულე რეგისტრირდება უვადოდ, ელექტრონულ საფულეში არსებული</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ელექტრონული ფული არ წარმოადგენს პროვაიდერთან განთავსებულ დეპოზიტს და მას არ ერიცხება სარგებელი ან პროცენტი;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.12. ელექტრონული საფულე ეკუთვნის მხოლოდ მომხმარებელს. დაუშვებელია ელექტრონული საფულის გასხვისება, გადაფორმება ან დაგირავება;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.13. ელექტრონული საფულიდან ფულადი სახსრების გატანა/გადარიცხვა გულისხმობს ენბისის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ანგარიშიდან ფულადი სახსრების გატანა/გადარიცხვას მომხმარებლის დავალების შესაბამისად, მომხმარებლის ელექტრონულ საფულეზე არსებული ელექტრონული ფულის მოცულობით, ოპერაცია მოიცავს იმ საკომისიოს ოდენობასაც, რომელიც ეკისრება მომხმარებელს კონკრეტული ოპერაციის შესრულებისათვის;</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>5. საგადახდო ბარათი</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.1. მომსახურების გაწევის მიზნით, ენბისი მომხმარებელს გადასცემს საბანკო პლასტიკურ ბარათს და აღნიშნული ბარათის მონაცემებით არეგისტრირებს მომხმარებელს ენბისის ვებგვერდზე. რეგისტრაციის შედეგად მომხმარებლის სახელზე იქმნება მომხმარებლის პლასტიკურ ბარათზე მიბმული ელექტრონული საფულე. მომხმარებელს ელექტრონული საფულის მართვის პანელთან წვდომა შეუძლია პროვაიდერის ვებ-გვერდიდან. პროვაიდერმა შესაძლოა შეიმუშავოს მობილური აპლიკაცია, რომლითაც მომხმარებელი ასევე შეძლებს თავის ელექტრონულ საფულესთან წვდომასა და შესაბამისი ფუნქციონალის გამოყენებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.2. მომხმარებელს პლასტიკური ბარათის მეშვეობით შეუძლია გაანაღდოს ელექტრონული საფულის ბალანსზე არსებული თანხები საქართველოს ტერიტორიაზე განთავსებული ცალკეული კომერციული ბანკების ATM ბანკომატებიდან;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.3. ელექტრონული საფულის ბალანსზე არსებული თანხების ფარგლებში, პლასტიკური ბარათისმეშვეობით მომხმარებელს შეუძლია განახორციელოს საქონლის ან მომსახურების ღირებულების გადახდა სავაჭრო ობიექტ(ებ)ში, მიიღოს Cashback &ndash;ი (თანხის ნაწილის საგადახდო ბარათზე დაბრუნება) საგადახდო ბარათით განხორციელებული ოპერაციიდან;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.4. ელქტრონული კომერციისათვის საგადახდო ბარათების (რომელი საგადახდო ბარათებისთვისაც დასაშვებია ელექტრონული კომერციით სარგებლობა) გამოყენების დროს შეიძლება ამოქმედდეს VISA და MasterCard-ის &bdquo;3დ უსაფრთხოების &ldquo;სერვისი (3D secure);</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.5. პროვაიდერის ვებ-გვერდზე განთავსებულია ინფორმაცია იმ კომერციული ბანკების შესახებ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >რომელთა ATM ბანკომატების თუ POS ტერმინალების მეშვეობით მომხმარებელი შეძლებს მომსახურების მიღებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.6. საგადახდო ბარათი ძალაშია იმ თვის ბოლომდე, რომელიც მითითებულია საგადახდო ბარათზე. მომხმარებლის პლასტიკური ბარათის მეშვეობით თანხის განაღდება ან/და ხარჯვა შესაძლოა დაექვემდებაროს გარკვეულ ლიმიტებს, რომლის თაობაზე ინფორმაცია ხელმისაწვდომი იქნება პროვაიდერის ვებ-გვერდზე;</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>6.ბარათის დაბლოკვა/შეჩერება</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.1. ენბისის უფლება აქვს ნებისმიერ დროს დაბლოკოს/შეაჩეროს ელექტრონული საფულე დამომხმარებლის პლასტიკური ბარათით სარგებლობა, თუ:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.1.1. გონივრული ეჭვის საფუძველზე ჩათვლის, რომ მომხმარებელი საგადახდო მომსახურებასა</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >და მომხმარებლის პლასტიკური ბარათით სარგებლობას ახორციელებს უკანონო შემოსავლისლეგალიზაციის მიზნით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.1.2. აღმოაჩენს, რომ ხელშეკრულების გაფორმებამდე არასათანადოდ განხორცდიელდა მომხმარებლის იდენტიფიკაცია;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.1.3. აღმოაჩენს, რომ მომხმარებელი ცდილობს გაანაღდოს ან გახარჯოს ელექტრონული საფულის ანგარიშზე სისტემური ხარვეზის შედეგად გაჩენილი არასანქცირებული თანხა, რომელიც მას არ ეკუთვნის;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.1.4. მომხმარებლის ელექტრონული საფულის ანგარიში და მომხმარებლის პლსატიკური ბარათი არააქტიურია 1 (ერთი) წლის განმავლობაში.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.2. ენბისის უფლებამოსილია საგადახდო ბარათით სარგებლობისათვის წვდომის კოდების ერთხელ ან მრავალჯერადად არასწორად გამოყენების შემთხვევაში, დაბლოკოს საგადახდო ბარათი.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>7. ბარათთან დაკავშირებული მხარეთა ვალდებულებები</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.1. მომხმარებელი ვალდებულია:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.1.1. გამოიყენოს მომხმარებლის პლასტიკური ბარათი ამ ხელშეკრულებით დადგენილი პირობების შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.1.2 დაიცვას მისთვის გაცემული მომხმარებლის პლასტიკური ბარათის უსაფრთხოების ზომები</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >და უზრუნველყოს ამ ინსტრუმენტის პერსონიფიცირებული საშუალებების დაცვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.1.3. პლასტიკური ბარათის დაკარგვის, მოპარვის, უკანონო მითვისების ან უკანონო გამოყენების ფაქტის აღმოჩენის შემთხვევაში მისი აღმოჩენიდან უმოკლეს ვადაში შეატყობინოს ამის თაობაზე პროვაიდერს ცხელ ხაზზე (რაც მითითებულია პროვაიდერის ვებ-გვერდზე).</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2. ენბისი ვალდებულია:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2.1. მიიღოს ყველა შესაძლო ზომა მომხმარებლის პლასტიკური ბარათის დაცულობისა და მისი</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >არამართლზომიერი გამოყენების თავიდან აცილების უზრუნველსაყოფად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2.2. არ გახადოს მომხმარებლის პლასტიკური ბარათის პერსონიფიცირებული უსაფრთხოების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მახასიათებლები და საშუალებები ხელმისაწვდომი სხვა პირებისათვის, გარდა თავად ამ მომხმარებლისა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2.3. ნათლად გააცნოს მომხმარებელს მომხმარებლის პლასტიკური ბარათის უსაფრთხოების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მოთხოვნები;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2.4. უზრუნველყოს მომხმარებლისგან ხელშეკრულების 7.1.3. პუნქტით განსაზღვრული შეტყობინების ნებისმიერ დროს მიღება;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2.5. მომხმარებლისგან 7.1.3. პუნქტით განსაზღვრული შეტყობინების მიღებისთანავე, დაუყონებლივ უზრუნველყოს მომხმარებლის პლასტიკური ბარათის შემდგომი გამოყენების აღკვეთა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.3. პროვაიდერი საკუთარ თავზე იღებს მომხმარებლის პლასტიკური ბარათის ან/და მისიპერსონიფიცირებული უსაფრთხოების მახასიათებლებისა და საშუალებების გაგზავნასთან დაკავშირებულ ყველა რისკს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.4. საგადახდო ბარათს ენბისი გადასცემს მომხმარებელს სარგებლობაში (დალუქულ კონვერტშიმოთავსებულ, ან/და განაცხადში დაფიქსირებული მობილური ტელეფონის ნომერზე მოკლე ტექსტური შეტყობინების მეშვეობით, ან/და ენბისის მიერ განსაზღვრული სხვა ელექტრონული ფორმით გაგზავნილ წვდომის კოდთან (PIN-კოდი) ერთად) ელექტრონული საფულის ოპერაციების საწარმოებლად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.5. ენბისი პასუხს არ აგებს მის მიერ შეთავაზებულ უსაფრთხოების ზომაზე მომხმარებლის მიერ უარის თქმის შემთხვევაში დამდგარი შედეგისთვის. ასეთ შემთხვევაში უსაფრთხოების ზომაზე</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >უარის თქმის შედეგად, დამდგარი ზიანისთვის პასუხისმგებელია მომხმარებელი;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.6. მხარეები ვალდებულნი არიან, ამ მუხლით განსაზღვრული ვალდებულებების განზრახ ანდაუდევრობით შეუსრულებლობით გამოწვეული ზიანი აანაზღაურონ საქართველოს მოქმედი</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >კანონმდებლობის შესაბამისად.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>8.არაავტორიზებული ან არასწორად შესრულებული ოპერაციები</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >8.1. არაავტორიზებულ ან არასწორად შესრულებულ ოპერაციებთან დაკავშირებული წესები განისაზღვრება საქართველოს კანონით &ldquo;საგადახდო სისტემისა და საგადახდო მომსახურების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >შესახებ&rdquo;.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>9. საკომისიო და ლიმიტები</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >9.1. ინფორმაცია ყველა სახის საკომისიოს და ლიმიტის შესახებ, რომელიც დადგენილია მომსახურების მიღებისთვის ან მასთან დაკავშირებით, განთავსებულია პროვაიდერის ვებგვერდზე.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>0. პერსონალური მონაცემების და კონფიდენციალურობის დაცვა</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.1. მომხმარებელი, შეთანხმების საფუძველზე ნებას რთავს ენბისის, რომ გამოიყენოს ნებისმიერი მის მიერ მოწოდებული ინფორმაცია, ამ ხელშეკრულებისა და ინფორმაციის დაცვის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პოლიტიკის შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.2. მომხმარებელი ადასტურებს, რომ ენბისის აქვს უფლება შეინახოს მომხმარებლის მიერ მოწოდებული ინფორმაცია ტრანზაქციების ისტორიის შექმნის და ვებ-გვერდის ფუნქციონირების სხვა მიზნებისთვის;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.3. ენბისის უფლება აქვს დაამუშაოს მომხმარებლის პერსონალური მონაცემები პერსონალურ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მონაცემთა დაცვის შესახებ საქართველოს კანონის მე-5 მუხლის &bdquo;ა&ldquo; , &bdquo;გ&ldquo; , &bdquo;თ&ldquo; პუნქტების და სხვა</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სამართლებრივი აქტების შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.4. ენბისი მომხმარებლის იდენტიფიკაცია/ვერიფიკაციის მიზნით აგროვებს ორი სახის ინფორმაციას - პირად და არაპირად მაიდენტიფიცირებელ ინფორმაციას:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ა) პირადი მაიდენტიფიცირებელი ინფორმაციის მეშვეობით ხდება კონკრეტული მომხმარებლის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >იდენტიფიკაცია: სახელი და გვარი, პირადი ნომერი, ელექტრონული ფოსტის მისამართი, სქესი</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დაბადების თარიღი, ბარათის ნომერი, ვადის გასვლის თარიღი, ავთენტურობის კოდი და სხვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბ) არაპირადი მაიდენტიფიცირებელი ინფორმაცია კი არ ახდენს კონკრეტული მომხმარებლისიდენტიფიკაციას და შეიძლება მოიცავდეს: ბრაუზერის სახეობას, &bdquo;IP &ldquo;მისამართს და სხვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.5. ენბისის უფლება აქვს მომხმარებლის მიერ ვებგვერდზე მოწოდებული ინფორმაცია</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გაუმჟღავნოს და გადასცეს: აფილირებულ ნებისმიერ პირს და მის უფლებამოსილ წარმომადგენელს, ნებისმიერ სხვა პირს ან ორგანიზაციას მომხმარებლის თანხმობით და შესაბამის პირს ან ორგანოს კანონმდებლობით გათვალისწინებულ შემთხვევაში ინფორმაციის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გაცემის უფლების ან ვალდებულების არსებობისას, როგორიცაა მაგალითად სასამართლო, არბიტრაჟი და სხვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.6. ენბისის მიერ მომხმარებლის ინფორმაციის (პერსონალური მონაცემების) დამუშავება შესაძლებელია განხორციელდეს სხვადასხვა მიზნით, მათ შორის, შეზღუდვის გარეშე:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.6.1. მომსახურების სრულად და ჯეროვნად განხორციელებისთვის;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.6.2. უსაფრთხოების უზრუნველსაყოფად, აგრეთვე თაღლითობის, გაყალბების ან გაყალბების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მცდელობის, ფულის გათეთრების ან სხვა დანაშაულის გამოსავლენად ან პრევენციისთვის;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.6.3. მარკეტინგული მიზნით, რაც მოიაზრებს ენბისის ან/და მისი პარტნიორი ორგანიზაციების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >(მათ შორის, შპს ლიდერბეთის) მიერ სხვადასხვა პროდუქტის/მომსახურების პერიოდულ შეთავაზებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.7. თუ მომხმარებელი თვლის, რომ მის შესახებ ენბისიში არსებული ინფორმაცია არ შეესაბამებასინამდვილეს ან არ არის სრული, იგი ვალდებულია, დაუყოვნებლივ წერილობით ან/და ცხელი ხაზის საშუალებით აცნობოს ენბისის აღნიშნულის შესახებ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.8. მომხმარებელი უფლებამოსილებას ანიჭებს ენბისის და აცხადებს თანხმობას, რომ პერსონალური მონაცემების გარდა, დაამუშავოს მომხმარებლის ფოტოსურათი, გამოსახულება</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >და საჭიროების შემთხვევაში, სხვა ბიომეტრიული მონაცემები. ამასთანავე, განახორციელოსმომხმარებლის ფოტოსურათის გამოთხოვა/მოპოვება სახელმწიფო სერვისების განვითარების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სააგენტოდან მის მიერ წარმოდგენილი ფოტოსურათების შემოწმება-შედარებისათვის, შემდგომში საიდენტიფიკაციო დოკუმენტის გაყალბების ან და გაყალბების მცდელობის თავიდან აცილების მიზნით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.9. ამასთანავე, მომხმარებელი თანახმაა, რომ ენბისიმ უშუალოდ ან უფლებამოსილი პირის მეშვეობით დისტანციურად განახორციელოს მისი ფოტო/ვიდეო გადაღება და შეამოწმოს მათი</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სიზუსტე შემდგომში გაყალბების თავიდან აცილების და იდენტობის დადგენის მიზნებისთვის.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბიომეტრიული მონაცემების წაშლა ხორციელდება იდენტიფიკაციის პროცესის დასრულებისთანავე;</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>1. მხარეთა პასუხისმგებლობა</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >11.1. ენბისის ეკისრება პასუხიმგებლობა და ვალდებულება, მხოლოდ იმ შემთხვევაში, თუ ეს გამოწვეულია მისი განზრახი ქმედებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >11.2. მომხმარებელი პასუხს აგებს ენბისის წინაშე ხელშეკრულებაში მოცემული ინფორმაციის სიზუსტეზე და სისრულეზე, ვებგ.ვერდზე დარეგისტრირებისას მითითებული ინფორმაციის სიზუსტეზე, ყველა იმ ინფორმაციის სიზუსტეზე, რომელიც მან პროვაიდერს მიაწოდა ხელშეკრულების დადებისას, დადებამდე ან დადების შემდგომ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >11.3. ფორს-მაჟორის მოქმედების განმავლობაში არცერთი მხარე არ აგებს პასუხს ხელშეკრულებით ნაკისრი ვალდებულებების სრულად ან ნაწილობრივ შეუსრულებლობისათვის ან არაჯეროვნად შესრულებისათვის.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>2. კომუნიკაცია მხარეთა შორის</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >12.1. მომხმარებელს შეუძლია ენბისისთან კომუნიკაცია მოახდინოს ვებ-გვერდზე მითითებულ ტელეფონის ნომერით (ცხელ ხაზზე) ან ელექტრონული ფოსტის მეშვეობით, ასევე გაგზავნოს წერილობითი შეტყობინება ფოსტით ენბისის იურიდიულ მისამართზე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >12.2. ენბისის მიერ მომხმარებლისთვის მის მიერ მითითებულ მობილურის ნომერზე (მოკლე ტექსტური შეტყობინების), ან/და ენბისის მიერ შემდგომში განსაზღვრული კომუნიკაციის სხვა საშუალებებით გაგზავნილი შეტყობინება ჩაბარებულად ითვლება შეტყობინების გაგზავნის დღეს, ხოლო ენბისის ვებ-გვერდზე ინფორმაციის განთავსებისას - ინფორმაციის განთავსების დღეს.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>3. ხელშეკრულების ხანგრძლივობა და შეწყვეტა</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.1. ეს ხელშეკრულება მოქმედებს რომელიმე მხარის მიერ მისი შეწყვეტის მომენტამდე პირობებით განსაზღვრული წესის შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.2. წინამდებარე პირობები ძალაში შევა მას შემდეგ, რაც მომხმარებელი შეავსებს შესაბამის განაცხადს, ხელს მოაწერს/დაადასტურებს ელექტრონული ფორმის თანხმობით და ენბისის განაცხადებს თანხმობას მომსახურების გაწევაზე. ენბისის თანხმობა გამოიხატება მომხმარებლისთვის მომსახურების გაწევის დაწყებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.3. ხელშეკრულება შესაძლოა შეწყდეს ამ ხელშეკრულებით განსაზღვრული დებულებების შესაბამისად. ხელშეკრულების შეწყვეტა ავტომატურად იწვევს მომხმარებლის სახელზე გახსნილი ელექტრონული საფულის ანგარიშის გაუქმებასა და პროვაიდერის ყველა მომსახურების შეწყვეტას.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.4. ხელშეკრულება შესაძლოა შეწყდეს:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.4.1. მომხმარებლის მიერ, ნებისმიერ დროს, პროვაიდერისათვის წერილობითი შეტყობინების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გაგზავნის გზით, რა შემთხვევაშიც პროვაიდერს უფლება აქვს, მოსთხოვოს მომხმარებელს გარკვეული დოკუმენტაციის წარმოდგენა ხელშეკრულების შეწყვეტის გაფორმებასა და დასრულებასთან დაკავშირებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.4.2. ენბისის მიერ, ნებისმიერ დროს, მომხმარებლისათვის წინასწარი წერილობითი შეტყობინების გაგზავნის გზით, რა შემთხვევაშიც შეწყვეტის შესახებ შეტყობინება მომხმარებელს უნდა გაეგზავნოს მინიმუმ 1 (ერთი) თვით ადრე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.4.3. ენბისის მიერ, ნებისმიერ დროს, მომხმარებლისათვის წინასწარი წერილობითი შეტყობინების გაგზავნის გზით, თუ მომხმარებელი არღვევს წინამდებარე ხელშეკრულებით განსაზღვრულ რომელიმე ვალდებულებას ან პირობას და არ აღმოფხვრის დარღვევას გაფრთხილების მიუხედავად.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>4. მარეგულირებელი კანონმდებლობა და დავების გადაწყვეტა</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.1. მომხმარებელი ვალდებულია: ტრანზაქციის გასაჩივრების შემთხვევაში, მიმართოს პროვაიდერს სადავო ტრანზაქციის განხორციელებიდან 40 დღის ვადაში და წარუდგინოს გასაჩივრებულ ტრანზაქციასთან დაკავშირებული ინფორმაცია/დოკუმენტები, რაც მოიცავს, მაგრამ არ შემოიფარგლება შემდეგით:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ტრანზაქციის თარიღი, ტრანზაქციის ოდენობა, ბარათის მონაცემები, ინფორმაცია სავაჭრო ობიექტზე (რელევანტურობის შემთხვევაში), შეძენილი ნივთი და/ ან მომსახურება (რელევანტურობის შემთხვევაში), ქვითარი/გადახდის დამადასტურებელი დოკუმენტი (რელევანტურობის შემთხვევაში) და ნებისმიერი სხვა დამატებითი ინფორამცია და/ან დოკუმენტი, რასაც პროვაიდერი, საკუთარი შეხედულებისამებრ, საჭიროდ მიიჩნევს საკითხის შესწავლისა და განხილვის პროცესში.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.2. პროვაიდერი ვალდებულია განიხილოს მომხმარებლის საჩივარი განხორციელებულ ოპერაციასთან დაკვაშირებით და მიიღოს გადაწყვეტილება და გააცნოს იგი მომხმარებელს საჩივრის მიღებიდან არაუგვიანეს 15 (თხუთმეტი) სამუშაო დღისა. საჩივრის მიღების დღედ ითვლება მომხმარებლის მიერ პროვაიდერისთვის საჩივრის წარდგენის სამუშაო დღე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.2.1. იმ შემთხვევაში, თუ პროვაიდერისაგან დამოუკიდებელი მიზეზების გამო მითითებულ 15</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >(თხუთმეტი) დღიან ვადაში ვერ ხერხდება საჩივრის განხილვა და მასზე გადაწყვეტილების მიღება, პროვაიდერი ვალდებულია ამის შესახებ წერილობითი შეტყობინებით აცნობოს მომხმარებელს სადაც იქნება მითითება დაგვიანების დასაბუთებული მიზეზისა და საჩივრის განხილვა/ გადაწყვეტისთვის ვადის გაგრძელების შესახებ. საჩივარზე გადაწყვეტილების მიღებისა და საბარათე ინსტრუმენტის მფლობელისთვის გაცნობის ვადა არ უნდა აღემატებოდეს საჩივრის მიღებიდან 55 სამუშაო დღეს.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.3. მომხმარებლის მიერ ოპერაციების გასაჩივრების თითოეული შემთხვევა განიხილება ინდივიდუალურად შესაბამისი საგადახდო სისტემების მიერ დადგენილი წესების, ადგილობრივ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ფინანსურ სექტორში დამკვიდრებული პრაქტიკის და მოქმედი კანონმდებლობის გათვალისწინებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.4. საჩივრების და პრეტენზიების მიღება ხორციელდება ყოველ სამუშაო დღეს 10:00 საათიდან 18:00 საათამდე, შემდეგი საშუალებებით: ცხელ ხაზთან დაკავშირებით, ელ-ფოსტით: <a href="mailto:info@nbc.ge" style="color:#954f72; text-decoration:underline">info@nbc.ge</a> &nbsp;ან წერილობითი ფორმით.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.5 თუ მხარეთა შორის წარმოიშვა დავა ან უთანხმოება წინამდებარე ხელშეკრულებასთან დაკავშირებით, მხარეები შეეცდებიან დავა გადაჭრან მოლაპარაკებების გზით, შეუთანხმებლობის შემთხვევაში, ნებისმიერ მხარეს უფლება აქვს მიმართოს თბილისის საქალაქო სასამართლოს.</span></span></span></p>
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.5.1. მომხმარებელი ასევე უფლებამოსილია, ენბისისთვის საჩივრით მიმართვის დღიდან არაუგვიანეს 6 თვის განმავლობაში, საგადახდო მომსახურების პროვაიდერის წინააღმდეგ საჩივრი წარადგინოს საქართველოს ეროვნულ ბანკთან არსებულ დავების განმხილველ კომისიას. მომხმარებლის მიერ ენბისისთვის წარდგენილი საჩივრის დადგენილ ვადაში დაუკმაყოფილებლობის (მათ შორის, პასუხის გაუცემლობის) ან ნაწილობრივ დაკმაყოფილების შემთხვევაში, მოთხოვნა არ უნდა აღემატებოდეს 50 000 ლარს ან 50 000 ლარის ეკვივალენტს უცხოურ ვალუტაში. კომისია დავას განიხილავს და გადაწყვეტილებას იღებს საჩივრის წარმოებაში მიღებიდან 90 კალენდარული დღის ვადაში (შესაძლოა გახანგრძლივდეს 30 კალენდარული დღით). კომისია დავას განიხილავს უსასყიდლოდ.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>5. დასკვნითი დებულებები</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.1. მომხმარებელი აცხადებს და იძლევა გარანტიას, რომ:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.1.1. ხელშეკრულების დასადებად წარმოდგენილია ზუსტი და უტყუარი ინფორმაცია;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.1.2. ენბისის მომსახურების მისაღებად ინფორმაციის მოწოდება ხდება მომხმარებლის სურვილით და ამისათვის მას გააჩნია კანონმდებლობით, ან ხელშეკრულებით გათვალისწინებული უფლება/ნებართვა.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.2. მომხმარებლის მიერ ელექტრონული ფორმით გაკეთებულ თანხმობას (რომელიც მკაფიოდ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გამოხატავს მომხმარებლის ნებას) აქვს მატერიალური დოკუმენტის თანაბარი იურიდიული ძალა (მატერიალურ დოკუმენტზე პირადი ხელმოწერის თანაბარი იურიდიული ძალა);</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.3. ამ ხელშეკრულებაში ცვლილებების შეტანა შესაძლებელია ვებ-გვერდზე <a href="http://www.nbc.ge/" style="color:#954f72; text-decoration:underline">www.nbc.ge</a> , <a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline">www.leadercard.ge</a> - ზე გამოქვეყნების გზით, რომელიც ძალაში შედის მისი გამოქვეყნებიდან 1(ერთი) კალენდარული თვის შემდეგ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.4. ხელშეკრულების პირობებში განხორციელებული ნებისმიერი ცვლილება ჩაითვლებამომხმარებელთან შეთანხმებულად, თუ ცვლილების ძალაში შესვლამდე არ განაცხადებს უარს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დაგეგმილი ცვლილების თაობაზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.5. ცვლილებების ამოქმედებამდე, მომხმარებელს უფლება აქვს დაუყოვნებლივ შეწყვიტოსხელშეკრულება ყოველგვარი საკომისიოს, ხარჯის და პირგასამტეხლოს გადახდის გარეშე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.6. მომხმარებელი ვალდებულია ენბისის მოთხოვნის შემთხვევაში და განსაზღვრულ ვადაში</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მიაწოდოს მოთხოვნილი ნებისმიერი დამატებითი ინფორმაცია ან/და დოკუმენტაცია;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.7. მხარეები თანხმდებიან, რომ ხელშეკრულების შესრულების ადგილად მიიჩნევა ენბისის იურიდიული მისამართი;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.8. მომხმარებელს უფლება აქვს, ხელშეკრულების მოქმედების მთელ პერიოდში, მოსთხოვოს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პროვაიდერს და მიიღოს ნებისმიერი ინფორმაცია, რომლის მიღების უფლებაც გააჩნია &ldquo;საგადახდო მომსახურების განხორციელებისას მომხმარებლისთვის აუცილებელი ინფორმაციის მიწოდების წესის დამტკიცების შესახებ&rdquo; საქართველოს ეროვნული ბანკის პრეზიდენტის 2016 წლის 6 იანვრის N1/04 ბრძანებით.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>შპს &bdquo;ენბისი &ldquo;</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >საიდენტიფიკაციო კოდი: 405150063</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სარეგისტრაციო ნომერი: 0053-9004</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >იურიდიული მისამართი: ქ. თბილისი, ონიაშვილის ქ. N1, კომერციული ფართი N5</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ფაქტობრივი მისამართი: ქ. თბილისი, ონიაშვილის ქ. N1, სართული 3 ბინა N7</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ელექტრონული ფოსტა: <a href="mailto:info@nbc.ge" style="color:#954f72; text-decoration:underline">info@nbc.ge</a> ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ეს ხელშეკრულება დადებულია და რეგულირდება ქართულ ენაზე.</span></span></span></p>

        `,
        Eng:`
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>გთხოვთ, საგადახდო მომსახურებით სარგებლობამდე ხელშეკრულების სავალდებულო</strong></span></span></span></p>
    
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>ხასიათის გათვალისწინებით ყურადღებით გაეცნოთ წინამდებარე პირობებს!</strong></span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>&bdquo;ენ ბი სი&ldquo;- ს საგადახდო მომსახურების ხელშეკრულება</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>+995 32 2 193 293 | </strong><a href="http://www.nbc.ge/" style="color:#954f72; text-decoration:underline"><strong>www.nbc.ge</strong></a><strong> , </strong><a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline"><strong>www.leadercard.ge</strong></a><strong> / | info@nbc.ge</strong></span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1. ზოგადი დებულებანი</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.1. წინამდებარე ხელშეკრულება მოიცავს მომხმარებლების მიერ ენბისის ელექტრონული საგადახდო მომსახურებით სარგებლობის სტანდარტულ და სპეციალურ პირობებს/წესებს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.2. კონკრეტული საგადახდო მომსახურების მიღებისას შესაძლოა მოქმედებდეს სპეციალური პირობები/წესები, რომლის შესახებაც მომხმარებელი ინფორმაციას იღებს კონკრეტული მომსახურების მიღების პროცესში;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.3. ხელშეკრულების პირობები ძალაში შედის მას შემდეგ, რაც მომხმარებელი განაცხადით ან/და კანონმდებლობით ნებადართული და ენბისისთვის მისაღები სხვა (დისტანციური მომსახურება) ფორმით, დაადასტურებს თანხმობას პირობებზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.4. წინამდებარე საგადახდო მომსახურებით სარგებლობის სტანდარტული და სპეციალური პირობები/წესები, მათ შორის ნებისმიერი დანართი მომხმარებლის განაცხადთან ერთად წარმოადგენს ენბისისა და მომხმარებელს შორის გაფორმებულ ხელშეკრულებას.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.5. ენბისი უფლებამოსილია საკუთარი შეხედულებისამებრ არ დაუშვას, ან სხვა სახით შევზღუდოს კონკრეტული მომხმარებლისთვის მომსახურების მიღება ასევე, იტოვებს უფლებას,</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >უარი თქვას კონკრეტული მომხმარებლის შემთხვევაში, კონკრეტულ გადახდასთან დაკავშირებით, ეროვნული ბანკის მიერ საგადახდო მომსახურების პროვაიდერისთვის დადგენილი სტანდარტების შესაბამისად.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.6. ვებ-გვერდზე რეგისტრაციისას ინფორმაციის მოწოდება ხდება მომხმარებლის სურვილით,</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >რაც ნიშნავს, რომ ენბისის აქვს უფლება შეინახოს მომხმარებლის მიერ მოწოდებული ინფორმაცია ტრანზაქციების, ისტორიის შექმნის და ვებ-გვერდის ფუნქციონირების სხვა მიზნებისთვის.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>2. ტერმინთა განმარტება</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >წინამდებარე ხელშეკრულებაში გამოყენებულ ტერმინებს, ამ &bdquo;ხელშეკრულების &ldquo;მიზნებისთვის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >აქვს შემდეგი მნიშვნელობა (თუ &bdquo;ხელშეკრულებით &ldquo;სხვა რამ არ არის განსაზღვრული, ან მისი კონტექსტიდან სხვა რამ არ გამომდინარეობს):</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.1. <strong>საგადახდო მომსახურების პროვაიდერი (პროვაიდერი)</strong> - შპს &bdquo;ენ ბი სი &ldquo;, საიდენტიფიკაციო</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ნომერი:405150063, საქართველოს ეროვნულ ბანკში რეგისტრაციის N 0053-9004, სათაო ოფისის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მისამართი: ონიაშვილის ქ. N1, კომერციული ფართი N5 თბილისი, საქართველო; ვებ-გვერდის მისამართი: <a href="http://www.nbc.ge/" style="color:#954f72; text-decoration:underline">www.nbc.ge</a> , <a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline">www.leadercard.ge</a> , ცხელი ხაზი: +995 32 2 193 293, მომხმარებელს უწევს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ელექტრონულ საგადახდო მომსახურებას &ldquo;საგადახდო სისტემისა და საგადახდო მომსახურების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >შესახებ&rdquo; საქართველოს კანონისა და სხვა ნორმატიული აქტებით განსაზღვრული პირობების შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.2. <strong>ავტორიზაცია</strong> - კანონმდებლობითა და ენბისის მიერ დადგენილი წესებისა და პროცედურების შესაბამისად მომხმარებლის მიერ გაცემული თანხმობა ოპერაციის განხორციელებაზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.3. <strong>ამონაწერი</strong> - ინფორმაცია მომხმარებლის მიერ ჩატარებული ოპერაციის შესახებ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.4. <strong>არაპერსონიფიცირებული საგადახდო ბარათი</strong> &ndash; Visa/Mastercard ან/და სხვა სისტემის საგადახდო ბარათი, რომელზეც არ არის ასახული ბარათის მფლობელის სახელი ან/და გვარი.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.5. <strong>განაცხადი </strong>- მომხმარებლის მიმართვა პროვაიდერს (ენბისის მიერ დადგენილი ფორმით</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მატერიალური დოკუმენტის, ელექტრონული ან სხვა მსგავსი საშუალებების გამოყენებით</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომხმარებლისთვის მიწოდებული ინფორმაცია, რომელსაც დადგენილი ფორმით ეთანხმება/არ ეთანხმება მომხმარებელი), რომ განაცხადშივე განსაზღვრული პირობების შესაბამისად, განახორციელოს ან/და შეაჩეროს/შეწყვიტოს საგადახდო მომსახურება;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.6. <strong>დავალება</strong> -მომხმარებლის მიერ საგადახდო მომსახურების ხელშეკრულების ფარგლებში</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >განხორციელებული ნებისმიერი დავალება ან/და მითითება, რომელსაც მომხმარებელი აძლევს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პროვაიდერს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.7. <strong>დისტანციური მომსახურება</strong> - ენბისის მიერ დადგენილი პროცედურების შესაბამისად, მოსახურების ცენტრში მიუსვლელად დისტანციურად მომსახურების მიღება, განაცხადის გაკეთება, სატელეფონო, SMS შეტყობინების დადასტურების, მომხმარებლის პროფილიდან და</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სხვადასხვა დისტანციური/ელექტრონული საკომუნიკაციო არხების საშუალებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.8. <strong>ელექტრონული საფულე</strong> - პირის უნიკალური ვირტუალური ანგარიში, რომელიც დაკავშირებულია პირის იდენტიფიცირებასთან. საფულეში განთავსებულია ელექტრონული</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ფული, რომელიც იმართება მომხმარებლის მიერ ენბისის დადგენილი წესების შესაბამისად და</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მოიცავს საფულის წვდომის რეკვიზიტებს, ავტორიზაციისთვის საჭირო რეკვიზიტებს, რომელიც</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ცნობილია მხოლოდ საფულის მფლობელისთვის და შეიცავს შემდეგ მონაცემებს: მომხმარებლის მიმდინარე ნაშთს, გადახდების ისტორიას და სხვა ინფორმაციას, რომელიც განახორციელა მომხმარებელმა საფულედან;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.9. <strong>ელექტრონული ფული</strong> - ენბისის მიერ მომხმარებლისგან გადახდის ოპერაციების განხორციელების მიზნით ფულადი სახსრების ექვივალენტური ღირებულება, რომელიც შენახულია საგადახდო ინსტრუმენტებზე და რომელიც აღიარებულია გადახდის საშუალებად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.10. <strong>ვებგვერდი</strong> - <a href="http://www.nbc.ge/" style="color:#954f72; text-decoration:underline">www.nbc.ge</a> , <a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline">www.leadercard.ge</a> საშუალებას აძლევს ენბისის მომხმარებლებს დისტანციურად შეავსონ განაცხადი ან/და მიიღონ სასურველი მომსახურება, სადაც განთავსებულია პროვაიდერის სათაო ოფისის მისამართი, ელექტრონული ფოსტის მისამართი, ტელეფონის ნომერი, მომსახურების ცენტრების მისამართები და სხვა ინფორმაცია, რაც მნიშვნელოვანია მხარეთა შორის სახელშეკრულებო ურთიერთობის პროცესში;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.11. <strong>კანონმდებლობა</strong> &ndash; საქართველოს მოქმედი საკანონმდებლო და კანონქვემდებარე ნორმატიული აქტები და საქართველოს ნორმატიულ აქტთა სისტემაში მოქცეული საერთაშორისო ხელშეკრულებები და შეთანხმებები;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.12.<strong> მომხმარებელი </strong>- სრულწლოვანი ქმედუნარიანი საქართველოს რეზიდენტი ფიზიკური პირი, რომელიც ეთანხმება წინამდებარე ხელშეკრულებით დადგენილ წესებსა და პირობებს,</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >აკმაყოფილებს კანონმდებლობით და ხელშეკრულებით გათვალისწინებულ მოთხოვნებს. ამასთანავე, რომლის იდენტიფიკაცია/ვერიფიკაცია ენბისიმ განახორციელა, უშუალოდ ან/და დისტანციური მომსახურების საშუალებით წარდგენილი განცხადების საფუძველზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.13.<strong> მომსახურება </strong>- ენბისი კისრულობს ვალდებულებას, ხელშეკრულების საფუძველზე გაუწიოს მომხმარებელს მომსახურება წარდგენილი განცხადების საფუძველზე, რეალურ ან/და</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დისტანციურ რეჟიმში ხელშეკრულებით გათვალისწინებული წესითა და პირობით საგადახდო</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომსახურება, რომელიც მოიცავს მომხმარებლის ელექტრონული საფულის გახსნასა და განკარგვას, ფულადი სახსრების ან/და მათი ეკვივალენტი ელექტრონული ფულის გატანას/გადარიცხვას, მომხმარებლის საგადახდო ბარათით მომსახურებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.14. <strong>მხარე/მხარეები</strong> - ენბისი და მომხმარებელი ერთობლივად ან ცალ-ცალკე, კონტექსტის შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.15. <strong>ოპერაცია</strong> - ენბისის(პროვაიდერის) მიერ მომხმარებლის დავალებით განხორციელებული</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ნებისმიერი სახის საგადახდო მომსახურება;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.16. <strong>პარტნიორი ორგანიზაცია</strong> - პარტნიორი ორგანიზაციების და პარტნიორების შესახებ მონაცემები განთავსებულია ვებგვერდზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.17. <strong>საგადახდო ბარათი (შემდგომში &ndash; ბარათი)</strong> &ndash; ენბისის მიერ გამოშვებული საგადახდო ინსტრუმენტი (პლასტიკური ბარათი), რომელიც დაკავშირებულია მომხმარებლის ელექტრონულ საფულესთან, რომლის საშუალებითაც ხდება მომსახურების საფასურის გადახდა, ნაღდი ფულის მიღება, ხელშეკრულებით გათვალისწინებული წესების/პირობების და</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >საქართველოს კანონმდებლობით ნებადართული სხვა ოპერაციების შესრულება;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.18. <strong>საკომისიო</strong> - ენბისის მიერ, მომხმარებლისთვის, ნებისმიერი საგადახდო მომსახურებით სარგებლობისათვის დაწესებული გადასახადი/მომსახურების საფასური, რომლის ოდენობა და</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გადახდის პირობები განისაზღვრება დანართით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.19. <strong>სამუშაო დღე</strong> - დღე, შაბათის, კვირის ასევე კანონმდებლობით განსაზღვრული უქმე დღეების გარდა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.20. <strong>ფორს-მაჟორი </strong>&ndash; ხელშეკრულების მხარეები თავისუფლდებიან პასუხისმგებლობისაგან მათმიერ ნაკისრი ვალდებულებების სრული ან ნაწილობრივი შეუსრულებლობისათვის დაუძლეველი ძალის მოქმედების შემთხვევაში, რომლის დროსაც შეუძლებელია ხელშეკრულებით გათვალისწინებული ვალდებულებების შესრულება. &bdquo;დაუძლეველი ძალის&ldquo;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ქვეშ იგულისხმება გარემოებები, რომლებიც არ არსებობდნენ ხელშეკრულების დადების დროს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >და რომელთა დადგომა, თუ ზემოქმედება მხარეებს არ შეეძლოთ თავიდან აეცილებინათ და გადაელახათ. დაუძლეველი ძალის გარემოებებს მიეკუთვნება: სტიქიური უბედურებები, აფეთქებები (ტერორისტული თუ დივერსიული აქტები), სახელმწიფო ორგანოების ისეთი აქტების მიღება, რაც არსებითად აფერხებს ხელშეკრულების მხარეთა მიერ ნაკისრი ვალდებულებების შესრულებას, ასევე ელექტროენერგიის მიწოდების შეწყვეტა, მოწყობილობების მწყობრიდან გამოსვლა, პროგრამული უზრუნველყოფის დაზიანება, სისტემური ან და ქსელის ისეთი ხარვეზი, დაზიანება, შეფერხება, შეზღუდვა რაც ლოკაციაზე შეუძლებელს ხდის მომსახურების გაწევას ან ენბისისთვის გადაულახავი და მისი კონტროლისაგან დამოუკიდებელი სხვა გარემოებები.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>3. მომსახურების მიღება</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.1.საგადახდო მომსახურების პროვაიდერის საზედამხედველო ორგანოს წარმოადგენს საქართველოს ეროვნული ბანკი, ვებ გვერდის მისამართი: <a href="http://www.nbg.ge/" style="color:#954f72; text-decoration:underline">www.nbg.ge</a> .</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.2. საზედამხეველო ორგანო არ არის პასუხისმგებელი, საგადახდო მომსახურების პროვაიდერის მიერ ნაკისრი ვალდებულებების არაჯეროვან შესრულებაზე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.3. ხელშეკრულების საფუძველზე ენბისი იღებს ვალდებულებას, გაუხსნას მომხმარებელს ელექტრონული საფულის ანგარიში და გაუწიოს მას აღნიშნულ ანგარიშთან დაკავშირებული საგადახდო მომსახურება, უზრუნველყოს ანგარიშზე ფულადი სახსრების ჩარიცხვა და მის შესრულებასთან დაკავშირებული ოპერაციები, გადახდები, საგადახდო ბარათის ან სხვა საგადახდო ინსტრუმენტის გამოყენებით, გადარიცხვის მუდმივი დავალების ჩათვლით, მომხმარებლის კუთვნილი თანხის ფარგლებში;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.4. ენბისი იღებს ვალდებულებას გაუწიოს მომხმარებელს საგადახდო მომსახურება, რის სანაცვლოდაც მომხმარებელი ვალდებულია შეასრულოს ხელშეკრულებით განსაზღვრული პირობები და გადაიხადოს საკომისიო (ასეთის არსებობის შემთხვევაში);</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.5. ენბისი საგადახდო მომსახურებას ახორციელებს მხოლოდ საქართველოს ტერიტორიაზე. ელექტრონულ საფულეს გააჩნია ელექტრონული ფულის ანგარიში ერთ ვალუტაში და ყველა შემთხვევაში - ლარში;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.6. მომხმარებელი იღებს ვალდებულებას, პერიოდულად გაეცნოს ვებგვერდზე განთავსებულ ინფორმაციას ცვლილებების და დამატებების შესახებ. მომხმარებელი უფლებამოსილია მოიძიოს ინფორმაცია ხელშეკრულების პირობების შესახებ ხელშეკრულების მოქმედების განმავლობაში, ენბისის (პროვაიდერის) ვებ გვერდზე &bdquo;პირადი პროფილი &ldquo;-ს საშუალებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.7. ხელშეკრულებით განსაზღვრული საგადახდო პროდუქტით მომსახურების მიღების ან/და სარგებლობის შეწყვეტის მიზნით, მომხმარებელი უფლებამოსილია მიმართოს განაცხადით ენბისის უშუალოდ მომსახურების ცენტრში მისვლით (დისტანციური მომსახურების არხების საშუალებით ასეთის არსებობის შემთხვევაში). ენბისის მიერ განაცხადის განხილვა მოხდება იმ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პირობით, თუ მომხმარებლის მიერ განაცხადის შევსების დროს დაკმაყოფილებული იქნება ხელშეკრულებით გათვალისწინებული ყველა პირობა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.8. ენბისი დავალებას მიიღებს მხოლოდ იმ შემთხვევაში, თუ მისი გაცემა მოხდა მომხმარებლის მიერ რეგისტრირებული იდენტიფიკატორების გამოყენების მეშვეობით და უფლებამოსილია, შეამოწმოს დავალების ნამდვილობა, ასევე მომხმარებლისგან მოითხოვოს დამატებითი ინფორმაციის/დოკუმენტის წარდგენა, თუ ეს აუცილებელია დავალების შესასრულებლად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.9. საგადახდო დავალების მიღების დროდ ითვლება მომენტი, როდესაც ენბისი მიიღებს საგადახდო დავალებას. იმ შემთხვევაში, თუ საგადახდო დავალება ინიცირებულია არასამუშაო</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დღეს, იგი მიღებულად ჩაითვლება მომდევნო სამუშაო დღეს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.10. ენბისი საგადახდო დავალების შესრულებას უზრუნველყოფს საგადახდო დავალების წარდგენიდან 2 (ორი) სამუშაო დღის ვადაში, გარდა კანონმდებლობით გათვალისწინებული შემთხვევებისა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.11. გადარიცხვის ოპერაციის შესახებ დავალება, მისი სათანადოდ შესრულებისათვის, აუცილებელია მოიცავდეს საქართველოს ეროვნული ბანკის პრეზიდენტის 2015 წლის 22 იანვარის №8/04 გადახდის ოპერაციის შესრულების წესის დამტკიცების შესახებ ბრძანებით დადგენილ რეკვიზიტებს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12. ენბისი უფლებამოსილია არ მიიღოს შესასრულებლად დავალება ან/და უარი განაცხადოს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დავალების შესრულებაზე:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.1. თუ დავალება არ შეესაბამება კანონმდებლობით დადგენილ მოთხოვნებს ან/და ენბისის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დადგენილ წესებსა და პროცედურებს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.2. თუ დავალება შეიცავს არაზუსტ ინფორმაციას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.3. თუ დავალების შესრულებისათვის ანგარიშზე არსებული თანხა, საკომისიოს ჩათვლით, არ არის საკმარისი;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.4. ოპერაცია გადააჭარბებს ენბისის მიერ დაწესებულ ლიმიტს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.5. არსებობს ეჭვი, რომ მომხმარებლის მხრიდან ადგილი აქვს თაღლითობას ან/და უკანონო</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ქმედებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.6. თუ არსებობს სხვა ისეთი ხარვეზი/გარემოება, რომელიც შეუძლებელს ხდის დავალების შესრულებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.13. მომხმარებლის მოთხოვნის საფუძველზე ენბისი იღებს ყველა ზომას, რათა დროულად, გონივრულ ვადაში, შეაჩეროს ან/და გააუქმოს ნებისმიერი დავალების განხორციელება, თუ კანონმდებლობით სხვა რამ არ არის გათვალისწინებული. ენბისი არ კისრუბლობს პასუხისმგებლობას აღნიშნული მოთხოვნის დაუკმაყოფილებლობის შემთხვევაში, თუ მას არ მიუღია შესაბამისი შეტყობინება და ამგვარი შეჩერება ან გაუქმება შეუძლებელია;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.14. მიღებული დავალების შესრულებისათვის ენბისი უფლებამოსილია მომხმარებელს მოსთხოვოს დავალების ერთჯერადი კოდით დადასტურება (ე.წ. OTP ავტორიზაცია) იმ ოპერაციებისათვის, რომლებიც კანონმდებლობით გათვალისწინებული წესით საჭიროებს დამატებით დადასტურებას. მომხმარებელი ერთჯერად კოდს იღებს მის მიერ განაცხადში დაფიქსირებული მობილური ტელეფონის ნომრის ან/და მხარეთა შორის შეთანხმებული სხვა მოწყობილობის გამოყენებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.15. იმ შემთხვევაში, თუ ოპერაციის შესრულებისას ნებისმიერი მიზეზით წარმოიშვა გადახარჯვა, მომხმარებელი ვალდებულია, არაუგვიანეს მეორე დღისა უზრუნველყოს გადახარჯვის აღმოფხვრა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.16. მომხმარებელი ვალდებულია გადაიხადოს საკომისიო (ასეთის არსებობის შემთხვევაში) გადახდის დროისათვის არსებული ენბისის მიერ დადგენილი ტარიფების შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.17. მომხმარებელი სრულად აგებს პასუხს მის მიერ ენბისისთვის საგადახდო დავალებით მოწოდებულ რეკვიზიტების სისწორესა და სისრულეზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.18. ენბისი უფლებამოსილია, მის მიერ დადგენილი ტარიფების შესაბამისად მომხმარებლის ელექტრონული საფულიდან, მომხმარებლის დამატებითი თანხმობის გარეშე ჩამოჭრას/დაბლოკოს საკომისიო და მოახდინოს მოსახურებით გათვალისწინებული საკომისიოს დაფარვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.19. მომსახურების მისაღებად მომხმარებელი ვალდებულია:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.19.1. ყურადღებით გაეცნოს ხელშეკრულებას (წინამდებარე პირობებს);</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.19.2. შეავსოს ან/და ხელი მოაწეროს (მათ შორის ელექტრონულად) განაცხადს (ასეთის არსებობის შემთხვევაში) ან/და სხვა სახის დოკუმენტს, რომელიც საჭიროა შესაბამისი მომსახურების მისაღებად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.19.3. ენბისისთვის წარდგენილ/გაგზავნილ უფლებამოსილების დამადასტურებელ დოკუმენტსა ან/და ინფორმაციაში განხოციელებული ნებისმიერი სახის ცვლილების შესახებ (მათ შორის, ტელეფონის ნომრის) შეატყობინოს ენბისის აღნიშნული გარემოებების დადგომიდან უმოკლეს ვადაში, წინააღმდეგ შემთხვევაში ენბისი არ იქნება პასუხისმგებელი ცვლილების შეტყობინების მიღებამდე განხორციელებული ქმედებების შედეგად დამდგარ ზიანზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.20. დეტალების შესახებ, მომხმარებელს ინფორმაციის მიღება შეუძლია როგორც ადგილზე ენბისის მომსახურების ცენტრებში, ასევე ცხელი ხაზისა და დისტანციური მომსახურების საშუალებით. ოპერაციის ტიპიდან გამომდინარე, მომხმარებელზე შეიძლება გაიცეს ამონაწერი,</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ცნობა, ქვითარი ან/და სხვა დოკუმენტი ენბისის მიერ განსაზღვრული ფორმით და პირობებით.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.21. მომხმარებელი პასუხისმგებელია მოპარული ან დაკარგული საგადახდო ინსტრუმენტით ან უკანონო გამოყენებით გამოწვეული, განხორციელებული არაავტორიზებული ოპერაციის შედეგად წარმოშობილი ზიანისთვის კანონმდებლობით დადგენილი ოდენობისა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.22. მომხმარებელი ვალდებულია დაიცვას საგადახდო ინსტრუმენტის უსაფრთხოების ზომები ენბისის მითითების და ხელშეკრულების დანართი N1-ის შესაბამისად, უზრუნველყოს ამ ანგარიშის პერსონიფიცირებული საშუალებების დაცვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.23. მომხმარებელი ვალდებულა საგადახდო ინსტრუმენტის (ანგარიშის, კოდის, პაროლის და სხვა გადახდის განხორციელებისათვის აუცილებელი მონაცემების) დაკარგვის, მოპარვის ან უკანონო გამოყენების ფაქტის აღმოჩენის შემთხვევაში მისი აღმოჩენიდან უმოკლეს ვადაში შეატყობინოს ენბისის ამის თაობაზე. წინააღმდეგ შემთხვევაში მომხმარებელი სრულად იქნება</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პასუხისმგებელი განზრახ და დაუდევრობით გამოწვეულ ნებისმიერ ზიანზე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.24. ენბისი საგადახდო ინსტრუმენტის (ანგარიშის, კოდის, პაროლის და სხვა გადახდისგანხორციელებისათვის აუცილებელი მონაცემების) დაკარგვის, მოპარვის, უკანონო გამოყენების ფაქტის აღმოჩენის ან/და შეტყობინების მიღებისთანავე დაუყოვნებლივ უზრუნველვყოფს დაცვითი ზომების გატარებას.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>4.ელექტრონული საფულე</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.1. ელექტრონული ფულის ელექტრონული საფულის ანგარიშზე აღრიცხვას და მომხმარებლისთვის ხელმისაწვდომობას, ენბისი უზრუნველყოფს მომხმარებლის მიერ მის ელექტრონულ საფულეზე ფულადი სახსრების განთავსებისთანავე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.2. ელექტრონულ საფულეზე ელექტრონული ფულის განთავსება/გადარიცხვა შესაძლებელიაგანხორციელდეს პარტნიორი ორგანიზაციის საშუალებით, ენბისის მიერ მომხმარებლისთვისშეთავაზებული სხვა მეთოდებით რომელსაც პროვაიდერი ხელმისაწვდომს გახდის მომხმარებლისთვის;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.3 ენბისის უფლება აქვს, ნებისმიერ დროს გააუქმოს ან დაამატოს ელექტრონული საფულის ანგარიშზე თანხის შეტანის საშუალებები. ინფორმაცია ელექტრონული საფულის ბალანსის შევსების მეთოდების თაობაზე ხელმისაწვდომი იქნება პროვაიდერის ვებ-გვერდზე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.4. ელექტრონული საფულიდან შეიძლება განხორციელდეს კომუნალური გადასახადების გადახდა პარტნიორ ორგანიზაციებში და ასევე გადახდები QR კოდის მეშვეობით პარტნიორ კომპანიებში;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.5. ელექტრონულ საფულეზე ელექტრონული ფულის აღრიცხვა ან/და ელექტრონულ საფულეზე არსებული ელექტორნული ფულის განკარგვა ხორციელდება მომხმარებლის დავალების საფუძველზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.6. დავალების მიღების დროდ ითვლება მომენტი, როდესაც ენბისი მიიღებს დავალებას, მიუხედავად მისი ინიცირების თარიღისა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.7. იმ შემთხვევაში თუ დავალება ინიცირებულია არასამუშაო დღეს, იგი მიღებულად ჩაითვლებამომდევნო სამუშაო დღეს. ამასთან, სამუშაო დღის 18:00 სთ-ის შემდეგ წარმოდგენილი დავალებები, მიღებულად ჩაითვლება მომდევნო სამუშაო დღეს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.8. გადარიცხვის ოპერაციის შესახებ დავალება, მისი სათანადოდ შესრულებისათვის, აუცილებელია მოიცავდეს კანონმდებლობით დადგენილ რეკვიზიტებს ან/და ენბისის მიერ დამატებით განსაზღვრულ სხვა რეკვიზიტებს დავალების ტიპიდან გამომდინარე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9. ენბისი უფლებამოსილია არ მიიღოს შესასრულებლად დავალება და უარი განაცხადოს დავალების შესრულებაზე შემდეგ შემთხვევაში:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.1. დავალება არ შეესაბამება კანონმდებლობით დადგენილ მოთხოვნებს ან და ენბისის მიერ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დადგენილ წესებსა და პროცედურებს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.2. დავალება შეიცავს არაზუსტ ინფორმაციას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.3. დავალების შესრულებისათვის ელექტრონულ საფულეზე არსებული თავისუფალი ელექტრონული ფულის მოცულობა, საკომისიოს ჩათვლით, არ არის საკმარისი დავალების შესასრულებლად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.4. ოპერაცია აჭარბებს ენბისის მიერ დაწესებულ ლიმიტებს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.5. არსებობს ეჭვი, რომ ადგილი აქვს თაღლითობას ან უკანონო ქმედებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.6. არსებობს სხვა ისეთი გარემოება რომელიც შეუძლებელს ხდის დავალების შესრულებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.10. მომხმარებელი პასუხისმგებელია ყველა ოპერაციაზე, რომელიც განხორციელდა ელექტრონული საფულის საშუალებით, ელექტრონული საფულის დახურვამდე ან დახურვის შემდეგ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.11. ელექტრონული საფულე რეგისტრირდება უვადოდ, ელექტრონულ საფულეში არსებული</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ელექტრონული ფული არ წარმოადგენს პროვაიდერთან განთავსებულ დეპოზიტს და მას არ ერიცხება სარგებელი ან პროცენტი;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.12. ელექტრონული საფულე ეკუთვნის მხოლოდ მომხმარებელს. დაუშვებელია ელექტრონული საფულის გასხვისება, გადაფორმება ან დაგირავება;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.13. ელექტრონული საფულიდან ფულადი სახსრების გატანა/გადარიცხვა გულისხმობს ენბისის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ანგარიშიდან ფულადი სახსრების გატანა/გადარიცხვას მომხმარებლის დავალების შესაბამისად, მომხმარებლის ელექტრონულ საფულეზე არსებული ელექტრონული ფულის მოცულობით, ოპერაცია მოიცავს იმ საკომისიოს ოდენობასაც, რომელიც ეკისრება მომხმარებელს კონკრეტული ოპერაციის შესრულებისათვის;</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>5. საგადახდო ბარათი</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.1. მომსახურების გაწევის მიზნით, ენბისი მომხმარებელს გადასცემს საბანკო პლასტიკურ ბარათს და აღნიშნული ბარათის მონაცემებით არეგისტრირებს მომხმარებელს ენბისის ვებგვერდზე. რეგისტრაციის შედეგად მომხმარებლის სახელზე იქმნება მომხმარებლის პლასტიკურ ბარათზე მიბმული ელექტრონული საფულე. მომხმარებელს ელექტრონული საფულის მართვის პანელთან წვდომა შეუძლია პროვაიდერის ვებ-გვერდიდან. პროვაიდერმა შესაძლოა შეიმუშავოს მობილური აპლიკაცია, რომლითაც მომხმარებელი ასევე შეძლებს თავის ელექტრონულ საფულესთან წვდომასა და შესაბამისი ფუნქციონალის გამოყენებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.2. მომხმარებელს პლასტიკური ბარათის მეშვეობით შეუძლია გაანაღდოს ელექტრონული საფულის ბალანსზე არსებული თანხები საქართველოს ტერიტორიაზე განთავსებული ცალკეული კომერციული ბანკების ATM ბანკომატებიდან;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.3. ელექტრონული საფულის ბალანსზე არსებული თანხების ფარგლებში, პლასტიკური ბარათისმეშვეობით მომხმარებელს შეუძლია განახორციელოს საქონლის ან მომსახურების ღირებულების გადახდა სავაჭრო ობიექტ(ებ)ში, მიიღოს Cashback &ndash;ი (თანხის ნაწილის საგადახდო ბარათზე დაბრუნება) საგადახდო ბარათით განხორციელებული ოპერაციიდან;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.4. ელქტრონული კომერციისათვის საგადახდო ბარათების (რომელი საგადახდო ბარათებისთვისაც დასაშვებია ელექტრონული კომერციით სარგებლობა) გამოყენების დროს შეიძლება ამოქმედდეს VISA და MasterCard-ის &bdquo;3დ უსაფრთხოების &ldquo;სერვისი (3D secure);</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.5. პროვაიდერის ვებ-გვერდზე განთავსებულია ინფორმაცია იმ კომერციული ბანკების შესახებ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >რომელთა ATM ბანკომატების თუ POS ტერმინალების მეშვეობით მომხმარებელი შეძლებს მომსახურების მიღებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.6. საგადახდო ბარათი ძალაშია იმ თვის ბოლომდე, რომელიც მითითებულია საგადახდო ბარათზე. მომხმარებლის პლასტიკური ბარათის მეშვეობით თანხის განაღდება ან/და ხარჯვა შესაძლოა დაექვემდებაროს გარკვეულ ლიმიტებს, რომლის თაობაზე ინფორმაცია ხელმისაწვდომი იქნება პროვაიდერის ვებ-გვერდზე;</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>6.ბარათის დაბლოკვა/შეჩერება</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.1. ენბისის უფლება აქვს ნებისმიერ დროს დაბლოკოს/შეაჩეროს ელექტრონული საფულე დამომხმარებლის პლასტიკური ბარათით სარგებლობა, თუ:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.1.1. გონივრული ეჭვის საფუძველზე ჩათვლის, რომ მომხმარებელი საგადახდო მომსახურებასა</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >და მომხმარებლის პლასტიკური ბარათით სარგებლობას ახორციელებს უკანონო შემოსავლისლეგალიზაციის მიზნით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.1.2. აღმოაჩენს, რომ ხელშეკრულების გაფორმებამდე არასათანადოდ განხორცდიელდა მომხმარებლის იდენტიფიკაცია;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.1.3. აღმოაჩენს, რომ მომხმარებელი ცდილობს გაანაღდოს ან გახარჯოს ელექტრონული საფულის ანგარიშზე სისტემური ხარვეზის შედეგად გაჩენილი არასანქცირებული თანხა, რომელიც მას არ ეკუთვნის;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.1.4. მომხმარებლის ელექტრონული საფულის ანგარიში და მომხმარებლის პლსატიკური ბარათი არააქტიურია 1 (ერთი) წლის განმავლობაში.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.2. ენბისის უფლებამოსილია საგადახდო ბარათით სარგებლობისათვის წვდომის კოდების ერთხელ ან მრავალჯერადად არასწორად გამოყენების შემთხვევაში, დაბლოკოს საგადახდო ბარათი.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>7. ბარათთან დაკავშირებული მხარეთა ვალდებულებები</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.1. მომხმარებელი ვალდებულია:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.1.1. გამოიყენოს მომხმარებლის პლასტიკური ბარათი ამ ხელშეკრულებით დადგენილი პირობების შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.1.2 დაიცვას მისთვის გაცემული მომხმარებლის პლასტიკური ბარათის უსაფრთხოების ზომები</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >და უზრუნველყოს ამ ინსტრუმენტის პერსონიფიცირებული საშუალებების დაცვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.1.3. პლასტიკური ბარათის დაკარგვის, მოპარვის, უკანონო მითვისების ან უკანონო გამოყენების ფაქტის აღმოჩენის შემთხვევაში მისი აღმოჩენიდან უმოკლეს ვადაში შეატყობინოს ამის თაობაზე პროვაიდერს ცხელ ხაზზე (რაც მითითებულია პროვაიდერის ვებ-გვერდზე).</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2. ენბისი ვალდებულია:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2.1. მიიღოს ყველა შესაძლო ზომა მომხმარებლის პლასტიკური ბარათის დაცულობისა და მისი</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >არამართლზომიერი გამოყენების თავიდან აცილების უზრუნველსაყოფად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2.2. არ გახადოს მომხმარებლის პლასტიკური ბარათის პერსონიფიცირებული უსაფრთხოების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მახასიათებლები და საშუალებები ხელმისაწვდომი სხვა პირებისათვის, გარდა თავად ამ მომხმარებლისა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2.3. ნათლად გააცნოს მომხმარებელს მომხმარებლის პლასტიკური ბარათის უსაფრთხოების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მოთხოვნები;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2.4. უზრუნველყოს მომხმარებლისგან ხელშეკრულების 7.1.3. პუნქტით განსაზღვრული შეტყობინების ნებისმიერ დროს მიღება;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2.5. მომხმარებლისგან 7.1.3. პუნქტით განსაზღვრული შეტყობინების მიღებისთანავე, დაუყონებლივ უზრუნველყოს მომხმარებლის პლასტიკური ბარათის შემდგომი გამოყენების აღკვეთა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.3. პროვაიდერი საკუთარ თავზე იღებს მომხმარებლის პლასტიკური ბარათის ან/და მისიპერსონიფიცირებული უსაფრთხოების მახასიათებლებისა და საშუალებების გაგზავნასთან დაკავშირებულ ყველა რისკს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.4. საგადახდო ბარათს ენბისი გადასცემს მომხმარებელს სარგებლობაში (დალუქულ კონვერტშიმოთავსებულ, ან/და განაცხადში დაფიქსირებული მობილური ტელეფონის ნომერზე მოკლე ტექსტური შეტყობინების მეშვეობით, ან/და ენბისის მიერ განსაზღვრული სხვა ელექტრონული ფორმით გაგზავნილ წვდომის კოდთან (PIN-კოდი) ერთად) ელექტრონული საფულის ოპერაციების საწარმოებლად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.5. ენბისი პასუხს არ აგებს მის მიერ შეთავაზებულ უსაფრთხოების ზომაზე მომხმარებლის მიერ უარის თქმის შემთხვევაში დამდგარი შედეგისთვის. ასეთ შემთხვევაში უსაფრთხოების ზომაზე</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >უარის თქმის შედეგად, დამდგარი ზიანისთვის პასუხისმგებელია მომხმარებელი;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.6. მხარეები ვალდებულნი არიან, ამ მუხლით განსაზღვრული ვალდებულებების განზრახ ანდაუდევრობით შეუსრულებლობით გამოწვეული ზიანი აანაზღაურონ საქართველოს მოქმედი</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >კანონმდებლობის შესაბამისად.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>8.არაავტორიზებული ან არასწორად შესრულებული ოპერაციები</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >8.1. არაავტორიზებულ ან არასწორად შესრულებულ ოპერაციებთან დაკავშირებული წესები განისაზღვრება საქართველოს კანონით &ldquo;საგადახდო სისტემისა და საგადახდო მომსახურების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >შესახებ&rdquo;.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>9. საკომისიო და ლიმიტები</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >9.1. ინფორმაცია ყველა სახის საკომისიოს და ლიმიტის შესახებ, რომელიც დადგენილია მომსახურების მიღებისთვის ან მასთან დაკავშირებით, განთავსებულია პროვაიდერის ვებგვერდზე.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>0. პერსონალური მონაცემების და კონფიდენციალურობის დაცვა</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.1. მომხმარებელი, შეთანხმების საფუძველზე ნებას რთავს ენბისის, რომ გამოიყენოს ნებისმიერი მის მიერ მოწოდებული ინფორმაცია, ამ ხელშეკრულებისა და ინფორმაციის დაცვის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პოლიტიკის შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.2. მომხმარებელი ადასტურებს, რომ ენბისის აქვს უფლება შეინახოს მომხმარებლის მიერ მოწოდებული ინფორმაცია ტრანზაქციების ისტორიის შექმნის და ვებ-გვერდის ფუნქციონირების სხვა მიზნებისთვის;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.3. ენბისის უფლება აქვს დაამუშაოს მომხმარებლის პერსონალური მონაცემები პერსონალურ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მონაცემთა დაცვის შესახებ საქართველოს კანონის მე-5 მუხლის &bdquo;ა&ldquo; , &bdquo;გ&ldquo; , &bdquo;თ&ldquo; პუნქტების და სხვა</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სამართლებრივი აქტების შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.4. ენბისი მომხმარებლის იდენტიფიკაცია/ვერიფიკაციის მიზნით აგროვებს ორი სახის ინფორმაციას - პირად და არაპირად მაიდენტიფიცირებელ ინფორმაციას:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ა) პირადი მაიდენტიფიცირებელი ინფორმაციის მეშვეობით ხდება კონკრეტული მომხმარებლის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >იდენტიფიკაცია: სახელი და გვარი, პირადი ნომერი, ელექტრონული ფოსტის მისამართი, სქესი</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დაბადების თარიღი, ბარათის ნომერი, ვადის გასვლის თარიღი, ავთენტურობის კოდი და სხვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბ) არაპირადი მაიდენტიფიცირებელი ინფორმაცია კი არ ახდენს კონკრეტული მომხმარებლისიდენტიფიკაციას და შეიძლება მოიცავდეს: ბრაუზერის სახეობას, &bdquo;IP &ldquo;მისამართს და სხვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.5. ენბისის უფლება აქვს მომხმარებლის მიერ ვებგვერდზე მოწოდებული ინფორმაცია</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გაუმჟღავნოს და გადასცეს: აფილირებულ ნებისმიერ პირს და მის უფლებამოსილ წარმომადგენელს, ნებისმიერ სხვა პირს ან ორგანიზაციას მომხმარებლის თანხმობით და შესაბამის პირს ან ორგანოს კანონმდებლობით გათვალისწინებულ შემთხვევაში ინფორმაციის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გაცემის უფლების ან ვალდებულების არსებობისას, როგორიცაა მაგალითად სასამართლო, არბიტრაჟი და სხვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.6. ენბისის მიერ მომხმარებლის ინფორმაციის (პერსონალური მონაცემების) დამუშავება შესაძლებელია განხორციელდეს სხვადასხვა მიზნით, მათ შორის, შეზღუდვის გარეშე:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.6.1. მომსახურების სრულად და ჯეროვნად განხორციელებისთვის;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.6.2. უსაფრთხოების უზრუნველსაყოფად, აგრეთვე თაღლითობის, გაყალბების ან გაყალბების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მცდელობის, ფულის გათეთრების ან სხვა დანაშაულის გამოსავლენად ან პრევენციისთვის;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.6.3. მარკეტინგული მიზნით, რაც მოიაზრებს ენბისის ან/და მისი პარტნიორი ორგანიზაციების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >(მათ შორის, შპს ლიდერბეთის) მიერ სხვადასხვა პროდუქტის/მომსახურების პერიოდულ შეთავაზებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.7. თუ მომხმარებელი თვლის, რომ მის შესახებ ენბისიში არსებული ინფორმაცია არ შეესაბამებასინამდვილეს ან არ არის სრული, იგი ვალდებულია, დაუყოვნებლივ წერილობით ან/და ცხელი ხაზის საშუალებით აცნობოს ენბისის აღნიშნულის შესახებ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.8. მომხმარებელი უფლებამოსილებას ანიჭებს ენბისის და აცხადებს თანხმობას, რომ პერსონალური მონაცემების გარდა, დაამუშავოს მომხმარებლის ფოტოსურათი, გამოსახულება</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >და საჭიროების შემთხვევაში, სხვა ბიომეტრიული მონაცემები. ამასთანავე, განახორციელოსმომხმარებლის ფოტოსურათის გამოთხოვა/მოპოვება სახელმწიფო სერვისების განვითარების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სააგენტოდან მის მიერ წარმოდგენილი ფოტოსურათების შემოწმება-შედარებისათვის, შემდგომში საიდენტიფიკაციო დოკუმენტის გაყალბების ან და გაყალბების მცდელობის თავიდან აცილების მიზნით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.9. ამასთანავე, მომხმარებელი თანახმაა, რომ ენბისიმ უშუალოდ ან უფლებამოსილი პირის მეშვეობით დისტანციურად განახორციელოს მისი ფოტო/ვიდეო გადაღება და შეამოწმოს მათი</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სიზუსტე შემდგომში გაყალბების თავიდან აცილების და იდენტობის დადგენის მიზნებისთვის.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბიომეტრიული მონაცემების წაშლა ხორციელდება იდენტიფიკაციის პროცესის დასრულებისთანავე;</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>1. მხარეთა პასუხისმგებლობა</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >11.1. ენბისის ეკისრება პასუხიმგებლობა და ვალდებულება, მხოლოდ იმ შემთხვევაში, თუ ეს გამოწვეულია მისი განზრახი ქმედებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >11.2. მომხმარებელი პასუხს აგებს ენბისის წინაშე ხელშეკრულებაში მოცემული ინფორმაციის სიზუსტეზე და სისრულეზე, ვებგ.ვერდზე დარეგისტრირებისას მითითებული ინფორმაციის სიზუსტეზე, ყველა იმ ინფორმაციის სიზუსტეზე, რომელიც მან პროვაიდერს მიაწოდა ხელშეკრულების დადებისას, დადებამდე ან დადების შემდგომ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >11.3. ფორს-მაჟორის მოქმედების განმავლობაში არცერთი მხარე არ აგებს პასუხს ხელშეკრულებით ნაკისრი ვალდებულებების სრულად ან ნაწილობრივ შეუსრულებლობისათვის ან არაჯეროვნად შესრულებისათვის.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>2. კომუნიკაცია მხარეთა შორის</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >12.1. მომხმარებელს შეუძლია ენბისისთან კომუნიკაცია მოახდინოს ვებ-გვერდზე მითითებულ ტელეფონის ნომერით (ცხელ ხაზზე) ან ელექტრონული ფოსტის მეშვეობით, ასევე გაგზავნოს წერილობითი შეტყობინება ფოსტით ენბისის იურიდიულ მისამართზე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >12.2. ენბისის მიერ მომხმარებლისთვის მის მიერ მითითებულ მობილურის ნომერზე (მოკლე ტექსტური შეტყობინების), ან/და ენბისის მიერ შემდგომში განსაზღვრული კომუნიკაციის სხვა საშუალებებით გაგზავნილი შეტყობინება ჩაბარებულად ითვლება შეტყობინების გაგზავნის დღეს, ხოლო ენბისის ვებ-გვერდზე ინფორმაციის განთავსებისას - ინფორმაციის განთავსების დღეს.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>3. ხელშეკრულების ხანგრძლივობა და შეწყვეტა</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.1. ეს ხელშეკრულება მოქმედებს რომელიმე მხარის მიერ მისი შეწყვეტის მომენტამდე პირობებით განსაზღვრული წესის შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.2. წინამდებარე პირობები ძალაში შევა მას შემდეგ, რაც მომხმარებელი შეავსებს შესაბამის განაცხადს, ხელს მოაწერს/დაადასტურებს ელექტრონული ფორმის თანხმობით და ენბისის განაცხადებს თანხმობას მომსახურების გაწევაზე. ენბისის თანხმობა გამოიხატება მომხმარებლისთვის მომსახურების გაწევის დაწყებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.3. ხელშეკრულება შესაძლოა შეწყდეს ამ ხელშეკრულებით განსაზღვრული დებულებების შესაბამისად. ხელშეკრულების შეწყვეტა ავტომატურად იწვევს მომხმარებლის სახელზე გახსნილი ელექტრონული საფულის ანგარიშის გაუქმებასა და პროვაიდერის ყველა მომსახურების შეწყვეტას.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.4. ხელშეკრულება შესაძლოა შეწყდეს:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.4.1. მომხმარებლის მიერ, ნებისმიერ დროს, პროვაიდერისათვის წერილობითი შეტყობინების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გაგზავნის გზით, რა შემთხვევაშიც პროვაიდერს უფლება აქვს, მოსთხოვოს მომხმარებელს გარკვეული დოკუმენტაციის წარმოდგენა ხელშეკრულების შეწყვეტის გაფორმებასა და დასრულებასთან დაკავშირებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.4.2. ენბისის მიერ, ნებისმიერ დროს, მომხმარებლისათვის წინასწარი წერილობითი შეტყობინების გაგზავნის გზით, რა შემთხვევაშიც შეწყვეტის შესახებ შეტყობინება მომხმარებელს უნდა გაეგზავნოს მინიმუმ 1 (ერთი) თვით ადრე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.4.3. ენბისის მიერ, ნებისმიერ დროს, მომხმარებლისათვის წინასწარი წერილობითი შეტყობინების გაგზავნის გზით, თუ მომხმარებელი არღვევს წინამდებარე ხელშეკრულებით განსაზღვრულ რომელიმე ვალდებულებას ან პირობას და არ აღმოფხვრის დარღვევას გაფრთხილების მიუხედავად.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>4. მარეგულირებელი კანონმდებლობა და დავების გადაწყვეტა</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.1. მომხმარებელი ვალდებულია: ტრანზაქციის გასაჩივრების შემთხვევაში, მიმართოს პროვაიდერს სადავო ტრანზაქციის განხორციელებიდან 40 დღის ვადაში და წარუდგინოს გასაჩივრებულ ტრანზაქციასთან დაკავშირებული ინფორმაცია/დოკუმენტები, რაც მოიცავს, მაგრამ არ შემოიფარგლება შემდეგით:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ტრანზაქციის თარიღი, ტრანზაქციის ოდენობა, ბარათის მონაცემები, ინფორმაცია სავაჭრო ობიექტზე (რელევანტურობის შემთხვევაში), შეძენილი ნივთი და/ ან მომსახურება (რელევანტურობის შემთხვევაში), ქვითარი/გადახდის დამადასტურებელი დოკუმენტი (რელევანტურობის შემთხვევაში) და ნებისმიერი სხვა დამატებითი ინფორამცია და/ან დოკუმენტი, რასაც პროვაიდერი, საკუთარი შეხედულებისამებრ, საჭიროდ მიიჩნევს საკითხის შესწავლისა და განხილვის პროცესში.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.2. პროვაიდერი ვალდებულია განიხილოს მომხმარებლის საჩივარი განხორციელებულ ოპერაციასთან დაკვაშირებით და მიიღოს გადაწყვეტილება და გააცნოს იგი მომხმარებელს საჩივრის მიღებიდან არაუგვიანეს 15 (თხუთმეტი) სამუშაო დღისა. საჩივრის მიღების დღედ ითვლება მომხმარებლის მიერ პროვაიდერისთვის საჩივრის წარდგენის სამუშაო დღე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.2.1. იმ შემთხვევაში, თუ პროვაიდერისაგან დამოუკიდებელი მიზეზების გამო მითითებულ 15</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >(თხუთმეტი) დღიან ვადაში ვერ ხერხდება საჩივრის განხილვა და მასზე გადაწყვეტილების მიღება, პროვაიდერი ვალდებულია ამის შესახებ წერილობითი შეტყობინებით აცნობოს მომხმარებელს სადაც იქნება მითითება დაგვიანების დასაბუთებული მიზეზისა და საჩივრის განხილვა/ გადაწყვეტისთვის ვადის გაგრძელების შესახებ. საჩივარზე გადაწყვეტილების მიღებისა და საბარათე ინსტრუმენტის მფლობელისთვის გაცნობის ვადა არ უნდა აღემატებოდეს საჩივრის მიღებიდან 55 სამუშაო დღეს.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.3. მომხმარებლის მიერ ოპერაციების გასაჩივრების თითოეული შემთხვევა განიხილება ინდივიდუალურად შესაბამისი საგადახდო სისტემების მიერ დადგენილი წესების, ადგილობრივ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ფინანსურ სექტორში დამკვიდრებული პრაქტიკის და მოქმედი კანონმდებლობის გათვალისწინებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.4. საჩივრების და პრეტენზიების მიღება ხორციელდება ყოველ სამუშაო დღეს 10:00 საათიდან 18:00 საათამდე, შემდეგი საშუალებებით: ცხელ ხაზთან დაკავშირებით, ელ-ფოსტით: <a href="mailto:info@nbc.ge" style="color:#954f72; text-decoration:underline">info@nbc.ge</a> &nbsp;ან წერილობითი ფორმით.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.2. თუ მხარეთა შორის წარმოიშვა დავა ან უთანხმოება წინამდებარე ხელშეკრულებასთან დაკავშირებით, მხარეები შეეცდებიან დავა გადაჭრან მოლაპარაკებების გზით, შეუთანხმებლობის შემთხვევაში, ნებისმიერ მხარეს უფლება აქვს მიმართოს თბილისის საქალაქო სასამართლოს.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>5. დასკვნითი დებულებები</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.1. მომხმარებელი აცხადებს და იძლევა გარანტიას, რომ:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.1.1. ხელშეკრულების დასადებად წარმოდგენილია ზუსტი და უტყუარი ინფორმაცია;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.1.2. ენბისის მომსახურების მისაღებად ინფორმაციის მოწოდება ხდება მომხმარებლის სურვილით და ამისათვის მას გააჩნია კანონმდებლობით, ან ხელშეკრულებით გათვალისწინებული უფლება/ნებართვა.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.2. მომხმარებლის მიერ ელექტრონული ფორმით გაკეთებულ თანხმობას (რომელიც მკაფიოდ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გამოხატავს მომხმარებლის ნებას) აქვს მატერიალური დოკუმენტის თანაბარი იურიდიული ძალა (მატერიალურ დოკუმენტზე პირადი ხელმოწერის თანაბარი იურიდიული ძალა);</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.3. ამ ხელშეკრულებაში ცვლილებების შეტანა შესაძლებელია ვებ-გვერდზე <a href="http://www.nbc.ge/" style="color:#954f72; text-decoration:underline">www.nbc.ge</a> , <a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline">www.leadercard.ge</a> - ზე გამოქვეყნების გზით, რომელიც ძალაში შედის მისი გამოქვეყნებიდან 1(ერთი) კალენდარული თვის შემდეგ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.4. ხელშეკრულების პირობებში განხორციელებული ნებისმიერი ცვლილება ჩაითვლებამომხმარებელთან შეთანხმებულად, თუ ცვლილების ძალაში შესვლამდე არ განაცხადებს უარს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დაგეგმილი ცვლილების თაობაზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.5. ცვლილებების ამოქმედებამდე, მომხმარებელს უფლება აქვს დაუყოვნებლივ შეწყვიტოსხელშეკრულება ყოველგვარი საკომისიოს, ხარჯის და პირგასამტეხლოს გადახდის გარეშე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.6. მომხმარებელი ვალდებულია ენბისის მოთხოვნის შემთხვევაში და განსაზღვრულ ვადაში</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მიაწოდოს მოთხოვნილი ნებისმიერი დამატებითი ინფორმაცია ან/და დოკუმენტაცია;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.7. მხარეები თანხმდებიან, რომ ხელშეკრულების შესრულების ადგილად მიიჩნევა ენბისის იურიდიული მისამართი;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.8. მომხმარებელს უფლება აქვს, ხელშეკრულების მოქმედების მთელ პერიოდში, მოსთხოვოს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პროვაიდერს და მიიღოს ნებისმიერი ინფორმაცია, რომლის მიღების უფლებაც გააჩნია &ldquo;საგადახდო მომსახურების განხორციელებისას მომხმარებლისთვის აუცილებელი ინფორმაციის მიწოდების წესის დამტკიცების შესახებ&rdquo; საქართველოს ეროვნული ბანკის პრეზიდენტის 2016 წლის 6 იანვრის N1/04 ბრძანებით.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>შპს &bdquo;ენბისი &ldquo;</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >საიდენტიფიკაციო კოდი: 405150063</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სარეგისტრაციო ნომერი: 0053-9004</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >იურიდიული მისამართი: ქ. თბილისი, ონიაშვილის ქ. N1, კომერციული ფართი N5</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ფაქტობრივი მისამართი: ქ. თბილისი, ონიაშვილის ქ. N1, სართული 3 ბინა N7</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ელექტრონული ფოსტა: <a href="mailto:info@nbc.ge" style="color:#954f72; text-decoration:underline">info@nbc.ge</a> ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ეს ხელშეკრულება დადებულია და რეგულირდება ქართულ ენაზე.</span></span></span></p>

        `,
        Rus:  `
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>გთხოვთ, საგადახდო მომსახურებით სარგებლობამდე ხელშეკრულების სავალდებულო</strong></span></span></span></p>
    
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>ხასიათის გათვალისწინებით ყურადღებით გაეცნოთ წინამდებარე პირობებს!</strong></span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>&bdquo;ენ ბი სი&ldquo;- ს საგადახდო მომსახურების ხელშეკრულება</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>+995 32 2 193 293 | </strong><a href="http://www.nbc.ge/" style="color:#954f72; text-decoration:underline"><strong>www.nbc.ge</strong></a><strong> , </strong><a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline"><strong>www.leadercard.ge</strong></a><strong> / | info@nbc.ge</strong></span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1. ზოგადი დებულებანი</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.1. წინამდებარე ხელშეკრულება მოიცავს მომხმარებლების მიერ ენბისის ელექტრონული საგადახდო მომსახურებით სარგებლობის სტანდარტულ და სპეციალურ პირობებს/წესებს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.2. კონკრეტული საგადახდო მომსახურების მიღებისას შესაძლოა მოქმედებდეს სპეციალური პირობები/წესები, რომლის შესახებაც მომხმარებელი ინფორმაციას იღებს კონკრეტული მომსახურების მიღების პროცესში;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.3. ხელშეკრულების პირობები ძალაში შედის მას შემდეგ, რაც მომხმარებელი განაცხადით ან/და კანონმდებლობით ნებადართული და ენბისისთვის მისაღები სხვა (დისტანციური მომსახურება) ფორმით, დაადასტურებს თანხმობას პირობებზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.4. წინამდებარე საგადახდო მომსახურებით სარგებლობის სტანდარტული და სპეციალური პირობები/წესები, მათ შორის ნებისმიერი დანართი მომხმარებლის განაცხადთან ერთად წარმოადგენს ენბისისა და მომხმარებელს შორის გაფორმებულ ხელშეკრულებას.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.5. ენბისი უფლებამოსილია საკუთარი შეხედულებისამებრ არ დაუშვას, ან სხვა სახით შევზღუდოს კონკრეტული მომხმარებლისთვის მომსახურების მიღება ასევე, იტოვებს უფლებას,</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >უარი თქვას კონკრეტული მომხმარებლის შემთხვევაში, კონკრეტულ გადახდასთან დაკავშირებით, ეროვნული ბანკის მიერ საგადახდო მომსახურების პროვაიდერისთვის დადგენილი სტანდარტების შესაბამისად.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1.6. ვებ-გვერდზე რეგისტრაციისას ინფორმაციის მოწოდება ხდება მომხმარებლის სურვილით,</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >რაც ნიშნავს, რომ ენბისის აქვს უფლება შეინახოს მომხმარებლის მიერ მოწოდებული ინფორმაცია ტრანზაქციების, ისტორიის შექმნის და ვებ-გვერდის ფუნქციონირების სხვა მიზნებისთვის.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>2. ტერმინთა განმარტება</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >წინამდებარე ხელშეკრულებაში გამოყენებულ ტერმინებს, ამ &bdquo;ხელშეკრულების &ldquo;მიზნებისთვის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >აქვს შემდეგი მნიშვნელობა (თუ &bdquo;ხელშეკრულებით &ldquo;სხვა რამ არ არის განსაზღვრული, ან მისი კონტექსტიდან სხვა რამ არ გამომდინარეობს):</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.1. <strong>საგადახდო მომსახურების პროვაიდერი (პროვაიდერი)</strong> - შპს &bdquo;ენ ბი სი &ldquo;, საიდენტიფიკაციო</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ნომერი:405150063, საქართველოს ეროვნულ ბანკში რეგისტრაციის N 0053-9004, სათაო ოფისის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მისამართი: ონიაშვილის ქ. N1, კომერციული ფართი N5 თბილისი, საქართველო; ვებ-გვერდის მისამართი: <a href="http://www.nbc.ge/" style="color:#954f72; text-decoration:underline">www.nbc.ge</a> , <a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline">www.leadercard.ge</a> , ცხელი ხაზი: +995 32 2 193 293, მომხმარებელს უწევს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ელექტრონულ საგადახდო მომსახურებას &ldquo;საგადახდო სისტემისა და საგადახდო მომსახურების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >შესახებ&rdquo; საქართველოს კანონისა და სხვა ნორმატიული აქტებით განსაზღვრული პირობების შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.2. <strong>ავტორიზაცია</strong> - კანონმდებლობითა და ენბისის მიერ დადგენილი წესებისა და პროცედურების შესაბამისად მომხმარებლის მიერ გაცემული თანხმობა ოპერაციის განხორციელებაზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.3. <strong>ამონაწერი</strong> - ინფორმაცია მომხმარებლის მიერ ჩატარებული ოპერაციის შესახებ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.4. <strong>არაპერსონიფიცირებული საგადახდო ბარათი</strong> &ndash; Visa/Mastercard ან/და სხვა სისტემის საგადახდო ბარათი, რომელზეც არ არის ასახული ბარათის მფლობელის სახელი ან/და გვარი.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.5. <strong>განაცხადი </strong>- მომხმარებლის მიმართვა პროვაიდერს (ენბისის მიერ დადგენილი ფორმით</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მატერიალური დოკუმენტის, ელექტრონული ან სხვა მსგავსი საშუალებების გამოყენებით</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომხმარებლისთვის მიწოდებული ინფორმაცია, რომელსაც დადგენილი ფორმით ეთანხმება/არ ეთანხმება მომხმარებელი), რომ განაცხადშივე განსაზღვრული პირობების შესაბამისად, განახორციელოს ან/და შეაჩეროს/შეწყვიტოს საგადახდო მომსახურება;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.6. <strong>დავალება</strong> -მომხმარებლის მიერ საგადახდო მომსახურების ხელშეკრულების ფარგლებში</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >განხორციელებული ნებისმიერი დავალება ან/და მითითება, რომელსაც მომხმარებელი აძლევს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პროვაიდერს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.7. <strong>დისტანციური მომსახურება</strong> - ენბისის მიერ დადგენილი პროცედურების შესაბამისად, მოსახურების ცენტრში მიუსვლელად დისტანციურად მომსახურების მიღება, განაცხადის გაკეთება, სატელეფონო, SMS შეტყობინების დადასტურების, მომხმარებლის პროფილიდან და</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სხვადასხვა დისტანციური/ელექტრონული საკომუნიკაციო არხების საშუალებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.8. <strong>ელექტრონული საფულე</strong> - პირის უნიკალური ვირტუალური ანგარიში, რომელიც დაკავშირებულია პირის იდენტიფიცირებასთან. საფულეში განთავსებულია ელექტრონული</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ფული, რომელიც იმართება მომხმარებლის მიერ ენბისის დადგენილი წესების შესაბამისად და</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მოიცავს საფულის წვდომის რეკვიზიტებს, ავტორიზაციისთვის საჭირო რეკვიზიტებს, რომელიც</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ცნობილია მხოლოდ საფულის მფლობელისთვის და შეიცავს შემდეგ მონაცემებს: მომხმარებლის მიმდინარე ნაშთს, გადახდების ისტორიას და სხვა ინფორმაციას, რომელიც განახორციელა მომხმარებელმა საფულედან;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.9. <strong>ელექტრონული ფული</strong> - ენბისის მიერ მომხმარებლისგან გადახდის ოპერაციების განხორციელების მიზნით ფულადი სახსრების ექვივალენტური ღირებულება, რომელიც შენახულია საგადახდო ინსტრუმენტებზე და რომელიც აღიარებულია გადახდის საშუალებად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.10. <strong>ვებგვერდი</strong> - <a href="http://www.nbc.ge/" style="color:#954f72; text-decoration:underline">www.nbc.ge</a> , <a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline">www.leadercard.ge</a> საშუალებას აძლევს ენბისის მომხმარებლებს დისტანციურად შეავსონ განაცხადი ან/და მიიღონ სასურველი მომსახურება, სადაც განთავსებულია პროვაიდერის სათაო ოფისის მისამართი, ელექტრონული ფოსტის მისამართი, ტელეფონის ნომერი, მომსახურების ცენტრების მისამართები და სხვა ინფორმაცია, რაც მნიშვნელოვანია მხარეთა შორის სახელშეკრულებო ურთიერთობის პროცესში;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.11. <strong>კანონმდებლობა</strong> &ndash; საქართველოს მოქმედი საკანონმდებლო და კანონქვემდებარე ნორმატიული აქტები და საქართველოს ნორმატიულ აქტთა სისტემაში მოქცეული საერთაშორისო ხელშეკრულებები და შეთანხმებები;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.12.<strong> მომხმარებელი </strong>- სრულწლოვანი ქმედუნარიანი საქართველოს რეზიდენტი ფიზიკური პირი, რომელიც ეთანხმება წინამდებარე ხელშეკრულებით დადგენილ წესებსა და პირობებს,</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >აკმაყოფილებს კანონმდებლობით და ხელშეკრულებით გათვალისწინებულ მოთხოვნებს. ამასთანავე, რომლის იდენტიფიკაცია/ვერიფიკაცია ენბისიმ განახორციელა, უშუალოდ ან/და დისტანციური მომსახურების საშუალებით წარდგენილი განცხადების საფუძველზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.13.<strong> მომსახურება </strong>- ენბისი კისრულობს ვალდებულებას, ხელშეკრულების საფუძველზე გაუწიოს მომხმარებელს მომსახურება წარდგენილი განცხადების საფუძველზე, რეალურ ან/და</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დისტანციურ რეჟიმში ხელშეკრულებით გათვალისწინებული წესითა და პირობით საგადახდო</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომსახურება, რომელიც მოიცავს მომხმარებლის ელექტრონული საფულის გახსნასა და განკარგვას, ფულადი სახსრების ან/და მათი ეკვივალენტი ელექტრონული ფულის გატანას/გადარიცხვას, მომხმარებლის საგადახდო ბარათით მომსახურებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.14. <strong>მხარე/მხარეები</strong> - ენბისი და მომხმარებელი ერთობლივად ან ცალ-ცალკე, კონტექსტის შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.15. <strong>ოპერაცია</strong> - ენბისის(პროვაიდერის) მიერ მომხმარებლის დავალებით განხორციელებული</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ნებისმიერი სახის საგადახდო მომსახურება;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.16. <strong>პარტნიორი ორგანიზაცია</strong> - პარტნიორი ორგანიზაციების და პარტნიორების შესახებ მონაცემები განთავსებულია ვებგვერდზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.17. <strong>საგადახდო ბარათი (შემდგომში &ndash; ბარათი)</strong> &ndash; ენბისის მიერ გამოშვებული საგადახდო ინსტრუმენტი (პლასტიკური ბარათი), რომელიც დაკავშირებულია მომხმარებლის ელექტრონულ საფულესთან, რომლის საშუალებითაც ხდება მომსახურების საფასურის გადახდა, ნაღდი ფულის მიღება, ხელშეკრულებით გათვალისწინებული წესების/პირობების და</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >საქართველოს კანონმდებლობით ნებადართული სხვა ოპერაციების შესრულება;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.18. <strong>საკომისიო</strong> - ენბისის მიერ, მომხმარებლისთვის, ნებისმიერი საგადახდო მომსახურებით სარგებლობისათვის დაწესებული გადასახადი/მომსახურების საფასური, რომლის ოდენობა და</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გადახდის პირობები განისაზღვრება დანართით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.19. <strong>სამუშაო დღე</strong> - დღე, შაბათის, კვირის ასევე კანონმდებლობით განსაზღვრული უქმე დღეების გარდა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.20. <strong>ფორს-მაჟორი </strong>&ndash; ხელშეკრულების მხარეები თავისუფლდებიან პასუხისმგებლობისაგან მათმიერ ნაკისრი ვალდებულებების სრული ან ნაწილობრივი შეუსრულებლობისათვის დაუძლეველი ძალის მოქმედების შემთხვევაში, რომლის დროსაც შეუძლებელია ხელშეკრულებით გათვალისწინებული ვალდებულებების შესრულება. &bdquo;დაუძლეველი ძალის&ldquo;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ქვეშ იგულისხმება გარემოებები, რომლებიც არ არსებობდნენ ხელშეკრულების დადების დროს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >და რომელთა დადგომა, თუ ზემოქმედება მხარეებს არ შეეძლოთ თავიდან აეცილებინათ და გადაელახათ. დაუძლეველი ძალის გარემოებებს მიეკუთვნება: სტიქიური უბედურებები, აფეთქებები (ტერორისტული თუ დივერსიული აქტები), სახელმწიფო ორგანოების ისეთი აქტების მიღება, რაც არსებითად აფერხებს ხელშეკრულების მხარეთა მიერ ნაკისრი ვალდებულებების შესრულებას, ასევე ელექტროენერგიის მიწოდების შეწყვეტა, მოწყობილობების მწყობრიდან გამოსვლა, პროგრამული უზრუნველყოფის დაზიანება, სისტემური ან და ქსელის ისეთი ხარვეზი, დაზიანება, შეფერხება, შეზღუდვა რაც ლოკაციაზე შეუძლებელს ხდის მომსახურების გაწევას ან ენბისისთვის გადაულახავი და მისი კონტროლისაგან დამოუკიდებელი სხვა გარემოებები.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>3. მომსახურების მიღება</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.1.საგადახდო მომსახურების პროვაიდერის საზედამხედველო ორგანოს წარმოადგენს საქართველოს ეროვნული ბანკი, ვებ გვერდის მისამართი: <a href="http://www.nbg.ge/" style="color:#954f72; text-decoration:underline">www.nbg.ge</a> .</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.2. საზედამხეველო ორგანო არ არის პასუხისმგებელი, საგადახდო მომსახურების პროვაიდერის მიერ ნაკისრი ვალდებულებების არაჯეროვან შესრულებაზე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.3. ხელშეკრულების საფუძველზე ენბისი იღებს ვალდებულებას, გაუხსნას მომხმარებელს ელექტრონული საფულის ანგარიში და გაუწიოს მას აღნიშნულ ანგარიშთან დაკავშირებული საგადახდო მომსახურება, უზრუნველყოს ანგარიშზე ფულადი სახსრების ჩარიცხვა და მის შესრულებასთან დაკავშირებული ოპერაციები, გადახდები, საგადახდო ბარათის ან სხვა საგადახდო ინსტრუმენტის გამოყენებით, გადარიცხვის მუდმივი დავალების ჩათვლით, მომხმარებლის კუთვნილი თანხის ფარგლებში;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.4. ენბისი იღებს ვალდებულებას გაუწიოს მომხმარებელს საგადახდო მომსახურება, რის სანაცვლოდაც მომხმარებელი ვალდებულია შეასრულოს ხელშეკრულებით განსაზღვრული პირობები და გადაიხადოს საკომისიო (ასეთის არსებობის შემთხვევაში);</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.5. ენბისი საგადახდო მომსახურებას ახორციელებს მხოლოდ საქართველოს ტერიტორიაზე. ელექტრონულ საფულეს გააჩნია ელექტრონული ფულის ანგარიში ერთ ვალუტაში და ყველა შემთხვევაში - ლარში;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.6. მომხმარებელი იღებს ვალდებულებას, პერიოდულად გაეცნოს ვებგვერდზე განთავსებულ ინფორმაციას ცვლილებების და დამატებების შესახებ. მომხმარებელი უფლებამოსილია მოიძიოს ინფორმაცია ხელშეკრულების პირობების შესახებ ხელშეკრულების მოქმედების განმავლობაში, ენბისის (პროვაიდერის) ვებ გვერდზე &bdquo;პირადი პროფილი &ldquo;-ს საშუალებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.7. ხელშეკრულებით განსაზღვრული საგადახდო პროდუქტით მომსახურების მიღების ან/და სარგებლობის შეწყვეტის მიზნით, მომხმარებელი უფლებამოსილია მიმართოს განაცხადით ენბისის უშუალოდ მომსახურების ცენტრში მისვლით (დისტანციური მომსახურების არხების საშუალებით ასეთის არსებობის შემთხვევაში). ენბისის მიერ განაცხადის განხილვა მოხდება იმ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პირობით, თუ მომხმარებლის მიერ განაცხადის შევსების დროს დაკმაყოფილებული იქნება ხელშეკრულებით გათვალისწინებული ყველა პირობა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.8. ენბისი დავალებას მიიღებს მხოლოდ იმ შემთხვევაში, თუ მისი გაცემა მოხდა მომხმარებლის მიერ რეგისტრირებული იდენტიფიკატორების გამოყენების მეშვეობით და უფლებამოსილია, შეამოწმოს დავალების ნამდვილობა, ასევე მომხმარებლისგან მოითხოვოს დამატებითი ინფორმაციის/დოკუმენტის წარდგენა, თუ ეს აუცილებელია დავალების შესასრულებლად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.9. საგადახდო დავალების მიღების დროდ ითვლება მომენტი, როდესაც ენბისი მიიღებს საგადახდო დავალებას. იმ შემთხვევაში, თუ საგადახდო დავალება ინიცირებულია არასამუშაო</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დღეს, იგი მიღებულად ჩაითვლება მომდევნო სამუშაო დღეს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.10. ენბისი საგადახდო დავალების შესრულებას უზრუნველყოფს საგადახდო დავალების წარდგენიდან 2 (ორი) სამუშაო დღის ვადაში, გარდა კანონმდებლობით გათვალისწინებული შემთხვევებისა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.11. გადარიცხვის ოპერაციის შესახებ დავალება, მისი სათანადოდ შესრულებისათვის, აუცილებელია მოიცავდეს საქართველოს ეროვნული ბანკის პრეზიდენტის 2015 წლის 22 იანვარის №8/04 გადახდის ოპერაციის შესრულების წესის დამტკიცების შესახებ ბრძანებით დადგენილ რეკვიზიტებს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12. ენბისი უფლებამოსილია არ მიიღოს შესასრულებლად დავალება ან/და უარი განაცხადოს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დავალების შესრულებაზე:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.1. თუ დავალება არ შეესაბამება კანონმდებლობით დადგენილ მოთხოვნებს ან/და ენბისის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დადგენილ წესებსა და პროცედურებს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.2. თუ დავალება შეიცავს არაზუსტ ინფორმაციას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.3. თუ დავალების შესრულებისათვის ანგარიშზე არსებული თანხა, საკომისიოს ჩათვლით, არ არის საკმარისი;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.4. ოპერაცია გადააჭარბებს ენბისის მიერ დაწესებულ ლიმიტს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.5. არსებობს ეჭვი, რომ მომხმარებლის მხრიდან ადგილი აქვს თაღლითობას ან/და უკანონო</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ქმედებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.12.6. თუ არსებობს სხვა ისეთი ხარვეზი/გარემოება, რომელიც შეუძლებელს ხდის დავალების შესრულებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.13. მომხმარებლის მოთხოვნის საფუძველზე ენბისი იღებს ყველა ზომას, რათა დროულად, გონივრულ ვადაში, შეაჩეროს ან/და გააუქმოს ნებისმიერი დავალების განხორციელება, თუ კანონმდებლობით სხვა რამ არ არის გათვალისწინებული. ენბისი არ კისრუბლობს პასუხისმგებლობას აღნიშნული მოთხოვნის დაუკმაყოფილებლობის შემთხვევაში, თუ მას არ მიუღია შესაბამისი შეტყობინება და ამგვარი შეჩერება ან გაუქმება შეუძლებელია;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.14. მიღებული დავალების შესრულებისათვის ენბისი უფლებამოსილია მომხმარებელს მოსთხოვოს დავალების ერთჯერადი კოდით დადასტურება (ე.წ. OTP ავტორიზაცია) იმ ოპერაციებისათვის, რომლებიც კანონმდებლობით გათვალისწინებული წესით საჭიროებს დამატებით დადასტურებას. მომხმარებელი ერთჯერად კოდს იღებს მის მიერ განაცხადში დაფიქსირებული მობილური ტელეფონის ნომრის ან/და მხარეთა შორის შეთანხმებული სხვა მოწყობილობის გამოყენებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.15. იმ შემთხვევაში, თუ ოპერაციის შესრულებისას ნებისმიერი მიზეზით წარმოიშვა გადახარჯვა, მომხმარებელი ვალდებულია, არაუგვიანეს მეორე დღისა უზრუნველყოს გადახარჯვის აღმოფხვრა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.16. მომხმარებელი ვალდებულია გადაიხადოს საკომისიო (ასეთის არსებობის შემთხვევაში) გადახდის დროისათვის არსებული ენბისის მიერ დადგენილი ტარიფების შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.17. მომხმარებელი სრულად აგებს პასუხს მის მიერ ენბისისთვის საგადახდო დავალებით მოწოდებულ რეკვიზიტების სისწორესა და სისრულეზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.18. ენბისი უფლებამოსილია, მის მიერ დადგენილი ტარიფების შესაბამისად მომხმარებლის ელექტრონული საფულიდან, მომხმარებლის დამატებითი თანხმობის გარეშე ჩამოჭრას/დაბლოკოს საკომისიო და მოახდინოს მოსახურებით გათვალისწინებული საკომისიოს დაფარვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.19. მომსახურების მისაღებად მომხმარებელი ვალდებულია:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.19.1. ყურადღებით გაეცნოს ხელშეკრულებას (წინამდებარე პირობებს);</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.19.2. შეავსოს ან/და ხელი მოაწეროს (მათ შორის ელექტრონულად) განაცხადს (ასეთის არსებობის შემთხვევაში) ან/და სხვა სახის დოკუმენტს, რომელიც საჭიროა შესაბამისი მომსახურების მისაღებად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.19.3. ენბისისთვის წარდგენილ/გაგზავნილ უფლებამოსილების დამადასტურებელ დოკუმენტსა ან/და ინფორმაციაში განხოციელებული ნებისმიერი სახის ცვლილების შესახებ (მათ შორის, ტელეფონის ნომრის) შეატყობინოს ენბისის აღნიშნული გარემოებების დადგომიდან უმოკლეს ვადაში, წინააღმდეგ შემთხვევაში ენბისი არ იქნება პასუხისმგებელი ცვლილების შეტყობინების მიღებამდე განხორციელებული ქმედებების შედეგად დამდგარ ზიანზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.20. დეტალების შესახებ, მომხმარებელს ინფორმაციის მიღება შეუძლია როგორც ადგილზე ენბისის მომსახურების ცენტრებში, ასევე ცხელი ხაზისა და დისტანციური მომსახურების საშუალებით. ოპერაციის ტიპიდან გამომდინარე, მომხმარებელზე შეიძლება გაიცეს ამონაწერი,</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ცნობა, ქვითარი ან/და სხვა დოკუმენტი ენბისის მიერ განსაზღვრული ფორმით და პირობებით.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.21. მომხმარებელი პასუხისმგებელია მოპარული ან დაკარგული საგადახდო ინსტრუმენტით ან უკანონო გამოყენებით გამოწვეული, განხორციელებული არაავტორიზებული ოპერაციის შედეგად წარმოშობილი ზიანისთვის კანონმდებლობით დადგენილი ოდენობისა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.22. მომხმარებელი ვალდებულია დაიცვას საგადახდო ინსტრუმენტის უსაფრთხოების ზომები ენბისის მითითების და ხელშეკრულების დანართი N1-ის შესაბამისად, უზრუნველყოს ამ ანგარიშის პერსონიფიცირებული საშუალებების დაცვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.23. მომხმარებელი ვალდებულა საგადახდო ინსტრუმენტის (ანგარიშის, კოდის, პაროლის და სხვა გადახდის განხორციელებისათვის აუცილებელი მონაცემების) დაკარგვის, მოპარვის ან უკანონო გამოყენების ფაქტის აღმოჩენის შემთხვევაში მისი აღმოჩენიდან უმოკლეს ვადაში შეატყობინოს ენბისის ამის თაობაზე. წინააღმდეგ შემთხვევაში მომხმარებელი სრულად იქნება</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პასუხისმგებელი განზრახ და დაუდევრობით გამოწვეულ ნებისმიერ ზიანზე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3.24. ენბისი საგადახდო ინსტრუმენტის (ანგარიშის, კოდის, პაროლის და სხვა გადახდისგანხორციელებისათვის აუცილებელი მონაცემების) დაკარგვის, მოპარვის, უკანონო გამოყენების ფაქტის აღმოჩენის ან/და შეტყობინების მიღებისთანავე დაუყოვნებლივ უზრუნველვყოფს დაცვითი ზომების გატარებას.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>4.ელექტრონული საფულე</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.1. ელექტრონული ფულის ელექტრონული საფულის ანგარიშზე აღრიცხვას და მომხმარებლისთვის ხელმისაწვდომობას, ენბისი უზრუნველყოფს მომხმარებლის მიერ მის ელექტრონულ საფულეზე ფულადი სახსრების განთავსებისთანავე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.2. ელექტრონულ საფულეზე ელექტრონული ფულის განთავსება/გადარიცხვა შესაძლებელიაგანხორციელდეს პარტნიორი ორგანიზაციის საშუალებით, ენბისის მიერ მომხმარებლისთვისშეთავაზებული სხვა მეთოდებით რომელსაც პროვაიდერი ხელმისაწვდომს გახდის მომხმარებლისთვის;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.3 ენბისის უფლება აქვს, ნებისმიერ დროს გააუქმოს ან დაამატოს ელექტრონული საფულის ანგარიშზე თანხის შეტანის საშუალებები. ინფორმაცია ელექტრონული საფულის ბალანსის შევსების მეთოდების თაობაზე ხელმისაწვდომი იქნება პროვაიდერის ვებ-გვერდზე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.4. ელექტრონული საფულიდან შეიძლება განხორციელდეს კომუნალური გადასახადების გადახდა პარტნიორ ორგანიზაციებში და ასევე გადახდები QR კოდის მეშვეობით პარტნიორ კომპანიებში;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.5. ელექტრონულ საფულეზე ელექტრონული ფულის აღრიცხვა ან/და ელექტრონულ საფულეზე არსებული ელექტორნული ფულის განკარგვა ხორციელდება მომხმარებლის დავალების საფუძველზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.6. დავალების მიღების დროდ ითვლება მომენტი, როდესაც ენბისი მიიღებს დავალებას, მიუხედავად მისი ინიცირების თარიღისა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.7. იმ შემთხვევაში თუ დავალება ინიცირებულია არასამუშაო დღეს, იგი მიღებულად ჩაითვლებამომდევნო სამუშაო დღეს. ამასთან, სამუშაო დღის 18:00 სთ-ის შემდეგ წარმოდგენილი დავალებები, მიღებულად ჩაითვლება მომდევნო სამუშაო დღეს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.8. გადარიცხვის ოპერაციის შესახებ დავალება, მისი სათანადოდ შესრულებისათვის, აუცილებელია მოიცავდეს კანონმდებლობით დადგენილ რეკვიზიტებს ან/და ენბისის მიერ დამატებით განსაზღვრულ სხვა რეკვიზიტებს დავალების ტიპიდან გამომდინარე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9. ენბისი უფლებამოსილია არ მიიღოს შესასრულებლად დავალება და უარი განაცხადოს დავალების შესრულებაზე შემდეგ შემთხვევაში:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.1. დავალება არ შეესაბამება კანონმდებლობით დადგენილ მოთხოვნებს ან და ენბისის მიერ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დადგენილ წესებსა და პროცედურებს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.2. დავალება შეიცავს არაზუსტ ინფორმაციას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.3. დავალების შესრულებისათვის ელექტრონულ საფულეზე არსებული თავისუფალი ელექტრონული ფულის მოცულობა, საკომისიოს ჩათვლით, არ არის საკმარისი დავალების შესასრულებლად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.4. ოპერაცია აჭარბებს ენბისის მიერ დაწესებულ ლიმიტებს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.5. არსებობს ეჭვი, რომ ადგილი აქვს თაღლითობას ან უკანონო ქმედებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.9.6. არსებობს სხვა ისეთი გარემოება რომელიც შეუძლებელს ხდის დავალების შესრულებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.10. მომხმარებელი პასუხისმგებელია ყველა ოპერაციაზე, რომელიც განხორციელდა ელექტრონული საფულის საშუალებით, ელექტრონული საფულის დახურვამდე ან დახურვის შემდეგ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.11. ელექტრონული საფულე რეგისტრირდება უვადოდ, ელექტრონულ საფულეში არსებული</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ელექტრონული ფული არ წარმოადგენს პროვაიდერთან განთავსებულ დეპოზიტს და მას არ ერიცხება სარგებელი ან პროცენტი;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.12. ელექტრონული საფულე ეკუთვნის მხოლოდ მომხმარებელს. დაუშვებელია ელექტრონული საფულის გასხვისება, გადაფორმება ან დაგირავება;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4.13. ელექტრონული საფულიდან ფულადი სახსრების გატანა/გადარიცხვა გულისხმობს ენბისის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ანგარიშიდან ფულადი სახსრების გატანა/გადარიცხვას მომხმარებლის დავალების შესაბამისად, მომხმარებლის ელექტრონულ საფულეზე არსებული ელექტრონული ფულის მოცულობით, ოპერაცია მოიცავს იმ საკომისიოს ოდენობასაც, რომელიც ეკისრება მომხმარებელს კონკრეტული ოპერაციის შესრულებისათვის;</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>5. საგადახდო ბარათი</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.1. მომსახურების გაწევის მიზნით, ენბისი მომხმარებელს გადასცემს საბანკო პლასტიკურ ბარათს და აღნიშნული ბარათის მონაცემებით არეგისტრირებს მომხმარებელს ენბისის ვებგვერდზე. რეგისტრაციის შედეგად მომხმარებლის სახელზე იქმნება მომხმარებლის პლასტიკურ ბარათზე მიბმული ელექტრონული საფულე. მომხმარებელს ელექტრონული საფულის მართვის პანელთან წვდომა შეუძლია პროვაიდერის ვებ-გვერდიდან. პროვაიდერმა შესაძლოა შეიმუშავოს მობილური აპლიკაცია, რომლითაც მომხმარებელი ასევე შეძლებს თავის ელექტრონულ საფულესთან წვდომასა და შესაბამისი ფუნქციონალის გამოყენებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.2. მომხმარებელს პლასტიკური ბარათის მეშვეობით შეუძლია გაანაღდოს ელექტრონული საფულის ბალანსზე არსებული თანხები საქართველოს ტერიტორიაზე განთავსებული ცალკეული კომერციული ბანკების ATM ბანკომატებიდან;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.3. ელექტრონული საფულის ბალანსზე არსებული თანხების ფარგლებში, პლასტიკური ბარათისმეშვეობით მომხმარებელს შეუძლია განახორციელოს საქონლის ან მომსახურების ღირებულების გადახდა სავაჭრო ობიექტ(ებ)ში, მიიღოს Cashback &ndash;ი (თანხის ნაწილის საგადახდო ბარათზე დაბრუნება) საგადახდო ბარათით განხორციელებული ოპერაციიდან;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.4. ელქტრონული კომერციისათვის საგადახდო ბარათების (რომელი საგადახდო ბარათებისთვისაც დასაშვებია ელექტრონული კომერციით სარგებლობა) გამოყენების დროს შეიძლება ამოქმედდეს VISA და MasterCard-ის &bdquo;3დ უსაფრთხოების &ldquo;სერვისი (3D secure);</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.5. პროვაიდერის ვებ-გვერდზე განთავსებულია ინფორმაცია იმ კომერციული ბანკების შესახებ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >რომელთა ATM ბანკომატების თუ POS ტერმინალების მეშვეობით მომხმარებელი შეძლებს მომსახურების მიღებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5.6. საგადახდო ბარათი ძალაშია იმ თვის ბოლომდე, რომელიც მითითებულია საგადახდო ბარათზე. მომხმარებლის პლასტიკური ბარათის მეშვეობით თანხის განაღდება ან/და ხარჯვა შესაძლოა დაექვემდებაროს გარკვეულ ლიმიტებს, რომლის თაობაზე ინფორმაცია ხელმისაწვდომი იქნება პროვაიდერის ვებ-გვერდზე;</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>6.ბარათის დაბლოკვა/შეჩერება</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.1. ენბისის უფლება აქვს ნებისმიერ დროს დაბლოკოს/შეაჩეროს ელექტრონული საფულე დამომხმარებლის პლასტიკური ბარათით სარგებლობა, თუ:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.1.1. გონივრული ეჭვის საფუძველზე ჩათვლის, რომ მომხმარებელი საგადახდო მომსახურებასა</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >და მომხმარებლის პლასტიკური ბარათით სარგებლობას ახორციელებს უკანონო შემოსავლისლეგალიზაციის მიზნით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.1.2. აღმოაჩენს, რომ ხელშეკრულების გაფორმებამდე არასათანადოდ განხორცდიელდა მომხმარებლის იდენტიფიკაცია;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.1.3. აღმოაჩენს, რომ მომხმარებელი ცდილობს გაანაღდოს ან გახარჯოს ელექტრონული საფულის ანგარიშზე სისტემური ხარვეზის შედეგად გაჩენილი არასანქცირებული თანხა, რომელიც მას არ ეკუთვნის;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.1.4. მომხმარებლის ელექტრონული საფულის ანგარიში და მომხმარებლის პლსატიკური ბარათი არააქტიურია 1 (ერთი) წლის განმავლობაში.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6.2. ენბისის უფლებამოსილია საგადახდო ბარათით სარგებლობისათვის წვდომის კოდების ერთხელ ან მრავალჯერადად არასწორად გამოყენების შემთხვევაში, დაბლოკოს საგადახდო ბარათი.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>7. ბარათთან დაკავშირებული მხარეთა ვალდებულებები</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.1. მომხმარებელი ვალდებულია:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.1.1. გამოიყენოს მომხმარებლის პლასტიკური ბარათი ამ ხელშეკრულებით დადგენილი პირობების შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.1.2 დაიცვას მისთვის გაცემული მომხმარებლის პლასტიკური ბარათის უსაფრთხოების ზომები</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >და უზრუნველყოს ამ ინსტრუმენტის პერსონიფიცირებული საშუალებების დაცვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.1.3. პლასტიკური ბარათის დაკარგვის, მოპარვის, უკანონო მითვისების ან უკანონო გამოყენების ფაქტის აღმოჩენის შემთხვევაში მისი აღმოჩენიდან უმოკლეს ვადაში შეატყობინოს ამის თაობაზე პროვაიდერს ცხელ ხაზზე (რაც მითითებულია პროვაიდერის ვებ-გვერდზე).</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2. ენბისი ვალდებულია:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2.1. მიიღოს ყველა შესაძლო ზომა მომხმარებლის პლასტიკური ბარათის დაცულობისა და მისი</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >არამართლზომიერი გამოყენების თავიდან აცილების უზრუნველსაყოფად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2.2. არ გახადოს მომხმარებლის პლასტიკური ბარათის პერსონიფიცირებული უსაფრთხოების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მახასიათებლები და საშუალებები ხელმისაწვდომი სხვა პირებისათვის, გარდა თავად ამ მომხმარებლისა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2.3. ნათლად გააცნოს მომხმარებელს მომხმარებლის პლასტიკური ბარათის უსაფრთხოების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მოთხოვნები;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2.4. უზრუნველყოს მომხმარებლისგან ხელშეკრულების 7.1.3. პუნქტით განსაზღვრული შეტყობინების ნებისმიერ დროს მიღება;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.2.5. მომხმარებლისგან 7.1.3. პუნქტით განსაზღვრული შეტყობინების მიღებისთანავე, დაუყონებლივ უზრუნველყოს მომხმარებლის პლასტიკური ბარათის შემდგომი გამოყენების აღკვეთა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.3. პროვაიდერი საკუთარ თავზე იღებს მომხმარებლის პლასტიკური ბარათის ან/და მისიპერსონიფიცირებული უსაფრთხოების მახასიათებლებისა და საშუალებების გაგზავნასთან დაკავშირებულ ყველა რისკს;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.4. საგადახდო ბარათს ენბისი გადასცემს მომხმარებელს სარგებლობაში (დალუქულ კონვერტშიმოთავსებულ, ან/და განაცხადში დაფიქსირებული მობილური ტელეფონის ნომერზე მოკლე ტექსტური შეტყობინების მეშვეობით, ან/და ენბისის მიერ განსაზღვრული სხვა ელექტრონული ფორმით გაგზავნილ წვდომის კოდთან (PIN-კოდი) ერთად) ელექტრონული საფულის ოპერაციების საწარმოებლად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.5. ენბისი პასუხს არ აგებს მის მიერ შეთავაზებულ უსაფრთხოების ზომაზე მომხმარებლის მიერ უარის თქმის შემთხვევაში დამდგარი შედეგისთვის. ასეთ შემთხვევაში უსაფრთხოების ზომაზე</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >უარის თქმის შედეგად, დამდგარი ზიანისთვის პასუხისმგებელია მომხმარებელი;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7.6. მხარეები ვალდებულნი არიან, ამ მუხლით განსაზღვრული ვალდებულებების განზრახ ანდაუდევრობით შეუსრულებლობით გამოწვეული ზიანი აანაზღაურონ საქართველოს მოქმედი</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >კანონმდებლობის შესაბამისად.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>8.არაავტორიზებული ან არასწორად შესრულებული ოპერაციები</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >8.1. არაავტორიზებულ ან არასწორად შესრულებულ ოპერაციებთან დაკავშირებული წესები განისაზღვრება საქართველოს კანონით &ldquo;საგადახდო სისტემისა და საგადახდო მომსახურების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >შესახებ&rdquo;.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>9. საკომისიო და ლიმიტები</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >9.1. ინფორმაცია ყველა სახის საკომისიოს და ლიმიტის შესახებ, რომელიც დადგენილია მომსახურების მიღებისთვის ან მასთან დაკავშირებით, განთავსებულია პროვაიდერის ვებგვერდზე.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>0. პერსონალური მონაცემების და კონფიდენციალურობის დაცვა</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.1. მომხმარებელი, შეთანხმების საფუძველზე ნებას რთავს ენბისის, რომ გამოიყენოს ნებისმიერი მის მიერ მოწოდებული ინფორმაცია, ამ ხელშეკრულებისა და ინფორმაციის დაცვის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პოლიტიკის შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.2. მომხმარებელი ადასტურებს, რომ ენბისის აქვს უფლება შეინახოს მომხმარებლის მიერ მოწოდებული ინფორმაცია ტრანზაქციების ისტორიის შექმნის და ვებ-გვერდის ფუნქციონირების სხვა მიზნებისთვის;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.3. ენბისის უფლება აქვს დაამუშაოს მომხმარებლის პერსონალური მონაცემები პერსონალურ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მონაცემთა დაცვის შესახებ საქართველოს კანონის მე-5 მუხლის &bdquo;ა&ldquo; , &bdquo;გ&ldquo; , &bdquo;თ&ldquo; პუნქტების და სხვა</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სამართლებრივი აქტების შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.4. ენბისი მომხმარებლის იდენტიფიკაცია/ვერიფიკაციის მიზნით აგროვებს ორი სახის ინფორმაციას - პირად და არაპირად მაიდენტიფიცირებელ ინფორმაციას:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ა) პირადი მაიდენტიფიცირებელი ინფორმაციის მეშვეობით ხდება კონკრეტული მომხმარებლის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >იდენტიფიკაცია: სახელი და გვარი, პირადი ნომერი, ელექტრონული ფოსტის მისამართი, სქესი</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დაბადების თარიღი, ბარათის ნომერი, ვადის გასვლის თარიღი, ავთენტურობის კოდი და სხვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბ) არაპირადი მაიდენტიფიცირებელი ინფორმაცია კი არ ახდენს კონკრეტული მომხმარებლისიდენტიფიკაციას და შეიძლება მოიცავდეს: ბრაუზერის სახეობას, &bdquo;IP &ldquo;მისამართს და სხვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.5. ენბისის უფლება აქვს მომხმარებლის მიერ ვებგვერდზე მოწოდებული ინფორმაცია</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გაუმჟღავნოს და გადასცეს: აფილირებულ ნებისმიერ პირს და მის უფლებამოსილ წარმომადგენელს, ნებისმიერ სხვა პირს ან ორგანიზაციას მომხმარებლის თანხმობით და შესაბამის პირს ან ორგანოს კანონმდებლობით გათვალისწინებულ შემთხვევაში ინფორმაციის</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გაცემის უფლების ან ვალდებულების არსებობისას, როგორიცაა მაგალითად სასამართლო, არბიტრაჟი და სხვა;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.6. ენბისის მიერ მომხმარებლის ინფორმაციის (პერსონალური მონაცემების) დამუშავება შესაძლებელია განხორციელდეს სხვადასხვა მიზნით, მათ შორის, შეზღუდვის გარეშე:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.6.1. მომსახურების სრულად და ჯეროვნად განხორციელებისთვის;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.6.2. უსაფრთხოების უზრუნველსაყოფად, აგრეთვე თაღლითობის, გაყალბების ან გაყალბების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მცდელობის, ფულის გათეთრების ან სხვა დანაშაულის გამოსავლენად ან პრევენციისთვის;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.6.3. მარკეტინგული მიზნით, რაც მოიაზრებს ენბისის ან/და მისი პარტნიორი ორგანიზაციების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >(მათ შორის, შპს ლიდერბეთის) მიერ სხვადასხვა პროდუქტის/მომსახურების პერიოდულ შეთავაზებას;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.7. თუ მომხმარებელი თვლის, რომ მის შესახებ ენბისიში არსებული ინფორმაცია არ შეესაბამებასინამდვილეს ან არ არის სრული, იგი ვალდებულია, დაუყოვნებლივ წერილობით ან/და ცხელი ხაზის საშუალებით აცნობოს ენბისის აღნიშნულის შესახებ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.8. მომხმარებელი უფლებამოსილებას ანიჭებს ენბისის და აცხადებს თანხმობას, რომ პერსონალური მონაცემების გარდა, დაამუშავოს მომხმარებლის ფოტოსურათი, გამოსახულება</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >და საჭიროების შემთხვევაში, სხვა ბიომეტრიული მონაცემები. ამასთანავე, განახორციელოსმომხმარებლის ფოტოსურათის გამოთხოვა/მოპოვება სახელმწიფო სერვისების განვითარების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სააგენტოდან მის მიერ წარმოდგენილი ფოტოსურათების შემოწმება-შედარებისათვის, შემდგომში საიდენტიფიკაციო დოკუმენტის გაყალბების ან და გაყალბების მცდელობის თავიდან აცილების მიზნით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10.9. ამასთანავე, მომხმარებელი თანახმაა, რომ ენბისიმ უშუალოდ ან უფლებამოსილი პირის მეშვეობით დისტანციურად განახორციელოს მისი ფოტო/ვიდეო გადაღება და შეამოწმოს მათი</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სიზუსტე შემდგომში გაყალბების თავიდან აცილების და იდენტობის დადგენის მიზნებისთვის.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბიომეტრიული მონაცემების წაშლა ხორციელდება იდენტიფიკაციის პროცესის დასრულებისთანავე;</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>1. მხარეთა პასუხისმგებლობა</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >11.1. ენბისის ეკისრება პასუხიმგებლობა და ვალდებულება, მხოლოდ იმ შემთხვევაში, თუ ეს გამოწვეულია მისი განზრახი ქმედებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >11.2. მომხმარებელი პასუხს აგებს ენბისის წინაშე ხელშეკრულებაში მოცემული ინფორმაციის სიზუსტეზე და სისრულეზე, ვებგ.ვერდზე დარეგისტრირებისას მითითებული ინფორმაციის სიზუსტეზე, ყველა იმ ინფორმაციის სიზუსტეზე, რომელიც მან პროვაიდერს მიაწოდა ხელშეკრულების დადებისას, დადებამდე ან დადების შემდგომ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >11.3. ფორს-მაჟორის მოქმედების განმავლობაში არცერთი მხარე არ აგებს პასუხს ხელშეკრულებით ნაკისრი ვალდებულებების სრულად ან ნაწილობრივ შეუსრულებლობისათვის ან არაჯეროვნად შესრულებისათვის.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>2. კომუნიკაცია მხარეთა შორის</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >12.1. მომხმარებელს შეუძლია ენბისისთან კომუნიკაცია მოახდინოს ვებ-გვერდზე მითითებულ ტელეფონის ნომერით (ცხელ ხაზზე) ან ელექტრონული ფოსტის მეშვეობით, ასევე გაგზავნოს წერილობითი შეტყობინება ფოსტით ენბისის იურიდიულ მისამართზე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >12.2. ენბისის მიერ მომხმარებლისთვის მის მიერ მითითებულ მობილურის ნომერზე (მოკლე ტექსტური შეტყობინების), ან/და ენბისის მიერ შემდგომში განსაზღვრული კომუნიკაციის სხვა საშუალებებით გაგზავნილი შეტყობინება ჩაბარებულად ითვლება შეტყობინების გაგზავნის დღეს, ხოლო ენბისის ვებ-გვერდზე ინფორმაციის განთავსებისას - ინფორმაციის განთავსების დღეს.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>3. ხელშეკრულების ხანგრძლივობა და შეწყვეტა</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.1. ეს ხელშეკრულება მოქმედებს რომელიმე მხარის მიერ მისი შეწყვეტის მომენტამდე პირობებით განსაზღვრული წესის შესაბამისად;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.2. წინამდებარე პირობები ძალაში შევა მას შემდეგ, რაც მომხმარებელი შეავსებს შესაბამის განაცხადს, ხელს მოაწერს/დაადასტურებს ელექტრონული ფორმის თანხმობით და ენბისის განაცხადებს თანხმობას მომსახურების გაწევაზე. ენბისის თანხმობა გამოიხატება მომხმარებლისთვის მომსახურების გაწევის დაწყებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.3. ხელშეკრულება შესაძლოა შეწყდეს ამ ხელშეკრულებით განსაზღვრული დებულებების შესაბამისად. ხელშეკრულების შეწყვეტა ავტომატურად იწვევს მომხმარებლის სახელზე გახსნილი ელექტრონული საფულის ანგარიშის გაუქმებასა და პროვაიდერის ყველა მომსახურების შეწყვეტას.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.4. ხელშეკრულება შესაძლოა შეწყდეს:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.4.1. მომხმარებლის მიერ, ნებისმიერ დროს, პროვაიდერისათვის წერილობითი შეტყობინების</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გაგზავნის გზით, რა შემთხვევაშიც პროვაიდერს უფლება აქვს, მოსთხოვოს მომხმარებელს გარკვეული დოკუმენტაციის წარმოდგენა ხელშეკრულების შეწყვეტის გაფორმებასა და დასრულებასთან დაკავშირებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.4.2. ენბისის მიერ, ნებისმიერ დროს, მომხმარებლისათვის წინასწარი წერილობითი შეტყობინების გაგზავნის გზით, რა შემთხვევაშიც შეწყვეტის შესახებ შეტყობინება მომხმარებელს უნდა გაეგზავნოს მინიმუმ 1 (ერთი) თვით ადრე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13.4.3. ენბისის მიერ, ნებისმიერ დროს, მომხმარებლისათვის წინასწარი წერილობითი შეტყობინების გაგზავნის გზით, თუ მომხმარებელი არღვევს წინამდებარე ხელშეკრულებით განსაზღვრულ რომელიმე ვალდებულებას ან პირობას და არ აღმოფხვრის დარღვევას გაფრთხილების მიუხედავად.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>4. მარეგულირებელი კანონმდებლობა და დავების გადაწყვეტა</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.1. მომხმარებელი ვალდებულია: ტრანზაქციის გასაჩივრების შემთხვევაში, მიმართოს პროვაიდერს სადავო ტრანზაქციის განხორციელებიდან 40 დღის ვადაში და წარუდგინოს გასაჩივრებულ ტრანზაქციასთან დაკავშირებული ინფორმაცია/დოკუმენტები, რაც მოიცავს, მაგრამ არ შემოიფარგლება შემდეგით:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ტრანზაქციის თარიღი, ტრანზაქციის ოდენობა, ბარათის მონაცემები, ინფორმაცია სავაჭრო ობიექტზე (რელევანტურობის შემთხვევაში), შეძენილი ნივთი და/ ან მომსახურება (რელევანტურობის შემთხვევაში), ქვითარი/გადახდის დამადასტურებელი დოკუმენტი (რელევანტურობის შემთხვევაში) და ნებისმიერი სხვა დამატებითი ინფორამცია და/ან დოკუმენტი, რასაც პროვაიდერი, საკუთარი შეხედულებისამებრ, საჭიროდ მიიჩნევს საკითხის შესწავლისა და განხილვის პროცესში.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.2. პროვაიდერი ვალდებულია განიხილოს მომხმარებლის საჩივარი განხორციელებულ ოპერაციასთან დაკვაშირებით და მიიღოს გადაწყვეტილება და გააცნოს იგი მომხმარებელს საჩივრის მიღებიდან არაუგვიანეს 15 (თხუთმეტი) სამუშაო დღისა. საჩივრის მიღების დღედ ითვლება მომხმარებლის მიერ პროვაიდერისთვის საჩივრის წარდგენის სამუშაო დღე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.2.1. იმ შემთხვევაში, თუ პროვაიდერისაგან დამოუკიდებელი მიზეზების გამო მითითებულ 15</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >(თხუთმეტი) დღიან ვადაში ვერ ხერხდება საჩივრის განხილვა და მასზე გადაწყვეტილების მიღება, პროვაიდერი ვალდებულია ამის შესახებ წერილობითი შეტყობინებით აცნობოს მომხმარებელს სადაც იქნება მითითება დაგვიანების დასაბუთებული მიზეზისა და საჩივრის განხილვა/ გადაწყვეტისთვის ვადის გაგრძელების შესახებ. საჩივარზე გადაწყვეტილების მიღებისა და საბარათე ინსტრუმენტის მფლობელისთვის გაცნობის ვადა არ უნდა აღემატებოდეს საჩივრის მიღებიდან 55 სამუშაო დღეს.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.3. მომხმარებლის მიერ ოპერაციების გასაჩივრების თითოეული შემთხვევა განიხილება ინდივიდუალურად შესაბამისი საგადახდო სისტემების მიერ დადგენილი წესების, ადგილობრივ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ფინანსურ სექტორში დამკვიდრებული პრაქტიკის და მოქმედი კანონმდებლობის გათვალისწინებით;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.4. საჩივრების და პრეტენზიების მიღება ხორციელდება ყოველ სამუშაო დღეს 10:00 საათიდან 18:00 საათამდე, შემდეგი საშუალებებით: ცხელ ხაზთან დაკავშირებით, ელ-ფოსტით: <a href="mailto:info@nbc.ge" style="color:#954f72; text-decoration:underline">info@nbc.ge</a> &nbsp;ან წერილობითი ფორმით.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14.2. თუ მხარეთა შორის წარმოიშვა დავა ან უთანხმოება წინამდებარე ხელშეკრულებასთან დაკავშირებით, მხარეები შეეცდებიან დავა გადაჭრან მოლაპარაკებების გზით, შეუთანხმებლობის შემთხვევაში, ნებისმიერ მხარეს უფლება აქვს მიმართოს თბილისის საქალაქო სასამართლოს.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>1</strong><strong>5. დასკვნითი დებულებები</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.1. მომხმარებელი აცხადებს და იძლევა გარანტიას, რომ:</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.1.1. ხელშეკრულების დასადებად წარმოდგენილია ზუსტი და უტყუარი ინფორმაცია;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.1.2. ენბისის მომსახურების მისაღებად ინფორმაციის მოწოდება ხდება მომხმარებლის სურვილით და ამისათვის მას გააჩნია კანონმდებლობით, ან ხელშეკრულებით გათვალისწინებული უფლება/ნებართვა.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.2. მომხმარებლის მიერ ელექტრონული ფორმით გაკეთებულ თანხმობას (რომელიც მკაფიოდ</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გამოხატავს მომხმარებლის ნებას) აქვს მატერიალური დოკუმენტის თანაბარი იურიდიული ძალა (მატერიალურ დოკუმენტზე პირადი ხელმოწერის თანაბარი იურიდიული ძალა);</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.3. ამ ხელშეკრულებაში ცვლილებების შეტანა შესაძლებელია ვებ-გვერდზე <a href="http://www.nbc.ge/" style="color:#954f72; text-decoration:underline">www.nbc.ge</a> , <a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline">www.leadercard.ge</a> - ზე გამოქვეყნების გზით, რომელიც ძალაში შედის მისი გამოქვეყნებიდან 1(ერთი) კალენდარული თვის შემდეგ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.4. ხელშეკრულების პირობებში განხორციელებული ნებისმიერი ცვლილება ჩაითვლებამომხმარებელთან შეთანხმებულად, თუ ცვლილების ძალაში შესვლამდე არ განაცხადებს უარს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დაგეგმილი ცვლილების თაობაზე;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.5. ცვლილებების ამოქმედებამდე, მომხმარებელს უფლება აქვს დაუყოვნებლივ შეწყვიტოსხელშეკრულება ყოველგვარი საკომისიოს, ხარჯის და პირგასამტეხლოს გადახდის გარეშე.</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.6. მომხმარებელი ვალდებულია ენბისის მოთხოვნის შემთხვევაში და განსაზღვრულ ვადაში</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მიაწოდოს მოთხოვნილი ნებისმიერი დამატებითი ინფორმაცია ან/და დოკუმენტაცია;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.7. მხარეები თანხმდებიან, რომ ხელშეკრულების შესრულების ადგილად მიიჩნევა ენბისის იურიდიული მისამართი;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15.8. მომხმარებელს უფლება აქვს, ხელშეკრულების მოქმედების მთელ პერიოდში, მოსთხოვოს</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პროვაიდერს და მიიღოს ნებისმიერი ინფორმაცია, რომლის მიღების უფლებაც გააჩნია &ldquo;საგადახდო მომსახურების განხორციელებისას მომხმარებლისთვის აუცილებელი ინფორმაციის მიწოდების წესის დამტკიცების შესახებ&rdquo; საქართველოს ეროვნული ბანკის პრეზიდენტის 2016 წლის 6 იანვრის N1/04 ბრძანებით.</span></span></span></p>
            
            <p style="text-align:justify">&nbsp;</p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>შპს &bdquo;ენბისი &ldquo;</strong></span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >საიდენტიფიკაციო კოდი: 405150063</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სარეგისტრაციო ნომერი: 0053-9004</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >იურიდიული მისამართი: ქ. თბილისი, ონიაშვილის ქ. N1, კომერციული ფართი N5</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ფაქტობრივი მისამართი: ქ. თბილისი, ონიაშვილის ქ. N1, სართული 3 ბინა N7</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ელექტრონული ფოსტა: <a href="mailto:info@nbc.ge" style="color:#954f72; text-decoration:underline">info@nbc.ge</a> ;</span></span></span></p>
            
            <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ეს ხელშეკრულება დადებულია და რეგულირდება ქართულ ენაზე.</span></span></span></p>

        `
    }
}