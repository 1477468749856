import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	contentContainerGrid: {
		width: "100%",
		margin: "auto",
		marginTop: 40,
		[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
			minHeight: 'max(calc(100vh - 250px), 700px)',
		}
	},
	featuredNewsContainer: {
		marginBottom: 85,
		maxWidth: '95rem',
	},
}));

export default useStyles;
