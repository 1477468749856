import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../styles/pages/AtmsStyles";
import AtmsMap from "../components/AtmsMap";
import AtmLocationsList from "../components/AtmLocationsList";
import locations from "../data/locations";
import useLanguage from "../hooks/useLanguage";


function Atms(props) {
    const classes = useStyles(props);
    const defaultCenter = { lat: 41.7151, lng: 44.8271 };
    const defaultZoom = 8;

    const { key: languageKey, pages: { atms: STRINGS } } = useLanguage();


    const [gMapRef, setGMapRef] = useState(null);
    const [supercluster, setSupercluster] = useState([]);
    const [clusters, setClusters] = useState([]);
    const [zoom, setZoom] = useState(defaultZoom);
    const [selectedCity, setSelectedCity] = useState('');


    const points = locations.map(({ id, city, address, phone, workingHours, position }) => ({
        id,
        type: "Feature",
        properties: { city: city[languageKey], address: address[languageKey], phone, workingHours },
        geometry: {
            Type: "Point",
            coordinates: [parseFloat(position.lng), parseFloat(position.lat)]
        }
    }))

    const handleLocationSelect = (location, type) => {
        if (location) {
            let cluster = clusters.find(cluster => cluster.id === location.id && cluster.properties.cluster);
            let expansionZoom = type === 'city' ? 11.5 : 17;
            if (type === 'city' && cluster) {
                expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id) + 2,
                    20
                );
            }
            setZoom(expansionZoom);
            setSelectedCity(location.properties.city);
            // console.log('ref', gMapRef.current)
            if (gMapRef) {
                gMapRef.panTo({ lat: location.geometry.coordinates[1], lng: location.geometry.coordinates[0] });
            }
        }
        else {
            setZoom(defaultZoom);
            setSelectedCity('');
            gMapRef.setCenter(defaultCenter);
        }
        // console.log('hls', location, clusters, supercluster);

    }


    return (
        <Grid item container xs={10} sm={11} md={10} xl={10} className={classes.contentContainerGrid} justify="center">
            < Grid item xs={12} className={classes.mapContainer} >
                <AtmsMap
                    points={points}
                    gMapRef={gMapRef}
                    setGMapRef={setGMapRef}
                    setSupercluster={setSupercluster}
                    setClusters={setClusters}
                    zoom={zoom}
                    addressClick={handleLocationSelect}
                    setZoom={setZoom}
                    defaultCenter={defaultCenter}
                />
                <Typography className={classes.addressesText}>
                    {STRINGS.addresses}
                </Typography>
                <AtmLocationsList selectedCity={selectedCity} points={points} onSelect={handleLocationSelect} onClick={handleLocationSelect} zoomIsDefault={zoom === defaultZoom} />
            </Grid >
        </Grid >
    );
}

export default Atms;
