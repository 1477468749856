import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import useStyles from "../styles/components/FAQItemStyles";
import AddSVGR from '../smart-assets/SVGRs/AddSVGR';
import SubtractSVGR from '../smart-assets/SVGRs/SubtractSVGR';
import classNames from "classnames";
import HTMLReactParser from 'html-react-parser'


const FAQItem = (props) => {
    const classes = useStyles(props);

    const { onToggle, question, answer, isOpen } = props;

    return (
        <Grid item xs={12} container className={classes.container} onClick={onToggle}>
            <Box className={classes.questionPlusContainer}>
                <Typography variant="body1" className={classes.question}>
                    {HTMLReactParser(question)}
                </Typography>
                {isOpen ? <SubtractSVGR className={classes.subtractSVGR} /> : <AddSVGR className={classes.addSVGR} />}
            </Box>
            <Typography variant="body1" className={classNames(classes.answer, isOpen ? classes.answerOpen : classes.answerClosed)} onClick={e => e.stopPropagation()}>
                {HTMLReactParser(answer)}
            </Typography>
        </Grid>
    );
};

export default FAQItem;
