import * as React from "react"

function UserSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={50}
            height={50}
            viewBox="0 0 50 50"
            {...props}
        >
            <path
                d="M0 25a25 25 0 1125 25A25 25 0 010 25zm25 20.313A20.329 20.329 0 0040.6 38a.538.538 0 00.11-.437.557.557 0 00-.262-.354 68.269 68.269 0 00-8.413-3.479l-1.406-.521a2 2 0 01-.994-1.5 4.552 4.552 0 01.519-3.647c1.748-1.936 3.456-4.312 3.456-10.249a8.64 8.64 0 10-17.227 0c0 5.938 1.708 8.313 3.456 10.249a4.552 4.552 0 01.519 3.647 2 2 0 01-.994 1.5l-1.4.521a68.2 68.2 0 00-8.417 3.479.557.557 0 00-.262.354.527.527 0 00.112.438A20.328 20.328 0 0025 45.312z"
                fill="#f1c157"
                fillRule="evenodd"
                data-name="Users / Natural-Close-Up-Single-User-Neutral / single-neutral-circle"
            />
        </svg>
    )
}

export default UserSVGR
