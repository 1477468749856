const news = [
  {
    id: 1,
    title: {
      Geo: "უკონტაქტო გადახდა: ლიდერქარდი ახლა უკვე უკონტაქტო გადახდის ფუნქციით.",
      Eng: "Contactless payment: Leadercard has now a contactless payment function.",
      Rus: "Бесконтактная оплата: Leadercard теперь имеет функцию бесконтактной оплаты.",
    },
    date: new Date(2020, 10, 9), // წელი-თვე-დღე
    shortDescription: {
      Geo: "უკონტაქტოდ გადახდა  შესაძლებელია ყველგან სადაც განთავსებულია თი ბი სი ბანკის ან საქართველოს ბანკის ტერმინალი.",
      Eng: "Contactless payment  possible everywhere ,where are placed  terminals of TBC Bank or Bank of Georgia.",
      Rus: "Бесконтактная оплата возможна везде, где есть терминалы банков TBC Bank или Bank of Georgia.",
    },
    description: {
      Geo: `●      უკონტაქტოდ გადახდა  შესაძლებელია ყველგან სადაც განთავსებულია თი ბი სი ბანკის ან საქართველოს ბანკის ტერმინალი.
		
●      ასევე შესაძლებელია თანხის განაღდება თი ბი სი ბანკისა და საქართველოს ბანკის ბანკომატებით მთელი საქართველოს მასშტაბით`,
      Eng: `●      Contactless payment  possible everywhere ,where are placed  terminals of TBC Bank or Bank of Georgia.
		
●      It is also possible to withdraw money through ATMs of TBC Bank and Bank of Georgia throughout Georgia.`,
      Rus: `●      Бесконтактная оплата возможна везде, где есть терминалы банков TBC Bank или Bank of Georgia.
		
●      Также возможно снятие денег через банкоматы TBC Bank и Bank of Georgia по всей Грузии.`,
    },
    images: {
      Geo: {
        s: require("../assets/news/1/Geo/s.jpg"),
        m: require("../assets/news/1/Geo/m.jpg"),
        l: require("../assets/news/1/Geo/l.jpg"),
      },
      Eng: {
        s: require("../assets/news/1/Eng/s.jpg"),
        m: require("../assets/news/1/Eng/m.jpg"),
        l: require("../assets/news/1/Eng/l.jpg"),
      },
      Rus: {
        s: require("../assets/news/1/Rus/s.jpg"),
        m: require("../assets/news/1/Rus/m.jpg"),
        l: require("../assets/news/1/Rus/l.jpg"),
      },
    },
  },
  /*{
    id: 2,
    title: {
      Geo: "1000 ქულა საჩუქრად ბეთმარკეტში",
      Eng: "1000 points as a gift at the Betmarket",
      Rus: "1000 баллов в подарок на Betmarket",
    },
    date: new Date(2020, 10, 9), // წელი-თვე-დღე
    shortDescription: {
      Geo: "აიღე ლიდერქარდი და მიიღე 1000 ქულა საჩუქრად ბეთმარკეტში!",
      Eng: "Take a Leadercard and get 1000 points for free on Betmarket!",
      Rus: "Возьми  карту Leadercard и получи 1000 очков в подарок на Betmarket!",
    },
    description: {
      Geo: `აიღე ლიდერქარდი და მიიღე 1000 ქულა საჩუქრად ბეთმარკეტში! 1000 ქულა დაერიცხებათ მხოლოდ იმ მომხმარებლებს, რომლებიც პირველად იღებენ ლიდერქარდს. ძველი ბარათის ახლით ჩანაცვლების შემთხვევაში ქულები არ ირიცხება. 

●      ბეთ მარკეტში სასაჩუქრე პაკეტები მოიცავს:
		სპორტის, სლოტების, მაგიდის სპინ თამაშების (სპინ ბურა, სპინ დომინო, სპინ ჯოკერი, სპინ პოკერი) და ვოლტის ბონუსებს. 
		
●	   ლიდერქარდის აღება შესაძლებელია ლიდერბეთის სალაროებსა და ლიდერქარდის სარეგისტრაციო ჯიხურებში, ან მარტივად ონლაინ გამოწერით.
		
●	   ლიდერქარდის აღება ან შეკვეთა არის სრულიად უფასო.`,
      Eng: `Take a Leadercard and get 1000 points for free on Betmarket! 1000 points will be awarded only to those users who receive a Leadercard for the first time. When replacing an old card with a new one, points are not awarded. 

●      Gift packages on BetMarket include:
		Sports, slots, table games (spin-bura, spin-domino, spin-joker, spin-poker) and Volt bonuses. 
		
●	   Leadercard can be obtained at Leaderbet cash registers and Leadercard advertising kiosks, or simply by online order.
		
●	   You can get a Leadercard absolutely free.`,
      Rus: `Возьми  карту Leadercard и получи 1000 очков в подарок на Betmarket! 1000 баллов будут начислены только тем пользователям, которые впервые получают карту LeaderСard. При замене старой карты на новую баллы не начисляются. 

●      В подарочные пакеты на BetMarket входят::
		Спорт, слоты, настольные игры (спин-бура, спин-домино, спин-джокер, спин-покер) и бонусы вольта 
		
●	   Leadercard можно получить в кассах Leaderbet и в рекламных киосках LeaderСard или просто заказать онлайн.
		
●	   Взять или заказать карту Leadercard  можно абсолютно бесплатно.`,
    },
    images: {
      Geo: {
        s: require("../assets/news/2/Geo/s.jpg"),
        m: require("../assets/news/2/Geo/m.jpg"),
        l: require("../assets/news/2/Geo/l.jpg"),
      },
      Eng: {
        s: require("../assets/news/2/Eng/s.jpg"),
        m: require("../assets/news/2/Eng/m.jpg"),
        l: require("../assets/news/2/Eng/l.jpg"),
      },
      Rus: {
        s: require("../assets/news/2/Rus/s.jpg"),
        m: require("../assets/news/2/Rus/m.jpg"),
        l: require("../assets/news/2/Rus/l.jpg"),
      },
    },
  },
  {
    id: 3,
    title: {
      Geo: "10% ქეშბექი: გადაიხადე ლიდერქარდით და დაიბრუნე გადახდილი თანხის 10% ყველგან და ყველაფერზე, მთელი საქართველოს მასშტაბით.",
      Eng: "10% Cashback: pay with a Leadercard and get back 10% of the paid amount ,anywhere and anytime, throughout Georgia.",
      Rus: "10% Cashback: оплати картой Leadercard и получи обратно 10% от уплаченной суммы где угодно и когда угодно, по всей Грузии.",
    },
    date: new Date(2020, 10, 9), // წელი-თვე-დღე
    shortDescription: {
      Geo: "ქეშბექის მიღება შესაძლებელია ნებისმიერი სავაჭრო ობიექტში გადახდისას, სადაც განთავსებულია საქართველოს ბანკის ან თიბისი ბანკის ტერმინალი.",
      Eng: "Cashback can be obtained by paying at any place where are placed the Bank of Georgia or TBC Bank terminals.",
      Rus: "Cashback можно получить, заплатив на любом месте, где находится терминал Банков Bank of Georgia или TBC Bank.",
    },
    description: {
      Geo: `●      ქეშბექის მიღება შესაძლებელია ნებისმიერი სავაჭრო ობიექტში გადახდისას, სადაც განთავსებულია საქართველოს ბანკის ან თიბისი ბანკის ტერმინალი.
 
●      გადახდილი თანხის 10% მომენტალურად აისახება ლიდერქარდის ანგარიშსზე ნებისმიერი გადახდის შემდეგ.

<small>*კომპანია რიგ შემთხვევებში იტოვებს უფლებას მომხმარებელს დაურიცხოს ქეშქები.<small>`,
      Eng: `●      Cashback can be obtained by paying at any place where are placed the Bank of Georgia or TBC Bank terminals.
 
●      10% of the paid amount will be instantly reflected on the Leadercard account after any payment.

<small>* The company in some cases reserves the right to accrue to the client with a cashback.</small>`,
      Rus: `●      Cashback можно получить, заплатив на любом месте, где находится терминал Банков Bank of Georgia или TBC Bank.
 
●      10% от уплаченной суммы будут моментально отражены на счете Leadercard после любой оплаты.

<small>* Компания в некоторых случаях оставляет за собой право начислить клиенту cashback.</small>`,
    },
    images: {
      Geo: {
        s: require("../assets/news/3/Geo/s.jpg"),
        m: require("../assets/news/3/Geo/m.jpg"),
        l: require("../assets/news/3/Geo/l.jpg"),
      },
      Eng: {
        s: require("../assets/news/3/Eng/s.jpg"),
        m: require("../assets/news/3/Eng/m.jpg"),
        l: require("../assets/news/3/Eng/l.jpg"),
      },
      Rus: {
        s: require("../assets/news/3/Rus/s.jpg"),
        m: require("../assets/news/3/Rus/m.jpg"),
        l: require("../assets/news/3/Rus/l.jpg"),
      },
    },
  },*/
  {
    id: 4,
    title: {
      Geo: "ახლა უკვე ლიდერქარდის ყველა სერვისის გამოყენება შეგიძლია უფრო მარტივად, შენს მობილურში!",
      Eng: "Now you can easily use all Leadercard services on your mobile phone!",
      Rus: "Теперь вы можете легко пользоваться всеми сервисами Leadercard на своем мобильном телефоне!",
    },
    date: new Date(2020, 6, 29), // წელი-თვე-დღე
    shortDescription: {
      Geo: "გადმოიწერე ლიდერქარდის აპლიკაცია დარეგისტრირდი და მიიღე წვდომა ყველა სერვისზე!",
      Eng: "Install the Leadercard app Register and get access to all services!",
      Rus: "Установите приложение Leadercard Зарегистрируйтесь и получите доступ ко всем сервисам!",
    },
    description: {
      Geo: `გადმოიწერე ლიდერქარდის აპლიკაცია 
დარეგისტრირდი და მიიღე წვდომა ყველა სერვისზე! 
	  
აპლიკაციის საშუალებით შეგეძლება:
●      გამოიყენო ლიდერქარდის სერვისები მარტივად 
●      შეამოწმო ბალანსზე არსებული ნაშთები და გაუწიო კონტროლი  
●      გადაიტანო თანხა ლიდერქარდის ანგარიშზე ან პირიქით 
●      შეამოწმო ტრანზაქციების ისტორია
●      მართო ლიდერქარდის ბარათი: შეცვალო ბარათის პინი და ა.შ
●      მუდმივად თვალი ადევნო ლიდერქარდის სიახლეებს

<span style="display: flex; margin-top: 10px;">
<a target="_blank" href='https://play.google.com/store/apps/details?id=com.leadercard' ><img style="margin-right: 10px;" width='130' height="38" alt='Get it on Google Play' src='${
        require("../assets/google-play-badge.png").default
      }'/></a>
<a target="_blank" href='https://apps.apple.com/us/app/leadercard-e-wallet/id1575881474'><img width='130' height="38" alt='Get it on Play Store' src='${
        require("../assets/app-store-badge.png").default
      }'/></a>
</span>
	  `,
      Eng: `Install the Leadercard app
Register and get access to all services!

Through the app you can:
●      Use Leadercard services easily
●      Check and control balance
●      Transfer money to LeaderCard account or vice versa
●      Check transaction history
●      Manage Leadercard: change PIN code , etc.
●      Constantly follow the news of  Leadercard`,
      Rus: `Установите приложение Leadercard
Зарегистрируйтесь и получите доступ ко всем сервисам!
	  
Через приложение вы сможете:
●      Легко воспользоваться сервисами Leadercard
●      Проверять и контролировать баланс
●      Перевести сумму насчет LeaderCard или наоборот
●      Проверить историю транзакций
●      Управлять Leadercard: изменить PIN-код карты и т.д.
●      Постоянно следить за новостями Leadercard
	  `,
    },
    images: {
      Geo: {
        s: require("../assets/news/4/Geo/s.jpg"),
        m: require("../assets/news/4/Geo/m.jpg"),
        l: require("../assets/news/4/Geo/l.jpg"),
      },
      Eng: {
        s: require("../assets/news/4/Eng/s.jpg"),
        m: require("../assets/news/4/Eng/m.jpg"),
        l: require("../assets/news/4/Eng/l.jpg"),
      },
      Rus: {
        s: require("../assets/news/4/Rus/s.jpg"),
        m: require("../assets/news/4/Rus/m.jpg"),
        l: require("../assets/news/4/Rus/l.jpg"),
      },
    },
  },
];

export default news.sort((a, b) => a.date.getTime() - b.date.getTime());
