import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footerStyles: {
    minHeight: 200,
    backgroundColor: "transparent",
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      // height: 285,
      borderTop: "none",
    },
    boxShadow: "none",
  },
  toolBarStyles: {
    // background: "blue",
    display: "flex",
    alignItems: "flex-start",
    // justifyContent: "space-between",
    height: "100%",
    width: "100%",
  },
  gutterStyles: {
    padding: 0,
    // padding: "0 100px",
    // [theme.breakpoints.down("md")]: {
    // 	padding: "0 50px",
    // },
    // [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
    // 	padding: '0 20px'
    // }
  },
  boxStyles: {
    marginTop: 28,
  },
  logo: {
    width: 144,
    height: 30,
    cursor: "pointer",
  },
  mainGrid: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
  topGrid: {
    height: "50%",
  },
  bottomGrid: {
    boxSizing: "border-box",
    borderTop: "1px solid #181e2e",
    height: "50%",
    // background: "green",
  },
  copyright: {
    fontFamily: "var(--font-family-2)",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 2,
    color: "#343e57",
  },
  appDownloads: {
    width: 230,
  },
  desktopVersion: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      display: "none",
    },
  },
  mobileVersion: {
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      display: "none",
    },
    "& $logo": {
      width: 124,
    },
    "& $topGrid": {
      height: 26,
    },
    "& $bottomGrid": {
      height: 320,
      borderTop: "none",
      margin: 0,
      display: "flex",
      justifyContent: "space-evenly",
    },
    "& $navigationContainer": {
      width: "100%",
    },
    "& $navigationContainer > div": {
      justifyContent: "center",
      width: "100%",
      marginLeft: 0,
    },
    "& $socialsContainer": {
      height: 90,
      width: "100%",
    },
    "& $socialsContainer svg": {
      height: 20,
      width: "auto",
    },
    "& $socialsContainer > div": {
      width: "100%",
      justifyContent: "space-evenly",
    },
    "& $socialsContainer > div > div:last-of-type": {
      height: 90,
      width: 90,
    },
    "& $socialsContainer > div > div:last-of-type svg": {
      height: 27,
      width: 27,
    },
    "& $copyright": {
      textAlign: "center",
      lineHeight: "12px",
    },
  },
  hr: {
    width: "calc(100% - 144px)",
    background: "#151A29",
    height: 2,
    border: "none",
    margin: 0,
  },
  navigationContainer: {},
  socialsContainer: {},
  containerGrid: {
    margin: "0 auto",
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      height: 200,
    },
  },
}));

export default useStyles;
