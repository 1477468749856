import React from "react";
import { Grid } from "@material-ui/core";
import useStyles from "../styles/components/ProfileStyles";
import PrivateInformation from "./PrivateInformation";
import ChangePassword from "./ChangePassword";
import PinRecovery from "./PinRecovery";


function Profile(props) {
    const classes = useStyles(props);

    return (
        <Grid item container xs={12} className={classes.contentContainerGrid}>
            <Grid xs={12} item className={classes.privateInformation}>
                <PrivateInformation />
            </Grid>
            <Grid xs={12} sm={6} item className={classes.changePassword}>
                <ChangePassword />
            </Grid>
            <Grid xs={12} sm={6} item className={classes.pinRecovery}>
                <PinRecovery />
            </Grid>
        </Grid>
    );
}

export default Profile;
