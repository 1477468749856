import React, { useLayoutEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { Grid, Box } from "@material-ui/core";
import theme from "./styles/theme";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import News from "./pages/News";
import _News from "./pages/_News";
import Contact from "./pages/Contact";
import Atms from "./pages/Atms";
import FAQ from "./pages/FAQ";
import Dashboard from "./pages/Dashboard";
import Register from "./pages/Register";
import Policy from "./pages/Policy";
import Terms from "./pages/Terms";

import Header from "./components/Header";
import Footer from "./components/Footer";
import useStyles from "./styles/AppStyles";
import GetApp from "./components/GetApp";
import { UserContext, UserProvider } from "./contexts/UserContext";
import { LanguageContext, LanguageProvider } from "./contexts/LanguageContext";

// useless comment

const AppRouter = (props) => {
  const classes = useStyles(theme);
  const { user, languages } = props;

  const languageRouteKeys = languages.map((e) => e.routeKey);

  const routeKey = languages.find(
    (e) => e.key === localStorage.getItem("langKey")
  ).routeKey;

  const BeforeLoginRoutes = () => (
    <Switch>
      <Route path="/:lang/about" exact component={About} />
      <Route path="/:lang/news" exact component={News} />
      <Route path="/:lang/news/:newsId" component={_News} />
      <Route path="/:lang/contact" exact component={Contact} />
      <Route path="/:lang/atms" exact component={Atms} />
      <Route path="/:lang/FAQ" exact component={FAQ} />
      <Route path="/:lang/terms" exact component={Terms} />
        <Route path="/:lang/terms/:key"  component={Terms} />

        <Route path="/:lang/policy" exact component={Policy} />
      <Route path="/:lang/register-disabled" exact component={Register} />
      <Route
        path="/:lang"
        render={(props) => {
          return languageRouteKeys.includes(props.match.params.lang) ? (
            <Home {...props} />
          ) : (
            <Redirect to={`/${routeKey}/${props.match.params.lang}`} />
          );
        }}
      />
      <Route
        path="/"
        render={(props) => {
          return <Redirect to={`/${routeKey}`} />;
        }}
      />
    </Switch>
  );

  const AfterLoginRoutes = () => (
    <Switch>
      <Route path="/:lang/account" exact component={Dashboard} />
      <Route path="/:lang/about" exact component={About} />
      <Route path="/:lang/news" exact component={News} />
      <Route path="/:lang/news/:newsId" component={_News} />
      <Route path="/:lang/contact" exact component={Contact} />
    <Route path="/:lang/terms" exact component={Terms} />
    <Route path="/:lang/terms/:key"  component={Terms} />
    <Route path="/:lang/atms" exact component={Atms} />
      <Route path="/:lang/FAQ" exact component={FAQ} />
      <Route path="/:lang/policy" exact component={Policy} />
      <Route
        path="/:lang"
        render={(props) => {
          return languageRouteKeys.includes(props.match.params.lang) ? (
            <Home {...props} />
          ) : (
            <Redirect to={`/${routeKey}/${props.match.params.lang}`} />
          );
        }}
      />
      <Route
        path="/"
        render={(props) => {
          return <Redirect to={`/${routeKey}`} />;
        }}
      />
    </Switch>
  );

  return (
    <Router>
      <Grid container direction="column" className={classes.containerGrid}>
        <Box className={classes.headerCover}>
          <Grid
            item
            xs={12}
            sm={11}
            md={10}
            className={classes.headerContainer}
          >
            <Box>
              <Header />
            </Box>
          </Grid>
        </Box>
        {user ? <AfterLoginRoutes /> : <BeforeLoginRoutes />}
        <Box className={classes.footerCover}>
          <Grid
            item
            xs={12}
            sm={11}
            md={10}
            className={classes.footerContainer}
          >
            <Box>
              <Footer />
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Router>
  );
};

function App() {
  useLayoutEffect(() => {
    const isFromMobileApplication = !!window.location.href.split(
      "fromMobileApplication"
    )[1];
    if (isFromMobileApplication) {
      document.getElementById("header").remove();
      document.getElementById("footer").remove();
      document.querySelector("#root > div").style["background"] = "#161C2A";
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <UserContext.Consumer>
          {({ user }) => (
            <LanguageProvider>
              <LanguageContext.Consumer>
                {({ languages }) => (
                  <React.Fragment>
                    <GetApp />
                    <AppRouter user={user} languages={languages} />
                  </React.Fragment>
                )}
              </LanguageContext.Consumer>
            </LanguageProvider>
          )}
        </UserContext.Consumer>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
