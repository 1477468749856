import { cAxios, checkLeaderbetTransverAvailablity, transferToLeaderbet, requestTransferToLeaderbetSMS } from "../../api/index";


function useAuth() {

    const _checkLeaderbetTransverAvailablity = async (pin) => {
        try {
            const response = await cAxios.get(checkLeaderbetTransverAvailablity, {
                params: {
                    pin,
                }
            })
            return response;
        } catch ({ response }) {
            return response;
        }
    }

    const _transferToLeaderbet = async (pin, amount, sms_code) => {
        const formData = new FormData();
        formData.append("pin", pin);
        formData.append("amount", Number(amount));
        formData.append("sms_code", sms_code);
        try {
            const { data } = await cAxios.post(transferToLeaderbet, formData);
            return data;
        } catch ({ response: { data } }) {
            return data;
        }
    };

    const _requestTransferToLeaderbetSMS = async () => {
            try {
              const response = await cAxios.get(requestTransferToLeaderbetSMS);
              return response;
            } catch ({ response }) {
              return response;
            }
    }

    return { checkLeaderbetTransverAvailablity: _checkLeaderbetTransverAvailablity, transferToLeaderbet: _transferToLeaderbet, requestTransferToLeaderbetSMS: _requestTransferToLeaderbetSMS };
}

export default useAuth