import React from "react";
import { Grid } from "@material-ui/core";
import FacebookSVGR from "../smart-assets/SVGRs/FacebookSVGR";
import InstagramSVGR from "../smart-assets/SVGRs/InstagramSVGR";
import TwitterSVGR from "../smart-assets/SVGRs/TwitterSVGR";
import ChatSVGR from "../smart-assets/SVGRs/ChatSVGR";
import useStyles from "../styles/components/SocialsStyles";

const Socials = (props) => {
    const classes = useStyles(props);

    const openUrl = (url) => {
        window.open(url, '_blank');
    }

    const componentOrder = [
        {
            contentKey: 'social-facebook',
            SVGR: <FacebookSVGR onClick={() => openUrl("https://www.facebook.com/leadercard.ge/")}/>,
        },
        {
            contentKey: 'social-instagram',
            SVGR: <InstagramSVGR onClick={() => openUrl("http://instagram.com/leadercard.ge")} />,
        },
        {
            contentKey: 'social-twitter',
            SVGR: <TwitterSVGR />,
        },
        {
            contentKey: 'social-chat',
            SVGR: <ChatSVGR />
        }
    ];

    return (
        <Grid item container className={classes.container} justify="flex-start" alignItems="center">
            {componentOrder.map(component => (
                <Grid item className={classes[component.contentKey]} key={component.contentKey}>
                    {component.SVGR}
                </Grid>
            ))}
        </Grid>
    );
};

export default Socials;
