import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	container: {
		minHeight: 75,
		borderRadius: 6,
		background: 'transparent',
		border: '2px solid #1c2539',
		alignItems: 'center',
		boxSizing: 'border-box',
		padding: '25px',
		paddingRight: 20,
		marginBottom: 8,
		position: 'relative',
		'&:last-of-type': {
			marginBottom: 0,
		},
		'&:first-of-type': {
			marginTop: 20,
		},
		'& mark': {
			color: '#F1C157',
			fontStyle: 'inherit',
			backgroundColor: 'transparent',
			display: 'contents'
		},
		cursor: 'pointer',
	},
	question: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 16,
		lineHeight: '25px',
		textAlign: 'left',
		color: '#c1c9df',
		boxSizing: 'border-box',
		paddingRight: 20,
	},
	questionPlusContainer: {
		width: '100%',
		position: 'relative',
	},
	addSVGR: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		right: 0,
	},
	subtractSVGR: {
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		right: 0,
	},
	answer: {
		marginTop: 28,
		borderRadius: 1,
		borderLeft: '2px solid #f1c157',
		width: 'calc(100% - 20px)',
		boxSizing: 'border-box',
		padding: '0 10px',
		fontFamily: "var(--font-family-2)",
		fontWeight: 'normal',
		fontSize: 14,
		lineHeight: '25px',
		textAlign: 'left',
		color: '#6b7a9e',
		marginBottom: 5,
		whiteSpace: "pre-wrap",
		cursor: 'auto',
	},
	answerClosed: {
		display: 'none',
	}
}));

export default useStyles;
