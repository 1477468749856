import * as React from "react"

function RadioCheckedSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            viewBox="0 0 20 20"
            {...props}
        >
            <defs>
                <filter
                    id="prefix__a"
                    x={1.5}
                    y={1.5}
                    width={17}
                    height={17}
                    filterUnits="userSpaceOnUse"
                >
                    <feOffset />
                    <feGaussianBlur stdDeviation={1.5} result="blur" />
                    <feFlood floodColor="#c1c9df" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
            </defs>
            <g data-name="Group 1183">
                <g data-name="Group 1182">
                    <circle
                        data-name="Ellipse 26"
                        cx={10}
                        cy={10}
                        r={10}
                        fill="#26314e"
                    />
                </g>
                <g filter="url(#prefix__a)">
                    <circle
                        data-name="Ellipse 27"
                        cx={4}
                        cy={4}
                        r={4}
                        transform="translate(6 6)"
                        fill="#c1c9df"
                    />
                </g>
            </g>
        </svg>
    )
}

export default RadioCheckedSVGR
