import * as React from "react"

function ValidationFailSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={18}
            viewBox="0 0 18 18"
            {...props}
        >
            <g
                fill="#711939"
                data-name="Interface-Essential / Form-Validation / remove-circle-alternate"
            >
                <path d="M12.315 5.683a.74.74 0 00-1.034 0L9.105 7.858a.144.144 0 01-.206 0L6.723 5.683a.737.737 0 00-.714-.2.731.731 0 00-.319 1.239l2.176 2.175a.15.15 0 01.043.105.148.148 0 01-.043.1L5.69 11.276a.733.733 0 000 1.035.74.74 0 001.034 0L8.9 10.137a.137.137 0 01.206 0l2.177 2.174a.751.751 0 001.034 0 .733.733 0 000-1.035l-2.176-2.174a.148.148 0 01-.043-.1.15.15 0 01.043-.105l2.175-2.175a.725.725 0 00.214-.515.747.747 0 00-.215-.524z" />
                <path
                    d="M0 9a9 9 0 119 9 9 9 0 01-9-9zm1.5 0A7.5 7.5 0 109 1.5 7.5 7.5 0 001.5 9z"
                    fillRule="evenodd"
                />
            </g>
        </svg>
    )
}

export default ValidationFailSVGR
