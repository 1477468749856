import { useState, useEffect } from "react";
import {
  cAxios,
  fetchParamList,
  register,
  registerSMS,
  checkDocumentNumber,
  checkAuthState,
} from "../../api/index";

function useRegister({ autoFetchParamList }) {
  const [paramList, setParamList] = useState(null);

  const registerUser = async (data) => {
    try {
      const response = await cAxios.post(register, data);
      return response;
    } catch ({ response }) {
      return response;
    }
  };

  const requestSMS = async (personal_id, phone) => {
    try {
      const response = await cAxios.get(registerSMS, {
        params: {
          personal_id,
          phone,
        },
      });
      return response;
    } catch ({ response }) {
      return response;
    }
  };
  const _checkDocumentNumber = async (document_number) => {
    try {
      const response = await cAxios.get(checkDocumentNumber, {
        params: {
          document_number,
        },
      });
      return response;
    } catch ({ response }) {
      return response;
    }
  };

  const _checkAuthState = async (personal_id) => {
    try {
      const response = await cAxios.get(checkAuthState, {
        params: {
          personal_id: personal_id,
        },
      });
      return response;
    } catch ({ response }) {
      return response;
    }
  };

  useEffect(() => {
    if (autoFetchParamList) {
      (async () => {
        const {
          data: { data },
        } = await cAxios.get(fetchParamList);
        console.log(data);
        setParamList(data);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    paramList,
    registerUser,
    requestSMS,
    checkDocumentNumber: _checkDocumentNumber,
    checkAuthState: _checkAuthState,
  };
}

export default useRegister;
