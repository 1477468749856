import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  languageText: {
    fontFamily: "var(--font-family-1)",
    fontWeight: 500,
    fontSize: 14,
    color: "#546283",
  },
  picker: {
    marginLeft: 7,
    // background: 'red',
    width: 6,
    height: 8,
  },
  rotated: {
    transform: "rotate(180deg)",
  },
  selectedLanguage: {
    // background: 'blue',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    outline: "none",
    // [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
    //   width: "100%",
    //   display: "flex",
    //   justifyContent: "center",
    //   alignItems: "center",
    // },
  },
  language: {
    width: 60,
    height: 60,
    minWidth: 60,
    minHeight: 60,
    borderRadius: 30,
    background: "#1c2539",
    boxShadow: "0px 0px 20px rgba(239, 197, 103, 0)",
    "&:not(:last-of-type)": {
      marginBottom: 8,
    },
    // textAlign: "right",
    // height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // // backgroundColor: '#161C2A',
    // boxSizing: "border-box",
    // padding: "20px 10px",
    cursor: "pointer",
    // "&:hover": {
    //   backgroundColor: "#f1c157",
    // },
  },
  languageSelector: {
    position: "absolute",
    top: 68,
    width: 60,
    // backgroundColor: "#161C2A",
    // borderRadius: 10,
    overflow: "hidden",
    // [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
    //   bottom: 50,
    //   background: "#0d111b",
    //   width: "100%",
    //   "& > div": {
    //     display: "flex",
    //     margin: "auto",
    //   },
    //   "& div > svg": {
    //     marginLeft: "auto",
    //   },
    //   "& div > p": {
    //     marginRight: "auto",
    //     width: 80,
    //     textAlign: "left",
    //   },
    // },
  },
  container: {
    position: "relative",
    width: 60,
    height: 60,
    minWidth: 60,
    minHeight: 60,
    marginLeft: 12,
    borderRadius: 30,
    background: "#1c2539",
    boxShadow: "0px 0px 20px rgba(239, 197, 103, 0)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    outline: "none",
    zIndex: 1,
    // [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
    // 	width: '80vw',
    // 	height: '50px',
    // 	borderRadius: '4px',
    // 	background: '#0d111b',
    // 	display: 'flex',
    // 	alignItems: 'center',
    // 	justifyContent: 'center',
    // }
  },
  flag: {
    minWidth: 30,
    minHeight: 30,
    width: 30,
    height: 30,
    // marginRight: 10,
  },
  svgContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default useStyles;
