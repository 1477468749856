const FAQ = {
    card: [
        {
            id: 1,
            question:
            {
                Geo: `რისთვის მჭირდება ლიდერ ქარდი?`,
                Eng: `Why do you need a Leadercard?`,
                Rus: `Зачем нужен Leadercard?`,

            },
            answer:
            {
                Geo: `ლიდერ ქარდის საშუალებით შესაძლებელია ლიდერბეთის ბალანსზე არსებული თანხის განაღდება ბანკომატებიდან ან გადახდა საბანკო ტერმინალის საშუალებით.`,
                Eng: `With Leadercard, you can withdraw money from the Leaderbet balance using ATMs or pay through a bank terminal.`,
                Rus: `С помощью Leadercard можно снимать деньги с баланса Leaderbet с помощью банкоматов или платить через банковский терминал.`,
            },
        },
        {
            id: 2,
            question:
            {
                Geo: `როგორ გადავიტანო თანხა ლიდერბეთიდან ლიდერქარდზე?`,
                Eng: `How to transfer money from Leaderbet to Leadercard?`,
                Rus: `Как перевести деньги с Leaderbet на Leadercard?`,
            },
            answer:
            {
                Geo: `ლიდერქარდის მფლობელი უნდა შევიდეს ლიდერბეთის ანგარიშზე თანხის გატანაში , მოძებნოს ლიდერქარდი და დააწვეს გატანის ღილაკს, მიუთითოს სასურველი თანხის რაოდენობა, შეიყვანოს 5 ციფრიანი სმს კოდი და დაასტუროს.`,
                Eng: `The Leadercard owner must log into the Leaderbet account at the withdrawal point, find the card and click on the withdrawal button, indicate the desired amount, enter the 5-digit SMS code ,which will be send on the owners mobile number, and confirm`,
                Rus: `Владелец  Leadercard должен зайти на аккаунт Leaderbet  в пункт  вывода суммы, найти карту и нажать на кнопку вывода, указать желаемую сумму, ввести 5-значный SMS код, который будет выслан на мобильный номер владельца карты, и подтвердить.`,
            },
        },
        {
            id: 3,
            question:
            {
                Geo: `რას ნიშნავს სინქრონიზაცია?`,
                Eng: `What does synchronization mean?`,
                Rus: `Что значит синхронизация?`
            },
            answer:
            {
                Geo: `ლიდერბეთის ანგარიშიდან თანხის გადატანის დროს შესაძლებელია ჩაირთოს სინქრონიზაცია, რაც ნიშნავს, რომ მომხმარებელი შეძლებს ლიდერქარდის ბარათით განაღდებას ან გადახდას, თანხის გადატანის გარეშე.

როგორ ჩავრთო სინქრონიზაცია: ლიდერბეთის ანგარიშზე თანხის გადატანის დროს მომხმარებელმა უნდა მონიშნოს სინქრონიზაცია (ერთჯერადად) , შემდგომ კი 4 ციფრიანი სმს კოდით უნდა დაადასტუროს.`,
                Eng: `When transferring money from a Leaderbet account, user can enable synchronization, which allows direct withdraw or pay with a Leadercard, without transferring money every time.

How to enable synchronization: when transferring money from a Leaderbet account, the user must select synchronization (once) and then confirm with a 4-digit SMS code.`,
                Rus: `При переводе денег со счета Leaderbet, можно включить синхронизацию, это означает, что пользователь сможет снимать или расплачиваться картой Leadercard без каждого раза перевода денег. 

Как включить синхронизацию: при переводе денег со счета Leaderbet, пользователь должен выбрать синхронизацию (один раз), а затем подтвердить 4-значным SMS кодом.`,
            },
        },
        {
            id: 4,
            question:
            {
                Geo: `რომელი ბანკის სალაროდან არის შესაძლებელი ლიდერქარდზე არსებული ნაშთის განაღდება?`,
                Eng: `At the cash desk of which bank can the balance on the leader's card be withdrawn?`,
                Rus: `В кассе какого банка можно снять остаток по карте лидера?`,
            },
            answer:
            {
                Geo: `ლიდერქარდით განხორციელებულ ოპერაციებს და არსებულ ნაშთს ბანკი ვერ ხედავს, შესაბამისად ბანკის სალაროდან თანხის განაღდება არ არის შესაძლებელი.`,
                Eng: `The bank does not see the transactions made with the Leadercard, and the existing balance, therefore, it is impossible to withdraw money from the bank's cash desk.`,
                Rus: `Банк не видит транзакции, проведенные с помощью Leadercard, и существующий баланс, поэтому снять деньги в кассе банка невозможно.`,
            },
        },
        {
            id: 5,
            question:
            {
                Geo: `სად შეიძლება ავიღო ლიდერ ქარდი?`,
                Eng: `Where can I get a Leadercard?`,
                Rus: `Где можно взять Leadercard?`,
            },
            answer:
            {
                Geo: `ლიდერ ქარდის მიღება შესაძლებელია ლიდერბეთის სალაროებსა და ლიდერქარდის სარეკლამო ჯიხურებში.`,
                Eng: `Leadercard can be obtained from Leaderbet cash registers and Leadercard advertising kiosks.`,
                Rus: `Leadercard можно получить в кассах Leaderbet и рекламных киосках Leadercard.`,
            },
        },
        {
            id: 6,
            question:
            {
                Geo: `რამდენია ლიდერ ქარდით განაღდების ლიმიტი?`,
                Eng: `What is the limit for cash withdrawals?`,
                Rus: `Какой есть лимит на снятие наличных?`,
            },
            answer:
            {
                Geo: `ლიდერ ქარდით განაღდების დღიური (24 საათიანი) ლიმიტი წარმოადგენს 3000 ლარს`,
                Eng: `The daily (24 hours) limit for cash withdrawals is 3000 GEL.`,
                Rus: `Суточный (24 часа) лимит снятия наличных с Leadercard составляет 3000 лари.`,
            },
        },
        {
            id: 7,
            question:
            {
                Geo: `რა დოკუმენტაცია დამჭირდება წარსადგენად ლიდერ ბეთის სალაროებში ბარათის მისაღებად?`,
                Eng: `What documents do I need to submit to receive a card at the LeaderBet cash registers?`,
                Rus: `Какие документы мне нужно предоставить, чтобы получить карту в кассе Leader Bet?`,
            },
            answer:
            {
                Geo: `ბარათის მიღებისთვის საჭიროა თან იქონიოთ აქტიური პირადობის დამადასტურებელი მოწმობა ან საქართველოს მოქალაქის პასპორტი, ასევე აქტიური ტელეფონის ნომერი სადაც სმს -ის სახით გამოგეგზავნებათ ბარათის PIN კოდი.`,
                Eng: `To receive a card, you need to have a valid identity card or passport of a citizen of Georgia, also an active phone number to which you will receive the card's PIN code by SMS.`,
                Rus: `Для получения карты вам необходимо иметь действующее удостоверение личности или паспорт гражданина Грузии, а также активный номер телефона, на который вам будет отправлен PIN-код карты в виде SMS.`,
            },
        },
    ],
    bank: [
        {
            id: 8,
            question:
            {
                Geo: `რამდენია ლიდერ ქარდით განაღდების და გადახდის მომსახურების საკომისიო?`,
                Eng: `How much is the commission for withdrawing and paying for Leadercard?`,
                Rus: `Сколько стоит комиссия за обналичивание и оплату карты Leadercard?`,
            },
            answer: {
                Geo: `ლიდერ ქარდით თანხის განაღდება ან ტერმინალზე გადახდა არის სრულიად უფასო.`,
                Eng: `Withdrawing cash or paying at the terminal using Leadercard is completely free.`,
                Rus: `Снятие наличных или оплата в терминале с помощью Leadercard совершенно бесплатно.`,
            },
        },
        {
            id: 9,
            question:
            {
                Geo: `რომელი ბანკის ბანკომატებით შემიძლია თანხის განაღდება?`,
                Eng: `From which bank ATM can I withdraw money?`,
                Rus: `В банкомате какого банка я могу снимать деньги?`,
            },
            answer:
            {
                Geo: `ლიდერ ქარდის მფლობელებს ლიდერბეთის ბალანსზე არსებული თანხის განაღდება შეუძლიათ თიბისი ბანკის და საქართველოს ბანკის ბანკომატებიდან.`,
                Eng: `Leadercard owners can withdraw money from Leaderbet balance at TBC Bank and Bank of Georgia ATMs.`,
                Rus: `Владельцы Leadercard могут снимать деньги на баланс Leaderbet в банкоматах TBC Bank и Bank of Georgia.`,
            },
        },
        {
            id: 10,
            question:
            {
                Geo: `სად შემიძლია გადავიხადო თანხა ლიდერ ქარდით?`,
                Eng: `Where can I pay with Leadercard?`,
                Rus: `Где я могу заплатить картой Leadercard?`,
            },
            answer:
            {
                Geo: `ლიდერ ქარდით თანხის გადახდა შესაძლებელია ყველგან სადაც განთავსებულია საქართველოს ბანკის ან/და თიბისი ბანკის ტერმინალები.`,
                Eng: `You can pay with Leadercard at any place where the terminals of Bank of Georgia and / or TBC Bank are located.`,
                Rus: `Leadercard можно оплатить в любом месте, где находятся терминалы банков Bank of Georgia и / или TBC Bank.`,
            },
        },
    ],
    discount: [
        {
            id: 11,
            question:
            {
                Geo: `რა არის ქეშბექი?`,
                Eng: `What is cashback?`,
                Rus: `Что такое cashback?`,
            },
            answer:
            {
                Geo: `ქეშბექი ლიდერქარდის მფლობელს საშუალებას აძლევს დაიბრუნოს გადახდილი თანხის 10% უკან ბარათზე. 
<small>*კომპანია რიგ შემთხვევებში იტოვებს უფლებას მომხმარებელს დაურიცხოს ქეშბექი.</small>`,
                Eng: `Cashback allows the Leadercard owner to return 10% of the paid amount of money to the card.
<small>*The company in some cases reserves the right to accrue to the client with a cashback.</small>`,
                Rus: `Cashback позволяет владельцу Leadercard вернуть 10% от выплаченной суммы на карту.
<small>*Компания в некоторых случаях оставляет за собой право начислить клиенту cashback.</small>`,
            },
        },
        {
            id: 12,
            question:
            {
                Geo: `სად შემიძლია მივიღო ქეშბექი?`,
                Eng: `Where can I get cashback?`,
                Rus: `Где я могу получить cashback?`,
            },
            answer:
            {
                Geo: `ლიდერ ქარდის მფლობელს შეუძლია მიიღოს ქეშბექი მთელი საქართველოს მასშტაბით ნებისმიერ სავაჭრო ობიექტში,სადაც განთავსებულია საქართველოს ბანკის ან თიბისი ბანკის ტერმინალი.`,
                Eng: `A Leadercard owner can receive a cashback at any place, throughout Georgia where the terminals of Bank of Georgia or TBC Bank is located.`,
                Rus: `Владелец карты Leadercard может получить саshback в любой торговой точке по всей Грузии, где находится терминал Bank of Georgia или TBC Bank.`,
            },
        },
        {
            id: 13,
            question:
            {
                Geo: `როგორია ქეშბექის მიღების წესი?`,
                Eng: `What is the rule for receiving cashback?`,
                Rus: `Какое правило получения cashback?`,
            },
            answer:
            {
                Geo: `ქეშბექის მისაღებად საკმარისია შეძენილი პროდუქციის ღირებულება გადაიხადოთ ლიდერ ქარდის საშუალებით.
<small>*კომპანია რიგ შემთხვევებში იტოვებს უფლებას მომხმარებელს დაურიცხოს ქეშქები.</small>`,
                Eng: `To get a cashback, it is enough to pay for the purchased goods via Leadercard.
<small>*The company in some cases reserves the right to accrue to the client with a cashback.</small>`,
                Rus: `Чтобы получить саshback, достаточно оплатить купленные товары через карту Leadercard.
<small>*Компания в некоторых случаях оставляет за собой право начислить клиенту cashback.</small>`,
            },
        },
        {
            id: 14,
            question:
            {
                Geo: `რომელ ანგარიშზე ბრუნდება ქეშბექი?`,
                Eng: `To what account is cashback returned?`,
                Rus: `На какой счет возвращается cashback?`,
            },
            answer:
            {
                Geo: `ქეშბექი ბრუნდება ლიდერქარდის ბარათის ანგარიშზე.`,
                Eng: `Cashback is returned to the Leadercard account.`,
                Rus: `Cashback возвращается на счет карты Leadercard.`,
            },
        },
    ]
}

export default FAQ;