import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    registerTextField: {
        width: "calc(100% - 16px)",
        borderRadius: 6,
        background: "#1c2539",
        "& label": {
            fontFamily: "var(--font-family-1)",
            fontWeight: 500,
            fontSize: 14,
            // lineHeight: "22px",
            textAlign: "left",
            color: "#6F7FA4",
        },
        '& label:not(.Mui-focused)': {
            maxWidth: 'calc(100% - 25px)',
        },
        "& .MuiFormLabel-root.Mui-disabled": {
            color: "#6F7FA4",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1c2539",
            borderRadius: 6,
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
            color: "#6F7FA4",
            fontFamily: "var(--font-family-1)",
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#6F7FA4",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1c2539",
            borderRadius: 6,
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: 6,
        },
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            width: '100%',
            marginLeft: 0,
        },
        // marginRight: 16,
        marginBottom: 16,
    },
    errorMessage: {
        color: '#CB3352',
        marginLeft: 3,
        marginTop: -15,
        width: '90%',
        fontFamily: "var(--font-family-2)",
        fontSize: 14,
        marginBottom: 10,
        fontWeight: 'normal',
        lineHeight: '25px',
        textAlign: 'left',
    },
    'errored': {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#CB3352 !important'
        }
    },
}));

export default useStyles;
