import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	contentContainerGrid: {
		height: '100%',
		margin: "auto",
		marginTop: 5,
		'& $imageUploadContainer:last-of-type': {

		},
		[theme.breakpoints.up(1800)]: {
			maxWidth: '95rem',
		},
	},
	registerTitle: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 28,
		lineHeight: '39px',
		textAlign: 'left',
		color: '#c1c9df',
		marginBottom: 60,
	},
	buttons: {
		flexDirection: 'row-reverse',
		justifyContent: 'flex-start',
		marginTop: 40,
	},
	nextButton: {
		width: 220,
		height: 50,
		borderRadius: 6,
		backgroundColor: '#f1c157',
		"&:hover": {
			backgroundColor: "#f1c157",
		},
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			width: '100%',
			height: 60,
		}
	},
	nextButtonText: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 16,
		lineHeight: '25px',
		textAlign: 'left',
		color: '#1f2637',
	},
	finishButton: {
		width: 220,
		height: 50,
		borderRadius: 6,
		backgroundColor: '#197163',
		'&:hover': {
			backgroundColor: '#197163',
		},
		'&:disabled': {
			backgroundColor: '#1B2234'
		},
		"&:disabled:hover": {
			backgroundColor: "#1B2234",
		},
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			width: '100%',
			height: 60,
		}
	},
	finishButtonText: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 16,
		lineHeight: '25px',
		textAlign: 'left',
		color: '#FFFFFF',
	},
	backButton: {
		marginRight: 30,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			marginRight: 0,
			marginTop: 12,
			width: '100%',
			height: 60,
			borderRadius: 4,
			background: 'transparent',
			border: '2px solid #1f283c',
		}
	},
	backButtonText: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 16,
		lineHeight: '25px',
		textAlign: 'left',
		color: '#a6b5d9',
	},
	stepProgressContainer: {
		marginBottom: 85,
		[theme.breakpoints.up(theme.breakpoints.values.md)]: {
			maxWidth: '57.5rem',
		},
	},
	radioLabel: {
		'& span': {
			fontFamily: "var(--font-family-1)",
			fontWeight: 500,
			fontSize: 14,
			lineHeight: '25px',
			textSlign: 'left',
			color: '#c1c9df',
		},
		'&:not(:last-of-type)': {
			// backgroundColor: 'blue',
			marginRight: 80,
		}
	},
	checkBoxLabel: {
		'& span': {
			fontFamily: "var(--font-family-1)",
			fontWeight: 500,
			fontSize: 14,
			lineHeight: '25px',
			textSlign: 'left',
			color: '#6F7FA4'
		},
		'&:not(:last-of-type)': {
			// backgroundColor: 'blue',
			marginRight: 80,
		},
		'& .Mui-checked ~ span': {
			color: '#c1c9df',
		}
	},
	sexRadioButtons: {
		marginTop: 30,
		'& > div': {
			marginLeft: 2,
		}
	},
	textFieldsContainer: {
		marginTop: 35,
		'& $textField1:last-of-type': {
			// marginRight: 0,
		}
	},
	textField1: {
		width: "calc(100% - 16px)",
		borderRadius: 6,
		background: "#1c2539",
		"& label": {
			fontFamily: "var(--font-family-1)",
			fontWeight: 500,
			fontSize: 14,
			// lineHeight: "22px",
			textAlign: "left",
			color: "#6F7FA4",
		},
		'& label:not(.Mui-focused)': {
			maxWidth: 'calc(100% - 25px)',
		},
		"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			borderColor: "#1c2539",
			borderRadius: 6,
		},
		"& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
			color: "#6F7FA4",
			fontFamily: "var(--font-family-1)",
		},
		"& .MuiInputLabel-outlined.Mui-focused": {
			color: "#6F7FA4",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#1c2539",
			borderRadius: 6,
		},
		"& .MuiOutlinedInput-root": {
			borderRadius: 6,
		},
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			width: '100%',
			marginLeft: 0,
		},
		// marginRight: 16,
		marginBottom: 16,
	},
	sectionHeader: {
		marginTop: 30,
	},
	imageUploadContainer: {
		height: 440,
		marginTop: 56,
		'& > div': {
			width: 'calc(100% - 10px)',
		},
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			'& > div': {
				width: '100%',
				margin: 0,
			},
		}
	},
	imageUploadContainerLeft: {
		'& > div': {
			marginRight: 'auto',
		}
	},
	imageUploadContainerRight: {
		'& > div': {
			marginLeft: 'auto'
		}

	},
	stepThree: {
		marginBottom: 20,
	},
	topParamTextContainer: {
		marginBottom: 85,
		// justifyContent: 'center',
	},
	topParamText: {
		// maxWidth: 600,
		minWidth: "100%",
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 20,
		lineHeight: '39px',
		textAlign: 'center',
		color: '#c1c9df',
	},
	// inputLabel: {
	// 	fontFamily: "var(--font-family-2)",
	// 	fontSize: 14,
	// 	textAlign: 'left',
	// 	color: '#6f7fa4',
	// 	paddingBottom: 12,
	// 	display: 'inline-block'
	// }
	errorMessage: {
		color: '#CB3352',
		marginLeft: 3,
		marginTop: -15,
		width: '90%',
		fontFamily: "var(--font-family-2)",
		fontSize: 14,
		marginBottom: 10,
	},
	formControl: {
		borderRadius: 6,
		background: '#1c2539',
		width: "calc(100% - 16px)",
		color: "#6F7FA4",
		'& select': {
			textTransform: 'uppercase',
			fontFamily: "var(--font-family-1)",
			fontWeight: 500,
			textAlign: 'left',
			color: "#6F7FA4",
		},
		"& label": {
			fontFamily: "var(--font-family-1)",
			fontWeight: 500,
			textAlign: "left",
			color: "#6F7FA4",
			fontSize: 14,
		},
		"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			borderColor: "#1c2539",
			borderRadius: 6,
		},
		"& .MuiInputLabel-outlined.Mui-focused": {
			color: "#6F7FA4",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#1c2539",
			borderRadius: 6,
		},
		"& .MuiOutlinedInput-root": {
			borderRadius: 6,
		},
		'& .MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOutlined': {
			color: '#C1C9DF'
		},
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			width: '100%',
			marginLeft: 0,
		},
		marginBottom: 11,
	},
	option: {
		color: '#161c2a',
		textTransform: 'uppercase',
	},
	'errored': {
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: '#CB3352 !important'
		},
	},
	erroredOptions: {
		'& > div': {
			borderStyle: 'solid',
			borderRadius: 6,
			borderWidth: 1,
			borderColor: '#CB3352 !important',
			padding: '0 10px',
		}
	}
}));

export default useStyles;
