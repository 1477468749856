import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	container: {
		width: 140,
		height: 140,
		borderRadius: 12,
		backgroundColor: '#1C2539',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		cursor: 'pointer',
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			flexDirection: 'row',
			width: '100%',
			height: 80,
			marginBottom: 12,
			justifyContent: 'flex-start',
			boxSizing: 'border-box',
			padding: '25px 30px',
		}
	},
	containerSelected: {
		backgroundColor: '#1D2E5A',
		'& svg path': {
			fill: '#C1C9DF'
		},
		'& title': {
			color: '#C1C9DF'
		}
	},
	title: {
		marginTop: 10,
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 14,
		lineHeight: '25px',
		textAlign: 'center',
		color: '#7588B4',
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			marginTop: 0,
			fontSize: 18,
		}

	},
	activeTitle: {
		backgroundColor: '#1d2e5a',
		cursor: 'default',
	},
	SVGRContainer: {
		width: 40,
		height: 40,
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			height: 30,
			width: 30,
			marginRight: 20,
			'&:first-of-type': {
				width: 35,
				height: 26,
			}
		}
	}
}));

export default useStyles;
