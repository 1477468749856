import React, { useState, useEffect, useContext, useMemo } from "react";
import { Grid, Typography, Box, Button } from "@material-ui/core";
import useStyles from "../styles/components/BalanceStyles";
import EyeOpenSVGR from "../smart-assets/SVGRs/EyeOpenSVGR";
import EyeCrossedSVGR from "../smart-assets/SVGRs/EyeCrossedSVGR";
import TransactionsCalendarSVGR from "../smart-assets/SVGRs/TransactionsCalendarSVGR";
import TransactionsSwapSVGR from "../smart-assets/SVGRs/TransactionsSwapSVGR";
import TransactionsRestoreSVGR from "../smart-assets/SVGRs/TransactionsRestoreSVGR";
import TransactionStatusInSVGR from "../smart-assets/SVGRs/TransactionStatusInSVGR";
import TransactionStatusOutSVGR from "../smart-assets/SVGRs/TransactionStatusOutSVGR";
import classNames from "classnames";
import moment from "moment";
import TransactionModal from "./Modals/TransactionModal";
import TransferToLiderBetForm from "./TransferToLiderbetForm";
import TransactionSectionToggleSVGR from "../smart-assets/SVGRs/TransactionSectionToggleSVGR";
import useLanguage from "../hooks/useLanguage";
import { UserContext } from "../contexts/UserContext";
import VisibilitySensor from "react-visibility-sensor";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "moment/locale/ka";
import "moment/locale/ru";
import "moment/locale/en-gb";
import MomentUtils from '@date-io/moment';


function Balance(props) {
    const classes = useStyles(props);

    const [transactionModalOpen, setTransactionModalOpen] = useState(false);
    const [balanceVisible, setBalanceVisible] = useState(true);
    const [openTransactionSectionKey, setOpenTransactionSectionKey] = useState(null);
    const { key: languageKey, pages: { dashboard: { balance: STRINGS } }, universal: { monthsShort } } = useLanguage();
    const { getUserBalance, balance, getTransactionHistory } = useContext(UserContext);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [onlyIncomeHistory, setOnlyIncomeHistory] = useState([]);
    const [onlyOutgoingHistory, setOnlyOutgoingHistory] = useState([]);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const locale = useMemo(() => {
        switch (languageKey) {
            case 'Geo':
                return 'ka'

            case 'Eng':
                return 'en-gb'

            case 'Rus':
                return 'ru'

            default:
                return 'ka'
        }
    }, [languageKey])

    const fetchHistory = async (params = {}) => {
        const data = await getTransactionHistory({
            ...params
        });
        return data;
    }

    useEffect(() => {
        getUserBalance();
        (async () => {
            console.log(dateTo, dateFrom)
            const params = {
                operation_type: openTransactionSectionKey === 'income' ? 1 : openTransactionSectionKey === 'outgoing' ? 2 : 3,
            }
            if (dateFrom) {
                params.date_from = dateFrom.format('DD-MM-YYYY');
                params.date_to = moment(new Date().getTime()).format('DD-MM-YYYY');
            }
            if (dateTo) {
                params.date_to = dateTo.format('DD-MM-YYYY');
            }
            setTransactionHistory([])
            setOnlyIncomeHistory([]);
            setOnlyOutgoingHistory([]);
            const data = await fetchHistory(params);
            openTransactionSectionKey === 'income' ? setOnlyIncomeHistory([...data]) : openTransactionSectionKey === 'outgoing' ? setOnlyOutgoingHistory([...data]) : setTransactionHistory([...data]);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openTransactionSectionKey, dateTo, dateFrom])

    const resetFilters = () => {
        setDateTo(null);
        setDateFrom(null);
        setOpenTransactionSectionKey(openTransactionSectionKey === null ? false : null);
    }

    const onBottomReached = (isBottomVisible) => {
        console.log(isBottomVisible)
        if (isBottomVisible) {
            (async () => {
                const selectedArr = (openTransactionSectionKey === 'income' ? onlyIncomeHistory : openTransactionSectionKey === 'outgoing' ? onlyOutgoingHistory : transactionHistory);
                const params = {
                    operation_type: openTransactionSectionKey === 'income' ? 1 : openTransactionSectionKey === 'outgoing' ? 2 : 3,
                    last_transaction_id: selectedArr.length !== 0 ? selectedArr[selectedArr.length - 1].id : null
                }
                if (dateFrom) {
                    params.date_from = dateFrom.format('DD-MM-YYYY');
                    params.date_to = moment(new Date().getTime()).format('DD-MM-YYYY');
                }
                if (dateTo) {
                    params.date_to = dateTo.format('DD-MM-YYYY');
                }
                const data = await fetchHistory(params);
                openTransactionSectionKey === 'income' ? setOnlyIncomeHistory([...selectedArr, ...data]) : openTransactionSectionKey === 'outgoing' ? setOnlyOutgoingHistory([...selectedArr, ...data]) : setTransactionHistory([...selectedArr, ...data]);
            })();
        }
    }


    const transactionSections = [
        {
            key: 'all',
            title: STRINGS.allTransactions,
        },
        {
            key: 'income',
            title: STRINGS.input,
        },
        {
            key: 'outgoing',
            title: STRINGS.output,
        },
    ]


    const Transaction = ({ transaction }) => {
        return (
            <Box className={classes.transactionContainer}>
                {transaction.amount > 0 ?
                    <TransactionStatusInSVGR className={classes.transactionDirectionIndicatorSVGR} />
                    :
                    <TransactionStatusOutSVGR className={classes.transactionDirectionIndicatorSVGR} />
                }
                <Typography className={classes.transactionTitle}>
                    {transaction.web_name}
                </Typography>
                <Typography className={classes.transactionDate}>
                    {new Date(transaction.date).getDate()} {monthsShort[new Date(transaction.date).getMonth()]}. {moment(transaction.date).format('YYYY / HH:mm:ss')}
                </Typography>
                <Typography className={classes.transactionDirection}>
                    {transaction.amount > 0 ? STRINGS.input : STRINGS.output}
                </Typography>
                <Typography className={classNames(classes.transactionAmount, transaction.amount > 0 ? classes.transactionAmountIncome : null)}>
                    {transaction.amount} ₾
                </Typography>
            </Box>
        )
    }

    const TransactionMobile = ({ transaction }) => {
        return (
            <Box className={classes.transactionMobileContainer}>
                {transaction.amount > 0 ?
                    <TransactionStatusInSVGR className={classes.transactionDirectionIndicatorSVGR} />
                    :
                    <TransactionStatusOutSVGR className={classes.transactionDirectionIndicatorSVGR} />
                }
                <Box className={classes.transactionTitleAndTime}>
                    <Typography className={classes.transactionTitleMobile}>
                        {transaction.web_name}
                    </Typography>
                    <Typography className={classes.transactionTimeMobile}>
                        {new Date(transaction.date).getDate()} {monthsShort[new Date(transaction.date).getMonth()]}. {moment(transaction.date).format('YYYY / HH:mm:ss')}
                    </Typography>
                </Box>
                <Typography className={classNames(classes.transactionAmount, transaction.amount > 0 ? classes.transactionAmountIncome : null)}>
                    {transaction.amount} ₾
                </Typography>
            </Box>
        )
    }

    return (
        <Grid item container xs={12} md={8} className={classes.contentContainerGrid}>
            <Grid item xs={12} container className={classes.preview} alignItems="center">
                {/* DESKTOP */}
                <Grid xs={6} item className={classes.totalBalanceDesktop}>
                    <Box className={classes.balanceAndCurreny}>
                        <Button className={classes.balanceVisibilityButton} onClick={() => setBalanceVisible(!balanceVisible)}>
                            {balanceVisible ? <EyeOpenSVGR className={classes.eyeOpenSVGR} /> : <EyeCrossedSVGR className={classes.eyeCrossedSVGR} />}
                        </Button>
                        {balanceVisible ? <Box className={classes.balanceAndCurrenyInnerWrapper}>
                            <Typography className={classes.NBC}>
                                {STRINGS.nbcBalance}
                            </Typography>
                            <Box className={classes.balanceAndCurrenyFlexRow}>
                                <Typography className={classes.balance}>
                                    {balance}
                                </Typography>
                                <Typography className={classes.currency}>
                                    ₾
                         </Typography>
                            </Box>
                        </Box>
                            : null
                        }
                    </Box>
                </Grid>
                {/* MOBILE */}
                <Grid xs={12} item className={classes.totalBalanceMobile}>
                    <Box className={classes.balanceAndCurreny}>
                        {balanceVisible ? <Box className={classes.balanceAndCurrenyInnerWrapper}>
                            <Typography className={classes.NBC}>
                                {STRINGS.nbcBalance}
                            </Typography>
                            <Box className={classes.balanceAndCurrenyFlexRow}>
                                <Typography className={classes.balance}>
                                    {balance}
                                </Typography>
                                <Typography className={classes.currency}>
                                    ₾
                         </Typography>
                            </Box>
                        </Box>
                            : null
                        }
                        <Button className={classes.balanceVisibilityButton} onClick={() => setBalanceVisible(!balanceVisible)}>
                            {balanceVisible ? <EyeOpenSVGR className={classes.eyeOpenSVGR} /> : <EyeCrossedSVGR className={classes.eyeCrossedSVGR} />}
                        </Button>
                    </Box>
                </Grid>
                <Grid xs={6} item container justify="center" alignItems="center" className={classes.transferToLiderbetContainer}>
                    <Button className={classes.transferToLiderbetButton} onClick={() => setTransactionModalOpen(true)}>
                        <Typography className={classes.transferToLiderbetButtonText}>
                            {STRINGS.transferToLeaderbet}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.informationLineMobile}>
                <hr />
                <Typography variant="body1" className={classes.informationLineText}>
                    {STRINGS.transferToLeaderbet}
                </Typography>
                <hr />
            </Grid >
            <Grid item xs={12} className={classes.transactionToLiderBetFormContainer}>
                <TransferToLiderBetForm refetch={resetFilters} />
            </Grid>
            <Grid item xs={12} className={classes.informationLineMobile}>
                <hr />
                <Typography variant="body1" className={classes.informationLineText}>
                    {STRINGS.transactionHistory}
                </Typography>
                <hr />
            </Grid >
            {transactionSections.map(section => (
                <Grid key={section.key} item xs={12} className={classes.transactionsSectionContainerMobile}>
                    <Button className={classes.transactionSectionToggleButton} onClick={() => {
                        if (openTransactionSectionKey === section.key) {
                            setOpenTransactionSectionKey(null)
                        }
                        else {
                            setOpenTransactionSectionKey(section.key)
                        }
                    }}>
                        <Typography variant="body1" className={classes.transactionSectionTitle}>
                            {section.title}
                        </Typography>
                        <TransactionSectionToggleSVGR
                            className={classNames(
                                classes.transactionSectionToggleSVGR,
                                openTransactionSectionKey === section.key ? classes.transactionSectionToggleSVGROpen : null
                            )}
                        />
                    </Button>
                    {openTransactionSectionKey === section.key ?
                        <React.Fragment>
                            <Box className={classes.transactionsFilterMobile}>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
                                    <DatePicker
                                        cancelLabel=""
                                        okLabel=""
                                        autoOk={true}
                                        className={classNames(classes.textField1, classes.calendar)}
                                        inputVariant="outlined"
                                        openTo="year"
                                        views={["year", "month", "date"]}
                                        label={STRINGS.from}
                                        format="DD-MM-YYYY"
                                        value={dateFrom}
                                        onChange={setDateFrom}
                                    />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
                                    <DatePicker
                                        cancelLabel=""
                                        okLabel=""
                                        autoOk={true}
                                        className={classNames(classes.textField1, classes.calendar)}
                                        inputVariant="outlined"
                                        openTo="year"
                                        views={["year", "month", "date"]}
                                        label={STRINGS.from}
                                        format="DD-MM-YYYY"
                                        value={dateTo}
                                        onChange={setDateTo}
                                    />
                                </MuiPickersUtilsProvider>
                                <Button className={classes.transactionsRestoreButton} onClick={resetFilters}>
                                    <TransactionsRestoreSVGR className={classes.transactionsRestoreSVGR} />
                                </Button>
                            </Box>
                            <VisibilitySensor onChange={onBottomReached} intervalCheck={true} scollCheck={true}>
                                <Grid item container xs={12} className={classes.transactionsContainerMobile}>
                                    {(openTransactionSectionKey === 'income' ? onlyIncomeHistory : openTransactionSectionKey === 'outgoing' ? onlyOutgoingHistory : transactionHistory).map(transaction => (
                                        <TransactionMobile transaction={transaction} key={`mobile-${section.key}-${transaction.id}`} />
                                    ))}
                                </Grid>
                            </VisibilitySensor>
                        </React.Fragment>
                        : null
                    }
                </Grid>
            ))}
            {/* DESKTOP */}
            <Grid item container xs={12} className={classes.transactionsWrapper} alignItems="flex-start">
                <Grid item xs={12} className={classes.informationLine}>
                    <hr />
                    <Typography variant="body1" className={classes.informationLineText}>
                        {STRINGS.transactionHistory}
                    </Typography>
                    <hr />
                </Grid >
                <Grid item container xs={12} className={classes.transactionsFiltersWrapper}>
                    <Box className={classes.transactionsFiltersContainer}>
                        <TransactionsCalendarSVGR className={classes.transactionsCalendarSVGR} />
                        {/* <TextField className={classes.textField1} label={STRINGS.from} variant="outlined" /> */}
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
                            <DatePicker
                                cancelLabel=""
                                okLabel=""
                                autoOk={true}
                                className={classNames(classes.textField1, classes.calendar)}
                                inputVariant="outlined"
                                openTo="year"
                                views={["year", "month", "date"]}
                                label={STRINGS.from}
                                format="DD-MM-YYYY"
                                value={dateFrom}
                                onChange={setDateFrom}
                            />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
                            <DatePicker
                                cancelLabel=""
                                okLabel=""
                                autoOk={true}
                                className={classNames(classes.textField1, classes.calendar)}
                                inputVariant="outlined"
                                openTo="year"
                                views={["year", "month", "date"]}
                                label={STRINGS.from}
                                format="DD-MM-YYYY"
                                value={dateTo}
                                onChange={setDateTo}
                            />
                        </MuiPickersUtilsProvider>
                        {/* <TextField className={classes.textField1} label={STRINGS.to} variant="outlined" /> */}
                        <TransactionsSwapSVGR className={classes.transactionsSwapSVGR} />
                        <Button className={classNames(classes.button, openTransactionSectionKey === 'income' ? classes.activeButton : null)} variant="outlined" onClick={() => setOpenTransactionSectionKey(openTransactionSectionKey === 'income' ? 'all' : 'income')} >{STRINGS.input}</Button>
                        <Button className={classNames(classes.button, openTransactionSectionKey === 'outgoing' ? classes.activeButton : null)} variant="outlined" onClick={() => setOpenTransactionSectionKey(openTransactionSectionKey === 'outgoing' ? 'all' : 'outgoing')} >{STRINGS.output}</Button>
                        <Button className={classes.transactionsRestoreButton} onClick={resetFilters} >
                            <TransactionsRestoreSVGR className={classes.transactionsRestoreSVGR} />
                        </Button>
                    </Box>
                </Grid>
                <VisibilitySensor onChange={onBottomReached} partialVisibility="bottom">
                    <Grid item container xs={12} className={classes.transactionsListContainer}>
                        {(openTransactionSectionKey === 'income' ? onlyIncomeHistory : openTransactionSectionKey === 'outgoing' ? onlyOutgoingHistory : transactionHistory).map(transaction => (
                            <Transaction transaction={transaction} key={`desktop-${transaction.id}`} />
                        ))}
                    </Grid>
                </VisibilitySensor>
            </Grid>
            <TransactionModal isOpen={transactionModalOpen} onClose={() => setTransactionModalOpen(false)} refetch={resetFilters} />
        </Grid>
    );
}

export default Balance;
