import * as React from "react"

function FacebookSVGR(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={9}
      height={16}
      viewBox="0 0 9 16"
      {...props}
    >
      <path
        d="M8.911 4.78a.342.342 0 00-.251-.113H6.1v-.94c0-.187.041-.4.346-.4H8.48a.3.3 0 00.237-.1A.331.331 0 008.816 3V.333A.336.336 0 008.477 0H5.54a3.292 3.292 0 00-3.506 3.567v1.1h-1.7A.336.336 0 000 5v2.667A.336.336 0 00.339 8h1.7v7.667a.336.336 0 00.339.333h3.391a.336.336 0 00.339-.333V8H8.38a.337.337 0 00.339-.3L9 5.033a.328.328 0 00-.089-.253z"
        fill="#546283"
        data-name="Logos / Social-Medias / social-media-facebook"
      />
    </svg>
  )
}

export default FacebookSVGR
