import * as React from "react"

function TransactionSectionToggleSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={10}
            height={6}
            viewBox="0 0 10 6"
            {...props}
        >
            <path
                d="M4.42 5.785L.068.563A.261.261 0 01.1.185.814.814 0 01.648 0h8.704A.813.813 0 019.9.185a.261.261 0 01.032.378L5.58 5.785A.773.773 0 015 6a.773.773 0 01-.58-.215z"
                fill="#a6b5d9"
                data-name="Video-Movies-TV / Controls / controls-play"
            />
        </svg>
    )
}

export default TransactionSectionToggleSVGR
