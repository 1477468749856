import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import useStyles from "../styles/components/NavigationStyles";
import classNames from "classnames";
import { UserContext } from "../contexts/UserContext";
import { LanguageContext } from "../contexts/LanguageContext";

const Navigation = (props) => {
  const classes = useStyles(props);
  const { user } = useContext(UserContext);
  const {
    selectedLanguage: { routeKey },
  } = useContext(LanguageContext);
  const { routes, inFooter, STRINGS } = props;

  return (
    <Grid container spacing={2}>
      {routes.map((route) =>
        typeof route.loggedIn !== "boolean" || (route.loggedIn && user) ? (
          route.isExternal ? (
            <a
              href={route.route}
              target="_blank"
              rel="noreferrer"
              className={classNames(
                classes.navigationButton,
                inFooter ? classes.inFooter : classes.inHeader
              )}
              key={route.route}
            >
              {STRINGS[route.nameKey]}
            </a>
          ) : (
            <NavLink
              to={`/${routeKey}${route.route}`}
              exact
              activeClassName={classes.activeNavigationButton}
              className={classNames(
                classes.navigationButton,
                inFooter ? classes.inFooter : classes.inHeader
              )}
              key={route.route}
            >
              {STRINGS[route.nameKey]}
            </NavLink>
          )
        ) : null
      )}
    </Grid>
  );
};

export default Navigation;
