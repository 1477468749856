import React, { useState, useEffect, useCallback } from "react";
import useStyles from "../styles/components/SMSInputWithStatusStyles";
import { Typography, TextField, InputAdornment, Box, Button } from "@material-ui/core";
import generateInputStatuses from "../generators/generateInputStatuses";
import classNames from "classnames";

const EndAdornment = ({ classes, inputStatuses, status, sentAt, timeoutSecs }) => {
    // eslint-disable-next-line no-unused-vars
    const [update, setUpdate] = useState(false)

    useEffect(() => {
        if (sentAt) {
            const key = setInterval(() => {
                setUpdate(Math.random())
            }, 1000);
            return () => {
                clearInterval(key)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let SVGR = inputStatuses[status]?.SVGR;
    if (SVGR) {
        return (
            <InputAdornment position="end" >
                <Box className={classes.endAdormentContainer}>
                    {SVGR ? <SVGR /> : null}
                    <Box className={classes.endAdormentTooltip}>
                        <Box className={classes.toopTipArrow} />
                        <Typography className={classes.endAdormentTooltipText}>
                            {inputStatuses[status]?.tooltipText}
                        </Typography>
                    </Box>
                </Box>
            </InputAdornment>
        )
    }
    else if (sentAt) {
        let secs = Math.round(timeoutSecs - (new Date().getTime() - sentAt.getTime()) / 1000)
        let mins = Math.floor(secs / 60)
        return <InputAdornment position="end" >
            <Box className={classes.endAdormentContainer}>
                <Typography className={classes.adormentText}>
                    {mins}:{`${secs % 60 < 10 ? 0 : ''}${secs % 60}`}
                </Typography>
            </Box>
        </InputAdornment>;
    }
    else {
        return null
    }
}


const TextFieldWithStatus = (props) => {
    const classes = useStyles(props);
    const { label, value, validate, textFieldProps, inputProps, setValue, setStatus, status, sentSMSAt, sendSMS, timeoutSecs, error } = props;
    const inputStatuses = generateInputStatuses(label);
    const [validationDelayKey, setValidationDleayKey] = useState(null);

    const validationWithDelay = useCallback(async (val) => {
        clearTimeout(validationDelayKey);
        if (val) {
            // setStatus(() => 'loading');
            const key = setTimeout(async () => {
                let status = await validate(val);
                setStatus(() => status);
            }, 0);
            setValidationDleayKey(key);
        }
        else {
            setStatus(() => null);
        }

    }, [validationDelayKey, validate, setStatus])

    useEffect(() => {
        validationWithDelay(value);
        // disabling eslint because the solutions for this warning will cause infinite or unoptimized loops
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [value])


    console.log('this', sentSMSAt, timeoutSecs, sentSMSAt && (new Date().getTime() - sentSMSAt.getTime()) / 1000)

    return (
        <TextField className={classNames(classes.textField, !error ? null : classes.errored)} label={label} variant="outlined"
            value={value}
            onChange={(event) => setValue(() => event.target.value)}
            spellCheck="false"
            InputProps={{
                endAdornment:
                    !sentSMSAt || (new Date().getTime() - sentSMSAt.getTime()) / 1000 > timeoutSecs ? <Button onClick={sendSMS} className={classes.smsCodeButton}><Typography className={classes.smsCodeButtonText}>SMS</Typography></Button>
                        :
                        status !== "success" ?
                            <EndAdornment classes={classes} sentAt={sentSMSAt} timeoutSecs={timeoutSecs} inputStatuses={inputStatuses} />
                            :
                            <EndAdornment classes={classes} inputStatuses={inputStatuses} status={status} />,
            }}
            inputProps={inputProps}
            {...textFieldProps}
        // onChange={(event) => setSmsCode(() => event.target.value.replace(/\D/g, ''))}
        />
    );
};

export default TextFieldWithStatus;
