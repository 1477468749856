import * as React from "react"

function SaleSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={36}
            height={36}
            viewBox="0 0 36 36"
            {...props}
        >
            <path
                d="M32.592 12.21a6.622 6.622 0 010 11.58.376.376 0 00-.18.435 6.623 6.623 0 01-8.188 8.188.373.373 0 00-.434.18 6.624 6.624 0 01-11.58 0 .373.373 0 00-.434-.18 6.625 6.625 0 01-8.188-8.188.376.376 0 00-.18-.435 6.622 6.622 0 010-11.58.374.374 0 00.18-.433 6.624 6.624 0 018.188-8.19.374.374 0 00.434-.18 6.624 6.624 0 0111.58 0 .374.374 0 00.434.18 6.624 6.624 0 018.188 8.19.374.374 0 00.18.433zM20.95 24.535a2.745 2.745 0 10.6-2.99 2.746 2.746 0 00-.6 2.99zm-9.207 1.844a1.5 1.5 0 01-2.116-2.117L24.256 9.633a1.5 1.5 0 112.116 2.117zm3.306-14.914a2.744 2.744 0 10-.6 2.991 2.745 2.745 0 00.6-2.992z"
                fill="#546283"
                fillRule="evenodd"
                data-name="Shopping-E-commerce / Discount/Coupons / discount-bubble"
            />
        </svg>
    )
}

export default SaleSVGR
