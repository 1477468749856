import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		// background: "blue",
		maxWidth: '95rem',
		margin: 'auto',
		// height: 232,
	},
	news: {
		boxSizing: "border-box",
		margin: "10px",
		borderRadius: 6,
		// background: "#181f2e",
		boxShadow: "0px 3px 2px #161c2a",
		display: "flex",
		flexDirection: "row",
		height: 100,
		cursor: "pointer"
	},
	imageContainer: {
		borderRadius: "6px 6px 6px 6px",
		minWidth: 100 * (375 / 267),
		minHeight: 100,
		position: "relative",
		"&:before": {
			content: "' '",
			backgroundColor: "#151B284D",
			borderRadius: "6px 6px 6px 6px",
			backgroundPosition: "left",
			position: "absolute",
			left: 0,
			top: 0,
			zIndex: 100,
			width: "100%" /*width of the css background*/,
			height: "100%",
		},
	},
	image: {
		width: "100%",
		objectFit: "cover",
		borderRadius: "6px 6px 6px 6px",
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
	},
	contents: {
		boxSizing: "border-box",
		paddingLeft: "20px",
	},
	date: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 12,
		lineHeight: "22px",
		textAlign: "left",
		color: "#6b7a9e",
		display: "-webkit-box",
		"-webkit-line-clamp": 1,
		"-webkit-box-orient": "vertical",
		overflow: "hidden",
	},
	title: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 16,
		lineHeight: "24px",
		textAlign: "left",
		color: "#c7d0da",
		display: "-webkit-box",
		"-webkit-line-clamp": 3,
		"-webkit-box-orient": "vertical",
		overflow: "hidden",
	},
	sectionTitleContainer: {
		marginBottom: 25,
	},
	sectionTitle: {
		marginLeft: 10,
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 22,
		lineHeight: "25px",
		textAlign: "left",
		color: "#a6b5d9",
	},
	currentPage: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 32,
		lineHeight: "48px",
		textAlign: "left",
		color: "#f1c157",
	},
	lastPageAndSlash: {
		marginTop: 6,
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 20,
		lineHeight: "48px",
		textAlign: "left",
		color: "#a6b5d9",
	},
	pervPageArrow: {
		marginLeft: 36,
		height: 15,
		width: 20,
		cursor: "pointer",
	},
	nextPageArrow: {
		marginLeft: 10,
		height: 15,
		width: 30,
		cursor: "pointer",
	},
	pagination: {
		marginTop: 15,
	}
}));

export default useStyles;
