import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 450,
    borderRadius: 6,
    background: "#171f32",
    padding: "37px 32px",
    outline: "none",
    [theme.breakpoints.down(500)]: {
      width: "calc(100% - 20px)",
    },
  },
  closeSVGR: {
    position: "absolute",
    top: 20,
    right: 20,
    cursor: "pointer",
    "& path": {
      transition: "fill 250ms ease-in-out",
    },
    "&:hover path": {
      fill: "#c7d0da",
    },
  },
  title: {
    fontFamily: "var(--font-family-1)",
    fontWeight: "normal",
    fontSize: 21,
    lineHeight: "39px",
    textAlign: "left",
    color: "#c7d0da",
  },
  titleContainer: {
    marginBottom: 30,
  },
  textFieldContainer: {
    marginBottom: 20,
  },
  inputHr: {
    width: "calc(100% - 20px)",
    marginTop: 20,
    marginBottom: 0,
    height: 1,
    background: "#1d273f",
    borderWidth: 0,
  },
  setPasswordButton: {
    marginTop: 20,
    marginBottom: -35,
    width: "100%",
    height: 50,
    borderRadius: 6,
    background: " #15639f",
    "&:hover": {
      background: " #15639f",
    },
    "&:disabled": {
      background: "#1B2234",
    },
  },
  setPasswrodButtonText: {
    fontFamily: "var(--font-family-1)",
    fontWeight: 500,
    fontSize: 14,
    textAlign: "left",
    color: "#c7d0da",
  },
  error: {
    marginTop: 12,
    padding: "0 5px",
    boxSizing: "border-box",
    fontFamily: "var(--font-family-2)",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "left",
    color: "#cb3352",
  },
  dimError: {
    color: "#6F7FA4",
  },
  goToMainPageButton: {
    background: "#232c44",
    marginTop: 20,
    marginBottom: -35,
    width: "100%",
    height: 50,
    borderRadius: 6,
    "&:hover": {
      background: "#232c44",
    },
  },
  goToMainPageButtonText: {
    fontFamily: "var(--font-family-1)",
    fontWeight: 500,
    fontSize: 14,
    textAlign: "left",
    color: "#c7d0da",
  },
  registerButton: {
    background: "#197163",
    marginTop: 20,
    marginBottom: -35,
    width: "100%",
    height: 50,
    borderRadius: 6,
    "&:hover": {
      background: "#197163",
    },
  },
  registerButtonText: {
    fontFamily: "var(--font-family-1)",
    fontWeight: 500,
    fontSize: 14,
    textAlign: "left",
    color: "#c7d0da",
  },
  resetPasswordPromptText: {
    marginTop: 10,
    marginLeft: 5,
    fontFamily: "var(--font-family-1)",
    fontWeight: 500,
    fontSize: 12,
    textAlign: "left",
    color: "#c7d0da",
    cursor: "pointer",
    opacity: 0.8,
    transition: "opacity 250ms",
    "&:hover": {
      opacity: 1,
    },
  },
}));

export default useStyles;
