import React from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "../styles/components/RegisterSectionHeaderStyles";
import classNames from "classnames"

const RegisterSectionHeader = (props) => {
    const classes = useStyles(props);
    const { text, subText, popupText } = props;

    return (
        <Box className={classNames(classes.registerSctionHeaderContainer, !!popupText ? classes.withPopupText : null)}>
            <Typography variant="h2" className={classes.registerSectionHeaderText}>
                {text}
            </Typography>
            {
                subText ?
                    <Typography variant="body2" className={classes.sectionHeaderSubText}>
                        {subText}
                    </Typography>
                    : null
            }
            {
                popupText ?
                    <Typography variant="body1" className={classes.sectionHeaderPopupText}>
                        {popupText}
                    </Typography>
                    : null
            }

            <hr className={classes.registerSctionHeaderLine} />
        </Box>
    );
};

export default RegisterSectionHeader;
