import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        borderRadius: 4,
        background: '#192031',
        padding: 8,
        '& > div > div': {
            borderRadius: 5,
        }
    }
}));

export default useStyles;
