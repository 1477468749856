import * as React from "react"

function FlagRussianSVGR(props) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30.001}
        viewBox="0 0 30 30.001"
        {...props}
      >
        <g transform="translate(.121 -.155)">
          <ellipse
            data-name="Ellipse 118"
            cx={14.922}
            cy={15}
            rx={14.922}
            ry={15}
            transform="translate(-.121 .155)"
            fill="#f0f0f0"
          />
          <path
            data-name="Path 78"
            d="M28.95 20.358a15.145 15.145 0 000-10.447H.929a15.145 15.145 0 000 10.447l14.01 1.306z"
            fill="#0052b4"
          />
          <path
            data-name="Path 79"
            d="M14.939 30.155a14.944 14.944 0 0014.005-9.794H.934a14.944 14.944 0 0014.005 9.794z"
            fill="#d80027"
          />
        </g>
      </svg>
    )
}

export default FlagRussianSVGR
