import React from "react";
import useStyles from "../styles/components/RegisterTextFieldStyles";
import { TextField, Grid, Typography } from "@material-ui/core";
import classNames from "classnames";



const RegisterTextField = (props) => {
    const classes = useStyles(props);
    const { label, register, name, error, value, disabled, xs = 12, sm = 6, md = 4, lg = 3, inputProps = {}, onChange = () => { }, defaultValue } = props;

    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg}>
            <TextField
                defaultValue={defaultValue}
                value={value}
                name={name}
                className={classNames(classes.registerTextField, error?.message ? classes.errored : null)}
                variant="outlined"
                label={label}
                inputRef={register}
                disabled={disabled}
                inputProps={inputProps}
                onChange={onChange}
            />
            <Typography className={classes.errorMessage}>
                {error?.message}
            </Typography>
        </Grid>
    );
};

export default RegisterTextField;
