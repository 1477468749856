import * as React from "react"

function TransactionsSwapSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={26}
            height={16}
            viewBox="0 0 26 16"
            {...props}
        >
            <g
                fill="#52648b"
                data-name="Internet-Networks-Servers / Data-Transfer / data-transfer-diagonal"
            >
                <path d="M12.303 15.543a1.472 1.472 0 002.132 0l7.184-7.384a.255.255 0 01.356 0l.889.919a1.02 1.02 0 00.711.3.8.8 0 00.268-.041 1 1 0 00.7-.744l1.424-5.835a1.042 1.042 0 00-.264-.981 1 1 0 00-.955-.279l-5.686 1.467a1.01 1.01 0 00-.721.723 1.052 1.052 0 00.254 1l.889.909a.273.273 0 010 .372L12.3 13.353a1.573 1.573 0 000 2.189zM11.6.453l-7.43 7.2a.245.245 0 01-.35 0l-.68-.7a.985.985 0 00-.979-.258 1.008 1.008 0 00-.707.744L.033 13.274a1.058 1.058 0 00.183.888 1.009 1.009 0 00.792.4.976.976 0 00.244-.031l5.686-1.466a1.009 1.009 0 00.72-.723 1.051 1.051 0 00-.253-1l-1.1-1.126a.264.264 0 01.006-.372l7.359-7.136a1.57 1.57 0 00.468-1.5A1.52 1.52 0 0013.086.062a1.474 1.474 0 00-1.485.392z" />
            </g>
        </svg>
    )
}

export default TransactionsSwapSVGR
