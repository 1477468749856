import React from "react";
import { Radio } from "@material-ui/core";
import RadioCheckedSVGR from "../smart-assets/SVGRs/RadioCheckedSVGR";
import RadioSVGR from "../smart-assets/SVGRs/RadioSVGR"
import useStyles from "../styles/components/CustomRadioButtonStyles";


function CustomRadioButton(props) {
    const classes = useStyles(props);
    return (
        <Radio disableRipple className={classes.customRadioButton} icon={<RadioSVGR />} checkedIcon={<RadioCheckedSVGR />} {...props} />
    );
}

export default CustomRadioButton;
