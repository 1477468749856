import React from "react";
import { Checkbox } from "@material-ui/core";
import CheckboxCheckedSVGR from "../smart-assets/SVGRs/CheckboxCheckedSVGR";
import CheckboxSVGR from "../smart-assets/SVGRs/CheckboxSVGR"
import useStyles from "../styles/components/CustomCheckboxStyles";


function CustomCheckbox(props) {
    const classes = useStyles(props);
    return (
        <Checkbox disableRipple className={classes.customCheckbox} icon={<CheckboxSVGR />} checkedIcon={<CheckboxCheckedSVGR />} {...props} />
    );
}

export default CustomCheckbox;
