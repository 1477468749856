import * as React from "react"

function TransactionStatusOutSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={40}
            height={40}
            viewBox="0 0 40 40"
            {...props}
        >
            <g data-name="Group 1183" transform="translate(-562 -641)">
                <circle
                    data-name="Ellipse 98"
                    cx={20}
                    cy={20}
                    r={20}
                    transform="translate(562 641)"
                    fill="#a12452"
                    opacity={0.1}
                />
                <g data-name="Group 1173">
                    <path
                        data-name="Path 58"
                        d="M585.499 667h2.5v-12h-12v2.5h9.5z"
                        fill="#a12452"
                    />
                    <path
                        data-name="Rectangle 481"
                        fill="#a12452"
                        d="M576.758 664.471l9.47-9.47 1.722 1.722-9.47 9.47z"
                    />
                </g>
            </g>
        </svg>
    )
}

export default TransactionStatusOutSVGR
