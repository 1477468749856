import * as React from "react"

function TwitterSVGR(props) {
  return (
    <svg
      data-name="Group 79"
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={12}
      viewBox="0 0 16 12"
      {...props}
    >
      <path
        data-name="Path 26"
        d="M16 1.421a7.289 7.289 0 01-1.89.478A3.067 3.067 0 0015.553.225a6.9 6.9 0 01-2.08.733 3.42 3.42 0 00-2.4-.958A3.16 3.16 0 007.8 3.029a2.887 2.887 0 00.076.69A9.561 9.561 0 011.115.552a2.872 2.872 0 00-.449 1.53A2.979 2.979 0 002.122 4.6 3.449 3.449 0 01.64 4.226v.033a3.113 3.113 0 002.628 2.975 3.532 3.532 0 01-.86.1 3.135 3.135 0 01-.621-.052 3.289 3.289 0 003.065 2.109 6.948 6.948 0 01-4.067 1.292A6.653 6.653 0 010 10.641 9.8 9.8 0 005.032 12a8.912 8.912 0 009.336-8.616c0-.134-.005-.263-.012-.391A6.29 6.29 0 0016 1.421z"
        fill="#546283"
      />
    </svg>
  )
}

export default TwitterSVGR
