import { Grid, Typography, Box } from '@material-ui/core';
import React from 'react'
import useStyles from "../styles/components/ContactListStyles";
import LocationPinSVGR from "../smart-assets/SVGRs/LocationPinSVGR";
import EmailSVGR from "../smart-assets/SVGRs/EmailSVGR";
import PhoneSVGR from "../smart-assets/SVGRs/PhoneSVGR";
import useLanguage from "../hooks/useLanguage";


const ContactList = (props) => {
    const classes = useStyles(props);

    const { pages: { contact: { contactList: STRINGS } } } = useLanguage();

    const componentOrder = [
        {
            contentKey: 'location',
            SVGR: <LocationPinSVGR />,
        },
        {
            contentKey: 'email',
            SVGR: <EmailSVGR />,
        },
        {
            contentKey: 'phone',
            SVGR: <PhoneSVGR />,
        }
    ];

    return (
        <Grid container item xs={10} sm={12} className={classes.container} justify="space-between">
            {componentOrder.map(component => (
                <Grid item className={classes.gridItem} key={component.contentKey}>
                    <div className={classes.SVGRContainer}>
                        {component.SVGR}
                    </div>
                    <Box className={classes.info}>
                        <Typography variant="body1" className={classes.title}>
                            {STRINGS[component.contentKey].title}
                        </Typography>
                        <Typography variant="body1" className={classes.content}>
                            {STRINGS[component.contentKey].content}
                        </Typography>
                    </Box>
                </Grid>
            ))}
        </Grid>
    )
}


export default ContactList
