import * as React from "react"

function PhoneSVGR(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={34}
      height={17}
      viewBox="0 0 34 17"
      {...props}
    >
      <path
        d="M24.631.096a8.18 8.18 0 00-6.122 4.716 8.817 8.817 0 00.289 7.934.379.379 0 01.01.37.35.35 0 01-.305.189H15.49a.35.35 0 01-.305-.189.379.379 0 01.01-.37 8.79 8.79 0 00-.887-9.8 7.918 7.918 0 00-9.16-2.35 8.555 8.555 0 003.332 16.4h17.032a8.519 8.519 0 008.481-8.151 8.694 8.694 0 00-2.706-6.677A7.93 7.93 0 0024.631.096zM3.541 8.501a4.607 4.607 0 114.6 4.8 4.706 4.706 0 01-4.6-4.8zm17.7 0a4.607 4.607 0 104.6-4.8 4.707 4.707 0 00-4.595 4.8z"
        fill="#161c2a"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default PhoneSVGR
