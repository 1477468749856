import React from "react";
import useStyles from "../../styles/components/Modals/StatusModalStyles";
import ModalCloseSVGR from "../../smart-assets/SVGRs/ModalCloseSVGR";
import { Grid, Typography } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';

const StatusModal = (props) => {
    const classes = useStyles(props);
    const { isOpen, onClose, text, SVGR, buttonText, onButtonPress } = props;

    return (
        <Modal
            open={isOpen}
        >
            <Grid container className={classes.container} alignItems="baseline">
                <ModalCloseSVGR className={classes.closeSVGR} onClick={onClose} />
                <Grid xs={12} item container className={classes.contentContainer} justify="center">
                    <Grid item container xs={12} justify="center">
                        <SVGR className={classes.statusVGR} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h2" className={classes.statusText}>
                            {text}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.buttonContainer}>
                    <Typography variant="h2" className={classes.buttonText} onClick={onButtonPress}>
                        {buttonText}
                    </Typography>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default StatusModal;
