import * as React from "react"

function InstagramSVGR(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M11.667 0H4.334A4.339 4.339 0 000 4.333v7.333A4.339 4.339 0 004.333 16h7.333A4.339 4.339 0 0016 11.667V4.334A4.339 4.339 0 0011.667 0zM8 11.667a3.667 3.667 0 112.593-1.074A3.666 3.666 0 018 11.667zm3.333-8.333a1 1 0 101-1 1 1 0 00-1 .999z"
        fill="#546283"
        fillRule="evenodd"
        data-name="Logos / Photos-Images / social-instagram"
      />
    </svg>
  )
}

export default InstagramSVGR
