import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	navigationButton: {
		color: "#5E6F9B",
		fontSize: 16,
		lineHeight: '25px',
		margin: "0 8px",
		"&:hover": {
			color: "#A6B5D9",
		},
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		textAlign: "left",
		textDecoration: "none",
		whiteSpace: "nowrap",
	},
	activeNavigationButton: {
		color: "#A6B5D9",
	},
	inFooter: {
		'&:first-of-type': {
			marginLeft: 10,
		},
		'&:last-of-type': {
			marginRight: 10,
		},
		margin: "0 20px",
		fontSize: 14,
	},
	inHeader: {
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			fontSize: 13,
			margin: "0 7px",
		},
	}
}));

export default useStyles;
