import React, { useState, useContext } from "react";
import { AppBar, Toolbar, Button, Box, Typography, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Navigation from "./Navigation";
import HeaderLogo from "../assets/headerLogo.png";
import ROUTES from "../data/headerRoutes";
import useStyles from "../styles/components/HeaderStyles";
import UserSVGR from "../smart-assets/SVGRs/UserSVGR";
import LogoutSVGR from "../smart-assets/SVGRs/LogoutSVGR";
import LoginModal from "./Modals/LoginModal";
import { UserContext } from "../contexts/UserContext";
import classNames from "classnames";
import useLanguage from "../hooks/useLanguage";
import ChangeLanguage from "../components/ChangeLanguage";

const Header = (props) => {
	const classes = useStyles(props);
	const history = useHistory();
	const [loginModalOpen, setLoginModalOpen] = useState(false);
	const { user, logout } = useContext(UserContext);

	const { globalComponents: { header: STRINGS } } = useLanguage();

	return (
		<AppBar id="header" position="static" className={classes.headerStyles}>
			<Grid container item className={classes.containerGrid}>
				<Toolbar
					classes={{
						gutters: classes.gutterStyles,
					}}
					className={classes.toolBarStyles}
				>
					<Grid item container xs={10} sm={1} className={classes.mobileTopPartContainerWrapper}>
						<Box className={classes.mobileTopPartContainer}>
							<img onClick={() => history.push(`/`)} src={HeaderLogo} className={classes.logo} alt="LeaderCard"></img>
							<Box className={classes.buttonAndChangeLanguage}>
								{user ?
									<Box className={classNames(classes.userLFNames, classes.userLFNamesMobile)}>
										<Typography variant="body1" className={classes.userLFNamesText}>
											{user.name[0]}{user.last_name[0]}
										</Typography>
									</Box>
									:
									<UserSVGR onClick={() => setLoginModalOpen(true)} className={classes.UserSVGR} />
								}
								<Box className={classes.changeLanguageContainer2}>
									<ChangeLanguage />
								</Box>
								{user ? 
									<LogoutSVGR onClick={logout} className={classes.logoutSVGR} />
									: 
									null
								}
							</Box>
						</Box>
					</Grid>
					<Box className={classes.boxStyles}>
						<Navigation routes={ROUTES} STRINGS={STRINGS.navigation} />
						{user ?
							<>
								<Box className={classes.userLFNames}>
									<Typography variant="body1" className={classes.userLFNamesText}>
										{user.name[0]}{user.last_name[0]}
									</Typography>
								</Box>
								<Box className={classes.changeLanguageContainer}>
								{/* <Button id="login-button" onClick={() => setLoginModalOpen(true)} className={classes.buttonStyles}>{STRINGS.loginButton}</Button> */}
									<ChangeLanguage />
								</Box>
								<LogoutSVGR onClick={logout} className={classNames(classes.logoutSVGR, classes.logoutSVGR2)} />
							</>
							:
							<Box className={classes.changeLanguageContainer}>
								<Button id="login-button" onClick={() => setLoginModalOpen(true)} className={classes.buttonStyles}>{STRINGS.loginButton}</Button>
								<ChangeLanguage />
							</Box>
						}
					</Box>

				</Toolbar>
			</Grid>
			<LoginModal isOpen={loginModalOpen} onClose={() => setLoginModalOpen(false)} />
		</AppBar>
	);
};

export default Header;
