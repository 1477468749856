import React from "react";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import useStyles from "../styles/pages/ContactStyles";
import ContactList from "../components/ContactList";
import SendSVGR from "../smart-assets/SVGRs/SendSVGR";
import useLanguage from "../hooks/useLanguage";

function Contact(props) {
	const classes = useStyles(props);

	const { pages: { contact: STRINGS } } = useLanguage();

	return (
		<Grid item container xs={10} sm={10} md={8} lg={7} xl={6} className={classes.contentContainerGrid} justify="center">
			<Grid item xs={12} sm={10}>
				<Typography variant="h2" className={classes.title}>
					{STRINGS.title}
				</Typography>
			</Grid>
			<Grid item xs={11} sm={10}>
				<Typography variant="body1" className={classes.subTitle}>
					{STRINGS.instruction}
				</Typography>
			</Grid>
			<Grid item xs={12} sm={5}>
				<TextField className={classes.textField1} label={STRINGS.nameLabel} variant="outlined" />
			</Grid>
			<Grid item xs={12} sm={5}>
				<TextField className={classes.textField1} label={STRINGS.emailLabel} variant="outlined" />
			</Grid>
			<Grid item xs={12} sm={10}>
				<TextField
					// label="შეტყობინება"
					placeholder={STRINGS.messagePlaceholder}
					multiline
					rows={16}
					variant="outlined"
					className={classes.textFieldMultiline}
					spellCheck="false"
				/>
			</Grid>
			<Grid item xs={12} sm={10}>
				<Button className={classes.sendButton}>
					<SendSVGR className={classes.sendSVGR} />
					{STRINGS.buttonText}
				</Button>
			</Grid>
			<Grid item xs={12} sm={10}>
				<Typography variant="body1" className={classes.informationText}>
					{STRINGS.informationBoxText}
				</Typography>
			</Grid>
			<Grid item xs={12} className={classes.informationLine}>
				<hr />
				<Typography variant="body1" className={classes.informationLineText}>
					{STRINGS.informationLineText}
				</Typography>
				<hr />
			</Grid>

			<Grid item xs={12} sm={12}>
				<ContactList />
			</Grid>

		</Grid>
	);
}

export default Contact;
