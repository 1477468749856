import React from "react";
import { Box } from "@material-ui/core";
import useStyles from "../../styles/smart-assets/Animated/AnimatedLoadingStyles";
import LoadingSVGR from "../SVGRs/ValidationLoadingSVGR";

const Header = (props) => {
    const classes = useStyles(props);

    return (
        <Box className={classes.container} {...props}>
            <LoadingSVGR className={classes.LoadingSVGR} />
        </Box>

    );
};

export default Header;
