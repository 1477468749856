import * as React from "react"

function EyeCrossedSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={18}
            viewBox="0 0 17 16"
            {...props}
        >
            <g fill="#546283" data-name="Interface-Essential / View / view-off">
                <path
                    d="M13.499 4.249a15.131 15.131 0 013.1 2.678 1.656 1.656 0 01.005 2.157c-1.847 2.09-4.9 4.315-7.967 4.315h-.216a8.027 8.027 0 01-3.107-.7l-2.989 3.08a.706.706 0 01-.909.1.757.757 0 01-.267-.9c0-.007.006-.007.01-.007s.006-.008.009-.008L15.227.457a.535.535 0 00.125-.216l.013-.015a.707.707 0 01.7-.2.727.727 0 01.515.528.756.756 0 01-.2.722zm-4.028 6.956a3.371 3.371 0 002.163-2.254 3.289 3.289 0 00-.145-2.276.184.184 0 00-.134-.1.181.181 0 00-.159.052l-4.01 4.135a.192.192 0 00-.051.164.2.2 0 00.1.142 3.021 3.021 0 002.235.137z"
                    fillRule="evenodd"
                />
                <path d="M5.565 9.098a.181.181 0 00.046-.181 3.308 3.308 0 01-.133-.876 3.4 3.4 0 013.4-3.4 3.132 3.132 0 01.876.128.179.179 0 00.181-.045l1.466-1.465a.193.193 0 00.051-.182.187.187 0 00-.127-.136 7.69 7.69 0 00-2.448-.377c-3.248-.045-6.5 2.228-8.45 4.38a1.624 1.624 0 00-.005 2.19 15.949 15.949 0 002.6 2.289.19.19 0 00.246-.022z" />
            </g>
        </svg>
    )
}

export default EyeCrossedSVGR
