import React, { useState, useCallback, useMemo } from "react";
import { Grid } from "@material-ui/core";
import useStyles from "../styles/pages/DashboardStyles";
import Profile from "../components/Profile";
import Balance from "../components/Balance";
import classNames from "classnames";
import useLanguage from "../hooks/useLanguage";

function Dashboard(props) {
    const classes = useStyles(props);
    const [currentRoute, setCurrentRoute] = useState('balance');
    const { pages: { dashboard: STRINGS } } = useLanguage();

    console.log(currentRoute)

    const routes = useMemo(() => ({
        profile: {
            component: <Profile />,
            displayName: STRINGS.profileTitle,
        },
        balance: {
            component: <Balance />,
            displayName: STRINGS.balanceTitle,
        }
    }), [STRINGS])


    const CurrentRoute = useCallback(() => {
        return routes[currentRoute] ? routes[currentRoute].component : null;
    }, [currentRoute, routes])

    return (
        <Grid container item xs={10} sm={11} className={classes.contentContainerGrid}>
            <Grid container item xs={12} className={classes.routeControl}>
                {Object.keys(routes).map(route => (
                    <Grid key={route} item className={classNames(classes.route, currentRoute === route ? classes.activeRoute : null)} onClick={() => setCurrentRoute(route)}>
                        {routes[route].displayName}
                        <hr />
                    </Grid>
                ))}
            </Grid>
            <Grid item xs={12}>
                <CurrentRoute />
            </Grid>
        </Grid>
    );
}

export default Dashboard;
