import * as React from "react"

function TransactionsRestoreSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={21}
            viewBox="0 0 18 21"
            {...props}
        >
            <g data-name="Group 1152" fill="#55658d">
                <path
                    data-name="Path 59"
                    d="M15.269 3.457L12.754.217a.537.537 0 00-.954.185l-.325 1.064a8.919 8.919 0 00-8.781 2.592 10.335 10.335 0 00-1.626 11.551.475.475 0 00.781.116l.824-.879a.528.528 0 00.108-.578 8.009 8.009 0 011.388-8.654 6.878 6.878 0 016.657-2.037l-.325 1.064a.585.585 0 00.672.764l3.816-1a.609.609 0 00.28-.948z"
                />
                <path
                    data-name="Path 60"
                    d="M15.306 16.938a10.334 10.334 0 001.626-11.544.475.475 0 00-.781-.116l-.824.879a.528.528 0 00-.108.578 8.009 8.009 0 01-1.388 8.654 6.879 6.879 0 01-6.656 2.035L7.5 16.36a.585.585 0 00-.672-.764l-3.816 1a.612.612 0 00-.3.949l2.515 3.24a.537.537 0 00.954-.185l.325-1.064a8.944 8.944 0 008.8-2.598z"
                />
            </g>
        </svg>
    )
}

export default TransactionsRestoreSVGR
