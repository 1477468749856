import * as React from "react"

function CoinSVGR(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={37.999}
      height={38}
      viewBox="0 0 37.999 38"
      {...props}
    >
      <path
        data-name="Path 27"
        d="M21.968 21.226a10.613 10.613 0 10-10.613-10.613 10.625 10.625 0 0010.613 10.613zm0-19a8.387 8.387 0 11-8.387 8.387 8.4 8.4 0 018.387-8.386z"
        fill="#5e6f9b"
      />
      <g data-name="Group 88">
        <path
          data-name="Path 28"
          d="M23.204 9.524a7.937 7.937 0 01-1.913-.881.435.435 0 01-.043-.343.619.619 0 01.4-.49 1.344 1.344 0 011.186.165 1.113 1.113 0 001.505-1.64 3.623 3.623 0 00-1.253-.677v-.092a1.113 1.113 0 10-2.227 0v.161a2.789 2.789 0 00-.939 4.667 9.3 9.3 0 002.544 1.228.743.743 0 01.558.889 1.057 1.057 0 01-1.057.9 1.975 1.975 0 01-1.46-.377 1.113 1.113 0 00-1.219 1.863 4.03 4.03 0 001.574.669v.107a1.113 1.113 0 002.227 0v-.233a3.127 3.127 0 00.117-5.916z"
          fill="#5e6f9b"
        />
      </g>
      <path
        data-name="Path 29"
        d="M32.214 21.673l-12.762 2.9a3.81 3.81 0 00-1-.655l-6.553-2.94a7.21 7.21 0 00-5.98.026l-5.267 2.4a1.113 1.113 0 00.922 2.026l5.273-2.4a4.989 4.989 0 014.14-.019l6.553 2.94a1.594 1.594 0 01-1.308 2.907l-5.666-2.55a1.113 1.113 0 00-.914 2.03l5.666 2.55a3.822 3.822 0 005.3-4.3c8.313-1.891 12.137-2.76 12.19-2.776a2.308 2.308 0 011.835 4.2l-11.994 7.064a4.957 4.957 0 01-4.175.415l-9.2-3.22a1.113 1.113 0 00-.764.01L.717 35.252a1.113 1.113 0 00.793 2.081l7.413-2.824 8.817 3.086a7.177 7.177 0 006.043-.6l11.994-7.064a4.534 4.534 0 00-3.562-8.259z"
        fill="#5e6f9b"
      />
    </svg>
  )
}

export default CoinSVGR
