import React, { useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import useStyles from "../styles/components/GetAppStyles";
import useLanguage from "../hooks/useLanguage";

const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    viewBox="0 0 36 36"
    {...props}
  >
    <g data-name="Group 1204" transform="translate(-14 -764)">
      <rect
        data-name="Rectangle 563"
        width={36}
        height={36}
        rx={6}
        transform="translate(14 764)"
        fill="#27334e"
      />
      <g data-name="Group 1202">
        <path
          data-name="Path 1"
          d="M41.09 788.273H28.124l-6.12-11.386-2.41 3.975 6.212 11.285h12.97z"
          fill="#efc567"
        />
        <path
          data-name="Path 2"
          d="M23.463 775.855h15.611l2.439-3.975h-15.64z"
          fill="#efc567"
        />
        <path
          data-name="Path 3"
          d="M45.132 782.978h-13l-6.26-11.1-2.409 3.977 6.365 10.993h12.961z"
          fill="#efc567"
        />
        <g data-name="Group 4">
          <path
            data-name="Path 4"
            d="M36.644 795.742l-.029-.012 9.591-16.04.029.019z"
            fill="#efc567"
            opacity={0.32}
          />
        </g>
        <path
          data-name="Path 5"
          d="M18.019 785.043l-.019-.01L27.59 769l.029.01z"
          fill="#efc567"
        />
        <path
          data-name="Path 6"
          d="M24.96 793.581l-.029-.019 9.6-16.033.019.01z"
          fill="#efc567"
        />
        <g data-name="Group 7">
          <path
            data-name="Path 7"
            d="M38.401 777.026l-.019-.01 3.725-6.231.029.01z"
            fill="#efc567"
            opacity={0.32}
          />
        </g>
        <g data-name="Group 8">
          <path
            data-name="Path 8"
            d="M42.136 780.878H38.48l-1.01-1.824h3.648z"
            fill="#efc567"
          />
        </g>
        <path
          data-name="Path 9"
          d="M40.116 778.637l-1.014-1.824h-7.306l2.02 3.648h3.648l-1.008-1.824z"
          fill="#efc567"
        />
      </g>
    </g>
  </svg>
);

const Footer = (props) => {
  const classes = useStyles(props);
  const [show, setShow] = useState(true);

  const {
    globalComponents: { getApp: STRINGS },
  } = useLanguage();

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  const isAndroid = /android/i.test(userAgent);

  const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

  console.log(isAndroid, isIOS);

  if (!show || (!isAndroid && !isIOS)) {
    return null;
  }

  return (
    <Box className={classes.container}>
      <Logo className={classes.logo} />
      <Box className={classes.titleDesc}>
        <Typography variant="body1" className={classes.title}>
          {STRINGS.title}
        </Typography>
        <Typography variant="body1" className={classes.desc}>
          {isAndroid ? STRINGS.descriptionAndroid : STRINGS.descriptionIOS}
        </Typography>
      </Box>
      <Button
        className={classes.button}
        onClick={() => {
          window.open(
            isAndroid
              ? "https://play.google.com/store/apps/details?id=com.leadercard"
              : "https://apps.apple.com/us/app/leadercard-e-wallet/id1575881474",
            "_blank"
          );
        }}
      >
        <Typography variant="body1" className={classes.buttonText}>
          {STRINGS.buttonText}
        </Typography>
      </Button>
      <Button
        className={classes.closeButton}
        onClick={() => {
          setShow(false);
        }}
      >
        <Typography variant="body1" className={classes.buttonText}>
          X
        </Typography>
      </Button>
    </Box>
  );
};

export default Footer;
