import { Grid, Typography } from '@material-ui/core';
import React from 'react'
import useStyles from "../styles/components/FeaturesStyles";
import VerifiedSVGR from "../smart-assets/SVGRs/VerifiedSVGR";
import DiscountSVGR from "../smart-assets/SVGRs/DiscountSVGR";
import CoinSVGR from "../smart-assets/SVGRs/CoinSVGR";
import DollarSVGR from "../smart-assets/SVGRs/DollarSVGR";
import useLanguage from "../hooks/useLanguage";


const Features = (props) => {
    const classes = useStyles(props);

    const { pages: { homePage: { features: STRINGS } } } = useLanguage();

    const componentOrder = [
        {
            contentKey: 'verified',
            SVGR: <VerifiedSVGR />,
        },
        {
            contentKey: 'discount',
            SVGR: <DiscountSVGR />,
        },
        {
            contentKey: 'coin',
            SVGR: <CoinSVGR />,
        },
        {
            contentKey: 'dollar',
            SVGR: <DollarSVGR />
        }
    ];

    return (
        <Grid container item xs={9} className={classes.container} justify="space-between">
            {componentOrder.map(component => (
                <Grid xs={6} sm={3} item className={classes.gridItem} key={component.contentKey}>
                    <div className={classes.SVGRContainer}>
                        {component.SVGR}
                    </div>
                    <Typography variant="body1" className={classes.text}>
                        {STRINGS[component.contentKey]}
                    </Typography>
                </Grid>
            ))}
        </Grid>
    )
}


export default Features
