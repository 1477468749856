import React, { useState, useCallback, useEffect } from "react";
import { user as userEndpoint, cAxios, 
    // ping as pingEndpoint, 
    checkBalance, transactionHistory as transactionHistoryEndpoint, logout as LogoutEndpoint } from "../api/index";

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [balance, setBalance] = useState(null);
    // const [pingIntervalKey, setPingIntervalKey] = useState(false);

    cAxios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        // console.log("ERROR")
        if (error.response.status === 401) {
            setUser(null);
        }
        return Promise.reject(error);
    });


    const fetchUser = useCallback(async () => {
        try {
            const { data } = await cAxios.get(userEndpoint);
            console.log(data);
            // const key = setInterval(async () => {
            //     await cAxios.get(pingEndpoint);
            // }, 55000);
            // if(pingIntervalKey){
            //     clearInterval(pingIntervalKey);
            // }
            // setPingIntervalKey(key);
            return data;
        } catch (err) {
            console.log(err)
            return err;
        }
    }, [
        // pingIntervalKey
    ]);

    useEffect(() => {
        (async () => {
            const { data } = await fetchUser();
            if(data){
                setUser(data)
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUserBalance = useCallback(async () => {
        try {
            const { data } = await cAxios.get(checkBalance);
            setBalance(data.data.balance);
            return data;
        } catch (err) {
            console.log(err)
            return err;
        }
    }, [setBalance]);

    const getTransactionHistory = async (params = {}) => {
        try {
            const { data } = await cAxios.get(transactionHistoryEndpoint, {
                params: {
                    ...params,
                    // ?operation_type=1&last_transaction_id=34476&date_from=03-09-2018&date_to=07-09-2018
                }
            });
            console.log(data.data)
            return data.data;
        } catch (err) {
            console.log(err)
            return err;
        }
    };

    const logout = () => {
        try {
            cAxios.post(LogoutEndpoint);
            setUser(null);
            // clearInterval(pingIntervalKey);
        } catch (error) {
            console.log("Logout Error", error);
        }
    }


    return (
        <UserContext.Provider
            value={{
                user,
                getUserBalance,
                getTransactionHistory,
                fetchUser,
                logout,
                balance,
                setUser,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export { UserProvider, UserContext };