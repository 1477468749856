import React, { useState, useEffect, useCallback } from "react";
import useStyles from "../styles/components/TextFieldPasswordStyles";
import { Typography, TextField, InputAdornment, Box } from "@material-ui/core";
import generateInputStatuses from "../generators/generateInputStatuses";
import classNames from "classnames";
import useLanguage from "../hooks/useLanguage";

const EndAdornment = ({ classes, inputStatuses, onClick, showPassword }) => {
    const { globalComponents: { loginModal: STRINGS } } = useLanguage();

    let status = showPassword ? 'showPassword' : 'password';
    let SVGR = inputStatuses[status]?.SVGR;
    if (SVGR) {
        return (
            <InputAdornment position="end" >
                <Box className={classes.endAdormentContainer}>
                    {SVGR ? <SVGR onClick={onClick} /> : null}
                    <Box className={classes.endAdormentTooltip}>
                        <Box className={classes.toopTipArrow} />
                        <Typography className={classes.endAdormentTooltipText}>
                            {showPassword ? STRINGS.hidePassword : STRINGS.showPassword}
                        </Typography>
                    </Box>
                </Box>
            </InputAdornment>
        )
    }
    else {
        return null;
    }
}


const TextFieldPassword = (props) => {
    const classes = useStyles(props);
    const { label, value, textFieldProps, inputProps, setValue, error, validate } = props;
    const inputStatuses = generateInputStatuses(label);
    const [showPassword, setShowPassword] = useState(false);

    const validation = useCallback(async (val) => {
        if (val) {
            await validate(val);
        }
    }, [validate])

    useEffect(() => {
        validation(value);
        // disabling eslint because the solutions for this warning will cause infinite or unoptimized loops
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [value])


    return (
        <TextField className={classNames(classes.textField, error ? classes.errored : null)} label={label} variant="outlined"
            value={value}
            onChange={(event) => setValue(() => event.target.value)}
            spellCheck="false"
            maxLength="5"
            InputProps={{
                endAdornment: <EndAdornment classes={classes} inputStatuses={inputStatuses} showPassword={showPassword} onClick={() => setShowPassword(!showPassword)} />,
            }}
            inputProps={{
                type: showPassword ? 'text' : 'password',
                ...inputProps
            }}
            {...textFieldProps}
        />
    );
};

export default TextFieldPassword;
