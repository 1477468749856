import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Button } from "@material-ui/core";
import useStyles from "../styles/components/FAQSearchStyles";
import FAQData from "../data/FAQ";
import SearchSVGR from '../smart-assets/SVGRs/SearchSVGR'
import AlertCircleSVGR from '../smart-assets/SVGRs/AlertCircleSVGR'
import FAQItem from "./FAQItem";
import useLanguage from "../hooks/useLanguage";

const allFAQ = (() => {
    var result = [];
    for (let section in FAQData) {
        result = [...result, ...FAQData[section]];
    }
    return result;
})();

function FAQ(props) {
    const classes = useStyles(props);
    const [inputVal, setInputVal] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSeachResults] = useState([]);
    const [selectedQuestionId, setSelectedQuestionId] = useState();

    const { key: languageKey, pages: { faq: { FAQSearch: STRINGS } } } = useLanguage();

    const search = (e) => {
        e.preventDefault();
        setSearchTerm(() => (inputVal));
    }

    useEffect(() => {
        var results = allFAQ.filter(QA => QA.question[languageKey].includes(searchTerm) || QA.answer[languageKey].includes(searchTerm))
        if (results && results.length !== 0) {
            results = results.map(QA => {
                var question = QA.question[languageKey];
                var answer = QA.answer[languageKey];
                question = question.replace(searchTerm, `<mark>${searchTerm}</mark>`);
                answer = answer.replace(searchTerm, `<mark>${searchTerm}</mark>`);
                return {
                    ...QA,
                    question,
                    answer
                };
            })
        }
        setSeachResults(results);
    }, [searchTerm, languageKey])

    return (
        <Grid item container className={classes.container} justify="center">
            <Grid xs={12} sm={9} item>
                <form onSubmit={search} className={classes.searchBarContainer}>
                    <TextField
                        value={inputVal}
                        onChange={(val) => setInputVal(val.target.value)}
                        className={classes.textField1}
                        label={STRINGS.placeholderText}
                        variant="outlined"
                    />
                    <Button onClick={search} className={classes.searchButton}>
                        <SearchSVGR />
                    </Button>
                </form>
            </Grid>
            { searchTerm && (!searchResults || searchResults.length === 0) && <Grid xs={12} sm={8} item className={classes.nothingFound}>
                <AlertCircleSVGR />
                <Typography className={classes.nothingFoundText}>
                    {STRINGS.sadlyNothing}
                    <br />
                    {STRINGS.wasFound}
                </Typography>
            </Grid>}
            { searchTerm && (searchResults && searchResults.length !== 0)
                &&
                <Grid sm={12} item container className={classes.FAQItemsContainer}>
                    {searchResults.map(({ id, question, answer }) => (
                        <FAQItem
                            isOpen={selectedQuestionId === id}
                            key={id}
                            onToggle={() => setSelectedQuestionId(id === selectedQuestionId ? null : id)}
                            question={question}
                            answer={answer}
                        />
                    ))}
                </Grid>
            }
        </Grid>
    );
}

export default FAQ;
