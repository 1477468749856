import React, { useState, useEffect, useCallback } from "react";
import useStyles from "../styles/components/TransferToLiderbetFormStyles";
import ModalCloseSVGR from "../smart-assets/SVGRs/ModalCloseSVGR";
import { Grid, Typography, Button, TextField, InputAdornment } from "@material-ui/core";
import useLanguage from "../hooks/useLanguage";
import useLBTransactions from "../hooks/services/useLBTransactions";
import SuccessStatusSVGR from "../smart-assets/SVGRs/SuccessStatusSVGR";
import ErrorStatusSVGR from "../smart-assets/SVGRs/ErrorStatusSVGR";
import classNames from "classnames";
import SMSInputWithStatus from "./SMSInputWithStatus";

const TransferToLiderbetForm = (props) => {
    const classes = useStyles(props);
    const { onClose = () => { }, refetch = () => { } } = props;
    const [amount, setAmount] = useState('');
    const [pinWasFound, setPinWasFound] = useState(true);
    const [pinCode, setPinCode] = useState('');
    const { pages: { dashboard: { balance: { transferToLeaderbetForm: STRINGS, transferToLeaderbetForm: { errorStatuses: ERROR_STATUSES } } } } } = useLanguage();
    const { checkLeaderbetTransverAvailablity, transferToLeaderbet, requestTransferToLeaderbetSMS } = useLBTransactions();
    const [statusText, setStatusText] = useState('');
    const [statusViewStatus, setStatusViewStatus] = useState(null);
    const [currentlyRequesting, setCurrentlyRequesting] = useState(false);
    const [error, setError] = useState(null);

    // SMS
    const [isOnSMSStage, setIsOnSMSStage] = useState(null);
    const [smsCode, setSMSCode] = useState('');
    const [smsCodeStatus, setSMSCodeStatus] = useState('');
    const [smsCodeTimeoutKey, setSMSCodeTimeoutKey] = useState(null);
    const [smsCodeError, setSMSCodeErorr] = useState('');
    const [sentSMSAt, setSentSMSAt] = useState(null);
    const [smsTimeout, setSMSTimeout] = useState(0);

    const _sendSMS = useCallback(async () => {
        setIsOnSMSStage(true)
        if (!sentSMSAt || (new Date().getTime() - sentSMSAt.getTime()) / 1000 > smsTimeout) {
            const data = await requestTransferToLeaderbetSMS();
            console.log('data', data);
            if (data.status === 200) {
                setSentSMSAt(new Date());
            }
            setSMSTimeout(data.data.data);
            if (smsCodeTimeoutKey) {
                clearTimeout(smsCodeTimeoutKey)
            }
            const key = setTimeout(() => {
                setSentSMSAt(false)
            }, data.data.data * 1000);
            setSMSCodeTimeoutKey(key);
        }
    }, [setIsOnSMSStage, requestTransferToLeaderbetSMS, sentSMSAt, smsCodeTimeoutKey, smsTimeout])

    useEffect(() => {
        (async () => {
            const res = await checkLeaderbetTransverAvailablity();
            if (res.data.status === 0) {
                setPinWasFound(true);
                setPinCode(String(res.data.data))
            } else {
                setError(ERROR_STATUSES[String(res.data.status)])
                setPinWasFound(false);
                setPinCode('');
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = async () => {
        setCurrentlyRequesting(true);
        const convertAmount = (amountSTR) => {
            const dotIndex = amountSTR.indexOf('.');
            if (dotIndex !== -1) {
                if (dotIndex === amountSTR.length - 2) {
                    amountSTR += "0";
                }
            }
            else {
                amountSTR += "00";
            }
            amountSTR = amountSTR.replace('.', '');
            return amountSTR;
        }
        const res = await transferToLeaderbet(pinCode, convertAmount(amount), smsCode);
        console.log('res', res);
        // REQUEST
        if (res.data > 0) {
            setStatusViewStatus('success');
            setStatusText(STRINGS.successMessage)
        } else {
            console.log(res.data, ERROR_STATUSES[String(res.data)], ERROR_STATUSES)
            setError(ERROR_STATUSES[String(res.data)]);
            // setStatusViewStatus('error');
            // setStatusText(STRINGS.errorMessage)
        }
        setCurrentlyRequesting(false);
    }

    const nullifyValues = useCallback(() => {
        setAmount('');
        setStatusText('');
        setStatusViewStatus(null);
        setIsOnSMSStage(null);
        setSMSCode('');
        setSMSCodeStatus('');
        setSMSCodeTimeoutKey(null);
        setSMSCodeErorr('');
        setSentSMSAt(null);
        setSMSTimeout(0);
        setError(null);
    },[ ])

    const onRetry = () => {
        if (!pinWasFound) {
            setPinCode('');
        }
        nullifyValues();
    }

    return (
        <Grid container className={classes.container} alignItems="baseline">
            <ModalCloseSVGR className={classes.closeSVGR} onClick={() => {
                onClose();
                refetch();
            }} />
            { !statusViewStatus ?
                <>
                    <Grid xs={12} item className={classes.textFieldContainer}>
                        <TextField className={classes.textField1} label={STRINGS.amount} variant="outlined"
                            value={amount}
                            onChange={(event) => {
                                if (/^[0-9]+(\.[0-9]{1,2})?$/gm.test(event.target.value) || (event.target.value.split('').filter(e => e === '.').length === 1 && event.target.value[event.target.value.length - 1] === '.' && event.target.value.length !== 1) || event.target.value.length === 0) {
                                    setAmount(() => event.target.value);
                                }
                            }}
                            spellCheck="false"
                            InputProps={{
                                endAdornment: <InputAdornment position="start" style={{ display: `${amount.length ? 'flex' : 'none'}`, minWidth: `calc(100% - ${amount.length} * 8.8px - 25px)`, marginBottom: -2 }}><span className={classes.endAdornment}>₾</span></InputAdornment>,
                            }}
                        />
                    </Grid>
                    { pinWasFound ? null : <Grid xs={12} item className={classes.textFieldContainer}>
                        <TextField className={classes.textField1} label={STRINGS.pinCode} variant="outlined"
                            value={pinCode}
                            onChange={(event) => setPinCode(() => event.target.value.replace(/\D/g, ''))}
                            spellCheck="false"
                            inputProps={{
                                maxLength: 7,
                            }}
                        />
                    </Grid>
                    }
                    {isOnSMSStage ? <>
                        <Grid xs={12} item className={classes.textFieldContainer}>
                            <SMSInputWithStatus
                                label={STRINGS.smsCode}
                                validate={(val) => {
                                    if (smsCodeError) {
                                        setSMSCodeErorr('');
                                    }
                                    let restrictedVal = val.replace(/[^0-9]/g, '');
                                    setSMSCode(restrictedVal);
                                }}
                                value={smsCode}
                                error={smsCodeError}
                                status={smsCodeStatus}
                                setValue={setSMSCode}
                                setStatus={setSMSCodeStatus}
                                sentSMSAt={sentSMSAt}
                                setCanSendSMS={setSentSMSAt}
                                sendSMS={_sendSMS}
                                timeoutSecs={smsTimeout}
                                inputProps={{ maxLength: 4 }}
                            />
                            {
                                smsCodeError ? <Typography className={classes.error}>
                                    {smsCodeError}
                                </Typography> : null
                            }
                        </Grid>
                        </> : null}
                        {error ? <>
                            <Typography className={classes.errorMessage}>
                                {error}
                            </Typography>
                        </> : null}
                    <Grid xs={12} item className={classes.textFieldContainer}>
                        <Button disabled={!(!currentlyRequesting && amount && pinCode.length !== 0) || (amount && amount[amount.length - 1] === '.') || (isOnSMSStage && smsCode && smsCode.length !== 4)} className={classes.submitButton} onClick={isOnSMSStage ? onSubmit : _sendSMS}>
                            <Typography className={classes.submitButtonText}>
                                {STRINGS.buttonText}
                            </Typography>
                        </Button>
                    </Grid>
                </>
                :
                <>
                    <Grid xs={12} item container className={classes.contentContainer} justify="center">
                        <Grid item container xs={12} justify="center">
                            {statusViewStatus === 'success' ? <SuccessStatusSVGR className={classes.statusSVGR} /> : <ErrorStatusSVGR className={classes.statusSVGR} />}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h2" className={classNames(classes.statusText, statusViewStatus === 'success' ? classes.successStatusText : classes.errorStatusText)}>
                                {statusText}
                                <br />
                                {statusViewStatus === 'success' ? `${STRINGS.pinNumber}: ${pinCode}` : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.buttonContainer}>
                        <Button className={classes.resetButton} onClick={onRetry}>
                            <Typography className={classes.submitButtonText}>
                                {STRINGS.transferAgainButtonText}
                            </Typography>
                        </Button>
                    </Grid>
                </>
            }
        </Grid>
    );
};

export default TransferToLiderbetForm;
