import * as React from "react"

function TransactionsCalendarSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={17}
            height={18}
            viewBox="0 0 17 18"
            {...props}
        >
            <path
                d="M13.489 2.25h2.033A1.491 1.491 0 0117 3.75V16.5a1.491 1.491 0 01-1.478 1.5H1.478A1.491 1.491 0 010 16.5V3.75a1.491 1.491 0 011.478-1.5h1.109a.371.371 0 01.37.375v1.688a.554.554 0 101.109 0V.75a.739.739 0 111.478 0v1.313a.186.186 0 00.185.188h4.62a.371.371 0 01.37.375v1.688a.554.554 0 101.109 0V.751a.739.739 0 111.478 0v1.313a.186.186 0 00.183.186zM1.848 16.5h13.3a.371.371 0 00.37-.375v-9a.371.371 0 00-.37-.375h-13.3a.37.37 0 00-.37.375v9a.371.371 0 00.37.375z"
                fill="#8f9bb8"
                fillRule="evenodd"
                data-name="Interface-Essential / Date/Calendar / calendar"
            />
        </svg>
    )
}

export default TransactionsCalendarSVGR
