import * as React from "react"

function ImageUploadSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={114}
            height={111}
            viewBox="0 0 114 111"
            {...props}
        >
            <g
                fill="#343e57"
                data-name="Internet-Networks-Servers / Upload/Download / upload-thick-bottom"
            >
                <path d="M40.873 80.997a6.941 6.941 0 006.935 6.963h16.178a6.94 6.94 0 006.93-6.963v-39.2a1.187 1.187 0 011.192-1.192h13.831a5.524 5.524 0 005.437-3.339 5.418 5.418 0 00-1.493-6.2L60.799 1.972a7.094 7.094 0 00-9.806 0L21.909 31.066a5.423 5.423 0 003.944 9.539h13.831a1.342 1.342 0 01.844.334 1.22 1.22 0 01.348.858z" />
                <path d="M0 91.779A19.219 19.219 0 0019.166 111h75.667A19.219 19.219 0 00114 91.779v-8.966a5.818 5.818 0 00-11.633 0v8.918a7.559 7.559 0 01-7.534 7.583H19.166a7.559 7.559 0 01-7.534-7.583v-8.918a5.818 5.818 0 00-11.633 0z" />
            </g>
        </svg>
    )
}

export default ImageUploadSVGR
