import * as React from "react"

function SuccessStatusSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={70}
            height={70}
            viewBox="0 0 70 70"
            {...props}
        >
            <g data-name="Group 177" fill="#197163">
                <path
                    data-name="Path 55"
                    d="M35 0a35 35 0 1035 35A35.04 35.04 0 0035 0zm0 65.625A30.625 30.625 0 1165.625 35 30.659 30.659 0 0135 65.625z"
                />
                <path
                    data-name="Path 54"
                    d="M46.919 25.907a2 2 0 00-2.842.138l-12.276 13.6-5.931-6.067a2 2 0 00-2.846-.024A2.031 2.031 0 0023 36.417l7.424 7.593a2 2 0 001.435.605.24.24 0 01.038 0 2.008 2.008 0 001.451-.664l13.707-15.187a2.035 2.035 0 00-.136-2.857z"
                />
            </g>
        </svg>
    )
}

export default SuccessStatusSVGR
