import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	contentContainerGrid: {
		width: "auto",
		margin: "auto",
		marginTop: -6,
		"& > div:last-of-type *:last-child": {
			// marginTop: 36,
			marginBottom: 13,
		},
		"& > div:first-of-type": {
			margin: 0,
		},
		[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
			maxWidth: '53.75rem',
		},
	},
	title: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 18,
		lineHeight: "28px",
		textAlign: "left",
		color: "#677aa7",
		marginBottom: 26,
		// "&:first-of-type": {
		// 	background: "red",
		// },
	},
	paragraph: {
		fontFamily: "var(--font-family-3)",
		fontWeight: "normal",
		fontSize: 16,
		lineHeight: "22px",
		textAlign: "left",
		color: "#a6b5d9",
		marginBottom: 47,
	},
	list: {
		padding: 0,
		paddingLeft: 15,
		margin: 0,
		'& li:not(:last-of-type)': {
			marginBottom: 24,
		},
	},
}));

export default useStyles;
