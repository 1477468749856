import { useEffect } from "react";
import {
  cAxios,
  checkAuthState,
  requestSMS,
  firstAuth,
  firstPasswordChange,
  login,
  requestPasswordRecoverySMS,
  authPasswordRecovery,
  requestVerifyAuthSMS,
  verifyAuthSMS,
} from "../../api/index";
import qs from "qs";

function useAuth() {
  const _checkAuthState = async (personal_id) => {
    const res = await cAxios.get(checkAuthState, {
      params: {
        personal_id: personal_id,
      },
    });
    console.log(res);
    return res;
  };

  const _requestSMS = async (personal_id) => {
    try {
      const response = await cAxios.get(requestSMS, {
        params: {
          personal_id: personal_id,
        },
      });
      return response;
    } catch ({ response }) {
      return response;
    }
  };

  const _requestPasswordRecoverySMS = async (personal_id) => {
    try {
      const response = await cAxios.get(requestPasswordRecoverySMS, {
        params: {
          personal_id: personal_id,
        },
      });
      return response;
    } catch ({ response }) {
      return response;
    }
  };

  const _requestVerifyAuthSMS = async () => {
    try {
      const response = await cAxios.get(requestVerifyAuthSMS);
      return response;
    } catch ({ response }) {
      return response;
    }
  };

  const _verifyAuthSMS = async (sms_code) => {
    const formData = new FormData();
    formData.append("sms_code", sms_code);
    try {
      const { data } = await cAxios.post(verifyAuthSMS, formData);
      return data;
    } catch ({ response: { data } }) {
      return data;
    }
  };

  const _firstAuth = async (personal_id, sms_code, card_last_numbers) => {
    const formData = new FormData();
    formData.append("personal_id", personal_id);
    formData.append("sms_code", sms_code);
    formData.append("card_last_numbers", card_last_numbers);
    try {
      const { data } = await cAxios.post(firstAuth, formData);
      return data;
    } catch ({ response: { data } }) {
      return data;
    }
  };

  const _authPasswordRecovery = async (personal_id, sms_code) => {
    const formData = new FormData();
    formData.append("personal_id", personal_id);
    formData.append("sms_code", sms_code);
    try {
      const { data } = await cAxios.post(authPasswordRecovery, formData);
      return data;
    } catch ({ response: { data } }) {
      return data;
    }
  };

  const _firstPasswordChange = async (password, repeated_password) => {
    const formData = new FormData();
    formData.append("password", password);
    formData.append("repeated_password", repeated_password);
    try {
      const { data } = await cAxios.post(firstPasswordChange, formData, {
        withCredentials: true,
      });
      return data;
    } catch ({ response: { data } }) {
      return data;
    }
  };

  const _login = async (username, password) => {
    try {
      const res = await cAxios.post(
        login,
        qs.stringify({ username, password }),
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      );
      return res;
    } catch ({ response: { data } }) {
      return data;
    }
  };

  useEffect(() => {
    // (async () => {
    //     const { data: { data } } = await cAxios.get(fetchParamList);
    //     console.log(data);
    //     setParamList(data);
    // })()
  }, []);

  return {
    checkAuthState: _checkAuthState,
    requestSMS: _requestSMS,
    firstAuth: _firstAuth,
    firstPasswordChange: _firstPasswordChange,
    login: _login,
    requestPasswordRecoverySMS: _requestPasswordRecoverySMS,
    authPasswordRecovery: _authPasswordRecovery,
    requestVerifyAuthSMS: _requestVerifyAuthSMS,
    verifyAuthSMS: _verifyAuthSMS,
  };
}

export default useAuth;
