import * as React from "react"

function FlagEnglishSVGR(props) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        viewBox="0 0 30 30"
        {...props}
      >
        <g data-name="Group 1192" fill="#29337a">
          <path
            data-name="Path 80"
            d="M2.642 6.5a14.935 14.935 0 00-2.148 4.675h6.823z"
          />
          <path
            data-name="Path 81"
            d="M10.799.6a14.946 14.946 0 00-4.735 2.354l4.738 4.738z"
          />
          <path
            data-name="Path 82"
            d="M6.414 27.295a14.924 14.924 0 004.386 2.1v-6.489z"
          />
          <path
            data-name="Path 83"
            d="M.666 19.429a14.943 14.943 0 002.227 4.423l4.423-4.423z"
          />
        </g>
        <g data-name="Group 1193" fill="#fff">
          <path
            data-name="Path 84"
            d="M2.889 6.152q-.126.172-.248.348l4.675 4.675H.493c-.111.421-.2.85-.275 1.285h8.979z"
          />
          <path
            data-name="Path 85"
            d="M6.065 27.048c.114.085.231.166.348.248l4.386-4.385V29.4c.421.123.849.23 1.285.315V21.03z"
          />
          <path
            data-name="Path 86"
            d="M.332 18.144c.093.435.2.864.333 1.285h6.65l-4.423 4.422a15.1 15.1 0 001.59 1.845l7.552-7.552z"
          />
          <path
            data-name="Path 87"
            d="M10.799.6v7.092L6.061 2.954a15.084 15.084 0 00-1.83 1.609l7.852 7.852V.285c-.435.086-.86.194-1.284.315z"
          />
        </g>
        <g data-name="Group 1194" fill="#d32030">
          <path data-name="Path 88" d="M10.799 19.384l-.045.045h.045z" />
          <path data-name="Path 89" d="M12.039 18.144h.045v-.045z" />
          <path data-name="Path 90" d="M17.811 12.46h-.043v.044z" />
          <path data-name="Path 91" d="M10.843 11.177l-.044-.045v.045z" />
          <path data-name="Path 92" d="M12.084 12.416v.044h.044z" />
        </g>
        <g data-name="Group 1195" fill="#252f6c">
          <path
            data-name="Path 93"
            d="M19.052 26.082v3.362a14.914 14.914 0 004.447-2.083l-3.643-3.643a11.8 11.8 0 01-.804 2.364z"
          />
          <path
            data-name="Path 94"
            d="M19.875 6.869l3.977-3.977a14.943 14.943 0 00-4.8-2.334v3.454a13.3 13.3 0 01.823 2.857z"
          />
          <path
            data-name="Path 95"
            d="M27.045 23.939a14.957 14.957 0 002.289-4.51h-6.8z"
          />
          <path
            data-name="Path 96"
            d="M29.506 11.175a14.93 14.93 0 00-2.21-4.762l-4.763 4.762z"
          />
        </g>
        <g data-name="Group 1196" fill="#e7e7e7">
          <path
            data-name="Path 97"
            d="M20.751 18.399l1.031 1.031 4.934 4.934c.112-.14.223-.28.33-.424l-4.51-4.51h6.8q.194-.631.333-1.285h-8.821c-.015.182-.08.073-.097.254z"
          />
          <path
            data-name="Path 98"
            d="M19.719 7.621l4.472-4.472a14.353 14.353 0 00-.339-.257l-3.977 3.977c.041.159-.194.592-.156.752z"
          />
          <path
            data-name="Path 99"
            d="M19.053 3.736V.558q-.631-.177-1.285-.3v1.55a8.3 8.3 0 011.285 1.928z"
          />
          <path
            data-name="Path 100"
            d="M20.649 12.461h9.132a15.102 15.102 0 00-.275-1.285h-6.973l4.763-4.762a15.077 15.077 0 00-1.557-1.881l-5.365 5.364c.145.971.21 1.538.275 2.564z"
          />
          <path
            data-name="Path 101"
            d="M19.639 23.497l3.86 3.861a15.05 15.05 0 001.811-1.469l-5.078-5.078a26.059 26.059 0 01-.593 2.686z"
          />
          <path
            data-name="Path 102"
            d="M17.768 28.016v1.728q.653-.121 1.285-.3v-3.362a8.342 8.342 0 01-1.285 1.934z"
          />
        </g>
        <path
          data-name="Path 103"
          d="M20.371 9.897l5.364-5.364a15 15 0 00-1.548-1.383l-4.215 4.216c.191.783.276 1.679.399 2.531z"
          fill="#d71f28"
        />
        <g data-name="Group 1197" fill="#d32030">
          <path
            data-name="Path 104"
            d="M14.251.018Q14.626 0 14.999 0q-.376 0-.748.018z"
          />
          <path
            data-name="Path 105"
            d="M14.999 30c-.249 0-.5-.007-.748-.019q.374.019.748.019z"
          />
          <path
            data-name="Path 106"
            d="M14.999 30a15.033 15.033 0 002.768-.26v-1.728c-.788.855-1.71 1.988-2.768 1.988z"
          />
        </g>
        <g data-name="Group 1198" fill="#d71f28">
          <path
            data-name="Path 107"
            d="M29.781 12.46h-9.014a40.312 40.312 0 01-.043 5.684h8.943a14.965 14.965 0 00.114-5.684z"
          />
          <path
            data-name="Path 108"
            d="M20.75 18.398c-.083.886-.374 1.577-.518 2.413l5.078 5.078a15.14 15.14 0 001.4-1.526l-4.934-4.935z"
          />
        </g>
        <path
          data-name="Path 109"
          d="M17.764 1.921V.259A15.072 15.072 0 0015 0c1.058 0 1.98 1.067 2.764 1.921z"
          fill="#d32030"
        />
        <g data-name="Group 1199" fill="#29337a">
          <path
            data-name="Path 110"
            d="M19.052 26.727a16.155 16.155 0 001.045-2.771l-1.045-1.045z"
          />
          <path
            data-name="Path 111"
            d="M20.227 6.518a16.871 16.871 0 00-1.175-3.245v4.423z"
          />
        </g>
        <g data-name="Group 1200" fill="#fff">
          <path
            data-name="Path 112"
            d="M21.042 18.69q.026-.271.047-.545h-.592z"
          />
          <path
            data-name="Path 113"
            d="M19.053 7.692V3.273a8.3 8.3 0 00-1.285-1.927v8.227l2.58-2.58c-.039-.161-.08-.319-.12-.476z"
          />
          <path
            data-name="Path 114"
            d="M17.812 12.46h3.321a36.3 36.3 0 00-.313-3.007z"
          />
          <path
            data-name="Path 115"
            d="M17.768 28.655a8.3 8.3 0 001.285-1.927v-3.816l1.045 1.045a25.875 25.875 0 00.6-2.677l-2.933-2.933v10.308z"
          />
        </g>
        <path
          data-name="Path 116"
          d="M17.768 28.654V18.347l2.932 2.932c.144-.836.258-1.7.341-2.59l-.545-.545h.592a40.326 40.326 0 00.043-5.684h-3.32l3.007-3.007a28.77 28.77 0 00-.471-2.46l-2.579 2.58V1.346A3.726 3.726 0 0015 0q-.374 0-.748.018a15.1 15.1 0 00-2.168.267v12.13L4.231 4.564a15.145 15.145 0 00-1.342 1.589l5.023 5.023L9.2 12.46H.218a14.963 14.963 0 00.114 5.684h11.707L4.487 25.7a15.107 15.107 0 001.578 1.352l6.018-6.018v8.684a15.114 15.114 0 002.168.267c.249.012.5.019.748.019a3.726 3.726 0 002.769-1.35z"
          fill="#e51d35"
        />
      </svg>
    )
}

export default FlagEnglishSVGR
