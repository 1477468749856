import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    registerSctionHeaderContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },
    withPopupText: {
        position: 'relative',
        '&:hover': {
            cursor: "help",
        },
        '&:hover $sectionHeaderPopupText': {
            display: 'unset',
        }
    },
    registerSectionHeaderText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '25px',
        textAlign: 'left',
        color: '#5e6f9b',
        marginRight: 18,
    },
    registerSctionHeaderLine: {
        flex: 1,
        height: 2,
        borderRadius: 1,
        border: 'none',
        background: '#1a2030',
    },
    sectionHeaderSubText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 12,
        textAlign: 'left',
        color: '#5e6f9b',
        marginRight: 18,
        marginTop: -10,
        marginLeft: -12,
    },
    sectionHeaderPopupText: {
        display: 'none',
        position: 'absolute',
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 13,
        textAlign: 'left',
        color: '#5e6f9b',
        maxWidth: 'max-content',
        zIndex: 2,
        left: 0,
        bottom: 0,
        transform: 'translateY(calc(100% + 10px))',
        backgroundColor: "#161C2A",
        borderRadius: 10,
        padding: 10,
        boxSizing: "border-box",
        boxShadow: '0px 0px 5px rgba(94, 111, 155, 0.15)',
    },
}));

export default useStyles;
