import * as React from "react"

function LogoutSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={33}
            height={24}
            viewBox="0 0 33 24"
            {...props}
        >
            <g data-name="noun_Log Out_2432000">
                <g data-name="Group 180">
                    <path
                        data-name="Path 57"
                        d="M16.494 10.217a1.783 1.783 0 000 3.566h10.415l-2.021 2.019a1.784 1.784 0 002.523 2.521l5.057-5.053a1.783 1.783 0 000-2.542L27.411 5.68a1.784 1.784 0 00-2.523 2.521l2.021 2.019zm3.753 6.037a1.367 1.367 0 012.431 1.252 12 12 0 110-11.011 1.367 1.367 0 01-2.431 1.251 9.261 9.261 0 100 8.508z"
                        fill="#7f8baa"
                    />
                </g>
            </g>
        </svg>
    )
}

export default LogoutSVGR
