import * as React from "react"

function DollarSVGR(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={38}
      height={38}
      viewBox="0 0 38 38"
      {...props}
    >
      <path
        data-name="Path 34"
        d="M19 38A19 19 0 015.565 5.565a19 19 0 0126.87 26.87A18.875 18.875 0 0119 38zm0-35.625A16.625 16.625 0 1035.625 19 16.644 16.644 0 0019 2.375z"
        fill="#5e6f9b"
      />
      <path
        data-name="Path 35"
        d="M19 17.813a2.969 2.969 0 112.969-2.969 1.188 1.188 0 102.375 0 5.352 5.352 0 00-4.156-5.209V8.313a1.188 1.188 0 00-2.375 0v1.322a5.343 5.343 0 001.188 10.553 2.969 2.969 0 11-2.969 2.969 1.188 1.188 0 00-2.375 0 5.352 5.352 0 004.156 5.209v1.322a1.188 1.188 0 002.375 0v-1.323A5.343 5.343 0 0019 17.812z"
        fill="#5e6f9b"
      />
    </svg>
  )
}

export default DollarSVGR
