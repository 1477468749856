import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "https://leadercard.ge/webapi/";

export const fetchParamList = "user/apply/paramList?lang=en";

export const register = "user/apply/register";

export const checkAuthState = "user/checkAuthState";

export const requestSMS = "user/requestSMS";

export const firstAuth = "user/firstAuth";

export const firstPasswordChange = "user/tempPasswordChange";

export const login = "login";

export const logout = "logout";

export const user = "secured/user/info";

// export const ping = "secured/user/ping";

export const registerSMS = "user/apply/requestSMS";

export const checkBalance = "secured/user/balance/check";

export const transactionHistory = "secured/user/transactions";

export const requestPasswordRecoverySMS = "/user/requestPasswordRecoverySMS";

export const checkDocumentNumber = "/user/checkDocumentNumber";

export const authPasswordRecovery = "/user/authPasswordRecovery";

export const checkLeaderbetTransverAvailablity = "/secured/transfers/lb/check";

export const transferToLeaderbet = "/secured/transfers/lb/transfer";

export const requestVerifyAuthSMS = "/secured/user/verifyAuth/requestSMS";

export const verifyAuthSMS = "/secured/user/verifyAuth/verifySMS";

export const requestTransferToLeaderbetSMS = "/secured/transfers/requestSMS";

axios.defaults.withCredentials = true;

export const cAxios = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});
