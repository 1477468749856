import * as React from "react"

function EyeOpenSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={18}
            viewBox="0 0 18 12"
            {...props}
        >
            <g fill="#546283" data-name="Interface-Essential / View / view">
                <path
                    d="M9.438.007c3.008.143 5.909 2.07 7.881 4.245a2.584 2.584 0 010 3.5c-.905 1-4.175 4.253-8.225 4.253H8.9c-4.051 0-7.319-3.255-8.223-4.253a2.593 2.593 0 010-3.5c.037-.037.075-.082.112-.12C2.903 1.86 5.806.032 8.996.007c.154-.007.296-.007.442 0zm.291 10.455c2.492-.233 4.854-1.928 6.482-3.72a1.107 1.107 0 000-1.485 13.733 13.733 0 00-1.853-1.7 11.544 11.544 0 00-2.55-1.485 7.1 7.1 0 00-2.81-.577c-2.751-.06-5.436 1.8-7.211 3.758a1.109 1.109 0 000 1.485c.081.09.163.18.247.263a10.24 10.24 0 006.965 3.5 5.913 5.913 0 00.73-.038z"
                    fillRule="evenodd"
                />
                <path d="M9 3c-.1 0-.2.008-.294.015a.186.186 0 00-.15.1.2.2 0 00.013.188 1.5 1.5 0 01-2.194 1.98.191.191 0 00-.178-.03.184.184 0 00-.122.135A2.661 2.661 0 006 6a3 3 0 103-3z" />
            </g>
        </svg>
    )
}

export default EyeOpenSVGR
