import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "sticky",
    backgroundColor: "#10151F",
    zIndex: 9999999,
    top: 0,
    width: "100%",
    boxSizing: "border-box",
    height: 62,
    padding: "12px 14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    animation: `$slideDown 1000ms ease-in-out`,
    borderBottom: "2px solid white",
  },
  logo: {
    marginRight: 12,
  },
  titleDesc: {
    marginRight: "auto",
  },
  title: {
    fontFamily: "Sofia Pro Regular",
    fontWeight: "normal",
    fontSize: 14,
    textAlign: "left",
    color: "#fff",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  desc: {
    fontFamily: "Sofia Pro Medium",
    fontWeight: 500,
    fontSize: 10,
    textAlign: "left",
    color: "#b3bbcb",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  button: {
    minWidth: "max-content",
    height: 34,
    background: "#27334e",
    borderRadius: 6,
    marginLeft: 6,
    justifySelf: "flex-end",
    "&:hover": {
      background: "#27334e",
    },
    padding: "6px 12px",
  },
  buttonText: {
    fontFamily: "Sofia Pro Bold",
    fontWeight: "bold",
    fontSize: 14,
    textAlign: "left",
    color: "#f1c157",
  },
  "@keyframes slideDown": {
    "0%": {
      transform: "translateY(-100%)",
    },
    "100%": {
      transform: "translateY(0)",
    },
  },
  closeButton: {
    position: "absolute",
    bottom: -32,
    right: 0,
    minHeight: 30,
    minWidth: 30,
    width: 30,
    height: 30,
    maxWidth: 30,
    maxHeight: 30,
    background: "#ffffff",
    "&:hover": {
      background: "#fff",
    },
    "border-radius": "0px 0px 0px 12px",
  },
}));

export default useStyles;
