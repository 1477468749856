import React, { useRef, useState, useEffect, useCallback } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import useStyles from "../styles/components/ImageUploadStyles";
import ImageUploadSVGR from "../smart-assets/SVGRs/ImageUploadSVGR";
import CameraSVGR from "../smart-assets/SVGRs/CameraSVGR";
import DeleteBinSVGR from "../smart-assets/SVGRs/DeleteBinSVGR";
import useLanguage from "../hooks/useLanguage";
import classNames from "classnames";
import Webcam from "react-webcam";

const Navigation = (props) => {
    const { pages: { register: { imageUpload: STRINGS } } } = useLanguage();
    const classes = useStyles(props);
    const { title, description, name, error, setValue, value } = props;
    const [imageURL, setImageURL] = useState(value);
    const [ withCamera, setWithCamera ] = useState(false);
    const fileInput = useRef();
    const webcamRef = useRef();

    useEffect(() => {
        return () => {
            setValue(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFileInputChange = () => {
        try {
            const url = window.URL.createObjectURL(fileInput.current?.files[0]);
            setValue(fileInput.current?.files[0]);
            setImageURL(url);
        } catch (error) {
            console.log(error);
        }

    }

    const deleteImage = () => {
        setImageURL(null);
        setValue(null);
        try {
            fileInput.value = "";
        } catch (error) {
            console.log(error)
        }
    }

    const openFileInput = () => {
        fileInput.current.click();
    }

    const toggleCamera = useCallback(async () => {
        if(withCamera && !imageURL){
            const imageSrc = webcamRef.current.getScreenshot();
            setValue(imageSrc);
            setImageURL(imageSrc);
            setWithCamera(false);
        }
        else {
            setWithCamera(true);
        }
    }, [webcamRef, setImageURL, setWithCamera, withCamera, imageURL, setValue])

    return (
        <Box className={classes.container}>
            <Box className={classNames(classes.imageContainer, error ? classes.errored : null)} onClick={openFileInput}>
                {imageURL ? <img src={imageURL} alt="" className={classes.image} /> :
                withCamera ?
                <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
        />
                :
                    <>
                        <ImageUploadSVGR />
                        {title && <Typography variant="body1" className={classes.title}>{title}</Typography>}
                        {description && <Typography variant="body1" className={classes.description}>{description}</Typography>}
                    </>
                }
            </Box>
            { imageURL ?
                <Button className={classes.deleteImageButton} onClick={deleteImage}>
                    <DeleteBinSVGR className={classes.deleteBinSVGR} />
                    <span>
                        {STRINGS.deleteFile}
                    </span>
                </Button>
                :
                <Button className={classes.captureImageButton} onClick={toggleCamera}>
                    <CameraSVGR className={classes.cameraSVGR} />
                    {STRINGS.captureWithCamera}
                </Button>
            }
                                <input
                        name={name}
                        type="file"
                        ref={fileInput}
                        value=""
                        onChange={handleFileInputChange}
                        style={{ display: 'none' }}
                        accept="image/*"
                    />
        </Box>
    );
};

export default Navigation;
