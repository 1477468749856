import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	container: {
		// background: "red",
		margin: "0 auto",
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			// flexDirection: 'column',
			// justifyContent: 'center',
			// alignItems: 'center',
		},
		maxWidth: '66.875rem',
	},
	gridItem: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		maxWidth: 192,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			'&:not(:last-of-type)': {
				marginBottom: 30,
			},
			'& $SVGRContainer': {
				width: 85,
				height: 85,
				borderRadius: 85,
			}
		},
		[theme.breakpoints.down(400)]: {
			maxWidth: '100%',
			width: '100%',
			flexBasis: 'unset'
		}
	},
	SVGRContainer: {
		width: 100,
		height: 100,
		borderRadius: 75,
		border: "3px solid #232c3e",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"& svg": {
			width: 35,
			height: 35,
		},
	},
	text: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		maxWidth: 140,
		fontSize: 15,
		marginTop: 24,
		lineHeight: "20px",
		textAlign: "center",
		color: "#a6b5d9",
		minWidth: '100%',
	},
}));

export default useStyles;
