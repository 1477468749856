import React, { useRef, useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import useStyles from "../styles/components/AtmsMapStyles";
import GoogleMapReact from "google-map-react";
import mapStyle from "../configs/atmMapStyle";
import Marker from "./AtmsMapMarker";
import useSupercluster from "use-supercluster";


const createMapOptions = function (maps) {
    return {
        panControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControlOptions: {
            position: maps.ControlPosition.LEFT_BOTTOM,
        },
        styles: mapStyle
    }
}

const AtmsMap = (props) => {
    const classes = useStyles(props);
    const { gMapRef, setGMapRef, setSupercluster, setClusters, zoom, setZoom, addressClick, defaultCenter } = props;
    const mapRef = useRef();
    const { points } = props;
    const [gMaps, setGMaps] = useState(null);

    const handleApiLoaded = (map, maps) => {
        setGMapRef(map);
        setGMaps(maps);
    };



    const [bounds, setBounds] = useState(null);

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        options: { radius: 75, maxZoom: 20 }
    });

    useEffect(() => {
        setSupercluster(supercluster)
        setClusters(clusters);
    }, [supercluster, setSupercluster, clusters, setClusters, zoom])


    return (
        <Grid container spacing={0} className={classes.container}>
            <GoogleMapReact
                ref={mapRef}
                options={createMapOptions}
                bootstrapURLKeys={{ key: "AIzaSyAJCmg6af3MCat0FCoYy5DQL4M15Nb9KCE" }}
                defaultCenter={defaultCenter}
                zoom={zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                onChange={({ zoom, bounds }) => {
                    setZoom(zoom);
                    setBounds([
                        bounds.nw.lng,
                        bounds.se.lat,
                        bounds.se.lng,
                        bounds.nw.lat
                    ]);
                }}
            >
                {gMaps ? clusters.map(({ id, geometry: { coordinates: [lng, lat] }, properties: { city, address, workingHours, phone, cluster: isCluster, point_count: clusteredAmount = '' } }) => (
                    <Marker
                        key={id}
                        lat={lat}
                        lng={lng}
                        text={`${clusteredAmount}`}
                        city={city}
                        workingHours={workingHours}
                        address={address}
                        phone={phone}
                        isCluster={isCluster}
                        clusterClick={() => {
                            const expansionZoom = Math.min(
                                supercluster.getClusterExpansionZoom(id) + 2,
                                20
                            );
                            setZoom(expansionZoom);
                            if (gMapRef) {
                                gMapRef.panTo({ lat, lng });
                            }
                        }}
                        addressClick={() => {
                            addressClick({ id, geometry: { coordinates: [lng, lat] }, properties: { city } }, 'addresss')
                        }}
                    />
                )) : null}

            </GoogleMapReact>
        </Grid>
    );
};

export default AtmsMap;
