import * as React from "react"

function CheckboxSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            viewBox="0 0 20 20"
            {...props}
        >
            <rect
                data-name="Rectangle 249"
                width={20}
                height={20}
                rx={4}
                fill="#26314e"
            />
        </svg>
    )
}

export default CheckboxSVGR
