import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	contentContainerGrid: {
		width: "100%",
		margin: "auto",
		marginBottom: 10,
	},
	title: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 28,
		lineHeight: '39px',
		textAlign: 'center',
		color: '#c1c9df',
		marginTop: 5,
	},
	paragraph: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 14,
		lineHeight: '23px',
		textAlign: 'center',
		color: '#5e6f9b',
		margin: 'auto',
		marginTop: 10,
		marginBottom: 39,
		maxWidth: '31.25rem',
	},
	topPart: {
		marginBottom: 10,
	},
	searchContainer: {
		marginTop: 70,
	},
	searchTitle: {
		margin: 'auto',
		width: '70%',
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 14,
		lineHeight: '23px',
		textAlign: 'center',
		color: '#5e6f9b',
		marginBottom: 20,
		maxWidth: '31.25rem',
	},
	questionsText: {
		[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
			display: 'none'
		},
		marginTop: 20,
		height: 60,
		borderradius: 4,
		background: 'transparent',
		border: '2px solid #1f283c',
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 18,
		textAlign: 'left',
		color: '#a6b5d9',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 65,
	},
	questionsLine: {
		[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
			display: 'none'
		},
		marginBottom: 50,
		display: 'flex',
		'& hr': {
			backgroundColor: '#1a1f2e',
			height: 3,
			borderRadius: 1.5,
			border: 'none',
		},
		'& hr:first-of-type': {
			flex: 1 / 6
		},
		'& p': {
			margin: '0 15px',
		},
		'& hr:last-of-type': {
			flex: 5 / 6
		},
	},
	questionsLineText: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 22,
		lineHeight: '22px',
		textAlign: 'left',
		color: '#a6b5d9',

	},
	FAQItemsContainer: {
		marginTop: 10,
	}
}));

export default useStyles;
