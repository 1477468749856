import React, { useState, useMemo } from "react";
import { Grid, Typography, } from "@material-ui/core";
import useStyles from "../styles/pages/FAQStyles";
import FAQSectionPicker from "../components/FAQSectionPicker";
import FAQItem from "../components/FAQItem";
import FAQConfig from "../configs/FAQConfig";
import FAQData from "../data/FAQ";
import FAQSearch from '../components/FAQSearch';
import useLanguage from "../hooks/useLanguage";

function FAQ(props) {
	const classes = useStyles(props);
	const [selectedQuestionId, setSelectedQuestionId] = useState();
	const [selectedSectionId, setSelectedSectionId] = useState(1);

	const { key: languageKey, pages: { faq: STRINGS } } = useLanguage();

	const getSectionItems = useMemo(() => {
		switch (selectedSectionId) {
			case 1:
				return FAQData.card;

			case 2:
				return FAQData.bank;
			case 3:
				return FAQData.discount;
			default:
				return []
		}
	}, [selectedSectionId])

	console.log(getSectionItems)

	return (
		<Grid item container xs={10} sm={11} md={10} lg={8} xl={7} className={classes.contentContainerGrid} justify="center">
			<Grid xs={12} sm={8} md={6} item className={classes.topPart}>
				<Typography variant="h1" className={classes.title}>
					{STRINGS.title}
				</Typography>
				<Typography variant="body1" className={classes.paragraph}>
					{STRINGS.description}
				</Typography>
				<FAQSectionPicker sections={FAQConfig.sections} selectedId={selectedSectionId} STRINGS={STRINGS.sections} onSelect={(id) => setSelectedSectionId(id)} />
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body1" className={classes.questionsText}>
					{STRINGS.questions}
				</Typography>
			</Grid>
			<Grid item xs={12} className={classes.questionsLine}>
				<hr />
				<Typography variant="body1" className={classes.questionsLineText}>
					{STRINGS.information}
				</Typography>
				<hr />
			</Grid>
			<Grid sm={12} item container className={classes.FAQItemsContainer}>
				{getSectionItems.map(({ id, question, answer }) => (
					<FAQItem
						isOpen={selectedQuestionId === id}
						key={id}
						onToggle={() => setSelectedQuestionId(id === selectedQuestionId ? null : id)}
						question={question[languageKey]}
						answer={answer[languageKey]}
					/>
				))}
			</Grid>
			<Grid sm={12} item container className={classes.searchContainer}>
				<Typography variant="body1" className={classes.searchTitle}>
					{STRINGS.searchInstructions}
				</Typography>
				<FAQSearch />
			</Grid>
		</Grid>
	);
}

export default FAQ;
