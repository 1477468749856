import React from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "../styles/components/AtmsMapMarkerStyles";
import AddressPinSVGR from "../smart-assets/SVGRs/AddressPinSVGR";
import ClusterPinSVGR from "../smart-assets/SVGRs/ClusterPinSVGR";

const AtmsMapMarker = (props) => {
    const classes = useStyles(props);
    const { text, lat, lng, city, address, workingHours, phone, isCluster, clusterClick, addressClick } = props;

    return (
        <Box
            lat={lat}
            lng={lng}
            className={classes.container}
            onClick={isCluster ? clusterClick : addressClick}
        >
            {isCluster ? <ClusterPinSVGR /> : <AddressPinSVGR />}
            <Typography className={classes.text}>
                {text}
            </Typography>
            {isCluster ?
                null :
                <Box className={classes.markerInfo}>
                    <Typography className={classes.city}>
                        {city}
                    </Typography>
                    <Typography className={classes.address}>
                        {address}
                    </Typography>
                    <Typography className={classes.phone}>
                        {phone}
                    </Typography>
                    <Typography className={classes.workingHours}>
                        {workingHours}
                    </Typography>
                </Box>
            }


        </Box>
    );
};

export default AtmsMapMarker;
