import React from "react";
import { AppBar, Toolbar, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Navigation from "./Navigation";
import FooterLogo from "../assets/footerLogo.png";
import ROUTES from "../data/footerRoutes";
import useStyles from "../styles/components/FooterStyles";
import Socials from "./Socials";
// import ChangeLanguage from "./ChangeLanguage";
import classNames from "classnames";
import useLanguage from "../hooks/useLanguage";

const Footer = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  const {
    globalComponents: { footer: STRINGS },
  } = useLanguage();

  return (
    <AppBar id="footer" position="static" className={classes.footerStyles}>
      <Grid container item className={classes.containerGrid}>
        <Toolbar
          classes={{
            gutters: classes.gutterStyles,
          }}
          className={classNames(classes.toolBarStyles, classes.desktopVersion)}
        >
          <Grid
            container
            diretion="column"
            justify="center"
            className={classes.mainGrid}
          >
            <Grid
              item
              container
              className={classes.topGrid}
              alignItems="center"
              justify="space-between"
            >
              <Grid item className={classes.navigationContainer}>
                <Navigation
                  routes={ROUTES}
                  inFooter={true}
                  STRINGS={STRINGS.navigation}
                />
              </Grid>
              <Grid item className={classes.socialsContainer}>
                <Socials />
              </Grid>
            </Grid>
            <Grid
              item
              container
              className={classes.bottomGrid}
              alignItems="center"
              justify="space-between"
            >
              <Grid onClick={() => history.push(`/`)} item>
                <img
                  src={FooterLogo}
                  className={classes.logo}
                  alt="LeaderCard"
                ></img>
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.copyright}>
                  {STRINGS.copyright}
                </Typography>
              </Grid>
              <Grid
                item
                container
                className={classes.appDownloads}
                justify="flex-end"
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.leadercard"
                >
                  <img
                    style={{ marginRight: 10 }}
                    width="110"
                    height="38"
                    alt="Get it on Google Play"
                    src={require("../assets/google-play-badge.png").default}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://apps.apple.com/us/app/leadercard-e-wallet/id1575881474"
                >
                  <img
                    width="110"
                    height="38"
                    alt="Get it on Play Store"
                    src={require("../assets/app-store-badge.png").default}
                  />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Grid>
      {/* MOBILE */}
      <Grid
        container
        item
        xs={12}
        sm={11}
        xl={10}
        className={classes.containerGrid}
      >
        <Toolbar
          classes={{
            gutters: classes.gutterStyles,
          }}
          className={classNames(classes.toolBarStyles, classes.mobileVersion)}
        >
          <Grid container justify="center" className={classes.mainGrid}>
            <Grid
              item
              container
              className={classes.topGrid}
              alignItems="center"
              justify="space-between"
            >
              <hr className={classes.hr} />
              <Grid onClick={() => history.push(`/`)} item>
                <img
                  src={FooterLogo}
                  className={classes.logo}
                  alt="LeaderCard"
                ></img>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="column"
              className={classes.bottomGrid}
              alignItems="center"
              justify="space-between"
            >
              <Grid item className={classes.socialsContainer}>
                <Socials />
              </Grid>
              <Grid item className={classes.navigationContainer}>
                <Navigation
                  routes={ROUTES}
                  inFooter={true}
                  STRINGS={STRINGS.navigation}
                />
              </Grid>
              <Grid
                item
                container
                className={classes.appDownloads}
                justify="flex-end"
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.leadercard"
                >
                  <img
                    style={{ marginRight: 10 }}
                    width="110"
                    height="38"
                    alt="Get it on Google Play"
                    src={require("../assets/google-play-badge.png").default}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://apps.apple.com/us/app/leadercard-e-wallet/id1575881474"
                >
                  <img
                    width="110"
                    height="38"
                    alt="Get it on Play Store"
                    src={require("../assets/app-store-badge.png").default}
                  />
                </a>
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.copyright}>
                  {STRINGS.copyright}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Grid>
    </AppBar>
  );
};

export default Footer;
