import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	container: {
		// background: "red",
		margin: "0 auto",
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			alignItems: "center",
			flexDirection: "column"
		},
	},
	gridItem: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			marginBottom: 30,
			width: 250,
			justifyContent: 'flex-start',
		},
		'&:last-of-type': {
			marginBottom: 0,
		}
	},
	SVGRContainer: {
		backgroundColor: "#f1c157",
		borderRadius: 70,
		border: "3px solid #f1c157",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"& svg": {
			width: 28,
			height: 28,
		},
		width: 84,
		height: 84,
	},
	info: {
		marginLeft: 10,
		maxWidth: 150,
	},
	title: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 16,
		lineHeight: "22px",
		textAlign: "left",
		color: "#a6b5d9",
	},
	content: {
		fontFamily: "var(--font-family-2)",
		fontWeight: "normal",
		fontSize: 14,
		lineHeight: "16px",
		textAlign: "left",
		color: "#859bd2",
	},
}));

export default useStyles;
