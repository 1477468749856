import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        // backgroundColor: '#fff',
        '& svg': {
            // animation: '$spin 1000ms linear infinite'
        }
    },
    // "@keyframes spin": {
    //     "0%": {
    //         transform: 'rotate(0deg)',
    //     },
    //     "100%": {
    //         transform: 'rotate(360deg)',
    //     }
    // },
}));

export default useStyles;
