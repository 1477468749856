import * as React from "react"

function PervPageArrowSVGR(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={17.825}
      viewBox="0 0 22 17.825"
      {...props}
    >
      <g data-name="Internet-Networks-Servers / Data-Transfer / data-transfer-horizontal">
        <g
          fill="none"
          stroke="#a6b5d9"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          data-name="Group 151"
        >
          <path data-name="Shape 1380" d="M21 8.913H1" />
          <path data-name="Shape 1382" d="M9.25 16.413L1 8.913l8.25-7.5" />
        </g>
      </g>
    </svg>
  )
}

export default PervPageArrowSVGR
