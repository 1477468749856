import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
        borderRadius: 6,
        background: "#1c2539",
        "& label": {
            fontFamily: "var(--font-family-1)",
            fontWeight: 500,
            // fontSize: 13,
            // lineHeight: "22px",
            textAlign: "left",
            color: "#405178",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1c2539",
            borderRadius: 6,
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
            color: "#405178",
            fontFamily: "var(--font-family-1)",
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#405178",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "red",
            borderRadius: 6,
            borderWidth: 0
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: 6,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: "#1c2539",
        },
    },
    endAdormentContainer: {
        position: "rleative",
        width: 18,
        height: 18,
        display: 'flex',
        justifyContent: 'center',
        '& svg': {
            cursor: "help",
        },
        '&:hover $endAdormentTooltip': {
            display: 'block',
        }
    },
    endAdormentTooltipText: {
        whiteSpace: 'break-spaces',
        fontFamily: "var(--font-family-2)",
        fontWeight: 500,
        fontSize: 10,
        lineHeight: '16px',
        textalign: 'left',
        color: '#546283',
    },
    endAdormentTooltip: {
        display: 'none',
        padding: 16,
        position: "absolute",
        top: 15,
        right: -210,
        zIndex: 12391232131,
        maxWidth: 160,
        // height: 79px;
        borderRadius: 2,
        background: '#1c2539',
        border: '1px solid #1e2a46',
    },
    toopTipArrow: {
        background: '#1c2539',
        position: 'absolute',
        top: 7.5,
        left: -5.8,
        transform: 'rotate(45deg)',
        boxSizing: 'border-box',
        width: 10,
        height: 10,
        border: '1px solid #1e2a46',
        borderTop: 'none',
        borderRight: 'none',
    },
    smsCodeButton: {
        minWidth: 70,
        marginRight: -13,
        height: 55,
        borderRadius: '0px 6px 6px 0px',
        background: '#15639f',
        "&:hover": {
            background: '#15639f',
        },
    },
    smsCodeButtonText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'left',
        color: '#c7d0da',
    },
    adormentText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'left',
        color: ' #738bb0',
        letterSpacing: 2,
    },
    errored: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#CB3352 !important',
        },
    }
}));

export default useStyles;
