import * as React from "react"

function EmailSVGR(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={20}
      viewBox="0 0 30 20"
      {...props}
    >
      <g fill="#161c2a" data-name="Emails / Envelopes / envelope">
        <path d="M13.893 13.195a1.565 1.565 0 002.21 0L28.21 1.087a.625.625 0 00-.351-1.061A2.455 2.455 0 0027.5 0h-25a2.49 2.49 0 00-.361.026.625.625 0 00-.351 1.061z" />
        <path d="M29.86 2.29a.227.227 0 00-.25.049l-7.75 7.75a.315.315 0 000 .442l6.3 6.3a.937.937 0 11-1.325 1.326l-6.3-6.3a.314.314 0 00-.443 0l-2.661 2.661a3.438 3.438 0 01-4.86 0l-2.664-2.659a.315.315 0 00-.443 0l-6.3 6.3a.938.938 0 01-1.325-1.326l6.3-6.3a.315.315 0 000-.443L.389 2.34a.224.224 0 00-.25-.049.228.228 0 00-.14.207v15a2.5 2.5 0 002.5 2.5h25a2.5 2.5 0 002.5-2.5v-15a.226.226 0 00-.139-.208z" />
      </g>
    </svg>
  )
}

export default EmailSVGR
