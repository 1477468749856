import * as React from "react"

function SendSVGR(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13}
      height={13}
      viewBox="0 0 13 13"
      {...props}
    >
      <path
        d="M12.921.082a.27.27 0 00-.3-.059L.163 5.407a.272.272 0 00-.02.49l3.613 1.92a.134.134 0 00.149-.011l5.574-4.514a.135.135 0 01.182.009.137.137 0 01.008.183l-4.5 5.6a.136.136 0 00-.014.149l1.913 3.628a.27.27 0 00.239.145h.01a.272.272 0 00.238-.163L12.977.382a.274.274 0 00-.056-.3z"
        fill="#c1c9df"
        data-name="Emails / Send-Email / send-email-3"
      />
    </svg>
  )
}

export default SendSVGR
