import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		margin: "auto",
	},
	searchBarContainer: {
		width: "100%",
		margin: "auto",
		position: "relative"
	},
	textField1: {
		width: "100%",
		borderRadius: 6,
		background: "#1c2539",
		"& label": {
			fontFamily: "var(--font-family-1)",
			fontWeight: 500,
			// fontSize: 13,
			// lineHeight: "22px",
			textAlign: "left",
			color: "#405178",
		},
		"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			borderColor: "#1c2539",
			borderRadius: 6,
		},
		"& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
			color: "#405178",
			fontFamily: "var(--font-family-1)",
			paddingRight: 90,
		},
		"& .MuiInputLabel-outlined.Mui-focused": {
			color: "#405178",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#1c2539",
			borderRadius: 6,
		},
		"& .MuiOutlinedInput-root": {
			borderRadius: 6,
		},
		// marginBottom: 20,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			width: '100%',
			marginLeft: 0,
		},
	},
	searchButton: {
		position: 'absolute',
		top: 9,
		bottom: 9,
		right: 9,
		width: 70,
		height: 38,
		borderRadius: 6,
		backgroundColor: '#f1c157',
		"&:hover": {
			backgroundColor: "#f1c157",
		},
	},
	nothingFound: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: 500,
	},
	nothingFoundText: {
		marginTop: 20,
		fontFamily: "var(--font-family-4)",
		fontWeight: 'normal',
		fontSize: 22,
		lineHeight: '34px',
		textAlign: 'center',
		color: '#6b7a9e',

	}
}));

export default useStyles;
