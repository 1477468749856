import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	container: {
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			flexDirection: 'column'
		}
	}
}));

export default useStyles;
