import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	container: {
		height: "100%",
		maxWidth: '87.5rem',
		// background: "red",
		margin: "auto",
		alignItems: "center",
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			overflow: "hidden",
			position: "relative",
			paddingBottom: 130,
			'& $title': {
				textAlign: 'center',
			},
			'& $paragraph': {
				// display: 'none',
				margin: '0 auto',
				maxWidth: '90%',
			},
			display: "flex",
			flexDirection: "column-reverse",
			'& > div': {
				height: 'auto',
				width: '100%',
				maxWidth: '100%',
				padding: 0,
				flexBasis: 'unset',
				margin: 0,
			},
			'& > div > div:nth-of-type(2)': {
				position: "absolute",
				bottom: 0,
				width: '100%',
				margin: 0,
				justifyContent: "space-evenly"
				// maxWidth: '100%',
			},
			'& > div > div .MuiButton-root': {
				height: 80,
				width: 155,
				borderRadius: 1000,
				margin: 0,
			},
			'& > div:first-of-type': {
				marginTop: 20,
				justifyContent: 'center'
			},
			'& > div:first-of-type img': {
				width: '130%',
				margin: 'auto',
			}
		}
	},
	infoContainer: {
		// background: "blue",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		// justifyContent: "space-between",
		padding: "10px",
		paddingLeft: 30,
		[theme.breakpoints.up("md")]: {
			paddingLeft: 90,
		},
		paddingRight: 0,
	},
	title: {
		width: '100%',
		maxWidth: '90vw',
		color: "#C1C9DF",
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 40,
		lineHeight: '56px',
		textAlign: "left",
		margin: '0 auto',
		marginBottom: 17,
		[theme.breakpoints.down("sm")]: {
			fontSize: 35,
		}
	},
	paragraph: {
		fontSize: 18,
		color: "#A6B5D9",
		fontWeight: 500,
		lineHeight: '40px',
		textAlign: "left",
		fontFamily: "var(--font-family-2)",
		// width: "80%",
		// maxWidth: 500,
		marginBottom: 30,
		marginTop: 0,
	},
	imageCol: {
		height: "100%",
		// background: "green",
	},
	requestButton: {
		background: "#f1c157",
		color: "#31343E",
		marginRight: 16,
		borderColor: "#f1c157",
		"&:hover": {
			backgroundColor: "#f1c157",
		},
	},
	moreInfoButton: {
		color: "#fff",
		borderWidth: 2,
		borderStyle: "solid",
		borderColor: "#fff",
		"&:hover": {
			backgroundColor: "transparent",
		},
	},
	button: {
		width: 170,
		height: 70,
		borderRadius: 35,
		boxSizing: "border-box",
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 18,
		textAlign: "left",
		'& span': {
			textAlign: "center",
		}
	},
	image: {
		display: "block",
		width: "100%",
		height: "100%",
		objectFit: "contain",
		objectPosition: "left",
		// backgroundColor: 'red'
	},
	svgAndText: {
		display: 'flex',
		alignItems: 'center',
		margin: '10px 0',
	},
	svgAndTextSVG: {
		minWidth: 40,
		minHeight: 40,
		height: 40,
		width: 40,
		marginRight: 15,
		// backgroundColor: 'red',
	},
	svgAndTextText: {
		fontSize: 18,
		color: "#A6B5D9",
		fontWeight: 500,
		lineHeight: '40px',
		textAlign: "left",
		fontFamily: "var(--font-family-2)",
	}
}));

export default useStyles;
