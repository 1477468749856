import { createMuiTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
const breakpoints = {
    breakpoints: createBreakpoints({
        values: {
            xs: 0,
            sm: 900,
            md: 1100,
            lg: 1280,
            xl: 1920,
        },
    }),
}

const theme = createMuiTheme({ ...breakpoints });

export default theme;
