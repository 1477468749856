import React from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../styles/pages/PolicyStyles";
import useLanguage from "../hooks/useLanguage";

function Policy(props) {
  const classes = useStyles(props);

  const {
    pages: { policy: STRINGS },
  } = useLanguage();

  return (
    <Grid
      item
      container
      xs={11}
      md={8}
      className={classes.contentContainerGrid}
    >
      <Grid item>
        <Typography variant="h1" className={classes.title}>
          {STRINGS.title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.policy}>
          {STRINGS.policy}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Policy;
