import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    step: {
        cursor: 'pointer',
        minWidth: 50,
        minHeight: 50,
        background: '#232c3e',
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&::before': {
            opacity: 0,
            // transition: 'all 1000ms',
            content: "' '",
            width: 14,
            height: 14,
            background: '#f1c157',
            borderRadius: '100%',
            position: 'absolute',
        }
    },
    stepText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '25px',
        textAlign: 'left',
        color: '#c1c9df',
        transition: 'all 0ms',
    },
    lineBetweenSteps: {
        width: '100%',
        height: 4,
        border: 'none',
        background: '#232c3e',
        '&$isPassed': {
            background: '#232c3e',
        },
        '&::before': {
            display: 'block',
            content: "' '",
            width: 0,
            height: '10px',
            background: '#f1c157'
        },
        '&$isPassed::before': {
            width: '100%',
            background: '#f1c157'
        }
    },
    lineBetweenStepsActive: {
    },
    isPassed: {
        transition: 'all 0ms',
        background: '#f1c157',
        '& > p': {
            transition: 'all 0ms',
            color: '#1f2637',
        }
    },
    isCurrent: {
        width: 50,
        height: 50,
        background: 'transparent',
        border: '3px solid #f1c157',
        boxSizing: 'border-box',
        '& > p': {
            visibility: 'hidden',
            position: 'absolute',

        },
        '&::before': {
            opacity: 1,
        }
    }
}));

export default useStyles;
