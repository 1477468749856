const routes = [
	{
		nameKey: "home",
		route: "/",
	},
	{
		nameKey: "account",
		route: "/account",
		loggedIn: true,
	},
	{
		nameKey: "news",
		route: "/news",
	},
	{
		nameKey: "atms",
		route: "/atms",
	},
	{
		nameKey: "faq",
		route: "/faq",
	},
	{
		nameKey: "contact",
		route: "/contact",
	},
];

export default routes;
