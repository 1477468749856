import * as React from "react"

function ValidationSuccessSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={18}
            viewBox="0 0 18 18"
            {...props}
        >
            <g
                fill="#197163"
                data-name="Interface-Essential / Form-Validation / check-circle-1-alternate"
            >
                <path d="M12.399 5.58a.669.669 0 00-.5-.125.694.694 0 00-.445.265l-3.221 4.345-2.048-1.626a.684.684 0 00-.944.114.672.672 0 00.095.94l2.6 2.069a.7.7 0 00.513.14.705.705 0 00.459-.27l3.643-4.907a.673.673 0 00-.146-.945z" />
                <path
                    d="M0 9a9 9 0 119 9 9 9 0 01-9-9zm1.5 0A7.5 7.5 0 109 1.5 7.5 7.5 0 001.5 9z"
                    fillRule="evenodd"
                />
            </g>
        </svg>
    )
}

export default ValidationSuccessSVGR
