import { makeStyles } from "@material-ui/core/styles";
import borderImage from "../../assets/image-upload-border-image.png";
import borderImageErrored from "../../assets/image-upload-border-image-errored.png";

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageContainer: {
        width: '100%',
        height: '100%',
        borderRadius: 6,
        background: 'transparent',
        border: '3px dotted #232c3e',
        borderImageSource: `url(${borderImage})`,
        borderImageWidth: 2,
        borderImageSlice: 6,
        borderImageRepeat: 'round',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        overflow: 'hidden',
    },
    errored: {
        borderImageSource: `url(${borderImageErrored})`,
    },
    title: {
        marginTop: 50,
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 24,
        lineHeight: '25px',
        textAlign: 'left',
        color: '#c1c9df',
    },
    description: {
        marginTop: 16,
        fontFamily: "var(--font-family-2)",
        fontWeight: 500,
        fontSize: 16,
        lineHeight: '25px',
        textAlign: 'center',
        color: '#546283',
        maxWidth: '60%',
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            maxWidth: '80%',
        }
    },
    captureImageButton: {
        marginTop: 28,
        width: "70%",
        height: 50,
        borderRadius: 6,
        background: "#15639f",
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "22px",
        textAlign: "left",
        color: '#c1c9df',
        "&:hover": {
            background: "#15639f",
        },
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            width: '100%',
        }
    },
    deleteImageButton: {
        marginTop: 28,
        height: 50,
        borderRadius: 6,
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "20px",
        textAlign: "left",
        background: "transparent",
        color: '#ad7171',
        "&:hover": {
            background: "transparent",
        },
        '& span': {
            marginBottom: -1,
        }
    },
    cameraSVGR: {
        marginRight: 12,
        width: 26,
        height: 20,
        background: 'transparent',
    },
    deleteBinSVGR: {
        marginRight: 12,
        width: 22,
        height: 24,
        background: 'transparent',
        marginBottom: 3,
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    }
}));

export default useStyles;
