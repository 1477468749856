import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        // width: 420,
        borderRadius: 6,
        background: 'transparent',
        [theme.breakpoints.up("sm")]: {
            border: '2px solid #1c2539',
            padding: '0 20px',
        },
    },
    informationLine: {
        marginTop: 26,
        display: 'flex',
        alignItems: 'center',
        '& hr': {
            backgroundColor: '#1c2539',
            height: 2,
            borderRadius: 1,
            border: 'none',
        },
        '& hr:first-of-type': {
            flex: 1 / 5
        },
        '& p': {
            margin: '0 15px',
        },
        '& hr:last-of-type': {
            flex: 4 / 5
        },
    },
    informationLineText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '28px',
        textAlign: 'left',
        color: '#a8b4d3',
    },
    label: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 16,
        textAlign: 'left',
        color: '#526089',
    },
    text: {
        fontFamily: "var(--font-family-2)",
        fontWeight: 500,
        fontSize: 20,
        textAlign: 'left',
        color: '#c1c9df',
    }
}));

export default useStyles;
