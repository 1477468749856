import * as React from "react"

function CameraSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={26}
            height={20}
            viewBox="0 0 26 20"
            {...props}
        >
            <g fill="#c1c9df" data-name="Images-Photography / Cameras / camera-1">
                <path d="M15.973 15.424a4.232 4.232 0 000-8.459 4.232 4.232 0 000 8.459z" />
                <path
                    d="M23.226 3.564h-1.425a.579.579 0 01-.521-.379c-.669-1.426-1.361-2.9-2.865-2.9H13c-1.16 0-1.772.851-2.879 2.4-.073.1-.138.2-.2.28-.274.4-.42.606-.718.606h-6.5c-2.234 0-2.7 1.322-2.7 2.432v11.366a2.48 2.48 0 00.768 1.973 2.428 2.428 0 002 .642h20.455a2.428 2.428 0 002-.642 2.48 2.48 0 00.768-1.973V5.996c0-1.109-.479-2.432-2.768-2.432zm-1.022 7.659a6.227 6.227 0 11-6.226-6.291 6.26 6.26 0 016.226 6.291zM4.067 9.034a1.367 1.367 0 10-1.354-1.367 1.36 1.36 0 001.354 1.367z"
                    fillRule="evenodd"
                />
                <path d="M2.721 2.519l3.259.018a.553.553 0 00.543-.564v-.281A1.662 1.662 0 004.894 0H3.809A1.662 1.662 0 002.18 1.692v.264a.553.553 0 00.541.563z" />
            </g>
        </svg>
    )
}

export default CameraSVGR
