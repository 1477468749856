import React from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../styles/pages/AboutStyles";
import useLanguage from "../hooks/useLanguage";

function About(props) {
	const classes = useStyles(props);

	const { pages: { about: STRINGS } } = useLanguage();

	return (
		<Grid item container xs={11} md={8} className={classes.contentContainerGrid}>
			<Grid item>
				<Typography variant="h1" className={classes.title}>
					{STRINGS.aboutUs}
				</Typography>
				<Typography variant="body1" className={classes.paragraph}>
					{STRINGS.aboutUsParagraph}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="h1" className={classes.title}>
					{STRINGS.howItFunctions}
				</Typography>
				<Typography variant="body1" className={classes.paragraph}>
					{STRINGS.howItFunctionsParagraph}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="h1" className={classes.title}>
					{STRINGS.whatConditions}
				</Typography>
				<ul className={classes.list}>
					{STRINGS.conditionsListArray.map(condition => (<li key={condition} className={classes.paragraph}>{condition}</li>))}
				</ul>
			</Grid>
			<Grid item>
				<Typography variant="body1" className={classes.paragraph}>
					{STRINGS.easyAccess}
					<br />
					<br />
					{STRINGS.companyIdentificationInfo}
					<br />
					{STRINGS.company}
					<br />
					{STRINGS.companyId}
				</Typography>
			</Grid>
		</Grid>
	);
}

export default About;
