import * as React from "react"

function CheckboxCheckedSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            viewBox="0 0 20 20"
            {...props}
        >
            <g data-name="Group 1182" transform="translate(-200 -1250)">
                <rect
                    data-name="Rectangle 248"
                    width={20}
                    height={20}
                    rx={4}
                    transform="translate(200 1250)"
                    fill="#26314e"
                />
                <path
                    data-name="Path 38"
                    d="M213.931 1256.218l-5.22 6.036-2.642-3.054a.553.553 0 00-.881 0 .781.781 0 000 1.018l3.082 3.564a.553.553 0 00.88 0l5.66-6.545a.781.781 0 000-1.018.553.553 0 00-.879-.001z"
                    fill="#c1c9df"
                />
            </g>
        </svg>
    )
}

export default CheckboxCheckedSVGR
