import React from "react";
import { Grid } from "@material-ui/core";
import useStyles from "../styles/pages/NewsStyles";
import FeaturedNews from '../components/FeaturedNews';
import LatestNews from "../components/LatestNews";
import newsData from "../data/news";

function News(props) {
	const classes = useStyles(props);
	return (
		<Grid item container xs={10} sm={11} md={10} className={classes.contentContainerGrid} justify="center">
			<Grid item xs={12} className={classes.featuredNewsContainer}>
				<FeaturedNews news={newsData} />
			</Grid>
			<Grid item xs={12}>
				<LatestNews news={newsData} />
			</Grid>
		</Grid>
	);
}

export default News;
