import * as React from "react"

function NextPageArrowSVGR(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={17.825}
      viewBox="0 0 32 17.825"
      {...props}
    >
      <g
        fill="none"
        stroke="#f1c157"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        data-name="Group 151"
      >
        <path data-name="Shape 1380" d="M1 8.913h30" />
        <path data-name="Shape 1382" d="M22.75 16.413l8.25-7.5-8.25-7.5" />
      </g>
    </svg>
  )
}

export default NextPageArrowSVGR
