import React, { useState, useRef, useMemo, useCallback } from "react";
import { Grid, Typography, Button, FormControlLabel, RadioGroup, TextField, InputAdornment, Select, FormControl, InputLabel } from "@material-ui/core";
import useStyles from "../styles/pages/RegisterStyles";
import StepProgress from "../components/StepProgress";
import RegisterSectionHeader from "../components/RegisterSectionHeader";
import CustomRadioButton from "../components/CustomRadioButton";
import CustomCheckbox from "../components/CustomCheckbox";
import ImageUpload from "../components/ImageUpload";
import classNames from "classnames"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from '@date-io/moment';
import "moment/locale/ka";
import "moment/locale/ru";
import "moment/locale/en-gb";
import TextFieldCalendarSVGR from "../smart-assets/SVGRs/TextFieldCalendarSVGR";
import RegisterTextField from "../components/RegisterTextField";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import useRegister from "../hooks/services/useRegister";
import useLanguage from "../hooks/useLanguage";
import StatusModal from "../components/Modals/StatusModal";
import SuccessStatusSVGR from "../smart-assets/SVGRs/SuccessStatusSVGR";
import { useHistory } from "react-router-dom";
import SMSModal from "../components/Modals/SMSModal";


const StepOne = (props) => {
    const { classes, register, errors, control, countries = [], documentTypes = [], STRINGS, locale = 'en-gb', setValue, watch } = props;

    return <>
        <Grid item xs={12}>
            <RegisterSectionHeader text={STRINGS.privateInformation.headerText} />
        </Grid>

        <Grid item className={classNames(classes.sexRadioButtons, errors.gender?.message ? classes.erroredOptions : null)}>
            <Controller
                control={control}
                name="gender"
                defaultValue=''
                as={
                    <RadioGroup aria-label="gender" name="gender1" row>
                        <FormControlLabel value="M" control={<CustomRadioButton />} label={STRINGS.privateInformation.genderRadios.male} className={classes.radioLabel} />
                        <FormControlLabel value="F" control={<CustomRadioButton />} label={STRINGS.privateInformation.genderRadios.female} className={classes.radioLabel} />
                    </RadioGroup>
                }
            />
            <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                {errors.gender?.message}
            </Typography>
        </Grid>

        <Grid item container className={classes.textFieldsContainer}>
            <RegisterTextField
                name="name"
                label={STRINGS.privateInformation.name}
                register={register}
                error={errors.name}
            />
            <RegisterTextField
                name="last_name"
                label={STRINGS.privateInformation.last_name}
                register={register}
                error={errors.last_name}
            />
            <RegisterTextField
                name="patronymic"
                label={STRINGS.privateInformation.patronymic}
                register={register}
                error={errors.patronymic}
            />
            <RegisterTextField
                name="personal_id"
                label={STRINGS.privateInformation.personal_id}
                register={register}
                error={errors.personal_id}
                inputProps={{
                    maxLength: 11,
                    minLength: 11,
                }}
                onChange={(e) => {
                    let restrictedVal = e.target.value.replace(/[^0-9]/g, '');
                    setValue('personal_id', restrictedVal);
                }}
            />
        </Grid>

        <Grid item xs={12} className={classes.sectionHeader}>
            <RegisterSectionHeader text={STRINGS.birthPlaceAndAddress.headerText} />
        </Grid>

        <Grid item container className={classes.textFieldsContainer}>
            <RegisterTextField
                name="birth_city"
                label={STRINGS.birthPlaceAndAddress.birth_city}
                register={register}
                error={errors.birth_city}
            />
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl variant="outlined" className={classNames(classes.formControl, errors.birth_country?.message ? classes.errored : null)}>
                    <InputLabel htmlFor="outlined-age-native-simple">{STRINGS.birthPlaceAndAddress.birth_country}</InputLabel>
                    <Select
                        name="birth_country"
                        native
                        inputRef={register}
                    >
                        <option aria-label="None" value="" className={classes.option} />
                        {countries.filter(e => Boolean(Number(e.BIRTH_COUNTRY))).map(({ CODE, NAME }) => (
                            <option key={CODE} value={CODE} className={classes.option}>{NAME}</option>
                        ))}
                    </Select>
                </FormControl>
                <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                    {errors.birth_country?.message}
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl variant="outlined" className={classNames(classes.formControl, errors.citizenship?.message ? classes.errored : null)}>
                    <InputLabel htmlFor="outlined-age-native-simple">{STRINGS.birthPlaceAndAddress.citizenship}</InputLabel>
                    <Select
                        name="citizenship"
                        native
                        inputRef={register}
                    >
                        <option aria-label="None" value="" className={classes.option} />
                        {countries.filter(e => Boolean(Number(e.CITIZENSHIP))).map(({ CODE, NAME }) => (
                            <option key={CODE} value={CODE} className={classes.option}>{NAME}</option>
                        ))}
                    </Select>
                </FormControl>
                <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                    {errors.citizenship?.message}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
                    <Controller
                        render={({ onChange, value = null }) => (
                            <DatePicker
                                cancelLabel=""
                                okLabel=""
                                autoOk={true}
                                className={classNames(classes.textField1, classes.calendar, errors.birth_date?.message ? classes.errored : null)}
                                inputVariant="outlined"
                                openTo="year"
                                views={["year", "month", "date"]}
                                label={STRINGS.birthPlaceAndAddress.birth_date}
                                format="DD-MM-YYYY"
                                value={value}
                                onChange={onChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldCalendarSVGR />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                        name="birth_date"
                        control={control}
                    />
                </MuiPickersUtilsProvider>
                <Typography className={classes.errorMessage}>
                    {errors.birth_date?.message}
                </Typography>
            </Grid>
        </Grid>

        <Grid item xs={12} className={classes.sectionHeader}>
            <RegisterSectionHeader text={STRINGS.cardInformation.headerText} />
        </Grid>

        <Grid item className={classNames(classes.sexRadioButtons, errors.document_type?.message ? classes.erroredOptions : null)}>
            <Controller
                control={control}
                name="document_type"
                defaultValue=''
                as={
                    <RadioGroup aria-label="document_type" row>
                        {documentTypes.map(({ ID, DOCNAME }) => (
                            <FormControlLabel key={ID} value={String(ID)} control={<CustomRadioButton />} label={DOCNAME} className={classes.radioLabel} />
                        ))}
                    </RadioGroup>
                }
            />
            <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                {errors.document_type?.message}
            </Typography>
        </Grid>

        <Grid item container className={classes.textFieldsContainer}>
            <RegisterTextField
                name="document_number"
                label={STRINGS.cardInformation.document_number}
                register={register}
                error={errors.document_number}
                inputProps={{
                    maxLength: 9,
                    minLength: 9,
                }}
                onChange={(e) => {
                    let restrictedVal = e.target.value.replace(/[^a-z0-9]/gi, '');
                    setValue('document_number', restrictedVal);
                }}
            />
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
                    <Controller
                        render={({ onChange, value = null }) => (
                            <DatePicker
                                cancelLabel=""
                                okLabel=""
                                autoOk={true}
                                className={classNames(classes.textField1, classes.calendar, errors.issue_date?.message ? classes.errored : null)}
                                inputVariant="outlined"
                                openTo="year"
                                views={["year", "month"]}
                                format="MM/YY"
                                label={STRINGS.cardInformation.issue_date}
                                value={value}
                                onChange={onChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldCalendarSVGR />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                        name="issue_date"
                        control={control}
                    />
                </MuiPickersUtilsProvider>
                <Typography className={classes.errorMessage}>
                    {errors.issue_date?.message}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
                    <Controller
                        render={({ onChange, value = null }) => (
                            <DatePicker
                                cancelLabel=""
                                okLabel=""
                                autoOk={true}
                                className={classNames(classes.textField1, classes.calendar, errors.valid_date?.message ? classes.errored : null)}
                                inputVariant="outlined"
                                openTo="year"
                                views={["year", "month"]}
                                label={STRINGS.cardInformation.valid_date}
                                format="MM/YY"
                                value={value}
                                onChange={onChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <TextFieldCalendarSVGR />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                        name="valid_date"
                        control={control}
                    />
                </MuiPickersUtilsProvider>
                <Typography className={classes.errorMessage}>
                    {errors.valid_date?.message}
                </Typography>
            </Grid>
            <RegisterTextField
                name="issuer_org"
                label={STRINGS.cardInformation.issuer_org}
                register={register}
                error={errors.issuer_org}
                defaultValue={STRINGS.cardInformation.issuer_org_DEFAULT_VALUE}
            />
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <FormControl variant="outlined" className={classNames(classes.formControl, errors.issuer_country?.message ? classes.errored : null)}>
                    <InputLabel htmlFor="outlined-age-native-simple">{STRINGS.cardInformation.issuer_country}</InputLabel>
                    <Select
                        name="issuer_country"
                        native
                        inputRef={register}
                    >
                        <option aria-label="None" value="" className={classes.option} />
                        {countries.filter(e => Boolean(Number(e.ISSUER_COUNTRY))).map(({ CODE, NAME }) => (
                            <option key={CODE} value={CODE} className={classes.option}>{NAME}</option>
                        ))}
                    </Select>
                </FormControl>
                <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                    {errors.issuer_country?.message}
                </Typography>
            </Grid>


        </Grid>

        <Grid item xs={12} className={classes.sectionHeader}>
            <RegisterSectionHeader text={STRINGS.legalAddress.headerText} />
        </Grid>

        <Grid item container className={classes.textFieldsContainer}>
            <RegisterTextField
                xs={12} sm={6} md={4} lg={4}
                name="legal_address"
                label={STRINGS.legalAddress.legal_address}
                register={register}
                error={errors.legal_address}
            />
            <RegisterTextField
                xs={12} sm={6} md={4} lg={4}
                name="legal_city"
                label={STRINGS.legalAddress.legal_city}
                register={register}
                error={errors.legal_city}
            />

            <Grid item xs={12} sm={6} md={4} lg={4}>
                <FormControl variant="outlined" className={classNames(classes.formControl, errors.legal_country?.message ? classes.errored : null)}>
                    <InputLabel htmlFor="outlined-age-native-simple">{STRINGS.legalAddress.legal_country}</InputLabel>
                    <Select
                        name="legal_country"
                        native
                        inputRef={register}
                    >
                        <option aria-label="None" value="" className={classes.option} />
                        {countries.filter(e => Boolean(Number(e.LEGAL_COUNTRY))).map(({ CODE, NAME }) => (
                            <option key={CODE} value={CODE} className={classes.option}>{NAME}</option>
                        ))}
                    </Select>
                </FormControl>
                <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                    {errors.legal_country?.message}
                </Typography>
            </Grid>

            <FormControlLabel name={`legal_equals_fact_address`} inputRef={register} value={watch('legal_equals_fact_address')} control={<CustomCheckbox defaultChecked={watch('legal_equals_fact_address')} />} label={STRINGS.legal_equals_fact_address} className={classes.checkBoxLabel} />
        </Grid>
        {!watch('legal_equals_fact_address') ?
            <>
                <Grid item xs={12} className={classes.sectionHeader}>
                    <RegisterSectionHeader text={STRINGS.factAddress.headerText} />
                </Grid>

                <Grid item container className={classes.textFieldsContainer}>
                    <RegisterTextField
                        xs={12} sm={6} md={4} lg={4}
                        name="fact_address"
                        label={STRINGS.factAddress.fact_address}
                        register={register}
                        error={errors.fact_address}
                    />
                    <RegisterTextField
                        xs={12} sm={6} md={4} lg={4}
                        name="fact_city"
                        label={STRINGS.factAddress.fact_city}
                        register={register}
                        error={errors.fact_city}
                    />
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                        <FormControl variant="outlined" className={classNames(classes.formControl, errors.fact_country?.message ? classes.errored : null)}>
                            <InputLabel htmlFor="outlined-age-native-simple">{STRINGS.factAddress.fact_country}</InputLabel>
                            <Select
                                name="fact_country"
                                native
                                inputRef={register}
                            >
                                <option aria-label="None" value="" className={classes.option} />
                                {countries.filter(e => Boolean(Number(e.FACT_COUNTRY))).map(({ CODE, NAME }) => (
                                    <option key={CODE} value={CODE} className={classes.option}>{NAME}</option>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                            {errors.fact_country?.message}
                        </Typography>
                    </Grid>
                </Grid>
            </>
            :
            null

        }


        <Grid item xs={12} className={classes.sectionHeader}>
            <RegisterSectionHeader text={STRINGS.contactInformation.headerText} />
        </Grid>

        <Grid item container className={classes.textFieldsContainer}>
            <RegisterTextField
                xs={12} sm={6} md={4} lg={4}
                label={STRINGS.contactInformation.country_phone_code}
                value={'+995'}
                disabled
            />
            <RegisterTextField
                xs={12} sm={6} md={4} lg={4}
                name="phone"
                label={STRINGS.contactInformation.phone}
                register={register}
                error={errors.phone}
                inputProps={{
                    maxLength: 9,
                    minLength: 9,
                }}
            />
            <RegisterTextField
                xs={12} sm={6} md={4} lg={4}
                name="email"
                label={STRINGS.contactInformation.email}
                register={register}
                error={errors.email}
            />
        </Grid>
    </>
}

const StepTwo = (props) => {
    const { classes, register, errors, control, watch, monthlyIncomeOptions = {}, incomeSourceOptions = {}, employmentOptions = {}, frequencyOptions = {}, monthlyAmountOptions, STRINGS } = props;

    return <>
        <Grid item xs={12} className={classes.sectionHeader}>
            <RegisterSectionHeader text={STRINGS.status.headerText} />
        </Grid>

        <Grid item className={classNames(classes.sexRadioButtons, errors.resident?.message ? classes.erroredOptions : null)}>
            <Controller
                control={control}
                name="resident"
                defaultValue='1'
                as={
                    <RadioGroup aria-label="resident" row>
                        <FormControlLabel value={'1'} control={<CustomRadioButton />} label={STRINGS.status.residentRadios.resident} className={classes.radioLabel} />
                    </RadioGroup>
                }
            />
            <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                {errors.resident?.message}
            </Typography>
        </Grid>

        <Grid item xs={12} className={classes.sectionHeader}>
            <RegisterSectionHeader text={STRINGS.politicalActivity.headerText} subText={STRINGS.politicalActivity.headerSubText} popupText={STRINGS.politicalActivity.popupText} />
        </Grid>

        <Grid item className={classNames(classes.sexRadioButtons, errors.political_activity_status?.message ? classes.erroredOptions : null)}>
            <Controller
                control={control}
                name="political_activity_status"
                defaultValue=''
                as={
                    <RadioGroup aria-label="political_activity_status" row>
                        <FormControlLabel value={'1'} control={<CustomRadioButton />} label={STRINGS.politicalActivity.politicalActivityStatusRadios.yes} className={classes.radioLabel} />
                        <FormControlLabel value={'0'} control={<CustomRadioButton />} label={STRINGS.politicalActivity.politicalActivityStatusRadios.no} className={classes.radioLabel} />
                    </RadioGroup>
                }
            />
            <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                {errors.political_activity_status?.message}
            </Typography>
        </Grid>

        <Grid item container className={classes.textFieldsContainer}>
            <Grid item xs={12}>
                <TextField
                    className={classNames(classes.textField1, errors.political_activity_info?.message ? classes.errored : null)}
                    name="political_activity_info"
                    multiline
                    rows={5}
                    label={STRINGS.politicalActivity.political_activity_info}
                    variant="outlined"
                    inputRef={register}
                />
            </Grid>
            <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                {errors.political_activity_info?.message}
            </Typography>
        </Grid>

        <Grid item xs={12} className={classes.sectionHeader}>
            <RegisterSectionHeader text={STRINGS.safetyAndPurpose.headerText} />
        </Grid>

        <Grid item container className={classes.textFieldsContainer}>
            <RegisterTextField
                xs={12} sm={6} md={6} lg={6}
                name="usage_period"
                label={STRINGS.safetyAndPurpose.usage_period}
                register={register}
                error={errors.usage_period}
                defaultValue={STRINGS.safetyAndPurpose.usage_period_DEFAULT_VALUE}
            />
            <RegisterTextField
                xs={12} sm={6} md={6} lg={6}
                name="currency"
                value={'GEL'}
                disabled
                label={STRINGS.safetyAndPurpose.currency}
                register={register}
                error={errors.currency}
            />
            <Grid item xs={12}>
                <TextField
                    name="additional_info"
                    className={classNames(classes.textField1, errors.additional_info?.message ? classes.errored : null)}
                    rows={5}
                    multiline
                    label={STRINGS.safetyAndPurpose.additional_info}
                    variant="outlined"
                    inputRef={register}
                />
                <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                    {errors.additional_info?.message}
                </Typography>
            </Grid>
        </Grid>

        <Grid item xs={12} className={classes.sectionHeader}>
            <RegisterSectionHeader text={STRINGS.safetyAndPurpose.frequency} />
        </Grid>
        <Grid item className={classNames(classes.sexRadioButtons, errors.frequency?.message ? classes.erroredOptions : null)}>
            <Controller
                control={control}
                name="frequency"
                defaultValue=''
                as={
                    <RadioGroup aria-label="frequency" row>
                        {Object.keys(frequencyOptions).map(key => (
                            <FormControlLabel key={key} value={key} control={<CustomRadioButton />} label={frequencyOptions[key]} className={classes.radioLabel} />
                        ))
                        }
                    </RadioGroup>
                }
            />
            <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                {errors.frequency?.message}
            </Typography>
        </Grid>
        <Grid item xs={12} className={classes.sectionHeader}>
            <RegisterSectionHeader text={STRINGS.safetyAndPurpose.monthly_amount} />
        </Grid>
        <Grid item className={classNames(classes.sexRadioButtons, errors.monthly_amount?.message ? classes.erroredOptions : null)}>
            <Controller
                control={control}
                name="monthly_amount"
                defaultValue=''
                as={
                    <RadioGroup aria-label="monthly_amount" row>
                        {Object.keys(monthlyAmountOptions).map(key => (
                            <FormControlLabel key={key} value={key} control={<CustomRadioButton />} label={monthlyAmountOptions[key]} className={classes.radioLabel} />
                        ))
                        }
                    </RadioGroup>
                }
            />
            <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                {errors.monthly_amount?.message}
            </Typography>
        </Grid>

        <Grid item xs={12} className={classes.sectionHeader}>
            <RegisterSectionHeader text={STRINGS.estimatedMonthlyIncome.headerText} />
        </Grid>

        <Grid item className={classNames(classes.sexRadioButtons, errors.monthly_income?.message ? classes.erroredOptions : null)}>
            <Controller
                control={control}
                name="monthly_income"
                defaultValue=''
                as={
                    <RadioGroup aria-label="monthly_income" row>
                        {Object.keys(monthlyIncomeOptions).map(key => (
                            <FormControlLabel key={key} value={key} control={<CustomRadioButton />} label={monthlyIncomeOptions[key]} className={classes.radioLabel} />
                        ))
                        }
                    </RadioGroup>
                }
            />
            <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                {errors.monthly_income?.message}
            </Typography>
        </Grid>

        <Grid item xs={12} className={classes.sectionHeader}>
            <RegisterSectionHeader text={STRINGS.incomeSource.headerText} />
        </Grid>

        <Grid xs={12} item className={classNames(classes.sexRadioButtons, errors.income_source?.message ? classes.erroredOptions : null)}>
            <div style={{ display: 'flex', width: 'fit-content', flexWrap: 'wrap' }}>
                {Object.keys(incomeSourceOptions).map(key => (
                    <FormControlLabel key={key} name={`income_source[${key}]`} inputRef={register} value={key} control={<CustomCheckbox defaultChecked={watch('income_source') ? watch('income_source').includes(key) : false} />} label={incomeSourceOptions[key]} className={classes.checkBoxLabel} />
                ))}
            </div>

            <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                {errors.income_source?.message}
            </Typography>
        </Grid>

        <Grid item container className={classes.textFieldsContainer}>
            <Grid item xs={12}>
                <TextField
                    name="income_info"
                    className={classNames(classes.textField1, errors.income_info?.message ? classes.errored : null)}
                    rows={5}
                    multiline
                    label={STRINGS.incomeSource.income_info}
                    variant="outlined"
                    inputRef={register}
                />
            </Grid>
            <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                {errors.income_info?.message}
            </Typography>
        </Grid>

        <Grid item className={classNames(classes.sexRadioButtons, errors.employment_status?.message ? classes.erroredOptions : null)}>
            <Controller
                control={control}
                name="employment_status"
                defaultValue=''
                as={
                    <RadioGroup aria-label="employment_status" row>
                        {Object.keys(employmentOptions).map(key => (
                            <FormControlLabel key={key} value={key} control={<CustomRadioButton />} label={employmentOptions[key]} className={classes.radioLabel} />
                        ))
                        }
                    </RadioGroup>
                }
            />
            <Typography className={classes.errorMessage} style={{ marginTop: 0, marginBottom: 0 }}>
                {errors.employment_status?.message}
            </Typography>
        </Grid>

        <Grid item container className={classes.textFieldsContainer}>

            {!['2', '3', '4'].includes(watch('employment_status')) ?
                <>
                    <RegisterTextField
                        xs={12} sm={6} md={4} lg={4}
                        name="employment_full_name"
                        label={STRINGS.incomeSource.employment_full_name}
                        register={register}
                        error={errors.employment_full_name}
                    />
                    <RegisterTextField
                        xs={12} sm={6} md={4} lg={4}
                        name="employment_form"
                        label={STRINGS.incomeSource.employment_form}
                        register={register}
                        error={errors.employment_form}
                    />
                    <RegisterTextField
                        xs={12} sm={6} md={4} lg={4}
                        name="employee_position"
                        label={STRINGS.incomeSource.employee_position}
                        register={register}
                        error={errors.employee_position}
                    />
                </>
                : null
            }
            <Grid item xs={12}>
                <TextField
                    name="bank_names"
                    className={classes.textField1}
                    rows={5}
                    multiline
                    label={STRINGS.incomeSource.bank_names}
                    variant="outlined"
                    inputRef={register}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    name="bank_services"
                    className={classes.textField1}
                    rows={5}
                    multiline
                    label={STRINGS.incomeSource.bank_services}
                    variant="outlined"
                    inputRef={register}
                />
            </Grid>
        </Grid>
    </>
}

const StepThree = (props) => {
    const { classes, STRINGS, watch, setValue, errors } = props;
    return <Grid item container xs={12} className={classes.stepThree}>
        <Grid item xs={12} className={classes.sectionHeader}>
            <RegisterSectionHeader text={STRINGS.documentCopy.headerText} />
        </Grid>
        <Grid item xs={12} sm={6} className={classNames(classes.imageUploadContainer, classes.imageUploadContainerLeft)}>
            <ImageUpload error={errors.photo1?.message} name='photo1' title={STRINGS.documentCopy.frontSide.title} description={STRINGS.documentCopy.frontSide.description} value={watch('photo1')} setValue={(val) => setValue('photo1', val)} />
        </Grid>
        <Grid item xs={12} sm={6} className={classNames(classes.imageUploadContainer, classes.imageUploadContainerRight)}>
            <ImageUpload error={errors.photo2?.message} name='photo2' title={STRINGS.documentCopy.backSide.title} description={STRINGS.documentCopy.backSide.description} value={watch('photo2')} setValue={(val) => setValue('photo2', val)} />
        </Grid>
    </Grid>
}


function Register(props) {
    const classes = useStyles(props);
    const { key: languageKey, pages: { register: STRINGS, } } = useLanguage();
    const steps = 3;
    const [currentStep, setCurrentStep] = useState(1);
    const pageTitleRef = useRef();
    const { paramList, registerUser, requestSMS, checkDocumentNumber, checkAuthState } = useRegister({ autoFetchParamList: true });
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [SMSModalOpen, setSMSModalOpen] = useState(false);
    const [maxStepReached, setMaxStepReached] = useState(1);
    const history = useHistory();

    const getMomentLocale = useMemo(() => {
        switch (languageKey) {
            case 'Geo':
                return 'ka'

            case 'Eng':
                return 'en-gb'

            case 'Rus':
                return 'ru'

            default:
                return 'ka'
        }
    }, [languageKey])

    const stepOneValidation = {
        gender: Yup.string().required(STRINGS.errors.required),
        name: Yup.string().required(STRINGS.errors.required).matches(/^[ა-ჰ -]+$/, STRINGS.errors.onlyGeorgianCharacters),
        last_name: Yup.string().required(STRINGS.errors.required).matches(/^[ა-ჰ -]+$/, STRINGS.errors.onlyGeorgianCharacters),
        patronymic: Yup.string().required(STRINGS.errors.required).matches(/^[ა-ჰ -]+$/, STRINGS.errors.onlyGeorgianCharacters),
        personal_id: Yup.string().required(STRINGS.errors.required).matches(/^[0-9]+$/, STRINGS.errors.onlyDigits).length(11, STRINGS.errors.personal_id),
        // დაბადების ადგილი და თარიღი
        birth_city: Yup.string().required(STRINGS.errors.required).matches(/^[ა-ჰ -]+$/, STRINGS.errors.onlyGeorgianCharacters),
        birth_country: Yup.string().required(STRINGS.errors.required), // ISO CODE
        citizenship: Yup.string().required(STRINGS.errors.required),
        birth_date: Yup.date().required(STRINGS.errors.required),
        // Age limit
        // ბარათის ინფორმაცია
        document_type: Yup.string().required(STRINGS.errors.required),
        document_number: Yup.string().required(STRINGS.errors.required).length(9, STRINGS.errors.document_number),
        issue_date: Yup.date().required(STRINGS.errors.required),
        valid_date: Yup.date().required(STRINGS.errors.required),
        issuer_org: Yup.string().required(STRINGS.errors.required),
        issuer_country: Yup.string().required(STRINGS.errors.required), // ISO CODE
        // იურიდიული მისამართი
        legal_address: Yup.string().required(STRINGS.errors.required),
        legal_city: Yup.string().required(STRINGS.errors.required).matches(/^[ა-ჰ -]+$/, STRINGS.errors.onlyGeorgianCharacters),
        legal_country: Yup.string().required(STRINGS.errors.required), // ISO CODE
        // ფაქტობრივი მისამართი
        legal_equals_fact_address: Yup.mixed(),
        fact_address: Yup.string().when('legal_equals_fact_address', {
            is: false, // alternatively: (val) => val == false
            then: Yup.string().required(STRINGS.errors.required),
        }),
        fact_city: Yup.string().when('legal_equals_fact_address', {
            is: false, // alternatively: (val) => val == false
            then: Yup.string().required(STRINGS.errors.required).matches(/^[ა-ჰ -]+$/, STRINGS.errors.onlyGeorgianCharacters),
        }),
        fact_country: Yup.string().when('legal_equals_fact_address', {
            is: false, // alternatively: (val) => val == true
            then: Yup.string().required(STRINGS.errors.required),
        }), // ISO CODE
        // საკონტაქტო ინფორმაცია
        phone: Yup.string().required(STRINGS.errors.required).matches(/^[0-9]+$/, STRINGS.errors.onlyDigits).length(9, STRINGS.errors.phone),
        // eslint-disable-next-line no-useless-escape
        email: Yup.string().required(STRINGS.errors.required).matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, STRINGS.errors.email),
    }

    const stepTwoValidation = {
        resident: Yup.string().required(STRINGS.errors.required),
        political_activity_status: Yup.string().required(STRINGS.errors.required),
        political_activity_info: Yup.string(),
        usage_period: Yup.string().required(STRINGS.errors.required),
        currency: Yup.string(),
        frequency: Yup.string().required(STRINGS.errors.required),
        monthly_amount: Yup.string().required(STRINGS.errors.required),
        // additional_info missing from api
        monthly_income: Yup.string().required(STRINGS.errors.required),
        income_source: Yup.array().ensure().compact().min(1, STRINGS.errors.atLeastOne),
        income_info: Yup.string(),
        employment_status: Yup.string().required(STRINGS.errors.required),
        employment_full_name: Yup.string().when('employment_status', {
            is: '1',
            then: Yup.string().required(STRINGS.errors.required),
        }),
        employment_form: Yup.string().when('employment_status', {
            is: '1',
            then: Yup.string().required(STRINGS.errors.required),
        }),
        employee_position: Yup.string().when('employment_status', {
            is: '1',
            then: Yup.string().required(STRINGS.errors.required),
        }),
        bank_names: Yup.string(),
        bank_services: Yup.string(),
    }

    const stepThreeValidation = {
        photo1: Yup.mixed().required("სავალდებულო").test('length', "სავალდებულო", (value) => {
            return value?.length !== 0
        }),
        photo2: Yup.mixed().required("სავალდებულო").test('length', "სავალდებულო", (value) => {
            return value?.length !== 0
        })
    }

    const { register, handleSubmit, watch, errors, control, trigger, clearErrors, setValue, setError } = useForm({
        shouldUnregister: false,
        mode: 'onTouched',
        resolver: yupResolver(Yup.object({
            ...stepOneValidation,
            ...stepTwoValidation,
            ...stepThreeValidation
        }))
    });

    const stepsObj = {
        1: stepOneValidation,
        2: stepTwoValidation,
        3: stepThreeValidation,
    }

    const canReachStep = async (stepNumber) => {
        if (stepNumber === 1) {
            return true;
        }
        let a = await trigger(Object.keys(stepsObj[stepNumber - 1]));
        console.log(stepNumber, a);
        if (!a) {
            setMaxStepReached(stepNumber - 1);
        }
        let b = await canReachStep(stepNumber - 1);
        return a && b;
    }


    const perviousStep = () => {
        if (currentStep !== 1) {
            clearErrors();
            setCurrentStep(currentStep - 1);
            pageTitleRef.current.scrollIntoView()
        }
    }

    const nextStep = async () => {
        const validInfo = await trigger(currentStep === 1 ? Object.keys(stepOneValidation) : currentStep === 2 ? Object.keys(stepTwoValidation) : Object.keys(stepThreeValidation));
        console.log(validInfo, errors, currentStep, steps, currentStep === 1 ? Object.keys(stepOneValidation) : currentStep === 2 ? Object.keys(stepTwoValidation) : Object.keys(stepThreeValidation));
        if (validInfo && currentStep !== steps) {
            if (currentStep === 1) {
                const idRes = await checkAuthState(watch('personal_id'));
                console.log('idRes', idRes);
                if (idRes.data.status !== 3) {
                    setError("personal_id", {
                        type: "manual",
                        message: STRINGS.errors.personal_id_registered,
                    });
                    return;
                }

                const docRes = await checkDocumentNumber(watch('document_number'));
                if (docRes.data.data) {
                    setError("document_number", {
                        type: "manual",
                        message: STRINGS.errors.document_number_registered,
                    });
                    return;
                }
            }
            clearErrors();
            setCurrentStep(currentStep + 1);
            if (currentStep + 1 > maxStepReached) {
                setMaxStepReached(currentStep + 1)
            }
            pageTitleRef.current.scrollIntoView()
        }
        else if (validInfo) {
            await _sendSMS(watch('personal_id'), watch('phone'));
            setSMSModalOpen(true);
        }
    }

    const urlToFile = (url, filename) => {
        try {
            let mimeType = url.split(';')[0].split(':')[1]
            return (fetch(url)
                .then(function (res) { return res.arrayBuffer(); })
                .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
            );
        } catch (error) {
            return url;
        }
    }

    const [smsCode, setSMSCode] = useState('');
    const [smsCodeStatus, setSMSCodeStatus] = useState('');
    const [smsCodeTimeoutKey, setSMSCodeTimeoutKey] = useState(null);
    const [smsCodeError, setSMSCodeErorr] = useState('');
    const [sentSMSAt, setSentSMSAt] = useState(null);
    const [smsTimeout, setSMSTimeout] = useState(0);

    const _sendSMS = useCallback(async (physicalID, phone) => {
        if (!sentSMSAt || (new Date().getTime() - sentSMSAt.getTime()) / 1000 > smsTimeout) {
            const data = await requestSMS(physicalID, phone);
            console.log('data', data);
            if (data.status === 200) {
                setSentSMSAt(new Date());
            }
            setSMSTimeout(data.data.data);
            if (smsCodeTimeoutKey) {
                clearTimeout(smsCodeTimeoutKey)
            }
            const key = setTimeout(() => {
                setSentSMSAt(false)
            }, data.data.data * 1000);
            setSMSCodeTimeoutKey(key);
        }
    }, [requestSMS, sentSMSAt, smsCodeTimeoutKey, smsTimeout])

    return (
        <Grid item container xs={10} sm={11} md={10} lg={10} xl={10} className={classes.contentContainerGrid}>

            <StatusModal text={STRINGS.statusModal.text}
                buttonText={STRINGS.statusModal.buttonText}
                onButtonPress={() => {
                    setShowStatusModal(false);
                    history.push(`/`);
                }}
                SVGR={SuccessStatusSVGR}
                isOpen={showStatusModal}
                onClose={() => {
                    setShowStatusModal(false);
                    history.push(`/`);
                }} />

            <SMSModal isOpen={SMSModalOpen}
                STRINGS={STRINGS.SMSModal}
                smsCodeStatus={smsCodeStatus}
                setSMSCodeStatus={setSMSCodeStatus}
                smsCode={smsCode}
                setSMSCode={setSMSCode}
                smsSentAt={sentSMSAt}
                setSentSMSAt={setSentSMSAt}
                smsTimeout={smsTimeout}
                setSMSCodeErorr={setSMSCodeErorr}
                smsCodeError={smsCodeError}
                _sendSMS={() => _sendSMS(watch('personal_id'), watch('phone'))}
                onClose={(val) => {
                    console.log('val', val)
                    setSMSModalOpen(false)
                }}
                onButtonPress={() => {
                    handleSubmit(async (data) => {
                        console.log('dataa', data)
                        const formattedData = { ...data };
                        console.log('formData', formattedData.photo1, formattedData.photo2)
                        if (formattedData.legal_equals_fact_address) {
                            formattedData.fact_address = formattedData.legal_address;
                            formattedData.fact_city = formattedData.legal_city;
                            formattedData.fact_country = formattedData.legal_country;
                        }
                        formattedData.birth_date = moment(formattedData.birth_date.toISOString()).format('DD/MM/YYYY');
                        formattedData.issue_date = moment(formattedData.issue_date.toISOString()).format('DD/MM/YYYY');
                        formattedData.valid_date = moment(formattedData.valid_date.toISOString()).format('DD/MM/YYYY');
                        formattedData.sms_code = smsCode;
                        console.log('formattedData', formattedData)
                        var formData = new FormData();
                        for (var key in formattedData) {
                            formData.append(key, formattedData[key]);
                        }
                        if (typeof formattedData.photo1 === "string") {
                            try {
                                let file = await urlToFile(formattedData.photo1);
                                formData.set('photo1', file);
                            } catch (error) {
                                formData.set('photo1', formattedData.photo1);
                                console.log('error');
                            }
                        }
                        if (typeof formattedData.photo2 === "string") {
                            try {
                                let file = await urlToFile(formattedData.photo2);
                                formData.set('photo2', file);
                            } catch (error) {
                                formData.set('photo2', formattedData.photo2);
                                console.log('error');
                            }
                        }
                        const response = await registerUser(formData);
                        if (response.status === 200) {
                            setSMSModalOpen(false)
                            setShowStatusModal(true);
                        }
                        else if (response.data.error === "SMS_VERIFY_ERROR") {
                            setSMSCodeErorr(STRINGS.SMSModal.wrongSMSCode)
                        }
                    })();
                }}
            />

            <Grid ref={pageTitleRef} item xs={12} className={classes.registerTitleContainer}>
                <Typography variant="h1" className={classes.registerTitle}>
                    {STRINGS.title}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={8} className={classes.stepProgressContainer}>
                <StepProgress maxReached={maxStepReached} setMaxReached={setMaxStepReached} steps={steps} currentStep={currentStep} setCurrentStep={setCurrentStep} canReachStep={canReachStep} />
            </Grid>

            <Grid container item xs={12} className={classes.topParamTextContainer}>
                <Typography className={classes.topParamText}>
                    {STRINGS.requirement}
                </Typography>
            </Grid>

            {currentStep === 1 &&
                <StepOne
                    classes={classes}
                    register={register}
                    errors={errors}
                    control={control}
                    countries={paramList?.countries}
                    documentTypes={paramList?.document_types}
                    STRINGS={STRINGS.stepOne}
                    locale={getMomentLocale}
                    setValue={setValue}
                    watch={watch}
                />
            }
            {currentStep === 2 &&
                <StepTwo
                    classes={classes}
                    register={register}
                    errors={errors}
                    control={control}
                    watch={watch}
                    monthlyIncomeOptions={paramList?.monthly_income}
                    incomeSourceOptions={paramList?.income_source}
                    employmentOptions={paramList?.employment_statuses}
                    frequencyOptions={paramList?.frequencies}
                    monthlyAmountOptions={paramList?.expected_amounts}
                    STRINGS={STRINGS.stepTwo}
                    setValue={setValue}
                />
            }
            {currentStep === 3 &&
                <StepThree
                    classes={classes}
                    register={register}
                    STRINGS={STRINGS.stepThree}
                    watch={watch}
                    setValue={setValue}
                    errors={errors}
                />
            }

            <Grid item container xs={12} className={classes.buttons} justify="flex-end" alignItems="center">
                <Grid item xs={12} sm={'auto'}>
                    {(steps !== currentStep
                        &&
                        (
                            <Button onClick={nextStep} className={classes.nextButton}>
                                <Typography variant="body1" className={classes.nextButtonText}>
                                    {STRINGS.nextButtonText}
                                </Typography>
                            </Button>
                        ))
                        ||
                        <Button onClick={nextStep} className={classes.finishButton}>
                            <Typography variant="body1" className={classes.finishButtonText}>
                                {STRINGS.submitButtonText}
                            </Typography>
                        </Button>
                    }

                </Grid>
                <Grid item xs={12} sm={'auto'}>
                    <Button onClick={perviousStep} className={classes.backButton}>
                        <Typography variant="body1" className={classes.backButtonText}>
                            {STRINGS.backButtonText}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>



    );
}

export default Register;
