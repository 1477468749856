import React from "react";
import { Grid } from "@material-ui/core";
import useStyles from "../styles/components/FAQSectionPickerStyles";
import FAQSectionPickerItem from "./FAQSectionPickerItem";

const FAQSectionPicker = (props) => {
    const classes = useStyles(props);
    const { sections, selectedId, onSelect, STRINGS } = props;

    return (
        <Grid container className={classes.container} justify="space-evenly">
            {sections.map(({ id, titleKey, SVGR }) => (
                <FAQSectionPickerItem isSelected={id === selectedId} key={id} title={STRINGS[titleKey]} SVGR={SVGR} onClick={() => onSelect(id)} />
            ))}
        </Grid>
    );
};

export default FAQSectionPicker;
