/* eslint-disable import/no-anonymous-default-export */
import FlagEnglishSVGR from "../../smart-assets/SVGRs/FlagEnglishSVGR";

export default {
    key: 'Eng',
    routeKey: "en",
    FlagSVGR: FlagEnglishSVGR,
    displayText: 'English',
    pages: { // გვერდები
        homePage: { // მთავარი გვერდი
            welcomeBanner: { // მთავარი გვერდის ბანერი
                title: "Leadercard Advantages",
                benefitsList: {
                    digitalPayment: 'Contactless Payment',
                    cashback: '10% Cashback',
                    giftedPoints: '1000 Points as a gift at the Betmarket'
                },
                buttons: {
                    request: "Request",
                    more: "Learn more"
                },
            },
            features: { // მთავარი გვერდის 4 უპირატესობა
                verified: "High level of confidentiality",
                discount: "Discounts available",
                coin: "Easy payment method",
                dollar: "Easy way to withdraw money",
            }
        },
        about: { // ჩვენ შესახებ
            aboutUs: "ABOUT US",
            aboutUsParagraph: "www.leadercard.ge is the property of NBC Ltd. which is an electronic wallet licensed by the National Bank of Georgia, operating in the Georgian market since 2017. LeaderCard offers its customers a unique contactless bank-type chip card. Cardholders have the ability to manage funds on leader-bet.com using LeaderCard.",
            howItFunctions: "How does LeaderCard work?",
            howItFunctionsParagraph: "Customers can easily transfer the winning amount to  LeaderCard account, withdraw cash or pay through ATMs and terminals of the Bank of Georgia and TBC Bank throughout Georgia.",
            whatConditions: "What conditions do we offer?",
            conditionsListArray: [
                "Cash out for free, no commission",
                "Daily withdrawal limit 3000 GEL",
                "Pay with LeaderCard at any store and get instant 10% cashback.",
            ],
            easyAccess: "You can get a LeaderCard free of charge at Leaderbet cash register offices and LeaderCard advertising kiosks or simply through online order.",
            companyIdentificationInfo: "Company identification data:",
            company: "NBC Ltd.",
            companyId: "I.C 405150063",
        },
        policy:  {
            title: "POLICY",
            policy: `Website leadercard.ge is managed by NBC LTD - (405150063), hereinafter – Company, registered and licensed under legislation of Georgia, as a provider of Payment Service. 
            The Company protects and respects the consumers’ rights, ensures safety of a consumer when using its services.   
            In the course of storing and processing of personal data, the Company acts under the Law of Georgia on “Personal Data Protection”.  
            
            Personal data processing, on the part of the company, is performed only when there are grounds provided by the law and the Company fulfills all requirements provided by the law in the course of data processing: 
            
            Data processing is performed fairly and lawfully without infringement of data subject’s dignity. 
            Data processing shall be performed only for certain, distinctly determined, legitimate purposes. The company does not perform personal data processing for any other purposes that are incompatible with the initial purpose.  
            c) Data may be processes only to the extent necessary for achieving relevant legitimate purposes. Data processing must be adequate and proportionate to the purpose for which they are processed. 
            d) Verification of the veracity and accuracy of data is performed by respective employees, furthermore, the aforementioned information may be updated where necessary. 
            
            The Company performs data processing when registration within the consumer’s system and then provision of service to him/her and/or data processing is necessary in order to fulfill the obligation under the regulator’s requirement.   
            
            The Company’s website uses automated data collection system, such as Cookies. Cookie is a packet of data stored on the hardware of the internet user. It does not provide comprehensible information, but it enables the internet user’s access to his personal data available by the consumers on leadercard.ge. Cookies are stored on our servers and the information it contains is not available for anyone. Only the Company performs the information processing collected by Cookies completely and anonymously in order to optimize its service and website taking into consideration the requirements and priority of the consumers. It is mandatory to obtain consent with regard to automated data collection procedure and use of Cookies for using the website and its service. If you want to disable Cookies, you can turn off Cookies Accept option in your browser settings.    
            
            The consumer is entitled to require correction, update, addition, blocking, removal or destruction of his personal data in case they are incomplete, inaccurate, are not updated or in the course of their collection and/or processing, the consumer changed his mind to enter into a business relationship with the company and/or wishes to terminate business relationship, but due to the fact that the Company acts under Law of Georgia, he may have difficulties with destruction of personal data with immediate effect. Liabilities with regard to different storage periods of the consumer’s data and processing may arise from the Law on “Combating Money Laundering”, tax legislation, “Payment System and Payment Services” and “Consumers’ Rights Protection”.   
            
            The Company undertakes to provide the consumer with the aforementioned information upon consumer’s request. The Company reserves a right to use personal data in the event there are specific contractual and/or legal grounds.    
            
            Confidentiality of processed data is protected under the law. Organizational and technical resources of the Company ensure protection of confidential information from accidental or unlawful destruction, alteration, disclosure, obtaining, unlawful utilization in any other manner and accidental or unlawful loss.  
            
            The company does not process special category data, the processing of which is prohibited by law. 
            `
        },
        faq: {
            title: "Do you have any questions?",
            description: "To solve the problem, choose the appropriate category and find answers to your questions.",
            sections: {
                card: "Card",
                bank: "Bank",
                discount: "Discount",
            },
            questions: "Questions",
            information: "Information",
            searchInstructions: "Or enter any word in the search field and find the answer to your question",
            FAQSearch: {
                searchPlaceholder: "Search word",
                sadlyNothing: "Unfortunately nothing",
                wasFound: "was found"
            }
        },
        contact: {
            title: "Leave  a message",
            instruction: "Write any information and we definitely will discuss it with our team.",
            nameLabel: "Name",
            emailLabel: "E-mail",
            messagePlaceholder: "Message",
            buttonText: "Send message",
            informationBoxText: "Information",
            informationLineText: "Information",
            contactList: {
                location: {
                    title: "Address",
                    content: "Otar Oniashvili St #1. Floor 3",
                },
                email: {
                    title: "Mail",
                    content: "info@nbc.ge",
                },
                phone: {
                    title: "Telephone",
                    content: "+995 032 2 193293\n10:00 - 22:00",
                }
            }
        },
        register: {
            title: "Registration",
            requirement: "To order a card, you must be registered on our website and have a verified Leaderbet account.",
            statusModal: {
                text: "You have successfully registered",
                buttonText: "Home page"
            },
            SMSModal: {
                title: "Registration",
                buttonText: "Confirm",
                smsCode: "SMS code",
                wrongSMSCode: "Incorrect SMS code",
            },
            stepOne: {
                privateInformation: {
                    headerText: "Personal information",
                    genderRadios: {
                        male: "Male",
                        female: "Female",
                    },
                    name: "Name",
                    last_name: "Surname",
                    patronymic: "Patronymic",
                    personal_id: "ID number",
                },
                birthPlaceAndAddress: {
                    headerText: "Place and date of birth",
                    birth_city: "City",
                    birth_country: "Country",
                    citizenship: "Citizenship",
                    birth_date: "Date of birth",
                },
                cardInformation: {
                    headerText: "ID information",
                    document_number: "Document number",
                    issue_date: "Issue date",
                    valid_date: "Expiry date",
                    issuer_org: "Organization of issuance of documents",
                    issuer_org_DEFAULT_VALUE: "House of Justice",
                    issuer_country: "Country of issue",
                },
                legalAddress: {
                    headerText: "Legal address",
                    legal_address: "Address",
                    legal_city: "City",
                    legal_country: "Country",
                },
                legal_equals_fact_address: "Same as legal address",
                factAddress: {
                    headerText: "The actual address",
                    fact_address: "Address",
                    fact_city: "City",
                    fact_country: "Country",
                },
                contactInformation: {
                    headerText: "Contact Information",
                    country_phone_code: "Country Phone Code",
                    phone: "Phone",
                    email: "E-mail",
                }
            },
            stepTwo: {
                status: {
                    headerText: "Status",
                    residentRadios: {
                        resident: "Resident",
                    },
                },
                politicalActivity: {
                    headerText: 'Are you politically active',
                    headerSubText: "Definition*",
                    popupText: "Politically active person - an individual, performing important public or political functions (with the exception of middle and lower-level officials) and / or members of their families (spouse, sister, brother, parent, child / stepson and his / her spouse).",
                    politicalActivityStatusRadios: {
                        yes: 'Yes',
                        no: 'No'
                    },
                    political_activity_info: "Additional Information",
                },
                safetyAndPurpose: {
                    headerText: "Purpose and intent of the relationship",
                    usage_period: "Service life",
                    usage_period_DEFAULT_VALUE: "3 Years",
                    currency: "In what currency the opperations are carried out",
                    frequency: "Estimated frequency of operations",
                    monthly_amount: "Estimated amount of money",
                    additional_info: "Additional Information",
                },
                estimatedMonthlyIncome: {
                    headerText: "Estimated monthly income in GEL",
                },
                incomeSource: {
                    headerText: "Source of income",
                    income_info: "Additional Information",
                    employment_full_name: "Full name of the employer",
                    employment_form: "Legal form of the employer",
                    employee_position: "Position",
                    bank_names: "Serving Banks",
                    bank_services: "Bank services",
                }
            },
            stepThree: {
                documentCopy: {
                    headerText: "Copy of the document",
                    frontSide: {
                        title: "Front side",
                        description: "Upload the front side of your ID"
                    },
                    backSide: {
                        title: "Backside",
                        description: "Upload the backside of your ID"
                    }
                }
            },
            imageUpload: {
                deleteFile: "Delete",
                captureWithCamera: "Take a photo",
            },
            backButtonText: "Back",
            nextButtonText: "Next",
            submitButtonText: "Submit",
            errors: {
                required: "* Required field",
                onlyGeorgianCharacters: "* Only Georgian characters allowed",
                personal_id: "* 11 digits required",
                phone: "* digits required",
                email: "* Enter the correct E-mail",
                number: "* Enter the number",
                onlyDigits: "* Only numbers allowed",
                atLeastOne: "* Be sure to select at least one",
                document_number: "* 9 symbols are required",
                personal_id_registered: "Personal ID number is already registered. Please login",
                document_number_registered: "Number of the document still registered. Please login",
            }
        },
        dashboard: {
            profileTitle: "Profile",
            balanceTitle: "Balance",
            profile: {
                privateInformation: {
                    title: "Personal information",
                    name: "Name",
                    lastName: "Surname",
                    personalId: "ID-number",
                    phoneNumber: "Mobile Number",
                },
                resetPassword: {
                    title: "Change Password",
                    currentPassword: "Current Password",
                    newPassword: "New password",
                    repeatNewPassword: "Repeat new password",
                    buttonText: "Save password",
                    SMSModal: {
                        title: "Change Password",
                        buttonText: "Confirm",
                        smsCode: "SMS code",
                        wrongSMSCode: "Incorrect SMS code",
                    },
                    statusModal: {
                        text: "You have successfully changed the password",
                        buttonText: "Return",
                    },
                },
                pinRecovery: {
                    title: "Card PIN recovery",
                    description: "If you have forgotten the card's PIN code, you can request a new one. In case if you have changed the PIN code already, the new one will not be sent",
                    requestPin: "Request PIN code",
                    pinSent: "PIN code is sent on your mobile number",
                },
                safety: "Safety",
            },
            balance: {
                nbcBalance: "NBC balance",
                transferToLeaderbet: "Transfer to Leaderbet",
                transactionHistory: "Transaction History",
                from: "From",
                to: "To",
                input: "Input",
                output: "Output",
                allTransactions: "All Transactions",
                transferToLeaderbetForm: {
                    amount: "Transfer amount",
                    pinCode: "PIN code",
                    buttonText: "Transfer",
                    transferAgainButtonText: "Re-transfer",
                    successMessage: "The transfer to Leaderbet was completed successfully",
                    errorMessage: "Transfer to Leaderbet failed",
                    pinNumber: "PIN number",
                    smsCode: "SMS code",
                    errorStatuses: {
                        "-1": "The  NBC’s First name of the user does not match the First name of the Leaderbet’s user",
                        "-2": "The NBC’s  Last name of the user does not match the Last name of the Leaderbe’s user",
                        "-3": "The user is not verified on Leaderbet",
                        "-4": "The user's ID number does not match the number indicated on Leaderbet (in a case if PIN is specified)",
                        "-5": "On the Leaderbet side, there is no user with this ID number",
                        "-6": "Several Leaderbet users are registered with this ID number",
                        "-7": "Insufficient amount of money on account",
                        "-8": "Incorrect SMS code",
                        "-10": "Unknown error",
                    }
                },
                transactionModal: {
                    title: "Transfer amount",
                },
                transactionStatuses: {
                    1: 'Transfer from Leaderbet',
                    2: 'Transfer to Leaderbet',
                    3: 'Payment',
                    4: 'Top up balance',
                }
            }
        },
        atms: {
            atmLocationsList: {
                city: "City",
                clearFilter: "Cancel filtering",
                pickCity: "Select city",
                toViewAddresses: "by address",
            },
            addresses: "Addresses",
        }
    },
    globalComponents: {  // გლობალური კომპონენტები
        getApp: {
            title: "Leadercard • e-wallet",
            descriptionAndroid: "Get it for free in the Play Store.",
            descriptionIOS: "Get it for free in the App Store.",
            buttonText: "GET"
        },
        header: { // ჰედერი
            navigation: {
                home: 'MAIN',
                news: 'NEWS',
                atms: 'CASH REGISTERS',
                faq: 'FAQ',
                contact: 'CONTACT',
                account: "MY ACCOUNT",
            },
            loginButton: "Login"
        },
        footer: { // ფუტერი
            navigation: {
                about: 'ABOUT US',
                news: 'NEWS',
                atms: 'CASH REGISTERS',
                faq: 'FAQ',
                contact: 'CONTACT',
                getCard: 'CARD ORDER',
                policy: "POLICY",
                termsAndConditions:'TERMS AND CONDITIONS'
            },
            copyright: "NBC Ltd. (JSC 405150063) NBC.GE - All rights reserved",
        },
        latestNews: {
            headerText: "Latest News"
        },
        loginModal: {
            title: "Authorization",
            personalId: "ID number",
            cardLastFourNumbers: "Last 4 digits of the card",
            setPassword: "Set a password",
            repeatPassword: "Repeat password",
            setPasswordButtonText: "Change password",
            loginButtonText: "Sign in",
            smsCode: "SMS code",
            password: "Password",
            register: "Register",
            errors: {
                physicalIDNotFound: 'Your personal number is not registered in our database. Try again or register online.',
                accountNotActivated: 'Your account is awaiting confirmation from the administrator, please wait.',
                wrongPassword: "Invalid password",
                cardLastFourNumbersNotFound: "A card with the same number is not registered on your account",
                wrongSMSCode: "Incorrect SMS code",
                passwordsNoMatch: "The repeated password does not match the original password",
            },
            showPassword: "See password",
            hidePassword: "Hide password",
            mainPage: "Main Page",
            loading: "The data is being processed. Please wait a few seconds.",
            correctPhysicalID: "Your ID number has been confirmed.",
            correctCardLastFourNumbers: "The last 4 digits of your card are confirmed",
            resetPasswordPrompt: "Password recovery",
            resettingPasswordButtonText: "Set a password",
        },
    },
    universal: {
        months: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
        monthsShort: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sept',
            'Oct',
            'Nov',
            'Dec'
        ],
    }
}