import * as React from "react"

function AlertCircleSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={113.999}
            height={114.001}
            viewBox="0 0 113.999 114.001"
            {...props}
        >
            <path
                d="M56.925.011a57.92 57.92 0 00-40.421 17.366 56.451 56.451 0 0039.55 96.624h1.014a57.536 57.536 0 0056.931-57.984A55.877 55.877 0 0056.925.012zM49.88 78.578a7.1 7.1 0 011.915-5.1 7.2 7.2 0 014.967-2.177h.129a7.265 7.265 0 017.233 6.976 7.075 7.075 0 01-1.915 5.1 6.986 6.986 0 01-4.967 2.177h-.129a7.234 7.234 0 01-7.234-6.977zm2.375-47.694v28.5a4.75 4.75 0 009.5 0v-28.5a4.75 4.75 0 00-9.5 0z"
                fill="#343e57"
                fillRule="evenodd"
                data-name="Interface-Essential / Alerts / alert-circle"
            />
        </svg>
    )
}

export default AlertCircleSVGR
