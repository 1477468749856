import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        borderRadius: 6,
        background: 'transparent',
        [theme.breakpoints.up("sm")]: {
            border: '2px solid #1c2539',
            padding: '0 20px',
        },
    },
    informationLine: {
        marginTop: 26,
        display: 'flex',
        alignItems: 'center',
        '& hr': {
            backgroundColor: '#1c2539',
            height: 2,
            borderRadius: 1,
            border: 'none',
        },
        '& hr:first-of-type': {
            flex: 1 / 5
        },
        '& p': {
            margin: '0 15px',
        },
        '& hr:last-of-type': {
            flex: 4 / 5
        },
    },
    informationLineText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '28px',
        textAlign: 'left',
        color: '#a8b4d3',
    },
    descriptionContainer: {
        paddingBottom: 20,
    },
    descriptionText:{
        fontFamily: "var(--font-family-2)",
        fontWeight: 500,
        fontSize: 14,
        lineHeight: "24px",
        color: "#5e6f9b",
    },
    button: {
        // marginTop: 20,
        width: '100%',
        height: 50,
        borderRadius: 6,
        background: ' #15639f',
        '&:hover': {
            background: ' #15639f',
        },
        '&:disabled': {
            opacity: 0.4,
        }
    },
    buttonText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'left',
        color: '#c7d0da',
    }
}));

export default useStyles;
