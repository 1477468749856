import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	contentContainerGrid: {
		margin: "auto",
		marginTop: -5,
		"& > div:first-of-type": {
			margin: 0,
		},
		[theme.breakpoints.up("md")]: {
			maxWidth: '53.75rem',
		},
		width: "100%",
		// border: "1px solid white"
	},
	route: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 20,
		// lineHeight: '39px',
		textAlign: 'left',
		color: '#2a3550',
		'&:not(:last-of-type)': {
			marginRight: 50,
			[theme.breakpoints.down(420)]: {
				marginRight: 'auto',
			}
		},
		'& hr': {
			width: 0,
			height: 2,
			borderRadius: 1,
			border: 'none',
			margin: 0,
			marginTop: 5,
			backgroundColor: '#f1c157',
			transition: 'width 500ms ease-in-out, visibility 500ms linear',
			visibility: 'hidden',
			'-webkit-backface-visibility': 'hidden',
			[theme.breakpoints.down("sm")]: {
				display: 'none'
			}
		},
		cursor: 'pointer',
		transition: 'color 500ms ease-in-out',
		[theme.breakpoints.down("sm")]: {
			fontSize: 16,
			width: 126,
			height: 60,
			borderRadius: 4,
			background: '#1c2539',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: '#7588B4',
		},
	},
	activeRoute: {
		cursor: 'default',
		color: '#c1c9df',
		[theme.breakpoints.down("sm")]: {
			color: '#161c2a',
			backgroundColor: '#F1C157',
		},
		'& hr': {
			visibility: 'visible',
			height: 2,
			width: 60,
		}
	},
	routeControl: {
		paddingBottom: 50,
	},
	logoutSVGR: {
		marginTop: 18,
		marginLeft: 'auto',
		cursor: 'pointer',
		[theme.breakpoints.up("sm")]: {
			display: 'none',
		}
	}
}));

export default useStyles;
