import * as React from "react"

function BankSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={36}
            height={36}
            viewBox="0 0 36 36"
            {...props}
        >
            <g
                fill="#546283"
                data-name="Landmarks / Official-Buildings / official-building-3"
            >
                <path d="M35.826 34.753l-3.75-4.563a.747.747 0 00-.576-.273h-27a.746.746 0 00-.576.274l-3.75 4.563a.769.769 0 00-.1.809.749.749 0 00.679.438h34.5a.751.751 0 00.68-.438.77.77 0 00-.107-.81zM4.562 16.39a.761.761 0 01.76-.76h4.562a.761.761 0 01.76.76v11.407a.761.761 0 01-.76.76H5.322a.761.761 0 01-.76-.76zM14.958 16.39a.761.761 0 01.76-.76h4.563a.761.761 0 01.76.76v11.407a.761.761 0 01-.76.76h-4.563a.761.761 0 01-.76-.76zM25.354 16.39a.761.761 0 01.76-.76h4.563a.761.761 0 01.76.76v11.407a.761.761 0 01-.76.76h-4.563a.761.761 0 01-.76-.76zM3.145 13.946a.746.746 0 00.623.339h28.464a.747.747 0 00.623-.339l1.5-2.281a.771.771 0 00.037-.78.749.749 0 00-.661-.4H2.27a.747.747 0 00-.66.4.77.77 0 00.037.78zM2.27 9.125h31.46a.752.752 0 00.733-.593.763.763 0 00-.41-.855L18.32.073a.748.748 0 00-.644 0l-15.73 7.6a.764.764 0 00-.41.855.752.752 0 00.734.597z" />
            </g>
        </svg>
    )
}

export default BankSVGR
