import * as React from "react"

function LoadingSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={18}
            viewBox="0 0 16 18"
            {...props}
        >
            <g
                id="Interface-Essential_Loading_loading-circle"
                data-name="Interface-Essential / Loading / loading-circle"
                transform="translate(-468.034 -6821.02)"
            >
                <g id="Group_751" transform="translate(468.034 6821.02)">
                    <g id="loading-circle">
                        <path
                            id="Shape_2007"
                            d="M478.814,6826.412a1.039,1.039,0,0,0,1.036-1.042v-3.315a1.036,1.036,0,0,0-2.072,0v3.315a1.039,1.039,0,0,0,1.036,1.042Z"
                            transform="translate(-470.814 -6821.02)"
                            fill="#546283"
                        />
                        <path
                            id="Shape_2008"
                            d="M473.652,6826.922a1.036,1.036,0,0,0,1.792-1.035l-.619-1.077a1.036,1.036,0,0,0-1.795,1.035Z"
                            transform="translate(-469.448 -6821.521)"
                            fill="#546283"
                        />
                        <path
                            id="Shape_2009"
                            d="M469.828,6829.8l1.077.619a1.037,1.037,0,0,0,1.036-1.8l-1.077-.618a1.037,1.037,0,0,0-1.036,1.8Z"
                            transform="translate(-468.228 -6822.824)"
                            fill="#546283"
                        />
                        <path
                            id="Shape_2010"
                            d="M469.041,6834.827h1.244a1.039,1.039,0,0,0,0-2.077h-1.244a1.039,1.039,0,0,0,0,2.077Z"
                            transform="translate(-468.034 -6824.067)"
                            fill="#546283"
                        />
                        <path
                            id="Shape_2011"
                            d="M470.9,6837.045l-1.077.627a1.034,1.034,0,1,0,1.036,1.789l1.077-.619a1.037,1.037,0,1,0-1.036-1.8Z"
                            transform="translate(-468.228 -6825.207)"
                            fill="#546283"
                        />
                        <path
                            id="Shape_2012"
                            d="M473.407,6842.96a1.039,1.039,0,0,0,1.416-.382l.621-1.077a1.036,1.036,0,1,0-1.795-1.034l-.621,1.077a1.034,1.034,0,0,0,.379,1.416Z"
                            transform="translate(-469.444 -6826.214)"
                            fill="#546283"
                        />
                        <path
                            id="Shape_2013"
                            d="M478.814,6844.383a1.039,1.039,0,0,0,1.036-1.042V6842.1a1.036,1.036,0,1,0-2.072,0v1.238a1.039,1.039,0,0,0,1.036,1.042Z"
                            transform="translate(-470.814 -6826.383)"
                            fill="#546283"
                        />
                        <path
                            id="Shape_2014"
                            d="M482.69,6842.572a1.036,1.036,0,1,0,1.795-1.034l-.621-1.077a1.034,1.034,0,0,0-1.819-.017,1.023,1.023,0,0,0,.027,1.051Z"
                            transform="translate(-472.068 -6826.208)"
                            fill="#546283"
                        />
                        <path
                            id="Shape_2015"
                            d="M487.607,6837.673l-1.077-.627a1.045,1.045,0,0,0-1.415.382,1.034,1.034,0,0,0,.379,1.415l1.077.619a1.034,1.034,0,1,0,1.036-1.789Z"
                            transform="translate(-473.206 -6825.207)"
                            fill="#546283"
                        />
                        <path
                            id="Shape_2016"
                            d="M486.089,6833.793a1.039,1.039,0,0,0,1.036,1.034h1.244a1.039,1.039,0,0,0,0-2.077h-1.244A1.045,1.045,0,0,0,486.089,6833.793Z"
                            transform="translate(-473.376 -6824.067)"
                            fill="#546283"
                        />
                        <path
                            id="Shape_2017"
                            d="M485.114,6830.039a1.031,1.031,0,0,0,1.416.382l1.077-.619a1.037,1.037,0,0,0-1.036-1.8l-1.077.618A1.039,1.039,0,0,0,485.114,6830.039Z"
                            transform="translate(-473.206 -6822.824)"
                            fill="#546283"
                        />
                        <path
                            id="Shape_2018"
                            d="M482.451,6827.3a1.039,1.039,0,0,0,1.416-.381l.621-1.077a1.036,1.036,0,0,0-1.795-1.035l-.619,1.077A1.038,1.038,0,0,0,482.451,6827.3Z"
                            transform="translate(-472.07 -6821.521)"
                            fill="#546283"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default LoadingSVGR
