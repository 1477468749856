import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	container: {
		width: 200,
		"& > div": {
			cursor: "pointer",
			marginRight: 40,
		},
		"& > div:last-of-type": {
			marginRight: 0,
		},
		"& > div:nth-last-of-type(2)": {
			marginRight: 28,
		}
	},
	"social-chat": {
		width: 50,
		height: 50,
		background: "#192031",
		borderRadius: 100,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
}));

export default useStyles;
