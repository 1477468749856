import React, {useEffect, useState} from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../styles/pages/TermsStyles";
import useLanguage from "../hooks/useLanguage";
import classNames from "classnames";
import TermsData from "../data/terms"
import {useHistory, useParams} from "react-router-dom";
function Terms(props) {
  const classes = useStyles(props);
  const history = useHistory();
  const {key} = useParams()
  const { key: languageKey,routeKey, pages: { faq: STRINGS } } = useLanguage();
  const [data,setData]=useState({})
 useEffect(()=>{
   if(key){
        const find = TermsData.find(value =>{
            return  value.key==key
        })
        if(find){
          setData(find)
            console.log(find)
        }else{
          history.push(`/${routeKey}/terms`)
        }
   }
 },[key])

  return (
    <Grid
      item
      container
      xs={11}
      md={11}
      className={classes.contentContainerGrid}
    >
      {
        key ? data && (
             <>
                 <Grid item>
                     <Typography variant="h1" className={classes.title}>
                         {data?.title?.[languageKey]}
                     </Typography>
                 </Grid>
                 <Grid item>
                     <Typography variant="body1" className={classes.policy} dangerouslySetInnerHTML={{__html:data?.content?.[languageKey]}}>

                     </Typography>
                 </Grid>
             </>
        ):(
            TermsData.map(({key,title,endDate})=>{
              return (
                  <Grid item md={12} key={key}>
                    <Typography variant="h3" className={classNames(classes.title,classes.underline)} onClick={()=>{
                      history.push(`/${routeKey}/terms/${key}`)
                    }}>
                      {title[languageKey]}

                    </Typography>
                      <Typography variant="span"  className={classNames(classes.end)} >
                        {endDate[languageKey]}

                      </Typography>
                  </Grid>
              )
            })
        )
      }
    </Grid>
  );
}

export default Terms;
