import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
    },
    closeSVGR: {
        position: 'absolute',
        top: 20,
        right: 20,
        cursor: 'pointer',
        '& path': {
            transition: 'fill 250ms ease-in-out',
        },
        "&:hover path": {
            fill: '#c7d0da',
        },
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
			display: 'none'
		}
    },
    title: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 'normal',
        fontSize: 21,
        lineHeight: '39px',
        textAlign: 'left',
        color: '#c7d0da',
    },
    titleContainer: {
        marginBottom: 30,
    },
    textFieldContainer: {
        marginBottom: 20,
    },
    submitButton: {
        marginTop: 5,
        marginBottom: -20,
        width: '100%',
        height: 50,
        borderRadius: 6,
        background: ' #15639f',
        '&:hover': {
            background: ' #15639f',
        },
        '&:disabled': {
            background: '#1B2234'
        }
    },
    submitButtonText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'left',
        color: '#c7d0da',
    },
    textField1: {
        width: '100%',
        textAlign: 'center',
        borderRadius: 6,
        background: "#1c2539",
        '&:first-of-type': {
            marginRight: 12,
        },
        '&:nth-of-type(3)': {
            marginRight: 12,
        },
        "& label": {
            fontFamily: "var(--font-family-1)",
            fontWeight: 500,
            // fontSize: 13,
            // lineHeight: "22px",
            textAlign: "left",
            color: "#405178",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1c2539",
            borderRadius: 6,
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
            color: "#C1C9DF",
            fontFamily: "var(--font-family-1)",
            // textShadow: '0 0 0 red',
            // color: 'transparent',
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#405178",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiInputAdornment-root": {
            dispaly: 'flex',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1c2539",
            borderRadius: 6,
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: 6,
        },
    },
    endAdornment: {
        color: "#C1C9DF"
    },
    // STATUS
    statusText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '22px',
        textAlign: 'center',
        color: '#197163',
        maxWidth: '90%',
        margin: 'auto',
    },
    successStatusText: {
        color: '#197163',
    },
    errorStatusText: {
        color: "#CB3352"
    },
    statusSVGR: {
        margin: 'auto',
        marginBottom: 20,
    },
    contentContainer: {
        marginTop: 10,
        marginBottom: 35
    },
    resetButton: {
        marginTop: 5,
        width: '100%',
        height: 50,
        borderRadius: 6,
        background: '#1D253A',
        '&:hover': {
            background: '#1D253A',
        },
        '&:disabled': {
            background: '#1B2234'
        }
    },
    errorMessage: {
		color: '#CB3352',
		marginLeft: 3,
		marginTop: -10,
		width: '90%',
		fontFamily: "var(--font-family-2)",
		fontSize: 13,
		marginBottom: 10,
	},
}));

export default useStyles;
