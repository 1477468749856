import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	contentContainerGrid: {
		width: "100%",
		margin: "auto",
		"& div:nth-of-type(3) $textField1": {
			marginLeft: 0,
		},
		marginBottom: 45,
	},
	title: {
		marginTop: 5,
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 28,
		lineHeight: "39px",
		textAlign: "center",
		color: "#c1c9df",
		marginBottom: 10,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			marginBottom: 30,
		}
	},
	subTitle: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 14,
		lineHeight: "23px",
		textAlign: "center",
		color: "#5e6f9b",
		margin: '0 auto',
		marginBottom: 35,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			display: 'none'
		},
		maxWidth: 500,
	},
	textField1: {
		width: "calc(100% - 20px)",
		marginLeft: 20,
		borderRadius: 6,
		background: "#1c2539",
		"& label": {
			fontFamily: "var(--font-family-1)",
			fontWeight: 500,
			// fontSize: 13,
			// lineHeight: "22px",
			textAlign: "left",
			color: "#405178",
		},
		"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			borderColor: "#1c2539",
			borderRadius: 6,
		},
		"& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
			color: "#405178",
			fontFamily: "var(--font-family-1)",
		},
		"& .MuiInputLabel-outlined.Mui-focused": {
			color: "#405178",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#1c2539",
			borderRadius: 6,
		},
		"& .MuiOutlinedInput-root": {
			borderRadius: 6,
		},
		marginBottom: 20,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			width: '100%',
			marginLeft: 0,
		},
	},
	textFieldMultiline: {
		width: "calc(100%)",
		borderRadius: 6,
		background: "#1c2539",
		"& label": {
			fontFamily: "var(--font-family-1)",
			fontWeight: 500,
			// fontSize: 13,
			// lineHeight: "22px",
			textAlign: "left",
			color: "#405178",
		},
		"& textarea": {
			padding: "0 10px",
			color: "#405178",
			fontFamily: "var(--font-family-1)",
		},
		"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			borderColor: "#1c2539",
			borderRadius: 6,
		},
		"& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
			color: "#405178",
			fontFamily: "var(--font-family-1)",
		},
		"& .MuiInputLabel-outlined.Mui-focused": {
			color: "#405178",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#1c2539",
			borderRadius: 6,
		},
		"& .MuiOutlinedInput-root": {
			borderRadius: 6,
		},
		marginBottom: 10,
	},
	sendButton: {
		width: "100%",
		height: 50,
		borderRadius: 6,
		background: "#15639f",
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 14,
		lineHeight: "22px",
		textAlign: "left",
		color: "#a6b5d9",
		"&:hover": {
			background: "#15639f",
		},
		marginBottom: 120,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			marginBottom: 10,
		}
	},
	sendSVGR: {
		marginRight: 5,
		width: 20,
		height: 20,
	},
	informationText: {
		width: '100%',
		height: 60,
		borderRadius: 4,
		background: 'transparent',
		border: '2px solid #1f283c',
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 18,
		textAlign: 'center',
		color: '#a6b5d9',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 50,
		[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
			display: 'none',
		}
	},
	informationLine: {
		[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
			display: 'none'
		},
		marginBottom: 50,
		display: 'flex',
		'& hr': {
			backgroundColor: '#1a1f2e',
			height: 3,
			borderRadius: 1.5,
			border: 'none',
		},
		'& hr:first-of-type': {
			flex: 1 / 6
		},
		'& p': {
			margin: '0 15px',
		},
		'& hr:last-of-type': {
			flex: 5 / 6
		},
	},
	informationLineText: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 22,
		lineHeight: '22px',
		textAlign: 'left',
		color: '#a6b5d9',

	}
}));

export default useStyles;
