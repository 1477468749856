import * as React from "react"

function TransactionStatusInSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={40}
            height={40}
            viewBox="0 0 40 40"
            {...props}
        >
            <g data-name="Group 1182" transform="translate(-562 -581)">
                <circle
                    data-name="Ellipse 86"
                    cx={20}
                    cy={20}
                    r={20}
                    transform="translate(562 581)"
                    fill="#197163"
                    opacity={0.1}
                />
                <g data-name="Group 1146">
                    <path
                        data-name="Path 58"
                        d="M578.502 595h-2.5v12h12v-2.5h-9.5z"
                        fill="#197163"
                    />
                    <path
                        data-name="Rectangle 481"
                        fill="#197163"
                        d="M587.24 597.529l-9.47 9.47-1.722-1.722 9.47-9.47z"
                    />
                </g>
            </g>
        </svg>
    )
}

export default TransactionStatusInSVGR
