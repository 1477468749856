import AnimatedValidationLoading from "../smart-assets/Animated/AnimatedValidationLoading";
import ValidationSuccessSVGR from "../smart-assets/SVGRs/ValidationSuccessSVGR";
import ValidationFailSVGR from "../smart-assets/SVGRs/ValidationFailSVGR";
import EyeCrossedSVGR from "../smart-assets/SVGRs/EyeCrossedSVGR";
import EyeOpenSVGR from "../smart-assets/SVGRs/EyeOpenSVGR";

const generateInputStatuses = (label) => ({
    loading: {
        SVGR: AnimatedValidationLoading,
        // tooltipText: 'მუშავდება მონაცამები. გთხოთ მოიცადოთ რამოდენიმე წამი.'
    },
    success: {
        SVGR: ValidationSuccessSVGR,
        // tooltipText: `თქვენი ${label} დადასტურდა.`
    },
    fail: {
        SVGR: ValidationFailSVGR,
        // tooltipText: `თქვენი ${label} არ არის ჩვენს ბაზაში მოცემული. გაიარეთ რეგისტრაცია`
    },
    password: {
        SVGR: EyeCrossedSVGR,
        // tooltipText: 'პაროლის ნახვა'
    },
    showPassword: {
        SVGR: EyeOpenSVGR,
        // tooltipText: 'პაროლის დამალვა'
    },
    notRegistered: {

    }
});

export default generateInputStatuses;