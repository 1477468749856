/* eslint-disable import/no-anonymous-default-export */
import FlagRussianSVGR from "../../smart-assets/SVGRs/FlagRussianSVGR";

export default {
    key: 'Rus',
    routeKey: "ru",
    FlagSVGR: FlagRussianSVGR,
    displayText: 'русский',
    pages: { // გვერდები
        homePage: { // მთავარი გვერდი
            welcomeBanner: { // მთავარი გვერდის ბანერი
                title: "Преимущества карты Leadercard",
                benefitsList: {
                    digitalPayment: 'Бесконтактная оплата',
                    cashback: '10% cashback',
                    giftedPoints: '1000 баллов в подарок на Betmarket'
                },
                buttons: {
                    request: "Запросить",
                    more: "Узнать больше"
                }
            },
            features: { // მთავარი გვერდის 4 უპირატესობა
                verified: "Высокий уровень конфиденциальности",
                discount: "Возможность получения скидки",
                coin: "Легкий способ оплаты",
                dollar: "Легкий способ вывода денег",
            }
        },
        about: { // ჩვენ შესახებ
            aboutUs: "О НАС",
            aboutUsParagraph: "www.leadercard.ge является собственностью NBC Ltd. NBC Ltd. - это электронный кошелек, лицензированный Национальным банком Грузии, работающий на грузинском рынке с 2017 года. LeaderCard предлагает своим клиентам уникальную бесконтактную чип-карту банковского типа. Владельцы карт имеют возможность распоряжаться средствами на счете leader-bet.com с помощью LeaderCard.",
            howItFunctions: "Как устроен LeaderCard?",
            howItFunctionsParagraph: "Клиенты могут легко перевести выигрышную сумму на счет LeaderCard, снять наличные или оплатить через банкоматы и терминалы Банков ‘Bank of Georgia’ и TBC Bank по всей Грузии",
            whatConditions: "Какие условия  предлагаем?",
            conditionsListArray: [
                "Обналичивание денег  бесплатно, без комиссии",
                "Суточный лимит Обналичивания 3000 лари",
                "Расплачивайтесь с помощью LeaderCard в любом магазине и получайте мгновенный кэшбэк в размере 10%.",
            ],
            easyAccess: "Получить LeaderCard можно бесплатно в кассах Leaderbet и рекламных киосках LeaderCard или просто через онлайн заказ.",
            companyIdentificationInfo: "Идентификационные данные компании:",
            company: "Компания NBC Ltd.",
            companyId: "И.К. 405150063",
        },
        policy:  {
            title: "ПОЛИТИКА",
            policy: `Веб-страница leadercard.ge управляется ООО «Эн-Би -Си» - (405150063), далее – компания, зарегистрированная и лицензированная в соответствии с законодательством Грузии, в виде провайдера платежных услуг.  
            Компания защищает и уважает права пользователей, также обеспечивает безопасность пользователя при использовании своих услуг. При обработке и хранения персональных данных лица, компания действует в соответствии с законом Грузии «о защите персональных данных».  
            Обработка персональных данных со стороны компании осуществляется только при наличии предусмотренных законом оснований, и во время их обработки компания выполняет все требования, предусмотренные законом: 
            А) Обработка данных осуществляется справедливо и в законном порядке, без ущемления достоинства субъекта данных.
            Б) Обработка данных должна осуществляться только для конкретных, точно определённых, законных целей.  Компания не осуществляет обработку персональных данных для других целей, не совместимых с первоначальной целью. 
            В) Данные могут обрабатываться только в объеме, необходимом для достижения соответствующих законных целей. Обработка данных должна быть адекватна и пропорциональна той цели, для достижения которой они обрабатываются. 
            Г) Проверка достоверности и точности данных осуществляется соответствующими сотрудниками, также в случае надобности, осуществляется обновление упомянутой информации.  
            Компания осуществляет обработку данных, когда регистрация пользователя в системе и затем оказание услуги для него и\\или отработка данных необходима для исполнения обязательств, предусмотренных требованиями регулировщика.  
            Веб-страница компании использует автоматическую систему сбора данных - Cookies. Cookies является устройством, который хранится на жестком диске интернет пользователя. Оно не содержит понятную информацию, но оно даёт возможность интернет пользователям иметь доступ к своим персональным данным, доступным для пользователей на leadercard.ge. Cookies распространены в наших серверах и никто не имеет доступ к той информации, которая хранится в них. Только компания обрабатывает информацию, целиком и анонимно собранную посредством Cookies для того, чтобы привести в действие ее сервис и оптимизацию веб-страницы в соответствии с потребностями и преимуществом пользователей. Необходимо получить согласие на процедуру автоматического сбора данных и использования Cookies для использования страницы и ее услуги. Если вы не желаете, чтобы ваш браузер подтвердил согласие на использование Cookies, вы можете отключить опцию принятия Cookies в параметрах вашего браузера.                          
            Пользователь имеет право требовать поправление, обновление, дополнение, блокировку, удаление или уничтожение своих личных данных в том случае, если такие данные являются неполными, не обновлёнными, или если в процессе их cбора и\\или разработки пользователь передумал вступать в деловые отношения с компанией и\\или намерен прекратить деловые отношения, но исходя из того, что компания действует в соответствии с законодательством Грузии, возможно у нее возникнут трудности при уничтожении персональных данных пользователя в незамедлительном порядке. Обязанности относительно разных срок хранения данных пользователя, и соответственно их обработки, возможно будут обусловлены законом «О борьбе против отмывании денег», налоговым законодательством, законом «О платёжной системе и платёжных услугах» и «О защите прав пользователей».    
            
            У компании возникает обязанность предоставить указанную информацию пользователям в случае требования пользователя. Компания оставляет за собой право использовать персональные данные только в том случае, если для этого имеется конкретное договорное и\\или законное основание. 
            
             Конфиденциальность разработанных данных защищена в соответствии с законодательством.
            Организационные и технические ресурсы компании обеспечивают защиту конфиденциальной информации от случайного или незаконного уничтожения, изменения, разглашения, добывания, незаконного использования любым иным способом и случайной или незаконной потери.         
            
            Компания не осуществляет разработку данных особой категории, разработка которых запрещена законодательством.   
            `
        },
        faq: {
            title: "Есть Вопросы?",
            description: "Для решения проблемы выбери подходящую категорию и найди ответы на интересующие вопросы.",
            sections: {
                card: "Карта",
                bank: "Банк",
                discount: "Скидка",
            },
            questions: "Вопросы",
            information: "Информация",
            searchInstructions: "Или введи любое слово в поле поиска и найди ответ на интересующий вопросю",
            FAQSearch: {
                searchPlaceholder: "Поисковое слово",
                sadlyNothing: "К сожалению ничего",
                wasFound: "не найдено"
            }
        },
        contact: {
            title: "Оставьте нам сообщение",
            instruction: "Напиши любую информацию и мы обязательно ее обсудим с нашей командой.",
            nameLabel: "Имя",
            emailLabel: "E-mail",
            messagePlaceholder: "Сообщение",
            buttonText: "Отправить",
            informationBoxText: "Информация",
            informationLineText: "Информация",
            contactList: {
                location: {
                    title: "Адрес",
                    content: "Ул. Отара Ониашвили #1. Этаж 3",
                },
                email: {
                    title: "Почта",
                    content: "info@nbc.ge",
                },
                phone: {
                    title: "Телефон",
                    content: "+995 032 2 193293\n10:00 - 22:00",
                }
            }
        },
        register: {
            title: "Регистрация",
            requirement: "Чтобы заказать карту, необходимо зарегистрироваться на нашем сайте, а также иметь верифицированную учетную запись Leaderbet.",
            statusModal: {
                text: "Вы успешно прошли регистрацию",
                buttonText: "Главная страница"
            },
            SMSModal: {
                title: "Регистрация",
                buttonText: "Подтвердить",
                smsCode: "SMS код",
                wrongSMSCode: "Неверный SMS код",
            },
            stepOne: {
                privateInformation: {
                    headerText: "Персональная информация",
                    genderRadios: {
                        male: "Мужчина",
                        female: "Женщина",
                    },
                    name: "Имя",
                    last_name: "Фамилия",
                    patronymic: "Отчество",
                    personal_id: "Номер удостоверения личности",
                },
                birthPlaceAndAddress: {
                    headerText: "Место и дата рождения",
                    birth_city: "Город",
                    birth_country: "Страна",
                    citizenship: "Гражданство",
                    birth_date: "Дата рождения",
                },
                cardInformation: {
                    headerText: "Информация удостоверения личности",
                    document_number: "Номер документа",
                    issue_date: "Дата выхода",
                    valid_date: "Срок годности",
                    issuer_org: "Организацыя выдачи документов",
                    issuer_org_DEFAULT_VALUE: "Дом Правосудия",
                    issuer_country: "Страна выдачи",
                },
                legalAddress: {
                    headerText: "Юридический адрес",
                    legal_address: "Адрес",
                    legal_city: "Город",
                    legal_country: "Страна",
                },
                legal_equals_fact_address: "Тот же юридический адрес",
                factAddress: {
                    headerText: "Фактический адрес",
                    fact_address: "Адрес",
                    fact_city: "Город",
                    fact_country: "Страна",
                },
                contactInformation: {
                    headerText: "Контактная информация",
                    country_phone_code: "Телефонный код страны",
                    phone: "Телефон",
                    email: "E-mail",
                }
            },
            stepTwo: {
                status: {
                    headerText: "Статус",
                    residentRadios: {
                        resident: "Резидент",
                    },
                },
                politicalActivity: {
                    headerText: 'Активны ли вы политически',
                    headerSubText: "Дефиниция*",
                    popupText: "Политически активное лицо - физическое лицо, выполняющее важные общественные или политические функции (за исключением чиновников среднего и нижнего звена) и / или члены их семей (супруга, сестра, брат, родитель, ребенок / пасынок и его / ее супруга).",
                    politicalActivityStatusRadios: {
                        yes: 'Да',
                        no: 'Нет'
                    },
                    political_activity_info: "Дополнительная информация",
                },
                safetyAndPurpose: {
                    headerText: "Цель и намерение отношений",
                    usage_period: "Срок использования услуги",
                    usage_period_DEFAULT_VALUE: "3 года",
                    currency: "В какой валюте проводятся операции",
                    frequency: "Предполагаемая частота операций",
                    monthly_amount: "Ориентировочная сумма",
                    additional_info: "Дополнительная информация",
                },
                estimatedMonthlyIncome: {
                    headerText: "Ориентировочный ежемесячный доход в лари",
                },
                incomeSource: {
                    headerText: "Источник дохода",
                    income_info: "Дополнительная информация",
                    employment_full_name: "Полное имя работодателя",
                    employment_form: "Юридическая форма работодателя",
                    employee_position: "Должность",
                    bank_names: "Обслуживающие Банки",
                    bank_services: "Сервисы Банка",
                }
            },
            stepThree: {
                documentCopy: {
                    headerText: "Копия документа",
                    frontSide: {
                        title: "Лицевая сторона",
                        description: "Загрузите первую сторону своего удостоверения личности"
                    },
                    backSide: {
                        title: "Задняя сторона",
                        description: "Загрузите обратную сторону своего  удостоверения личности"
                    }
                }
            },
            imageUpload: {
                deleteFile: "Удалять",
                captureWithCamera: "Снимать камерой",
            },
            backButtonText: "Назад",
            nextButtonText: "Вперед",
            submitButtonText: "Подтверждение",
            errors: {
                required: "* Обязательное поле",
                onlyGeorgianCharacters: "* Разрешены только Грузинские символы",
                personal_id: "* Требуется 11 цифр",
                phone: "* Требуется 9 цифр",
                email: "* Введите правильный E-mail",
                number: "* Введите число",
                onlyDigits: "* Разрешены только цифры",
                atLeastOne: "* Обязательно выбрать хотя бы один",
                document_number: "* Необходимо 9 символов",
                personal_id_registered: "Номер удостоверения личности уже зарегистрирован, пожалуйста пройдите авторизацию",
                document_number_registered: "Номер документа уже зарегистрирован, пожалуйста пройдите авторизацию",
            }
        },
        dashboard: {
            profileTitle: "Профиль",
            balanceTitle: "Баланс",
            profile: {
                privateInformation: {
                    title: "Персональная информация",
                    name: "Имя",
                    lastName: "Фамилия",
                    personalId: "ном. Удостоверения личности",
                    phoneNumber: "Номер Мобильного",
                },
                resetPassword: {
                    title: "Изменить пароль",
                    currentPassword: "Текущий пароль",
                    newPassword: "Новый пароль",
                    repeatNewPassword: "Повторите новый пароль",
                    buttonText: "Установить пароль",
                    SMSModal: {
                        title: "Изменить пароль",
                        buttonText: "Подтвердить",
                        smsCode: "SMS код",
                        wrongSMSCode: "Неверный SMS код",
                    },
                    statusModal: {
                        text: "Вы успешно сменили пароль",
                        buttonText: "Вернуться назад",
                    },
                },
                pinRecovery: {
                    title: "Восстановление PIN-кода карты",
                    description: "Если забыли PIN-код карты, вы можете заново его запросить. В случае уже измененного PIN-кода, новый не будет отправлен.",
                    requestPin: "Запросить PIN-кодю",
                    pinSent: "PIN-код отправлен на ваш мобильный номер",
                },
                safety: "Безопасность",
            },
            balance: {
                nbcBalance: "NBC баланс",
                transferToLeaderbet: "Перевод на Leaderbet",
                transactionHistory: "История транзакций",
                from: "От",
                to: "До",
                input: "Ввод",
                output: "Вывод",
                allTransactions: "Все транзакций",
                transferToLeaderbetForm: {
                    amount: "Зачисление денег",
                    pinCode: "PIN-код",
                    buttonText: "Перевод",
                    transferAgainButtonText: "Повторное перечисление суммы",
                    successMessage: "Перечисление суммы на Leaderbet успешно завершен",
                    errorMessage: "Перечисление суммы на Leaderbet не удался",
                    pinNumber: "Номер PIN-кода",
                    smsCode: "SMS код",
                    errorStatuses: {
                        "-1": "Имя пользователя NBC не совпадает имени пользователя Leaderbet",
                        "-2": "Фамилия пользователя NBC не совпадает фамилии пользователя Leaderbet",
                        "-3": "Пользователь не верифицирован на Leaderbet",
                        "-4": "Номер удостоверения личности пользователя не совпадает с номером, указанным на Leaderbet (в том случае если указан PIN)",
                        "-5": "На стороне Leaderbet, нет номера удостоверения личности пользователя",
                        "-6": "С этим личным номером на Leaderbet зарегистрированы несколько пользователей",
                        "-7": "На счете недостаточная сумма",
                        "-8": "Неверный SMS код",
                        "-10": "Неизвестная ошибка",
                    }
                },
                transactionModal: {
                    title: "Сумма перевода",
                },
                transactionStatuses: {
                    1: 'Зачисление денег с Leaderbet',
                    2: 'Перечисление денег на Leaderbet',
                    3: 'Оплата',
                    4: 'Пополнить баланс',
                }
            }
        },
        atms: {
            atmLocationsList: {
                city: "Город",
                clearFilter: "Отменить фильтрацию",
                pickCity: "Выберите город",
                toViewAddresses: "по адресу",
            },
            addresses: "Адреса",
        }
    },
    globalComponents: { // გლობალური კომპონენტები
        getApp: {
            title: "Leadercard • e-wallet",
            descriptionAndroid: "Установи бесплатно из Play Store.",
            descriptionIOS: "Установи бесплатно из App Store.",
            buttonText: "Установить"
        },
        header: { // ჰედერი
            navigation: {
                home: 'ГЛАВНОЕ',
                news: 'НОВОСТИ',
                atms: 'КАССЫ',
                faq: 'FAQ',
                contact: 'КОНТАКТЫ',
                account: "МОЙ ОТЧЕТ",
            },
            loginButton: "Вход"
        },
        footer: { // ფუტერი
            navigation: {
                about: 'О НАС',
                news: 'НОВОСТИ',
                atms: 'КАССЫ',
                faq: 'FAQ',
                contact: 'КОНТАКТЫ',
                getCard: 'ЗАКАЗАТЬ КАРТУ',
                policy: "ПОЛИТИКА",
                termsAndConditions:'УСЛОВИЯ И ПОЛОЖЕНИЯ'

            },
            copyright: "© NBC Ltd. (JSC 405150063) NBC.GE - Все права защищены",
        },
        latestNews: {
            headerText: "Последние новости",
        },
        loginModal: {
            title: "Авторизация",
            personalId: "Номер удостоверения личности",
            cardLastFourNumbers: "Последние 4 цифры карты",
            setPassword: "Установи пароль",
            repeatPassword: "Повтори пароль",
            setPasswordButtonText: "Измени пароль",
            loginButtonText: "Ввоити",
            smsCode: "SMS код",
            password: "Пароль",
            register: "Регистрация",
            errors: {
                physicalIDNotFound: 'Ваш личный номер не зарегистрирован в нашей базе данных. Попробуйте еще раз или зарегистрируйтесь онлайн.',
                accountNotActivated: 'Ваша учетная запись ожидает подтверждения от администратора, пожалуйста подождите.',
                wrongPassword: "Неверный пароль",
                cardLastFourNumbersNotFound: "Карта с таким же номером не зарегистрирована на вашем аккаунте",
                wrongSMSCode: "Неверный SMS код",
                passwordsNoMatch: "Повторяющийся пароль не соответствует исходному паролю",
            },
            showPassword: "Посмотреть пароль",
            hidePassword: "Скрыть пароль",
            mainPage: "Главное страница",
            loading: "Данные обрабатываются. Пожалуйста подождите несколько секунд.",
            correctPhysicalID: "Ваш номер удостоверения личности подтвержден.",
            correctCardLastFourNumbers: "Последние 4 цифры вашей карты подтверждены",
            resetPasswordPrompt: "Восстановление пароля",
            resettingPasswordButtonText: "Установление пароля",
        }
    },
    universal: {
        months: [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь",
        ],
        monthsShort: [
            'Янв',
            'Фев',
            'Мар',
            'Апр',
            'May',
            'Июн',
            'Июл',
            'Авг',
            'Сен',
            'Окт',
            'Ноя',
            'Дек'
        ],
    }
}