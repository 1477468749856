import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useStyles from "../styles/components/FeaturedNewsStyles";
import useLanguage from "../hooks/useLanguage";

const FeaturedNews = (props) => {
    const classes = useStyles(props);
    const { news } = props;
    const history = useHistory();

    const { key: languageKey, universal: { months } } = useLanguage();

    return (
        <Grid container className={classes.container}>
            {news.map((entry) => (
                <Grid xs={12} sm={6} md={4} item className={classes.newsContainer} key={entry.id} onClick={() => history.push(`news/${entry.id}`)}>
                    <Box className={classes.news}>
                        <Box className={classes.imageContainer}>
                            <img src={entry.images[languageKey].m.default} alt="" className={classes.image} />
                        </Box>
                        <Box className={classes.contents}>
                            <Typography variant="body1" className={classes.date}>
                                {`${entry.date.getDate()} ${months[entry.date.getMonth()]}, ${entry.date.getFullYear()}`}
                            </Typography>
                            <Typography variant="body1" className={classes.title}>
                                {entry.title[languageKey]}
                            </Typography>
                            <Typography variant="body1" className={classes.shortDescription}>
                                {entry.shortDescription[languageKey]}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            ))}
        </Grid>
    );
};

export default FeaturedNews;
