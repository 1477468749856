import * as React from "react"

function VerifiedSVGR(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={38}
      viewBox="0 0 32 38"
      {...props}
    >
      <path
        data-name="Path 36"
        d="M31.087 6.153L16.568.114a1.479 1.479 0 00-1.136 0L.913 6.153A1.484 1.484 0 000 7.524v7.342A24.859 24.859 0 0015.44 37.89a1.479 1.479 0 001.12 0A24.859 24.859 0 0032 14.866V7.524a1.484 1.484 0 00-.913-1.371zm-2.05 8.713A21.9 21.9 0 0116 34.9 21.9 21.9 0 012.963 14.866V8.515L16 3.092l13.037 5.423zm-14.675 5l6.368-6.38a1.485 1.485 0 112.1 2.1l-7.415 7.43a1.479 1.479 0 01-2.1 0l-4.14-4.148a1.485 1.485 0 112.1-2.1z"
        fill="#5e6f9b"
      />
    </svg>
  )
}

export default VerifiedSVGR
