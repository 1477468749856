export default {
    key:1,
    title:{
        Geo: `საგადახდო მომსახურების ხელშეკრულების დანართი N1 - საგადახდო ბარათის გამოყენების წესი`,
        Eng: `საგადახდო მომსახურების ხელშეკრულების დანართი N1 - საგადახდო ბარათის გამოყენების წესი`,
        Rus: `საგადახდო მომსახურების ხელშეკრულების დანართი N1 - საგადახდო ბარათის გამოყენების წესი`,
    },
    endDate:{
        Geo: `ცვლილებები ძალაშია 14.03.2023-დან`,
        Eng: `ცვლილებები ძალაშია 14.03.2023-დან`,
        Rus: `ცვლილებები ძალაშია 14.03.2023-დან`,
    },
    content:{
        Geo: `
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>საგადახდო მომსახურების ხელშეკრულების დანართი N1 - საგადახდო ბარათის გამოყენების წესი</strong></span></span></span></p>

        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1. მომხმარებელი არის ბარათის მფლობელი პირი, რომელიც უკვეთავს ბარათს და დებს შესაბამისხელშეკრულებას პროვაიდერთან.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.მომხმარებელს ელექტრონული საფულის მართვის პანელთან წვდომა შეუძლია როგორც პროვაიდერის ვებ-გვერდიდან, ასევე მობილური აპლიკაციის დახმარებით, რაც მომხმარებელს</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >საშუალებას აძლევს მარტივად მოახდინოს ბარათით განხორციელებული ტრანზაქციების კონტროლი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3. Leader Card -ი არის ლოკალური ტიპის წინასწარი გადახდის ბარათი. ლიდერქარდით სარგებლობაშესაძლებელია მხოლოდ საქართველოს ტერიტორიაზე. ბარათით ოპერაციები სრულდება მხოლოდწინასწარ ჩარიცხული თანხის ფარგლებში (საკრედიტო ლიმიტი არ დაიშვება);</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>ბარათზე მითითებულია ბარათის ნომერი, ბარათის მოქმედების ვადა და შემდეგი აუცილებელი</strong><strong>ინფორმაცია:</strong></span></span></span></p>
        
        <ul>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">დატანილია პროვაიდერის დასახელება და ლოგო - NBC;</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">დატანილია ლოკალური ბარათის სახელწოდება leader card და ლოგო, გამოყენებულია</span></span></li>
        </ul>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ყვითელი ტონები;</span></span></span></p>
        
        <ul>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ჩიპი მიკროსქემა, რომელზეც ჩაწერილია მონაცემები (ბარათის ჩიპის ნომერი Optelio</span></span></li>
        </ul>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >Contactless D16 R9 FA PURE C180);</span></span></span></p>
        
        <ul>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">უკონტაქტო ბარათის აღმნიშვნელი სიმბოლო;</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათის ნომერი - 16 ნიშნიანი ნომერი;</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">მოქმედების ვადა ამოტვიფრულია შემდეგი ფორმატით: თვე / წელი</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">მითითებულია ცხელი ხაზის ნომერი, პროვაიდერის მისამართი, ვებგვერდის მისამართი</span></span></li>
        </ul>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>ბარათის მომსახურების ტარიფები</strong></span></span></span></p>
        
        <ul>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათის მოქმედების ვადა (წელი) - 4 წელი</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათის საფასური - უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">თანხის განაღდება &bdquo;საქართველოს ბანკისა &ldquo;და &bdquo;თიბისი ბანკის&ldquo;ბანკომატების - უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათით გადახდის ოპერაციის საკომისიო -უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">თანხის გადატანა ლიდერბეთის ანგარიშიდან ლიდერქარდზე - უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათის პინ-კოდის ცვლილება - უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათის დაბლოკვა - უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">საბარათე ანგარიში ტიპი ერთ ვალუტაში - ლარი</span></span></li>
        </ul>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4. მომხმარებლებს სტანდარტულად სისტემა ანიჭებს განაღდების და გადახდის ლიმიტს 24 საათისგანმავლობაში, რომელიც განაღდების შემთხვევაში წარმოადგენს 6000 ლარს, ხოლო გადახდისშემთხვევაში 1400 ლარს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ა) ბანკომატის საშუალებით თანხის განაღდების ლიმიტი 24 (ოცდაოთხი) საათის განმავლობაში</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >შეადგენს 6000 (ექვსი ათას) ლარს;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბ) პოს-ტერმინალით შესრულებული გადახდების დღიური ლიმიტი 24 (ოცდაოთხი) საათის</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >განმავლობაში შეადგენს 1400 (ათას ოთხას) ლარს;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გ) ბარათებზე ასევე დაწესებულია ტრანზაქციების რაოდენობრივი ლიმიტი: 24 საათის განმავლობაში - 10 (ათი) ტრანზაქცია, ხოლო თვის განმავლობაში 250 (ორას ორმოცდაათი) ტრანზაქცია.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5. ბარათს აქვს პინ კოდი. პინ-კოდი არის ბარათის თანმხლები პერსონალური, კონფიდენციალური კოდი, რომელიც ბარათთან ერთად წარმოადგენს მომხმარებლის ელექტრონულ იდენტიფიკატორს. იგი გამოიყენება ბანკომატებსა და პოს-ტერმინალებში ტრანზაქციების შესასრულებლად; (ბანკომატით, პოს-ტერმინალით შესრულებული ნებისმიერი ტრანზაქცია დასტურდება პინკოდით)</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6. ბარათის მომსახურების სატელეფონო ნომრის მომხმარებლის მიერ პროვაიდერთან დაფიქსირებისა და აღნიშნულ ნომერზე პლასტიკური ბარათის საწყისი პინ-კოდის მიღებასთან</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დაკავშირებული მომსახურეობის გააქტიურების შემდგომ, მომხმარებელს მითითებულ სატელეფონო ნომერზე ეგზავნება კოდი, რომელიც წარმოადგენს ბარათის კუთვნილ პინ-კოდს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >აღნიშნული პინ-კოდის საშუალებით შესაძლებელია საბარათე ოპერაციების შესრულება.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7. პინ-კოდი ცნობილია მხოლოდ მომხმარებლისთვის, რომელიც ვალდებულია დაიმახსოვროს დაუზრუნველყოს პინ-კოდის საიდუმლოება. პინ-კოდის ბარათზე რაიმე ფორმით მითითება დაუშვებელია.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >8. მომხმარებლის სურვილის შემთხვევაში, შესაძლებელია ბარათის პინ-კოდის შეცვლა:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >8.1. პინ კოდის შეცვლა ხორციელდება ბანკომატის მეშვეობით, თუ მომხმარებელს ახსოვს მიმდინარე პინ-კოდი, ბანკომატზე მისი შეყვანის შემდეგ ეძლევა ახალი პინ-კოდის მინიჭების საშუალება. პინკოდის ცვლილებასთან დაკავშირებით პასუხისმგებლობა ეკისრება მომხმარებელს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >9. ბარათი წარმოადგენს პროვაიდერის საკუთრებას. ბარათით სარგებლობის პერიოდი განისაზღვრება ბარათზე მითითებული ვადით. მოქმედების ვადა იწურება ბარათზე მითითებული თვის ბოლო დღის გასვლის შემდეგ. დაუშვებელია ბარათის მოქმედების ვადის გაზრდა/შემცირება.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10. ტერმინი &ldquo;საბარათე ანგარიში&rdquo; ნიშნავს ბარათზე მიბმულ ელექტრონული ფულის ანგარიშს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ენბისი საგადახდო მომსახურებას ახორციელებს მხოლოდ საქართველოს ტერიტორიაზე. ელექტრონულ საფულეს გააჩნია ელექტრონული ფულის ანგარიში ერთ ვალუტაში და ყველა შემთხვევაში - ლარში;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >11. ბარათით სარგებლობის პირობები და წესები განისაზღვრება წინამდებარე დოკუმენტით,</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბარათით მომსახურების ტარიფებით და საქართველოს კანონმდებლობით. დადგენილი ტარიფებისშესახებ ინფორმაცია ხელმისაწვდომია კომპანიის ოფიციალური ვებ-გვერდზე <a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline">www.leadercard.ge</a>, ასევე მომხმარებელი ვალდებულია ოპერაციის განხორციელებამდე ვებ-გვერდის მეშვეობით გაეცნოს შესაბამისი ოპერაციისთვის დადგენილ ტარიფებს;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >12. ბარათის საფასური, ასევე ბარათთან დაკავშირებული მომსახურების საფასური და ბარათით</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >განხორციელებული ტრანზაქციების საკომისიოები და მათი ოდენობები განისაზღვრება ბარათისმომსახურების ტარიფებით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13. ბარათის საფასურის, ბარათით და მასთან დაკავშირებული მომსახურების საფასურის და ბარათით განხორციელებული ტრანზაქციებისათვის დადგენილი საკომისიოების გადახდა ეკისრება მომხმარებელს (ასეთის არსებობის შემთხვევაში).</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14. მომხმარებელს შეუძლია დაუკავშირდეს ცხელ ხაზს, კითხვა-პასუხის გამოყენების მეშვეობით</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გაიაროს იდენტიფიკაცია და მიიღოს ინფორმაცია ბარათის და/ან ბარათთან დაკავშირებული ოპერაციების შესახებ და/ან დაბლოკოს ბარათი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15. ბარათზე მიმაგრებული საბარათე ანგარიშიდან ბარათის საშუალებით შესაძლებელია წარმოებულიქნეს:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; ნაღდი ოპერაცია - თანხის განაღდება &bdquo;საქართველოს ბანკისა &ldquo;და &bdquo;თიბისი ბანკის &ldquo; ბანკომატების/პოს-ტერმინალების საშუალებით;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; უნაღდო ოპერაცია - თანხის გადახდა &bdquo;საქართველოს ბანკისა &ldquo;და &bdquo;თიბისი ბანკის &ldquo;პოსტერმინალების საშუალებით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >16. ბარათით შესრულებული ფაქტობრივი ოპერაციის თარიღი, შესაძლოა განსხვავდებოდეს საბარათე ანგარიშზე მისი ასახვის თარიღისაგან, რაც დამოკიდებულია ტრანზაქციის ტიპზე და</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბანკომატების/პოსტერმინალების ქსელზე. ოპერაციის წარმატებით შესრულების შემთხვევაში</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომხამრებლის ანგარიშზე ცვლილება აისახება 5-დან 10 წამის ფარგლებში;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >17. ბარათი არ შეიძლება გამოყენებული იქნას უკანონო მიზნებისთვის, მათ შორის იმ საქონლის დამომსახურების შესაძენად, რომელიც აკრძალულია საქართველოს მოქმედი კანონმდებლობით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >18. ბარათის დაკარგვის/მოპარვის ფაქტის პროვაიდერისთვის არ შეტყობინების ან დაგვიანებით</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >შეტყობინების შემთხვევაში, პროვაიდერს არ ეკისრება მატერიალური პასუხისმგებლობა შეტყობინებამდე, დაკარგული/მოპარული ბარათით, შესრულებულ ტრანზაქციებზე.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >19. მომხმარებელს უფლება აქვს ბარათის დაკარგვის/მოპარვის შემთხვევაში მოითხოვოს ბარათის სტოპ-სიაში ჩასმა.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >20. სტოპ-სიაში შესაძლებელია ბარათების ჩასმა მხოლოდ 15 დღის ვადით. აღნიშნული ვადის</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გასვლის შემდეგ, მომხმარებლის სურვილის მიხედვით, თავიდან უნდა მოხდეს ბარათის ჩასმა სტოპსიაში.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >21. მომხმარებელს შეუძლია ბარათის დაბლოკვიდან თხუთმეტი კალენდარული დღის განმავლობაშიმოითხოვოს დაბლოკილი ბარათის განბლოკვა. ბარათის დაბლოკვიდან თხუთმეტი კალენდარული დღის გასვლის შემდეგ პროვაიდერი მომხმარებლის შეტყობინების/გაფრთხილების გარეშე ხურავს ბარათს. დახურული ბარათი არ ექვემდებარება აღდგენას.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>22. მომხმარებელი ვალდებულია:</strong></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ა. ბარათის მოქმედების ვადის ამოწურვის ან ბარათის ვადამდე დახურვის შემთხვევაში თხუთმეტიკალენდარული დღის განმავლობაში დაუბრუნოს ბარათი პროვაიდერს;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბ. შეინახოს ბარათით განხორციელებული ყველა ოპერაციის დამადასტურებელი საბუთი/ანგარიშქვითარი და რეგულარულად შეამოწმოს საბარათე ანგარიშის ამონაწერი, არანაკლებ თვეში ერთხელ.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გ. ტრანზაქციის გასაჩივრების შემთხვევაში, მიმართოს პროვაიდერს სადავო ტრანზაქციის</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >განხორციელებიდან 40 დღის ვადაში და წარუდგინოს გასაჩივრებულ ტრანზაქციასთან</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დაკავშირებული ინფორმაცია/დოკუმენტები, რაც მოიცავს, მაგრამ არ შემოიფარგლება შემდეგით:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ტრანზაქციის თარიღი, ტრანზაქციის ოდენობა, ბარათის მონაცემები, ინფორმაცია სავაჭრო ობიექტზე(რელევანტურობის შემთხვევაში), შეძენილი ნივთი და/ ან მომსახურება (რელევანტურობის შემთხვევაში), ქვითარი/გადახდის დამადასტურებელი დოკუმენტი (რელევანტურობის შემთხვევაში) და ნებისმიერი სხვა დამატებითი ინფორამცია და/ან დოკუმენტი, რასაც პროვაიდერი, საკუთარი შეხედულებისამებრ, საჭიროდ მიიჩნევს საკითხის შესწავლისა და განხილვის პროცესში.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >23. მომხმარებელი უფლებამოსილია მიიღოს ინფორმაცია ბარათით განხორციელებული ოპერაციების შესახებ;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >24. ბარათის საშუალებით შეძენილი საქონლის/მომსახურების ხარისხთან და/ან რაოდენობასთანდაკავშირებით რაიმე პრეტენზიის შემთხვევაში დავა უნდა გადაწყვიტოს მომხმარებელმა შესაბამისსავაჭრო დაწესებულებასთან.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >25. პროვაიდერი ვალდებულია დაიცვას მომხმარებლის პერსონალური მონაცემების და საბარათეანგარიშის შესახებ ინფორმაციის კონფიდენციალურობა (ჩატარებული ოპერაციები, ნაშთები და სხვ.).გამონაკლისს წარმოადგენს კანონმდებლობით გათვალისწინებული შემთხვევები.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >26. პროვაიდერი ვალდებულია მომხმარებელს მოთხოვნისთანავე მიაწოდოს ამონაწერი საბარათეანგარიშიდან.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >27. პროვაიდერი უფლებამოსილია მომხმარებლის წინასწარი გაფრთხილების/შეტყობინების გარეშე:დროებით დაბლოკოს ბარათი, თუ არსებობს საფუძვლიანი ეჭვი ბარათით არასანქცირებული ოპერაციების განხორციელების შესახებ. პროვაიდერის მიერ ბარათი დაბლოკილი იქნება ტრანზაქციების გარკვევამდე, არაუმეტეს 15 კალენდარული დღისა. აღნიშნული ვადის გასვლის შემდეგ ბლოკირება უქმდება;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >28. პროვაიდერი უფლებამოსილია დახუროს ბარათი იმ შემთხვევაში, თუ მოხდა პოს ტერმინალისმეშვეობით სავაჭრო (მომსახურების) ობიექტის მიერ ან ბანკომატის მიერ ბარათის დაკავება, ამოღებული ბარათი მომხმარებელს უკან არ უბრუნდება. მოთხოვნის შემთხვევაში მომხმარებელზე გაიცემა ახალი ბარათი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >29. პროვაიდერი უფლებამოსილია ბარათის დამზადების და/ან ვადის გასვლის შესახებ მომხმარებელს მიაწოდოს ინფორმაცია SMS-ის, ელექტრონული ფოსტის ან სხვა საშუალებით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>30. პროვაიდერი ვალდებულია:</strong></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ა. მიიღოს ყველა შესაძლო ზომა ბარათის დაცულობისა და მისი არამართლზომიერი გამოყენების თავიდან აცილების უზრუნველსაყოფად;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბ. არ გახადოს ბარათის პერსონიფიცირებული უსაფრთხოების მახასიათებლები და საშუალებებიხელმისაწვდომი სხვა პირებისათვის, გარდა ამ ბარათის მომხმარებლისა;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გ. უზრუნველყოს ბარათის დაკარგვის, მოპარვის, უკანონო მითვისების ან უკანონო გამოყენების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ფაქტის შესახებ შეტყობინების მიღება უშუალოდ მომხმარებლისგან. მოთხოვნის შემთხვევაში</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პროვაიდერი ვალდებულია მიაწოდოს მას შეტყობინების მიღების დასტური, თუ შეტყობინების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მიღებიდან გასული არ არის 18 თვეზე მეტი;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დ. ზემოაღნიშნული შეტყობინების მიღებისთანავე, დაუყოვნებლივ უზრუნველყოს ბარათის შემდგომი გამოყენების აღკვეთა სისტემური დაბლოკვის გზით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >30.1. განიხილოს მომხმარებლის საჩივარი განხორციელებულ ოპერაციასთან დაკვაშირებით და</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >მიიღოს გადაწყვეტილება და გააცნოს იგი მომხმარებელს საჩივრის მიღებიდან არაუგვიანეს 15</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >(თხუთმეტი) სამუშაო დღისა. საჩივრის მიღების დღედ ითვლება მომხმარებლის მიერ</span> <span >პროვაიდერისთვის საჩივრის წარდგენის სამუშაო დღე.</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >30.1.2. იმ შემთხვევაში, თუ პროვაიდერისაგან დამოუკიდებელი მიზეზების გამო მითითებულ 15</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >(თხუთმეტი) დღიან ვადაში ვერ ხერხდება საჩივრის განხილვა და მასზე გადაწყვეტილების მიღება,</span><span >პროვაიდერი ვალდებულია ამის შესახებ წერილობითი შეტყობინებით აცნობოს მომხმარებელს</span> <span >სადაც იქნება მითითება დაგვიანების დასაბუთებული მიზეზისა და საჩივრის განხილვა/</span> <span >გადაწყვეტისთვის ვადის გაგრძელების შესახებ. საჩივარზე გადაწყვეტილების მიღებისა და საბარათე</span> <span >ინსტრუმენტის მფლობელისთვის გაცნობის ვადა არ უნდა აღემატებოდეს საჩივრის მიღებიდან 55</span> <span >სამუშაო დღეს.</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >30.1.3. მომხმარებლის მიერ ოპერაციების გასაჩივრების თითოეული შემთხვევა განიხილება</span><span >ინდივიდუალურად შესაბამისი საგადახდო სისტემების მიერ დადგენილი წესების, ადგილობრივ</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >ფინანსურ სექტორში დამკვიდრებული პრაქტიკის და მოქმედი კანონმდებლობის</span> <span >გათვალისწინებით;</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >30.1.4. საჩივრების და პრეტენზიების მიღება ხორციელდება ყოველ სამუშაო დღეს 10:00 საათიდან</span> <span >18:00 საათამდე, შემდეგი საშუალებებით: ცხელ ხაზთან დაკავშირებით, ელ-ფოსტით: info@nbc.ge ან</span><span >წერილობითი ფორმით.</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >31. ბარათის ან/და მისი პერსონიფიცირებული უსაფრთხოების მახასიათებლებისა და საშუალებებისფოსტით გაგზავნის შემთხვევაში, პროვაიდერი საკუთარ თავზე იღებს მათ გაგზავნასთან დაკავშირებულ ყველა რისკს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >32. მომხმარებელი ვალდებულია, ბარათი გამოიყენოს დადგენილი პირობების შესაბამისად, დაიცვასმისთვის გაცემული ბარათის უსაფრთხოების ზომები, უზრუნველყოს ამ ინსტრუმენტის</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პერსონიფიცირებული საშუალებების დაცვა, ბარათის დაკარგვის შემთხვევაში დაუყოვნებლივ</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >აცნობოს პროვაიდერს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >33. მომხმარებელი უფლებამოსილია, მოსთხოვოს პროვაიდერს საქართველოს ტერიტორიაზე</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >არაავტორიზებული ან არასწორად განხორციელებული ოპერაციის შესაბამისი თანხის ანაზღაურება იმ შემთხვევაში, თუ არაავტორიზებული ოპერაციის შესრულების თარიღიდან არ გასულა 40 დღეზე მეტი ან არასწორად განხორციელებული ოპერაციის შესრულების თარიღიდან არ გასულა 180 დღეზე მეტი და მომხმარებელმა აღნიშნული ოპერაციის შესახებ დაუყოვნებლივ შეატყობინა პროვაიდერს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >34. მომხმარებელი პასუხისმგებელია მოპარული/დაკარგული ბარათით საქართველოს ტერიტორიაზე განხორციელებული არაავტორიზებული ოპერაციის შედეგად წარმოშობილი ზიანისთვის არა უმეტეს 100 ლარისა, გარდა კანონით გათვალისწინებული შემთხვევებისა.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომხმარებელი სრულად აგებს პასუხს გადახდის ოპერაციასთან დაკავშირებულ იმ ზიანისთვის,</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >რომელიც გამოწვეულია მისი ბრალეულობით, მის მიერ წინამდებარე დოკუმენტში მითითებულიპირობების/უსაფრთხოების წესების განზრახ ან დაუდევრობით შეუსრულებლობით. თუმცა ეს არათავისუფლებს პროვაიდერს ვალდებულებისაგან, თავისი შესაძლებლობის ფარგლებში დაეხმაროსმომხმარებელს არაავტორიზებული ან არასწორად გადარიცხული თანხის დაბრუნებაში.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >35. პროვაიდერი პასუხისმგებელია მომხმარებლის მიერ პროვაიდერისთვის შეტყობინების შემდეგშესრულებულ არაავტორიზებულ გადახდის ოპერაციაზე, თუ პროვაიდერის მიერ არ მოხდა შეტყობინების მიღება და დროული რეაგირება ბარათის შემდგომი გამოყენების აღკვეთასთან დაკავშირებით ან პერსონიფიცირებული უსაფრთხოების დაუცველობით გამოწვეული არაავტორიზებული ოპერაციის შედეგად წარმოშობილი ზიანისთვის, თუ ეს ზიანი გამოწვეული არ არის მომხმარებლის დანაშაულებრივი ან გაზრახ ქმედებით ან დაუდევრობით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >36. პროვაიდერი არ არის პასუხისმგებელი არაავტორიზებული ოპერაციის შედეგად წარმოშობილიზიანისთვის, თუ მომხმარებელი ბარათის დაკარგვის, მოპარვის ან უკანონო გამოყენების ფაქტისაღმოჩენის შემთხვევაში დაუყოვნებლივ არ შეატყობინებს პროვაიდერს წინამდებარე დოკუმენტისშესაბამისად, ან დაარღვევს აღნიშნული მუხლით გათვალისწინებულ რომელიმე ვალდებულებას, ან თუ ეს ზიანი გამოწვეულია მომხმარებლის დანაშაულებრივი ან განზრახი ქმედებით/დაუდევრობით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >37. ბარათზე მომხმარებლის/ბარათის მფლობელის მიერ შესაძლებელია განხორციელდეს თაღლითობის მაღალი რისკის მქონე სავაჭრო/მომსახურების ობიექტებში გადახდების დაშვება/დაბლოკვა;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >38. მომხმარებელი უფლებამოსილია საბარათე ოპერაციებზე დაწესებული შეზღუდვების გაუქმების და 38.1. მუხლში განსაზღვრულ MCC კოდებზე ოპერაციების დაშვების მიზნით მიმართოს პროვაიდერს განაცხადით ნებისმიერ დროს, დადგენილი წესების შესაბამისად.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >38.1. ენბისი არ არის პასუხისმგებელი: მითითებულ MCC კოდებზე შეზღუდულ ოპერაციებზე, კლიენტის მომართვის საფუძველზე ოპერაციების დაშვების შემდეგ, კლიენტისათვის დამდგარ</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ნებისმიერ ზიანზე/ზარალზე. ბარათით, კლიენტის დამატებითი თანხმობის გარეშე არ დაიშვება ტრანზაქციები შემდეგ MCC კოდებზე:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >(ა)5967 - პირდაპირი მარკეტინგი, +18 სერვისი;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >39. პლასტიკური ბარათის გააქტიურებისას დაბლოკილია თაღლითობის მაღალი რისკის მქონე</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სავაჭრო/მომსახურების ობიექტებში გადახდები. რაც ნიშნავს, რომ ბარათის გააქტიურებისას პროვაიდერის მიერ დაბლოკილია თაღლითობის მაღალი რისკის მქონე სავაჭრო/მომსახურების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ობიექტებში გადახდები. პროვაიდერი უფლებამოსილია ცალმხრივად, ნებისმიერ დროს დაამატოს და/ან შეამციროს მაღალი რისკის მქონე სავაჭრო/მომსახურების ობიექტების ჩამონათვალი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >40. მოთხოვნის შემთხვევაში, როგორც ამონაწერით ასევე სხვა საშუალებით მომხმარებლისთვის</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ძირითად ინფორმაციასთან ერთად ხელმისაწვდომია ინფორმაცია სავაჭრო/მომსახურების ობიექტისდასახელებისა და MCC- შესახებ (ექვაირერი უზრუნველყოფს სავაჭრო/მომსახურების ობიექტისთვის მისი საქმიანობის შესაბამისი MCC-ის მინიჭებას).</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >41. მომხმარებელი უფლებამოსილია ნებისმიერ დროს ბარათზე გააქტიუროს/დაბლოკოს თაღლითობის მაღალი რისკის მქონე სავაჭრო/მომსახურების ობიექტებში გადახდები.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >42. თაღლითობის მაღალი რისკის მქონე სავაჭრო/მომსახურების ობიექტებში გადახდების შეზღუდვაემსახურება მომხმარებლის დაცვას შესაძლო თაღლითობის და/ან სხვა სახის მომეტებულისაფრთხეებიდან, ხოლო მისი გააქტიურებით მომხმარებელი აცხადებს, რომ სრულად აქვსგაცნობიერებული გააქტიურებასთან დაკავშირებული რისკები.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >43. ბარათზე ნებისმიერი ცვლილება ხორციელდება მომხმარებლის წერილობითი განაცხადისსაფუძველზე, ცვლილების მოთხოვნით მომხმარებელს შეუძლია მიმართოს ნებისმიერმომსახურებისპუნქტს. გამონაკლისი არის მხოლოდ ბარათის დაბლოკვა და სტოპ-სიაში ჩასმა, რომლ(ებ)იცშესაძლებელია სატელეფონო ზარის საშუალებით, შემდეგ ტელეფონის ნომერზე: *+995 032 2 193293</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>44. ბარათთან დაკავშირებული უსაფრთხოების მექანიზმები:</strong></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.1. წინამდებარე მუხლში მოცემულია როგორც პროვაიდერის მიერ გატარებული და დანერგილიუსაფრთხოების ზომები, ასევე მითითებები, რომელთა გათვალისწინება მომხმარებელს დაეხმარებაბარათთან დაკავშირებული რისკების თავიდან აცილებაში.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.2. პინ-კოდი:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.2.1. პინ-კოდი წარმოადგენს პერსონალური იდენტიფიკაციის 4-ციფრიან ნომერს. პინ-კოდი არისკონფიდენციალური. ის მხოლოდ მომხმარებლისთვის არის ცნობილი და ევალება დაიცვას მისისაიდუმლოება. უსაფრთხოების თვალსაზრისით აკრძალულია:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; პინ-კოდის სხვა პირებისთვის, მათ შორის ნათესავებისთვის, პროვაიდერის თანამშრომლებისთვის,სავაჭრო და მომსახურების ობიექტების თანამშრომლებისთვის გამჟღავნება;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; პინ-კოდის ჩანიშვნა ან ჩაწერა, მათ შორის რაიმე ფორმით ბარათზე მითითება;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; პინ-კოდისა და ბარათის ერთად ტარება;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; პინ-კოდის გამჟღავნება ელ. ფოსტის, ტელეფონისა და კომუნიკაციის სხვა საშუალებების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გამოყენებით;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; პინ-კოდის გამოყენება, გარდა ბანკომატებისა და პოს-ტერმინალებისა.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.2.2. აღნიშნული მითითებების გათვალისწინება მომხმარებელს დაეხმარება შეამციროს ბარათისგაყალბების და/ან ფულადი სახსრების დაკარგვის რისკი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.2.3. პროვაიდერი რეგულარულად ამოწმებს არსებული უსაფრთხოების სისტემების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გამართულობასა და ეფექტურობას, რომელთა განახლებაც ხდება პერიოდულად;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.3. ბარათის სავაჭრო ობიექტებში, ბანკომატში გამოყენება:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.3.1. სავაჭრო/მომსახურების ობიექტებში შენაძენის საფასურის ბარათით გადახდა ხორციელდება პოს-ტერმინალის მეშვეობით. პოს-ტერმინალით გადახდისას ბარათის მფლობელმა/მომხმარებელმატრანზაქცია უნდა დაადასტუროს პინ-კოდით, რაც ზრდის მომხმარებლისა და სავაჭრო/მომსახურების ობიექტის თანხების უსაფრთხოების ხარისხს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; მომხმარებელმა აუცილებელია მოითხოვოს, რომ სავაჭრო/მომსახურების ობიექტებში ბარათითსაგადამხდელო ოპერაციები განხორციელდეს მისი თანდასწრებით და არ გაატანოს ბარათი მომსახურე პერსონალს;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; შენაძენის საფასურის გადახდის დროს ტრანზაქციის დადასტურებისას მომხმარებელმა უნდა</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გააკონტროლოს ქვითარზე მითითებული თანხის, ვალუტისა და თარიღის სისწორე და მოითხოვოს ქვითრის ასლი. მომხმარებელი უნდა დარწმუნდეს, რომ პინ-კოდის შეყვანისას მისი</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >კონფიდენციალურობის უფლებები არ ირღვევა;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; ბანკომატში ბარათის გამოსაყენებლად აუცილებელია პინ-კოდის შეყვანა. ბანკომატი არ უნდა იყოსგარეგნულად დაზიანებული და ბანკომატის კორპუსზე არ უნდა იყოს დამონტაჟებული დამატებითიმოწყობილობები;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; მომხმარებელი ვალდებულია დაიცვას პერსონალური იდენტიფიკაციის ნებისმიერი და ყველა</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მონაცემის კონფიდენციალურობა და უზრუნველყოს აღნიშნული მონაცემებისა და ელექტრონულიმოწყობილობების უსაფრთხოება.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.3.2. უსაფრთხოების მიზნით აკრძალულია:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; ბარათის გადაცემა სხვა პირისთვის ან ბარათზე არსებული ინფორმაციის გამჟღავნება, ვინაიდანგაცემული ინფორმაცია საკმარისი იქნება არა ავტორიზებული შესყიდვებისთვის.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.3.3. უსაფრთხოების მიზნით მიზანშეწონილია:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; მომხმარებელმა მუდმივად გააკონტროლოს ბარათის შენახვის ადგილი და ასევე, მის ანგარიშზეთანხების ბრუნვა. კონტროლი შესაძლებელია ლიდერქარდის მობილური აპლიკაციის მეშვეობით;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; ლიდერქარდის მობილური აპლიკაციის მეშვეობით მომხმარებელს საშუალება ეძლევ აკონტროლოსაქტივობები ონლაინ:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; გამოიყენოს ლიდერქარდის სერვისები მარტივად</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; შეამოწმოს ბალანსზე არსებული ნაშთები და გაუწიოს კონტროლი</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull;გადაიტანოს თანხა ლიდერბეთის ანგარიშიდან ლიდერქარდზე (ოპერაცია ხორციელდება</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომხმარებლის მიერ შპს &bdquo;ენ ბი სი&ldquo;-სგან ვერიფიცირებულ მობილურის ნომერზე მიღებული</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ერთჯერადი კოდის (OTP) შესაბამის ველში მითითებით)</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; შეამოწმოს ტრანზაქციების ისტორია</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; მართოს ლიდერქარდის ბარათი: შეცვალო ბარათის პინი (ოპერაცია ხორციელდება მომხმარებლის მიერ შპს &bdquo;ენ ბი სი&ldquo;-სგან ვერიფიცირებულ მობილურის ნომერზე მიღებული ერთჯერადი კოდის (OTP) შესაბამის ველში მითითებით)</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; მუდმივად თვალი ადევნოს ლიდერქარდის სიახლეებს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >აპლიკაციით სარგებლობისთვის მომხმარებელმა საჭიროა გაიაროს ავტორიზაცია, შეავსოს ველები:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომხმარებელი და პაროლი. ლიდერქარდის მობილური აპლიკაციის გამოყენება მხოლოდ</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ავტორიზაციის შედეგად არის შესაძლებელი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull;მომხმარებელმა უნდა გაითვალისწინოს, რომ პროვაიდერი თავისი ინიციატივით არასდროს</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დაუკავშირდება მომხმარებელს ბარათის სრული ნომრის, პინ-კოდის ან სხვა კოდების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დასაზუსტებლად.</span></span></span></p>
        
        
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >45. ზემოაღნიშნული მოთხოვნების შესრულება დაეხმარება მომხმარებელს უსაფრთხოდ გამოიყენოსპლასტიკური ბარათი. ნებისმიერი შეკითხვის შემთხვევაში, მომხმარებელს შეუძლია დაუკავშირდეს</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ცხელ ხაზს: *+995 032 2 193293 (10:00 საათიდან 22:00 საათამდე) ან <a href="mailto:info@nbc.ge" style="color:#954f72; text-decoration:underline">info@nbc.ge</a> &nbsp;შეტყობინებით; ასევედეტალური ინფორმაცია შეგიძლიათ იხილოთ პროვაიდერის ოფიციალურ ვებგვერდზე:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline">www.leadercard.ge</a> &nbsp;.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >45.1. პლასტიკური ბარათის დაბლოკვა ხდება ბარათის დაკარგვის, მოპარვის შემთხვევაში და ასევე თუ პერსონალური იდენტიფიკაციის ნებისმიერი მონაცემი პინ-კოდი და სხვა ინფორმაცია ცნობილი გახდა მესამე პირთათვის. მომხმარებელი ვალდებულია დაუყოვნებლივ მიმართოს პროვაიდერს და მიაწოდოს ინფორმაცია ბარათის დაბლოკვის მიზნით. ყოველი ზეპირი განაცხადი უნდა დადასტურდეს პროვაიდერის მომსახურების პუნქტში მისვლით და წერილობითი განცხადებით (წერილობითი განცხადება პროვაიდერთან უნდა დაფიქსირდეს ზეპირი განცხადებიდან 1 (ერთი) სამუშაო დღის განმავლობაში;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >45.2. მომხმარებლის მიერ ბარათთან და/ან ბარათით შესრულებულ ოპერაციებთან დაკავშირებითპროვაიდერის მიერ დადგენილი უსაფრთხოების ვალდებულებების, რეკომენდაციების და/ან მითითებების შეუსრულებლობის შემთხვევაში, მომხმარებლის ან მესამე პირების მიმართ დამდგარ ნებისმიერ ზიანზე სრულადაა პასუხისმგებელი თავად მომხმარებელი.</span></span></span></p>
        `,
        Eng: `
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>საგადახდო მომსახურების ხელშეკრულების დანართი N1 - საგადახდო ბარათის გამოყენების წესი</strong></span></span></span></p>

        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1. მომხმარებელი არის ბარათის მფლობელი პირი, რომელიც უკვეთავს ბარათს და დებს შესაბამისხელშეკრულებას პროვაიდერთან.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.მომხმარებელს ელექტრონული საფულის მართვის პანელთან წვდომა შეუძლია როგორც პროვაიდერის ვებ-გვერდიდან, ასევე მობილური აპლიკაციის დახმარებით, რაც მომხმარებელს</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >საშუალებას აძლევს მარტივად მოახდინოს ბარათით განხორციელებული ტრანზაქციების კონტროლი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3. Leader Card -ი არის ლოკალური ტიპის წინასწარი გადახდის ბარათი. ლიდერქარდით სარგებლობაშესაძლებელია მხოლოდ საქართველოს ტერიტორიაზე. ბარათით ოპერაციები სრულდება მხოლოდწინასწარ ჩარიცხული თანხის ფარგლებში (საკრედიტო ლიმიტი არ დაიშვება);</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>ბარათზე მითითებულია ბარათის ნომერი, ბარათის მოქმედების ვადა და შემდეგი აუცილებელი</strong><strong>ინფორმაცია:</strong></span></span></span></p>
        
        <ul>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">დატანილია პროვაიდერის დასახელება და ლოგო - NBC;</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">დატანილია ლოკალური ბარათის სახელწოდება leader card და ლოგო, გამოყენებულია</span></span></li>
        </ul>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ყვითელი ტონები;</span></span></span></p>
        
        <ul>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ჩიპი მიკროსქემა, რომელზეც ჩაწერილია მონაცემები (ბარათის ჩიპის ნომერი Optelio</span></span></li>
        </ul>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >Contactless D16 R9 FA PURE C180);</span></span></span></p>
        
        <ul>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">უკონტაქტო ბარათის აღმნიშვნელი სიმბოლო;</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათის ნომერი - 16 ნიშნიანი ნომერი;</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">მოქმედების ვადა ამოტვიფრულია შემდეგი ფორმატით: თვე / წელი</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">მითითებულია ცხელი ხაზის ნომერი, პროვაიდერის მისამართი, ვებგვერდის მისამართი</span></span></li>
        </ul>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>ბარათის მომსახურების ტარიფები</strong></span></span></span></p>
        
        <ul>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათის მოქმედების ვადა (წელი) - 4 წელი</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათის საფასური - უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">თანხის განაღდება &bdquo;საქართველოს ბანკისა &ldquo;და &bdquo;თიბისი ბანკის&ldquo;ბანკომატების - უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათით გადახდის ოპერაციის საკომისიო -უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">თანხის გადატანა ლიდერბეთის ანგარიშიდან ლიდერქარდზე - უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათის პინ-კოდის ცვლილება - უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათის დაბლოკვა - უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">საბარათე ანგარიში ტიპი ერთ ვალუტაში - ლარი</span></span></li>
        </ul>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4. მომხმარებლებს სტანდარტულად სისტემა ანიჭებს განაღდების და გადახდის ლიმიტს 24 საათისგანმავლობაში, რომელიც განაღდების შემთხვევაში წარმოადგენს 6000 ლარს, ხოლო გადახდისშემთხვევაში 1400 ლარს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ა) ბანკომატის საშუალებით თანხის განაღდების ლიმიტი 24 (ოცდაოთხი) საათის განმავლობაში</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >შეადგენს 6000 (ექვსი ათას) ლარს;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბ) პოს-ტერმინალით შესრულებული გადახდების დღიური ლიმიტი 24 (ოცდაოთხი) საათის</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >განმავლობაში შეადგენს 1400 (ათას ოთხას) ლარს;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გ) ბარათებზე ასევე დაწესებულია ტრანზაქციების რაოდენობრივი ლიმიტი: 24 საათის განმავლობაში - 10 (ათი) ტრანზაქცია, ხოლო თვის განმავლობაში 250 (ორას ორმოცდაათი) ტრანზაქცია.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5. ბარათს აქვს პინ კოდი. პინ-კოდი არის ბარათის თანმხლები პერსონალური, კონფიდენციალური კოდი, რომელიც ბარათთან ერთად წარმოადგენს მომხმარებლის ელექტრონულ იდენტიფიკატორს. იგი გამოიყენება ბანკომატებსა და პოს-ტერმინალებში ტრანზაქციების შესასრულებლად; (ბანკომატით, პოს-ტერმინალით შესრულებული ნებისმიერი ტრანზაქცია დასტურდება პინკოდით)</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6. ბარათის მომსახურების სატელეფონო ნომრის მომხმარებლის მიერ პროვაიდერთან დაფიქსირებისა და აღნიშნულ ნომერზე პლასტიკური ბარათის საწყისი პინ-კოდის მიღებასთან</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დაკავშირებული მომსახურეობის გააქტიურების შემდგომ, მომხმარებელს მითითებულ სატელეფონო ნომერზე ეგზავნება კოდი, რომელიც წარმოადგენს ბარათის კუთვნილ პინ-კოდს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >აღნიშნული პინ-კოდის საშუალებით შესაძლებელია საბარათე ოპერაციების შესრულება.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7. პინ-კოდი ცნობილია მხოლოდ მომხმარებლისთვის, რომელიც ვალდებულია დაიმახსოვროს დაუზრუნველყოს პინ-კოდის საიდუმლოება. პინ-კოდის ბარათზე რაიმე ფორმით მითითება დაუშვებელია.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >8. მომხმარებლის სურვილის შემთხვევაში, შესაძლებელია ბარათის პინ-კოდის შეცვლა:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >8.1. პინ კოდის შეცვლა ხორციელდება ბანკომატის მეშვეობით, თუ მომხმარებელს ახსოვს მიმდინარე პინ-კოდი, ბანკომატზე მისი შეყვანის შემდეგ ეძლევა ახალი პინ-კოდის მინიჭების საშუალება. პინკოდის ცვლილებასთან დაკავშირებით პასუხისმგებლობა ეკისრება მომხმარებელს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >9. ბარათი წარმოადგენს პროვაიდერის საკუთრებას. ბარათით სარგებლობის პერიოდი განისაზღვრება ბარათზე მითითებული ვადით. მოქმედების ვადა იწურება ბარათზე მითითებული თვის ბოლო დღის გასვლის შემდეგ. დაუშვებელია ბარათის მოქმედების ვადის გაზრდა/შემცირება.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10. ტერმინი &ldquo;საბარათე ანგარიში&rdquo; ნიშნავს ბარათზე მიბმულ ელექტრონული ფულის ანგარიშს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ენბისი საგადახდო მომსახურებას ახორციელებს მხოლოდ საქართველოს ტერიტორიაზე. ელექტრონულ საფულეს გააჩნია ელექტრონული ფულის ანგარიში ერთ ვალუტაში და ყველა შემთხვევაში - ლარში;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >11. ბარათით სარგებლობის პირობები და წესები განისაზღვრება წინამდებარე დოკუმენტით,</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბარათით მომსახურების ტარიფებით და საქართველოს კანონმდებლობით. დადგენილი ტარიფებისშესახებ ინფორმაცია ხელმისაწვდომია კომპანიის ოფიციალური ვებ-გვერდზე <a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline">www.leadercard.ge</a>, ასევე მომხმარებელი ვალდებულია ოპერაციის განხორციელებამდე ვებ-გვერდის მეშვეობით გაეცნოს შესაბამისი ოპერაციისთვის დადგენილ ტარიფებს;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >12. ბარათის საფასური, ასევე ბარათთან დაკავშირებული მომსახურების საფასური და ბარათით</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >განხორციელებული ტრანზაქციების საკომისიოები და მათი ოდენობები განისაზღვრება ბარათისმომსახურების ტარიფებით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13. ბარათის საფასურის, ბარათით და მასთან დაკავშირებული მომსახურების საფასურის და ბარათით განხორციელებული ტრანზაქციებისათვის დადგენილი საკომისიოების გადახდა ეკისრება მომხმარებელს (ასეთის არსებობის შემთხვევაში).</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14. მომხმარებელს შეუძლია დაუკავშირდეს ცხელ ხაზს, კითხვა-პასუხის გამოყენების მეშვეობით</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გაიაროს იდენტიფიკაცია და მიიღოს ინფორმაცია ბარათის და/ან ბარათთან დაკავშირებული ოპერაციების შესახებ და/ან დაბლოკოს ბარათი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15. ბარათზე მიმაგრებული საბარათე ანგარიშიდან ბარათის საშუალებით შესაძლებელია წარმოებულიქნეს:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; ნაღდი ოპერაცია - თანხის განაღდება &bdquo;საქართველოს ბანკისა &ldquo;და &bdquo;თიბისი ბანკის &ldquo; ბანკომატების/პოს-ტერმინალების საშუალებით;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; უნაღდო ოპერაცია - თანხის გადახდა &bdquo;საქართველოს ბანკისა &ldquo;და &bdquo;თიბისი ბანკის &ldquo;პოსტერმინალების საშუალებით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >16. ბარათით შესრულებული ფაქტობრივი ოპერაციის თარიღი, შესაძლოა განსხვავდებოდეს საბარათე ანგარიშზე მისი ასახვის თარიღისაგან, რაც დამოკიდებულია ტრანზაქციის ტიპზე და</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბანკომატების/პოსტერმინალების ქსელზე. ოპერაციის წარმატებით შესრულების შემთხვევაში</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომხამრებლის ანგარიშზე ცვლილება აისახება 5-დან 10 წამის ფარგლებში;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >17. ბარათი არ შეიძლება გამოყენებული იქნას უკანონო მიზნებისთვის, მათ შორის იმ საქონლის დამომსახურების შესაძენად, რომელიც აკრძალულია საქართველოს მოქმედი კანონმდებლობით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >18. ბარათის დაკარგვის/მოპარვის ფაქტის პროვაიდერისთვის არ შეტყობინების ან დაგვიანებით</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >შეტყობინების შემთხვევაში, პროვაიდერს არ ეკისრება მატერიალური პასუხისმგებლობა შეტყობინებამდე, დაკარგული/მოპარული ბარათით, შესრულებულ ტრანზაქციებზე.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >19. მომხმარებელს უფლება აქვს ბარათის დაკარგვის/მოპარვის შემთხვევაში მოითხოვოს ბარათის სტოპ-სიაში ჩასმა.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >20. სტოპ-სიაში შესაძლებელია ბარათების ჩასმა მხოლოდ 15 დღის ვადით. აღნიშნული ვადის</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გასვლის შემდეგ, მომხმარებლის სურვილის მიხედვით, თავიდან უნდა მოხდეს ბარათის ჩასმა სტოპსიაში.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >21. მომხმარებელს შეუძლია ბარათის დაბლოკვიდან თხუთმეტი კალენდარული დღის განმავლობაშიმოითხოვოს დაბლოკილი ბარათის განბლოკვა. ბარათის დაბლოკვიდან თხუთმეტი კალენდარული დღის გასვლის შემდეგ პროვაიდერი მომხმარებლის შეტყობინების/გაფრთხილების გარეშე ხურავს ბარათს. დახურული ბარათი არ ექვემდებარება აღდგენას.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>22. მომხმარებელი ვალდებულია:</strong></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ა. ბარათის მოქმედების ვადის ამოწურვის ან ბარათის ვადამდე დახურვის შემთხვევაში თხუთმეტიკალენდარული დღის განმავლობაში დაუბრუნოს ბარათი პროვაიდერს;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბ. შეინახოს ბარათით განხორციელებული ყველა ოპერაციის დამადასტურებელი საბუთი/ანგარიშქვითარი და რეგულარულად შეამოწმოს საბარათე ანგარიშის ამონაწერი, არანაკლებ თვეში ერთხელ.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გ. ტრანზაქციის გასაჩივრების შემთხვევაში, მიმართოს პროვაიდერს სადავო ტრანზაქციის</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >განხორციელებიდან 40 დღის ვადაში და წარუდგინოს გასაჩივრებულ ტრანზაქციასთან</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დაკავშირებული ინფორმაცია/დოკუმენტები, რაც მოიცავს, მაგრამ არ შემოიფარგლება შემდეგით:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ტრანზაქციის თარიღი, ტრანზაქციის ოდენობა, ბარათის მონაცემები, ინფორმაცია სავაჭრო ობიექტზე(რელევანტურობის შემთხვევაში), შეძენილი ნივთი და/ ან მომსახურება (რელევანტურობის შემთხვევაში), ქვითარი/გადახდის დამადასტურებელი დოკუმენტი (რელევანტურობის შემთხვევაში) და ნებისმიერი სხვა დამატებითი ინფორამცია და/ან დოკუმენტი, რასაც პროვაიდერი, საკუთარი შეხედულებისამებრ, საჭიროდ მიიჩნევს საკითხის შესწავლისა და განხილვის პროცესში.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >23. მომხმარებელი უფლებამოსილია მიიღოს ინფორმაცია ბარათით განხორციელებული ოპერაციების შესახებ;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >24. ბარათის საშუალებით შეძენილი საქონლის/მომსახურების ხარისხთან და/ან რაოდენობასთანდაკავშირებით რაიმე პრეტენზიის შემთხვევაში დავა უნდა გადაწყვიტოს მომხმარებელმა შესაბამისსავაჭრო დაწესებულებასთან.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >25. პროვაიდერი ვალდებულია დაიცვას მომხმარებლის პერსონალური მონაცემების და საბარათეანგარიშის შესახებ ინფორმაციის კონფიდენციალურობა (ჩატარებული ოპერაციები, ნაშთები და სხვ.).გამონაკლისს წარმოადგენს კანონმდებლობით გათვალისწინებული შემთხვევები.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >26. პროვაიდერი ვალდებულია მომხმარებელს მოთხოვნისთანავე მიაწოდოს ამონაწერი საბარათეანგარიშიდან.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >27. პროვაიდერი უფლებამოსილია მომხმარებლის წინასწარი გაფრთხილების/შეტყობინების გარეშე:დროებით დაბლოკოს ბარათი, თუ არსებობს საფუძვლიანი ეჭვი ბარათით არასანქცირებული ოპერაციების განხორციელების შესახებ. პროვაიდერის მიერ ბარათი დაბლოკილი იქნება ტრანზაქციების გარკვევამდე, არაუმეტეს 15 კალენდარული დღისა. აღნიშნული ვადის გასვლის შემდეგ ბლოკირება უქმდება;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >28. პროვაიდერი უფლებამოსილია დახუროს ბარათი იმ შემთხვევაში, თუ მოხდა პოს ტერმინალისმეშვეობით სავაჭრო (მომსახურების) ობიექტის მიერ ან ბანკომატის მიერ ბარათის დაკავება, ამოღებული ბარათი მომხმარებელს უკან არ უბრუნდება. მოთხოვნის შემთხვევაში მომხმარებელზე გაიცემა ახალი ბარათი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >29. პროვაიდერი უფლებამოსილია ბარათის დამზადების და/ან ვადის გასვლის შესახებ მომხმარებელს მიაწოდოს ინფორმაცია SMS-ის, ელექტრონული ფოსტის ან სხვა საშუალებით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>30. პროვაიდერი ვალდებულია:</strong></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ა. მიიღოს ყველა შესაძლო ზომა ბარათის დაცულობისა და მისი არამართლზომიერი გამოყენების თავიდან აცილების უზრუნველსაყოფად;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბ. არ გახადოს ბარათის პერსონიფიცირებული უსაფრთხოების მახასიათებლები და საშუალებებიხელმისაწვდომი სხვა პირებისათვის, გარდა ამ ბარათის მომხმარებლისა;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გ. უზრუნველყოს ბარათის დაკარგვის, მოპარვის, უკანონო მითვისების ან უკანონო გამოყენების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ფაქტის შესახებ შეტყობინების მიღება უშუალოდ მომხმარებლისგან. მოთხოვნის შემთხვევაში</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პროვაიდერი ვალდებულია მიაწოდოს მას შეტყობინების მიღების დასტური, თუ შეტყობინების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მიღებიდან გასული არ არის 18 თვეზე მეტი;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დ. ზემოაღნიშნული შეტყობინების მიღებისთანავე, დაუყოვნებლივ უზრუნველყოს ბარათის შემდგომი გამოყენების აღკვეთა სისტემური დაბლოკვის გზით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >30.1. განიხილოს მომხმარებლის საჩივარი განხორციელებულ ოპერაციასთან დაკვაშირებით და</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >მიიღოს გადაწყვეტილება და გააცნოს იგი მომხმარებელს საჩივრის მიღებიდან არაუგვიანეს 15</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >(თხუთმეტი) სამუშაო დღისა. საჩივრის მიღების დღედ ითვლება მომხმარებლის მიერ</span> <span >პროვაიდერისთვის საჩივრის წარდგენის სამუშაო დღე.</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >30.1.2. იმ შემთხვევაში, თუ პროვაიდერისაგან დამოუკიდებელი მიზეზების გამო მითითებულ 15</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >(თხუთმეტი) დღიან ვადაში ვერ ხერხდება საჩივრის განხილვა და მასზე გადაწყვეტილების მიღება,</span><span >პროვაიდერი ვალდებულია ამის შესახებ წერილობითი შეტყობინებით აცნობოს მომხმარებელს</span> <span >სადაც იქნება მითითება დაგვიანების დასაბუთებული მიზეზისა და საჩივრის განხილვა/</span> <span >გადაწყვეტისთვის ვადის გაგრძელების შესახებ. საჩივარზე გადაწყვეტილების მიღებისა და საბარათე</span> <span >ინსტრუმენტის მფლობელისთვის გაცნობის ვადა არ უნდა აღემატებოდეს საჩივრის მიღებიდან 55</span> <span >სამუშაო დღეს.</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >30.1.3. მომხმარებლის მიერ ოპერაციების გასაჩივრების თითოეული შემთხვევა განიხილება</span><span >ინდივიდუალურად შესაბამისი საგადახდო სისტემების მიერ დადგენილი წესების, ადგილობრივ</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >ფინანსურ სექტორში დამკვიდრებული პრაქტიკის და მოქმედი კანონმდებლობის</span> <span >გათვალისწინებით;</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >30.1.4. საჩივრების და პრეტენზიების მიღება ხორციელდება ყოველ სამუშაო დღეს 10:00 საათიდან</span> <span >18:00 საათამდე, შემდეგი საშუალებებით: ცხელ ხაზთან დაკავშირებით, ელ-ფოსტით: info@nbc.ge ან</span><span >წერილობითი ფორმით.</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >31. ბარათის ან/და მისი პერსონიფიცირებული უსაფრთხოების მახასიათებლებისა და საშუალებებისფოსტით გაგზავნის შემთხვევაში, პროვაიდერი საკუთარ თავზე იღებს მათ გაგზავნასთან დაკავშირებულ ყველა რისკს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >32. მომხმარებელი ვალდებულია, ბარათი გამოიყენოს დადგენილი პირობების შესაბამისად, დაიცვასმისთვის გაცემული ბარათის უსაფრთხოების ზომები, უზრუნველყოს ამ ინსტრუმენტის</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პერსონიფიცირებული საშუალებების დაცვა, ბარათის დაკარგვის შემთხვევაში დაუყოვნებლივ</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >აცნობოს პროვაიდერს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >33. მომხმარებელი უფლებამოსილია, მოსთხოვოს პროვაიდერს საქართველოს ტერიტორიაზე</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >არაავტორიზებული ან არასწორად განხორციელებული ოპერაციის შესაბამისი თანხის ანაზღაურება იმ შემთხვევაში, თუ არაავტორიზებული ოპერაციის შესრულების თარიღიდან არ გასულა 40 დღეზე მეტი ან არასწორად განხორციელებული ოპერაციის შესრულების თარიღიდან არ გასულა 180 დღეზე მეტი და მომხმარებელმა აღნიშნული ოპერაციის შესახებ დაუყოვნებლივ შეატყობინა პროვაიდერს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >34. მომხმარებელი პასუხისმგებელია მოპარული/დაკარგული ბარათით საქართველოს ტერიტორიაზე განხორციელებული არაავტორიზებული ოპერაციის შედეგად წარმოშობილი ზიანისთვის არა უმეტეს 100 ლარისა, გარდა კანონით გათვალისწინებული შემთხვევებისა.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომხმარებელი სრულად აგებს პასუხს გადახდის ოპერაციასთან დაკავშირებულ იმ ზიანისთვის,</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >რომელიც გამოწვეულია მისი ბრალეულობით, მის მიერ წინამდებარე დოკუმენტში მითითებულიპირობების/უსაფრთხოების წესების განზრახ ან დაუდევრობით შეუსრულებლობით. თუმცა ეს არათავისუფლებს პროვაიდერს ვალდებულებისაგან, თავისი შესაძლებლობის ფარგლებში დაეხმაროსმომხმარებელს არაავტორიზებული ან არასწორად გადარიცხული თანხის დაბრუნებაში.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >35. პროვაიდერი პასუხისმგებელია მომხმარებლის მიერ პროვაიდერისთვის შეტყობინების შემდეგშესრულებულ არაავტორიზებულ გადახდის ოპერაციაზე, თუ პროვაიდერის მიერ არ მოხდა შეტყობინების მიღება და დროული რეაგირება ბარათის შემდგომი გამოყენების აღკვეთასთან დაკავშირებით ან პერსონიფიცირებული უსაფრთხოების დაუცველობით გამოწვეული არაავტორიზებული ოპერაციის შედეგად წარმოშობილი ზიანისთვის, თუ ეს ზიანი გამოწვეული არ არის მომხმარებლის დანაშაულებრივი ან გაზრახ ქმედებით ან დაუდევრობით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >36. პროვაიდერი არ არის პასუხისმგებელი არაავტორიზებული ოპერაციის შედეგად წარმოშობილიზიანისთვის, თუ მომხმარებელი ბარათის დაკარგვის, მოპარვის ან უკანონო გამოყენების ფაქტისაღმოჩენის შემთხვევაში დაუყოვნებლივ არ შეატყობინებს პროვაიდერს წინამდებარე დოკუმენტისშესაბამისად, ან დაარღვევს აღნიშნული მუხლით გათვალისწინებულ რომელიმე ვალდებულებას, ან თუ ეს ზიანი გამოწვეულია მომხმარებლის დანაშაულებრივი ან განზრახი ქმედებით/დაუდევრობით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >37. ბარათზე მომხმარებლის/ბარათის მფლობელის მიერ შესაძლებელია განხორციელდეს თაღლითობის მაღალი რისკის მქონე სავაჭრო/მომსახურების ობიექტებში გადახდების დაშვება/დაბლოკვა;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >38. მომხმარებელი უფლებამოსილია საბარათე ოპერაციებზე დაწესებული შეზღუდვების გაუქმების და 38.1. მუხლში განსაზღვრულ MCC კოდებზე ოპერაციების დაშვების მიზნით მიმართოს პროვაიდერს განაცხადით ნებისმიერ დროს, დადგენილი წესების შესაბამისად.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >38.1. ენბისი არ არის პასუხისმგებელი: მითითებულ MCC კოდებზე შეზღუდულ ოპერაციებზე, კლიენტის მომართვის საფუძველზე ოპერაციების დაშვების შემდეგ, კლიენტისათვის დამდგარ</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ნებისმიერ ზიანზე/ზარალზე. ბარათით, კლიენტის დამატებითი თანხმობის გარეშე არ დაიშვება ტრანზაქციები შემდეგ MCC კოდებზე:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >(ა)5967 - პირდაპირი მარკეტინგი, +18 სერვისი;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >39. პლასტიკური ბარათის გააქტიურებისას დაბლოკილია თაღლითობის მაღალი რისკის მქონე</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სავაჭრო/მომსახურების ობიექტებში გადახდები. რაც ნიშნავს, რომ ბარათის გააქტიურებისას პროვაიდერის მიერ დაბლოკილია თაღლითობის მაღალი რისკის მქონე სავაჭრო/მომსახურების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ობიექტებში გადახდები. პროვაიდერი უფლებამოსილია ცალმხრივად, ნებისმიერ დროს დაამატოს და/ან შეამციროს მაღალი რისკის მქონე სავაჭრო/მომსახურების ობიექტების ჩამონათვალი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >40. მოთხოვნის შემთხვევაში, როგორც ამონაწერით ასევე სხვა საშუალებით მომხმარებლისთვის</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ძირითად ინფორმაციასთან ერთად ხელმისაწვდომია ინფორმაცია სავაჭრო/მომსახურების ობიექტისდასახელებისა და MCC- შესახებ (ექვაირერი უზრუნველყოფს სავაჭრო/მომსახურების ობიექტისთვის მისი საქმიანობის შესაბამისი MCC-ის მინიჭებას).</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >41. მომხმარებელი უფლებამოსილია ნებისმიერ დროს ბარათზე გააქტიუროს/დაბლოკოს თაღლითობის მაღალი რისკის მქონე სავაჭრო/მომსახურების ობიექტებში გადახდები.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >42. თაღლითობის მაღალი რისკის მქონე სავაჭრო/მომსახურების ობიექტებში გადახდების შეზღუდვაემსახურება მომხმარებლის დაცვას შესაძლო თაღლითობის და/ან სხვა სახის მომეტებულისაფრთხეებიდან, ხოლო მისი გააქტიურებით მომხმარებელი აცხადებს, რომ სრულად აქვსგაცნობიერებული გააქტიურებასთან დაკავშირებული რისკები.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >43. ბარათზე ნებისმიერი ცვლილება ხორციელდება მომხმარებლის წერილობითი განაცხადისსაფუძველზე, ცვლილების მოთხოვნით მომხმარებელს შეუძლია მიმართოს ნებისმიერმომსახურებისპუნქტს. გამონაკლისი არის მხოლოდ ბარათის დაბლოკვა და სტოპ-სიაში ჩასმა, რომლ(ებ)იცშესაძლებელია სატელეფონო ზარის საშუალებით, შემდეგ ტელეფონის ნომერზე: *+995 032 2 193293</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>44. ბარათთან დაკავშირებული უსაფრთხოების მექანიზმები:</strong></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.1. წინამდებარე მუხლში მოცემულია როგორც პროვაიდერის მიერ გატარებული და დანერგილიუსაფრთხოების ზომები, ასევე მითითებები, რომელთა გათვალისწინება მომხმარებელს დაეხმარებაბარათთან დაკავშირებული რისკების თავიდან აცილებაში.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.2. პინ-კოდი:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.2.1. პინ-კოდი წარმოადგენს პერსონალური იდენტიფიკაციის 4-ციფრიან ნომერს. პინ-კოდი არისკონფიდენციალური. ის მხოლოდ მომხმარებლისთვის არის ცნობილი და ევალება დაიცვას მისისაიდუმლოება. უსაფრთხოების თვალსაზრისით აკრძალულია:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; პინ-კოდის სხვა პირებისთვის, მათ შორის ნათესავებისთვის, პროვაიდერის თანამშრომლებისთვის,სავაჭრო და მომსახურების ობიექტების თანამშრომლებისთვის გამჟღავნება;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; პინ-კოდის ჩანიშვნა ან ჩაწერა, მათ შორის რაიმე ფორმით ბარათზე მითითება;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; პინ-კოდისა და ბარათის ერთად ტარება;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; პინ-კოდის გამჟღავნება ელ. ფოსტის, ტელეფონისა და კომუნიკაციის სხვა საშუალებების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გამოყენებით;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; პინ-კოდის გამოყენება, გარდა ბანკომატებისა და პოს-ტერმინალებისა.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.2.2. აღნიშნული მითითებების გათვალისწინება მომხმარებელს დაეხმარება შეამციროს ბარათისგაყალბების და/ან ფულადი სახსრების დაკარგვის რისკი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.2.3. პროვაიდერი რეგულარულად ამოწმებს არსებული უსაფრთხოების სისტემების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გამართულობასა და ეფექტურობას, რომელთა განახლებაც ხდება პერიოდულად;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.3. ბარათის სავაჭრო ობიექტებში, ბანკომატში გამოყენება:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.3.1. სავაჭრო/მომსახურების ობიექტებში შენაძენის საფასურის ბარათით გადახდა ხორციელდება პოს-ტერმინალის მეშვეობით. პოს-ტერმინალით გადახდისას ბარათის მფლობელმა/მომხმარებელმატრანზაქცია უნდა დაადასტუროს პინ-კოდით, რაც ზრდის მომხმარებლისა და სავაჭრო/მომსახურების ობიექტის თანხების უსაფრთხოების ხარისხს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; მომხმარებელმა აუცილებელია მოითხოვოს, რომ სავაჭრო/მომსახურების ობიექტებში ბარათითსაგადამხდელო ოპერაციები განხორციელდეს მისი თანდასწრებით და არ გაატანოს ბარათი მომსახურე პერსონალს;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; შენაძენის საფასურის გადახდის დროს ტრანზაქციის დადასტურებისას მომხმარებელმა უნდა</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გააკონტროლოს ქვითარზე მითითებული თანხის, ვალუტისა და თარიღის სისწორე და მოითხოვოს ქვითრის ასლი. მომხმარებელი უნდა დარწმუნდეს, რომ პინ-კოდის შეყვანისას მისი</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >კონფიდენციალურობის უფლებები არ ირღვევა;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; ბანკომატში ბარათის გამოსაყენებლად აუცილებელია პინ-კოდის შეყვანა. ბანკომატი არ უნდა იყოსგარეგნულად დაზიანებული და ბანკომატის კორპუსზე არ უნდა იყოს დამონტაჟებული დამატებითიმოწყობილობები;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; მომხმარებელი ვალდებულია დაიცვას პერსონალური იდენტიფიკაციის ნებისმიერი და ყველა</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მონაცემის კონფიდენციალურობა და უზრუნველყოს აღნიშნული მონაცემებისა და ელექტრონულიმოწყობილობების უსაფრთხოება.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.3.2. უსაფრთხოების მიზნით აკრძალულია:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; ბარათის გადაცემა სხვა პირისთვის ან ბარათზე არსებული ინფორმაციის გამჟღავნება, ვინაიდანგაცემული ინფორმაცია საკმარისი იქნება არა ავტორიზებული შესყიდვებისთვის.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.3.3. უსაფრთხოების მიზნით მიზანშეწონილია:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; მომხმარებელმა მუდმივად გააკონტროლოს ბარათის შენახვის ადგილი და ასევე, მის ანგარიშზეთანხების ბრუნვა. კონტროლი შესაძლებელია ლიდერქარდის მობილური აპლიკაციის მეშვეობით;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; ლიდერქარდის მობილური აპლიკაციის მეშვეობით მომხმარებელს საშუალება ეძლევ აკონტროლოსაქტივობები ონლაინ:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; გამოიყენოს ლიდერქარდის სერვისები მარტივად</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; შეამოწმოს ბალანსზე არსებული ნაშთები და გაუწიოს კონტროლი</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull;გადაიტანოს თანხა ლიდერბეთის ანგარიშიდან ლიდერქარდზე (ოპერაცია ხორციელდება</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომხმარებლის მიერ შპს &bdquo;ენ ბი სი&ldquo;-სგან ვერიფიცირებულ მობილურის ნომერზე მიღებული</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ერთჯერადი კოდის (OTP) შესაბამის ველში მითითებით)</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; შეამოწმოს ტრანზაქციების ისტორია</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; მართოს ლიდერქარდის ბარათი: შეცვალო ბარათის პინი (ოპერაცია ხორციელდება მომხმარებლის მიერ შპს &bdquo;ენ ბი სი&ldquo;-სგან ვერიფიცირებულ მობილურის ნომერზე მიღებული ერთჯერადი კოდის (OTP) შესაბამის ველში მითითებით)</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; მუდმივად თვალი ადევნოს ლიდერქარდის სიახლეებს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >აპლიკაციით სარგებლობისთვის მომხმარებელმა საჭიროა გაიაროს ავტორიზაცია, შეავსოს ველები:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომხმარებელი და პაროლი. ლიდერქარდის მობილური აპლიკაციის გამოყენება მხოლოდ</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ავტორიზაციის შედეგად არის შესაძლებელი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull;მომხმარებელმა უნდა გაითვალისწინოს, რომ პროვაიდერი თავისი ინიციატივით არასდროს</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დაუკავშირდება მომხმარებელს ბარათის სრული ნომრის, პინ-კოდის ან სხვა კოდების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დასაზუსტებლად.</span></span></span></p>
        
       
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >45. ზემოაღნიშნული მოთხოვნების შესრულება დაეხმარება მომხმარებელს უსაფრთხოდ გამოიყენოსპლასტიკური ბარათი. ნებისმიერი შეკითხვის შემთხვევაში, მომხმარებელს შეუძლია დაუკავშირდეს</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ცხელ ხაზს: *+995 032 2 193293 (10:00 საათიდან 22:00 საათამდე) ან <a href="mailto:info@nbc.ge" style="color:#954f72; text-decoration:underline">info@nbc.ge</a> &nbsp;შეტყობინებით; ასევედეტალური ინფორმაცია შეგიძლიათ იხილოთ პროვაიდერის ოფიციალურ ვებგვერდზე:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline">www.leadercard.ge</a> &nbsp;.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >45.1. პლასტიკური ბარათის დაბლოკვა ხდება ბარათის დაკარგვის, მოპარვის შემთხვევაში და ასევე თუ პერსონალური იდენტიფიკაციის ნებისმიერი მონაცემი პინ-კოდი და სხვა ინფორმაცია ცნობილი გახდა მესამე პირთათვის. მომხმარებელი ვალდებულია დაუყოვნებლივ მიმართოს პროვაიდერს და მიაწოდოს ინფორმაცია ბარათის დაბლოკვის მიზნით. ყოველი ზეპირი განაცხადი უნდა დადასტურდეს პროვაიდერის მომსახურების პუნქტში მისვლით და წერილობითი განცხადებით (წერილობითი განცხადება პროვაიდერთან უნდა დაფიქსირდეს ზეპირი განცხადებიდან 1 (ერთი) სამუშაო დღის განმავლობაში;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >45.2. მომხმარებლის მიერ ბარათთან და/ან ბარათით შესრულებულ ოპერაციებთან დაკავშირებითპროვაიდერის მიერ დადგენილი უსაფრთხოების ვალდებულებების, რეკომენდაციების და/ან მითითებების შეუსრულებლობის შემთხვევაში, მომხმარებლის ან მესამე პირების მიმართ დამდგარ ნებისმიერ ზიანზე სრულადაა პასუხისმგებელი თავად მომხმარებელი.</span></span></span></p>
        `,
        Rus:  `
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>საგადახდო მომსახურების ხელშეკრულების დანართი N1 - საგადახდო ბარათის გამოყენების წესი</strong></span></span></span></p>

        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >1. მომხმარებელი არის ბარათის მფლობელი პირი, რომელიც უკვეთავს ბარათს და დებს შესაბამისხელშეკრულებას პროვაიდერთან.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >2.მომხმარებელს ელექტრონული საფულის მართვის პანელთან წვდომა შეუძლია როგორც პროვაიდერის ვებ-გვერდიდან, ასევე მობილური აპლიკაციის დახმარებით, რაც მომხმარებელს</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >საშუალებას აძლევს მარტივად მოახდინოს ბარათით განხორციელებული ტრანზაქციების კონტროლი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >3. Leader Card -ი არის ლოკალური ტიპის წინასწარი გადახდის ბარათი. ლიდერქარდით სარგებლობაშესაძლებელია მხოლოდ საქართველოს ტერიტორიაზე. ბარათით ოპერაციები სრულდება მხოლოდწინასწარ ჩარიცხული თანხის ფარგლებში (საკრედიტო ლიმიტი არ დაიშვება);</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>ბარათზე მითითებულია ბარათის ნომერი, ბარათის მოქმედების ვადა და შემდეგი აუცილებელი</strong><strong>ინფორმაცია:</strong></span></span></span></p>
        
        <ul>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">დატანილია პროვაიდერის დასახელება და ლოგო - NBC;</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">დატანილია ლოკალური ბარათის სახელწოდება leader card და ლოგო, გამოყენებულია</span></span></li>
        </ul>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ყვითელი ტონები;</span></span></span></p>
        
        <ul>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ჩიპი მიკროსქემა, რომელზეც ჩაწერილია მონაცემები (ბარათის ჩიპის ნომერი Optelio</span></span></li>
        </ul>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >Contactless D16 R9 FA PURE C180);</span></span></span></p>
        
        <ul>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">უკონტაქტო ბარათის აღმნიშვნელი სიმბოლო;</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათის ნომერი - 16 ნიშნიანი ნომერი;</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">მოქმედების ვადა ამოტვიფრულია შემდეგი ფორმატით: თვე / წელი</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">მითითებულია ცხელი ხაზის ნომერი, პროვაიდერის მისამართი, ვებგვერდის მისამართი</span></span></li>
        </ul>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>ბარათის მომსახურების ტარიფები</strong></span></span></span></p>
        
        <ul>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათის მოქმედების ვადა (წელი) - 4 წელი</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათის საფასური - უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">თანხის განაღდება &bdquo;საქართველოს ბანკისა &ldquo;და &bdquo;თიბისი ბანკის&ldquo;ბანკომატების - უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათით გადახდის ოპერაციის საკომისიო -უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">თანხის გადატანა ლიდერბეთის ანგარიშიდან ლიდერქარდზე - უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათის პინ-კოდის ცვლილება - უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">ბარათის დაბლოკვა - უფასო</span></span></li>
        \t<li style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif">საბარათე ანგარიში ტიპი ერთ ვალუტაში - ლარი</span></span></li>
        </ul>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >4. მომხმარებლებს სტანდარტულად სისტემა ანიჭებს განაღდების და გადახდის ლიმიტს 24 საათისგანმავლობაში, რომელიც განაღდების შემთხვევაში წარმოადგენს 6000 ლარს, ხოლო გადახდისშემთხვევაში 1400 ლარს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ა) ბანკომატის საშუალებით თანხის განაღდების ლიმიტი 24 (ოცდაოთხი) საათის განმავლობაში</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >შეადგენს 6000 (ექვსი ათას) ლარს;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბ) პოს-ტერმინალით შესრულებული გადახდების დღიური ლიმიტი 24 (ოცდაოთხი) საათის</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >განმავლობაში შეადგენს 1400 (ათას ოთხას) ლარს;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გ) ბარათებზე ასევე დაწესებულია ტრანზაქციების რაოდენობრივი ლიმიტი: 24 საათის განმავლობაში - 10 (ათი) ტრანზაქცია, ხოლო თვის განმავლობაში 250 (ორას ორმოცდაათი) ტრანზაქცია.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >5. ბარათს აქვს პინ კოდი. პინ-კოდი არის ბარათის თანმხლები პერსონალური, კონფიდენციალური კოდი, რომელიც ბარათთან ერთად წარმოადგენს მომხმარებლის ელექტრონულ იდენტიფიკატორს. იგი გამოიყენება ბანკომატებსა და პოს-ტერმინალებში ტრანზაქციების შესასრულებლად; (ბანკომატით, პოს-ტერმინალით შესრულებული ნებისმიერი ტრანზაქცია დასტურდება პინკოდით)</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >6. ბარათის მომსახურების სატელეფონო ნომრის მომხმარებლის მიერ პროვაიდერთან დაფიქსირებისა და აღნიშნულ ნომერზე პლასტიკური ბარათის საწყისი პინ-კოდის მიღებასთან</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დაკავშირებული მომსახურეობის გააქტიურების შემდგომ, მომხმარებელს მითითებულ სატელეფონო ნომერზე ეგზავნება კოდი, რომელიც წარმოადგენს ბარათის კუთვნილ პინ-კოდს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >აღნიშნული პინ-კოდის საშუალებით შესაძლებელია საბარათე ოპერაციების შესრულება.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >7. პინ-კოდი ცნობილია მხოლოდ მომხმარებლისთვის, რომელიც ვალდებულია დაიმახსოვროს დაუზრუნველყოს პინ-კოდის საიდუმლოება. პინ-კოდის ბარათზე რაიმე ფორმით მითითება დაუშვებელია.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >8. მომხმარებლის სურვილის შემთხვევაში, შესაძლებელია ბარათის პინ-კოდის შეცვლა:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >8.1. პინ კოდის შეცვლა ხორციელდება ბანკომატის მეშვეობით, თუ მომხმარებელს ახსოვს მიმდინარე პინ-კოდი, ბანკომატზე მისი შეყვანის შემდეგ ეძლევა ახალი პინ-კოდის მინიჭების საშუალება. პინკოდის ცვლილებასთან დაკავშირებით პასუხისმგებლობა ეკისრება მომხმარებელს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >9. ბარათი წარმოადგენს პროვაიდერის საკუთრებას. ბარათით სარგებლობის პერიოდი განისაზღვრება ბარათზე მითითებული ვადით. მოქმედების ვადა იწურება ბარათზე მითითებული თვის ბოლო დღის გასვლის შემდეგ. დაუშვებელია ბარათის მოქმედების ვადის გაზრდა/შემცირება.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >10. ტერმინი &ldquo;საბარათე ანგარიში&rdquo; ნიშნავს ბარათზე მიბმულ ელექტრონული ფულის ანგარიშს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ენბისი საგადახდო მომსახურებას ახორციელებს მხოლოდ საქართველოს ტერიტორიაზე. ელექტრონულ საფულეს გააჩნია ელექტრონული ფულის ანგარიში ერთ ვალუტაში და ყველა შემთხვევაში - ლარში;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >11. ბარათით სარგებლობის პირობები და წესები განისაზღვრება წინამდებარე დოკუმენტით,</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბარათით მომსახურების ტარიფებით და საქართველოს კანონმდებლობით. დადგენილი ტარიფებისშესახებ ინფორმაცია ხელმისაწვდომია კომპანიის ოფიციალური ვებ-გვერდზე <a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline">www.leadercard.ge</a>, ასევე მომხმარებელი ვალდებულია ოპერაციის განხორციელებამდე ვებ-გვერდის მეშვეობით გაეცნოს შესაბამისი ოპერაციისთვის დადგენილ ტარიფებს;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >12. ბარათის საფასური, ასევე ბარათთან დაკავშირებული მომსახურების საფასური და ბარათით</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >განხორციელებული ტრანზაქციების საკომისიოები და მათი ოდენობები განისაზღვრება ბარათისმომსახურების ტარიფებით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >13. ბარათის საფასურის, ბარათით და მასთან დაკავშირებული მომსახურების საფასურის და ბარათით განხორციელებული ტრანზაქციებისათვის დადგენილი საკომისიოების გადახდა ეკისრება მომხმარებელს (ასეთის არსებობის შემთხვევაში).</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >14. მომხმარებელს შეუძლია დაუკავშირდეს ცხელ ხაზს, კითხვა-პასუხის გამოყენების მეშვეობით</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გაიაროს იდენტიფიკაცია და მიიღოს ინფორმაცია ბარათის და/ან ბარათთან დაკავშირებული ოპერაციების შესახებ და/ან დაბლოკოს ბარათი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >15. ბარათზე მიმაგრებული საბარათე ანგარიშიდან ბარათის საშუალებით შესაძლებელია წარმოებულიქნეს:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; ნაღდი ოპერაცია - თანხის განაღდება &bdquo;საქართველოს ბანკისა &ldquo;და &bdquo;თიბისი ბანკის &ldquo; ბანკომატების/პოს-ტერმინალების საშუალებით;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; უნაღდო ოპერაცია - თანხის გადახდა &bdquo;საქართველოს ბანკისა &ldquo;და &bdquo;თიბისი ბანკის &ldquo;პოსტერმინალების საშუალებით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >16. ბარათით შესრულებული ფაქტობრივი ოპერაციის თარიღი, შესაძლოა განსხვავდებოდეს საბარათე ანგარიშზე მისი ასახვის თარიღისაგან, რაც დამოკიდებულია ტრანზაქციის ტიპზე და</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბანკომატების/პოსტერმინალების ქსელზე. ოპერაციის წარმატებით შესრულების შემთხვევაში</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომხამრებლის ანგარიშზე ცვლილება აისახება 5-დან 10 წამის ფარგლებში;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >17. ბარათი არ შეიძლება გამოყენებული იქნას უკანონო მიზნებისთვის, მათ შორის იმ საქონლის დამომსახურების შესაძენად, რომელიც აკრძალულია საქართველოს მოქმედი კანონმდებლობით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >18. ბარათის დაკარგვის/მოპარვის ფაქტის პროვაიდერისთვის არ შეტყობინების ან დაგვიანებით</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >შეტყობინების შემთხვევაში, პროვაიდერს არ ეკისრება მატერიალური პასუხისმგებლობა შეტყობინებამდე, დაკარგული/მოპარული ბარათით, შესრულებულ ტრანზაქციებზე.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >19. მომხმარებელს უფლება აქვს ბარათის დაკარგვის/მოპარვის შემთხვევაში მოითხოვოს ბარათის სტოპ-სიაში ჩასმა.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >20. სტოპ-სიაში შესაძლებელია ბარათების ჩასმა მხოლოდ 15 დღის ვადით. აღნიშნული ვადის</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გასვლის შემდეგ, მომხმარებლის სურვილის მიხედვით, თავიდან უნდა მოხდეს ბარათის ჩასმა სტოპსიაში.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >21. მომხმარებელს შეუძლია ბარათის დაბლოკვიდან თხუთმეტი კალენდარული დღის განმავლობაშიმოითხოვოს დაბლოკილი ბარათის განბლოკვა. ბარათის დაბლოკვიდან თხუთმეტი კალენდარული დღის გასვლის შემდეგ პროვაიდერი მომხმარებლის შეტყობინების/გაფრთხილების გარეშე ხურავს ბარათს. დახურული ბარათი არ ექვემდებარება აღდგენას.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>22. მომხმარებელი ვალდებულია:</strong></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ა. ბარათის მოქმედების ვადის ამოწურვის ან ბარათის ვადამდე დახურვის შემთხვევაში თხუთმეტიკალენდარული დღის განმავლობაში დაუბრუნოს ბარათი პროვაიდერს;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბ. შეინახოს ბარათით განხორციელებული ყველა ოპერაციის დამადასტურებელი საბუთი/ანგარიშქვითარი და რეგულარულად შეამოწმოს საბარათე ანგარიშის ამონაწერი, არანაკლებ თვეში ერთხელ.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გ. ტრანზაქციის გასაჩივრების შემთხვევაში, მიმართოს პროვაიდერს სადავო ტრანზაქციის</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >განხორციელებიდან 40 დღის ვადაში და წარუდგინოს გასაჩივრებულ ტრანზაქციასთან</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დაკავშირებული ინფორმაცია/დოკუმენტები, რაც მოიცავს, მაგრამ არ შემოიფარგლება შემდეგით:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ტრანზაქციის თარიღი, ტრანზაქციის ოდენობა, ბარათის მონაცემები, ინფორმაცია სავაჭრო ობიექტზე(რელევანტურობის შემთხვევაში), შეძენილი ნივთი და/ ან მომსახურება (რელევანტურობის შემთხვევაში), ქვითარი/გადახდის დამადასტურებელი დოკუმენტი (რელევანტურობის შემთხვევაში) და ნებისმიერი სხვა დამატებითი ინფორამცია და/ან დოკუმენტი, რასაც პროვაიდერი, საკუთარი შეხედულებისამებრ, საჭიროდ მიიჩნევს საკითხის შესწავლისა და განხილვის პროცესში.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >23. მომხმარებელი უფლებამოსილია მიიღოს ინფორმაცია ბარათით განხორციელებული ოპერაციების შესახებ;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >24. ბარათის საშუალებით შეძენილი საქონლის/მომსახურების ხარისხთან და/ან რაოდენობასთანდაკავშირებით რაიმე პრეტენზიის შემთხვევაში დავა უნდა გადაწყვიტოს მომხმარებელმა შესაბამისსავაჭრო დაწესებულებასთან.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >25. პროვაიდერი ვალდებულია დაიცვას მომხმარებლის პერსონალური მონაცემების და საბარათეანგარიშის შესახებ ინფორმაციის კონფიდენციალურობა (ჩატარებული ოპერაციები, ნაშთები და სხვ.).გამონაკლისს წარმოადგენს კანონმდებლობით გათვალისწინებული შემთხვევები.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >26. პროვაიდერი ვალდებულია მომხმარებელს მოთხოვნისთანავე მიაწოდოს ამონაწერი საბარათეანგარიშიდან.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >27. პროვაიდერი უფლებამოსილია მომხმარებლის წინასწარი გაფრთხილების/შეტყობინების გარეშე:დროებით დაბლოკოს ბარათი, თუ არსებობს საფუძვლიანი ეჭვი ბარათით არასანქცირებული ოპერაციების განხორციელების შესახებ. პროვაიდერის მიერ ბარათი დაბლოკილი იქნება ტრანზაქციების გარკვევამდე, არაუმეტეს 15 კალენდარული დღისა. აღნიშნული ვადის გასვლის შემდეგ ბლოკირება უქმდება;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >28. პროვაიდერი უფლებამოსილია დახუროს ბარათი იმ შემთხვევაში, თუ მოხდა პოს ტერმინალისმეშვეობით სავაჭრო (მომსახურების) ობიექტის მიერ ან ბანკომატის მიერ ბარათის დაკავება, ამოღებული ბარათი მომხმარებელს უკან არ უბრუნდება. მოთხოვნის შემთხვევაში მომხმარებელზე გაიცემა ახალი ბარათი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >29. პროვაიდერი უფლებამოსილია ბარათის დამზადების და/ან ვადის გასვლის შესახებ მომხმარებელს მიაწოდოს ინფორმაცია SMS-ის, ელექტრონული ფოსტის ან სხვა საშუალებით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>30. პროვაიდერი ვალდებულია:</strong></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ა. მიიღოს ყველა შესაძლო ზომა ბარათის დაცულობისა და მისი არამართლზომიერი გამოყენების თავიდან აცილების უზრუნველსაყოფად;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ბ. არ გახადოს ბარათის პერსონიფიცირებული უსაფრთხოების მახასიათებლები და საშუალებებიხელმისაწვდომი სხვა პირებისათვის, გარდა ამ ბარათის მომხმარებლისა;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გ. უზრუნველყოს ბარათის დაკარგვის, მოპარვის, უკანონო მითვისების ან უკანონო გამოყენების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ფაქტის შესახებ შეტყობინების მიღება უშუალოდ მომხმარებლისგან. მოთხოვნის შემთხვევაში</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პროვაიდერი ვალდებულია მიაწოდოს მას შეტყობინების მიღების დასტური, თუ შეტყობინების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მიღებიდან გასული არ არის 18 თვეზე მეტი;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დ. ზემოაღნიშნული შეტყობინების მიღებისთანავე, დაუყოვნებლივ უზრუნველყოს ბარათის შემდგომი გამოყენების აღკვეთა სისტემური დაბლოკვის გზით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >30.1. განიხილოს მომხმარებლის საჩივარი განხორციელებულ ოპერაციასთან დაკვაშირებით და</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >მიიღოს გადაწყვეტილება და გააცნოს იგი მომხმარებელს საჩივრის მიღებიდან არაუგვიანეს 15</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >(თხუთმეტი) სამუშაო დღისა. საჩივრის მიღების დღედ ითვლება მომხმარებლის მიერ</span> <span >პროვაიდერისთვის საჩივრის წარდგენის სამუშაო დღე.</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >30.1.2. იმ შემთხვევაში, თუ პროვაიდერისაგან დამოუკიდებელი მიზეზების გამო მითითებულ 15</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >(თხუთმეტი) დღიან ვადაში ვერ ხერხდება საჩივრის განხილვა და მასზე გადაწყვეტილების მიღება,</span><span >პროვაიდერი ვალდებულია ამის შესახებ წერილობითი შეტყობინებით აცნობოს მომხმარებელს</span> <span >სადაც იქნება მითითება დაგვიანების დასაბუთებული მიზეზისა და საჩივრის განხილვა/</span> <span >გადაწყვეტისთვის ვადის გაგრძელების შესახებ. საჩივარზე გადაწყვეტილების მიღებისა და საბარათე</span> <span >ინსტრუმენტის მფლობელისთვის გაცნობის ვადა არ უნდა აღემატებოდეს საჩივრის მიღებიდან 55</span> <span >სამუშაო დღეს.</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >30.1.3. მომხმარებლის მიერ ოპერაციების გასაჩივრების თითოეული შემთხვევა განიხილება</span><span >ინდივიდუალურად შესაბამისი საგადახდო სისტემების მიერ დადგენილი წესების, ადგილობრივ</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >ფინანსურ სექტორში დამკვიდრებული პრაქტიკის და მოქმედი კანონმდებლობის</span> <span >გათვალისწინებით;</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><span >30.1.4. საჩივრების და პრეტენზიების მიღება ხორციელდება ყოველ სამუშაო დღეს 10:00 საათიდან</span> <span >18:00 საათამდე, შემდეგი საშუალებებით: ცხელ ხაზთან დაკავშირებით, ელ-ფოსტით: info@nbc.ge ან</span><span >წერილობითი ფორმით.</span></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >31. ბარათის ან/და მისი პერსონიფიცირებული უსაფრთხოების მახასიათებლებისა და საშუალებებისფოსტით გაგზავნის შემთხვევაში, პროვაიდერი საკუთარ თავზე იღებს მათ გაგზავნასთან დაკავშირებულ ყველა რისკს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >32. მომხმარებელი ვალდებულია, ბარათი გამოიყენოს დადგენილი პირობების შესაბამისად, დაიცვასმისთვის გაცემული ბარათის უსაფრთხოების ზომები, უზრუნველყოს ამ ინსტრუმენტის</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >პერსონიფიცირებული საშუალებების დაცვა, ბარათის დაკარგვის შემთხვევაში დაუყოვნებლივ</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >აცნობოს პროვაიდერს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >33. მომხმარებელი უფლებამოსილია, მოსთხოვოს პროვაიდერს საქართველოს ტერიტორიაზე</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >არაავტორიზებული ან არასწორად განხორციელებული ოპერაციის შესაბამისი თანხის ანაზღაურება იმ შემთხვევაში, თუ არაავტორიზებული ოპერაციის შესრულების თარიღიდან არ გასულა 40 დღეზე მეტი ან არასწორად განხორციელებული ოპერაციის შესრულების თარიღიდან არ გასულა 180 დღეზე მეტი და მომხმარებელმა აღნიშნული ოპერაციის შესახებ დაუყოვნებლივ შეატყობინა პროვაიდერს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >34. მომხმარებელი პასუხისმგებელია მოპარული/დაკარგული ბარათით საქართველოს ტერიტორიაზე განხორციელებული არაავტორიზებული ოპერაციის შედეგად წარმოშობილი ზიანისთვის არა უმეტეს 100 ლარისა, გარდა კანონით გათვალისწინებული შემთხვევებისა.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომხმარებელი სრულად აგებს პასუხს გადახდის ოპერაციასთან დაკავშირებულ იმ ზიანისთვის,</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >რომელიც გამოწვეულია მისი ბრალეულობით, მის მიერ წინამდებარე დოკუმენტში მითითებულიპირობების/უსაფრთხოების წესების განზრახ ან დაუდევრობით შეუსრულებლობით. თუმცა ეს არათავისუფლებს პროვაიდერს ვალდებულებისაგან, თავისი შესაძლებლობის ფარგლებში დაეხმაროსმომხმარებელს არაავტორიზებული ან არასწორად გადარიცხული თანხის დაბრუნებაში.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >35. პროვაიდერი პასუხისმგებელია მომხმარებლის მიერ პროვაიდერისთვის შეტყობინების შემდეგშესრულებულ არაავტორიზებულ გადახდის ოპერაციაზე, თუ პროვაიდერის მიერ არ მოხდა შეტყობინების მიღება და დროული რეაგირება ბარათის შემდგომი გამოყენების აღკვეთასთან დაკავშირებით ან პერსონიფიცირებული უსაფრთხოების დაუცველობით გამოწვეული არაავტორიზებული ოპერაციის შედეგად წარმოშობილი ზიანისთვის, თუ ეს ზიანი გამოწვეული არ არის მომხმარებლის დანაშაულებრივი ან გაზრახ ქმედებით ან დაუდევრობით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >36. პროვაიდერი არ არის პასუხისმგებელი არაავტორიზებული ოპერაციის შედეგად წარმოშობილიზიანისთვის, თუ მომხმარებელი ბარათის დაკარგვის, მოპარვის ან უკანონო გამოყენების ფაქტისაღმოჩენის შემთხვევაში დაუყოვნებლივ არ შეატყობინებს პროვაიდერს წინამდებარე დოკუმენტისშესაბამისად, ან დაარღვევს აღნიშნული მუხლით გათვალისწინებულ რომელიმე ვალდებულებას, ან თუ ეს ზიანი გამოწვეულია მომხმარებლის დანაშაულებრივი ან განზრახი ქმედებით/დაუდევრობით.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >37. ბარათზე მომხმარებლის/ბარათის მფლობელის მიერ შესაძლებელია განხორციელდეს თაღლითობის მაღალი რისკის მქონე სავაჭრო/მომსახურების ობიექტებში გადახდების დაშვება/დაბლოკვა;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >38. მომხმარებელი უფლებამოსილია საბარათე ოპერაციებზე დაწესებული შეზღუდვების გაუქმების და 38.1. მუხლში განსაზღვრულ MCC კოდებზე ოპერაციების დაშვების მიზნით მიმართოს პროვაიდერს განაცხადით ნებისმიერ დროს, დადგენილი წესების შესაბამისად.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >38.1. ენბისი არ არის პასუხისმგებელი: მითითებულ MCC კოდებზე შეზღუდულ ოპერაციებზე, კლიენტის მომართვის საფუძველზე ოპერაციების დაშვების შემდეგ, კლიენტისათვის დამდგარ</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ნებისმიერ ზიანზე/ზარალზე. ბარათით, კლიენტის დამატებითი თანხმობის გარეშე არ დაიშვება ტრანზაქციები შემდეგ MCC კოდებზე:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >(ა)5967 - პირდაპირი მარკეტინგი, +18 სერვისი;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >39. პლასტიკური ბარათის გააქტიურებისას დაბლოკილია თაღლითობის მაღალი რისკის მქონე</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >სავაჭრო/მომსახურების ობიექტებში გადახდები. რაც ნიშნავს, რომ ბარათის გააქტიურებისას პროვაიდერის მიერ დაბლოკილია თაღლითობის მაღალი რისკის მქონე სავაჭრო/მომსახურების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ობიექტებში გადახდები. პროვაიდერი უფლებამოსილია ცალმხრივად, ნებისმიერ დროს დაამატოს და/ან შეამციროს მაღალი რისკის მქონე სავაჭრო/მომსახურების ობიექტების ჩამონათვალი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >40. მოთხოვნის შემთხვევაში, როგორც ამონაწერით ასევე სხვა საშუალებით მომხმარებლისთვის</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ძირითად ინფორმაციასთან ერთად ხელმისაწვდომია ინფორმაცია სავაჭრო/მომსახურების ობიექტისდასახელებისა და MCC- შესახებ (ექვაირერი უზრუნველყოფს სავაჭრო/მომსახურების ობიექტისთვის მისი საქმიანობის შესაბამისი MCC-ის მინიჭებას).</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >41. მომხმარებელი უფლებამოსილია ნებისმიერ დროს ბარათზე გააქტიუროს/დაბლოკოს თაღლითობის მაღალი რისკის მქონე სავაჭრო/მომსახურების ობიექტებში გადახდები.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >42. თაღლითობის მაღალი რისკის მქონე სავაჭრო/მომსახურების ობიექტებში გადახდების შეზღუდვაემსახურება მომხმარებლის დაცვას შესაძლო თაღლითობის და/ან სხვა სახის მომეტებულისაფრთხეებიდან, ხოლო მისი გააქტიურებით მომხმარებელი აცხადებს, რომ სრულად აქვსგაცნობიერებული გააქტიურებასთან დაკავშირებული რისკები.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >43. ბარათზე ნებისმიერი ცვლილება ხორციელდება მომხმარებლის წერილობითი განაცხადისსაფუძველზე, ცვლილების მოთხოვნით მომხმარებელს შეუძლია მიმართოს ნებისმიერმომსახურებისპუნქტს. გამონაკლისი არის მხოლოდ ბარათის დაბლოკვა და სტოპ-სიაში ჩასმა, რომლ(ებ)იცშესაძლებელია სატელეფონო ზარის საშუალებით, შემდეგ ტელეფონის ნომერზე: *+995 032 2 193293</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><strong>44. ბარათთან დაკავშირებული უსაფრთხოების მექანიზმები:</strong></span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.1. წინამდებარე მუხლში მოცემულია როგორც პროვაიდერის მიერ გატარებული და დანერგილიუსაფრთხოების ზომები, ასევე მითითებები, რომელთა გათვალისწინება მომხმარებელს დაეხმარებაბარათთან დაკავშირებული რისკების თავიდან აცილებაში.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.2. პინ-კოდი:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.2.1. პინ-კოდი წარმოადგენს პერსონალური იდენტიფიკაციის 4-ციფრიან ნომერს. პინ-კოდი არისკონფიდენციალური. ის მხოლოდ მომხმარებლისთვის არის ცნობილი და ევალება დაიცვას მისისაიდუმლოება. უსაფრთხოების თვალსაზრისით აკრძალულია:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; პინ-კოდის სხვა პირებისთვის, მათ შორის ნათესავებისთვის, პროვაიდერის თანამშრომლებისთვის,სავაჭრო და მომსახურების ობიექტების თანამშრომლებისთვის გამჟღავნება;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; პინ-კოდის ჩანიშვნა ან ჩაწერა, მათ შორის რაიმე ფორმით ბარათზე მითითება;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; პინ-კოდისა და ბარათის ერთად ტარება;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; პინ-კოდის გამჟღავნება ელ. ფოსტის, ტელეფონისა და კომუნიკაციის სხვა საშუალებების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გამოყენებით;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; პინ-კოდის გამოყენება, გარდა ბანკომატებისა და პოს-ტერმინალებისა.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.2.2. აღნიშნული მითითებების გათვალისწინება მომხმარებელს დაეხმარება შეამციროს ბარათისგაყალბების და/ან ფულადი სახსრების დაკარგვის რისკი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.2.3. პროვაიდერი რეგულარულად ამოწმებს არსებული უსაფრთხოების სისტემების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გამართულობასა და ეფექტურობას, რომელთა განახლებაც ხდება პერიოდულად;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.3. ბარათის სავაჭრო ობიექტებში, ბანკომატში გამოყენება:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.3.1. სავაჭრო/მომსახურების ობიექტებში შენაძენის საფასურის ბარათით გადახდა ხორციელდება პოს-ტერმინალის მეშვეობით. პოს-ტერმინალით გადახდისას ბარათის მფლობელმა/მომხმარებელმატრანზაქცია უნდა დაადასტუროს პინ-კოდით, რაც ზრდის მომხმარებლისა და სავაჭრო/მომსახურების ობიექტის თანხების უსაფრთხოების ხარისხს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; მომხმარებელმა აუცილებელია მოითხოვოს, რომ სავაჭრო/მომსახურების ობიექტებში ბარათითსაგადამხდელო ოპერაციები განხორციელდეს მისი თანდასწრებით და არ გაატანოს ბარათი მომსახურე პერსონალს;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; შენაძენის საფასურის გადახდის დროს ტრანზაქციის დადასტურებისას მომხმარებელმა უნდა</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >გააკონტროლოს ქვითარზე მითითებული თანხის, ვალუტისა და თარიღის სისწორე და მოითხოვოს ქვითრის ასლი. მომხმარებელი უნდა დარწმუნდეს, რომ პინ-კოდის შეყვანისას მისი</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >კონფიდენციალურობის უფლებები არ ირღვევა;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; ბანკომატში ბარათის გამოსაყენებლად აუცილებელია პინ-კოდის შეყვანა. ბანკომატი არ უნდა იყოსგარეგნულად დაზიანებული და ბანკომატის კორპუსზე არ უნდა იყოს დამონტაჟებული დამატებითიმოწყობილობები;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; მომხმარებელი ვალდებულია დაიცვას პერსონალური იდენტიფიკაციის ნებისმიერი და ყველა</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მონაცემის კონფიდენციალურობა და უზრუნველყოს აღნიშნული მონაცემებისა და ელექტრონულიმოწყობილობების უსაფრთხოება.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.3.2. უსაფრთხოების მიზნით აკრძალულია:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; ბარათის გადაცემა სხვა პირისთვის ან ბარათზე არსებული ინფორმაციის გამჟღავნება, ვინაიდანგაცემული ინფორმაცია საკმარისი იქნება არა ავტორიზებული შესყიდვებისთვის.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >44.3.3. უსაფრთხოების მიზნით მიზანშეწონილია:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; მომხმარებელმა მუდმივად გააკონტროლოს ბარათის შენახვის ადგილი და ასევე, მის ანგარიშზეთანხების ბრუნვა. კონტროლი შესაძლებელია ლიდერქარდის მობილური აპლიკაციის მეშვეობით;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; ლიდერქარდის მობილური აპლიკაციის მეშვეობით მომხმარებელს საშუალება ეძლევ აკონტროლოსაქტივობები ონლაინ:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; გამოიყენოს ლიდერქარდის სერვისები მარტივად</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; შეამოწმოს ბალანსზე არსებული ნაშთები და გაუწიოს კონტროლი</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull;გადაიტანოს თანხა ლიდერბეთის ანგარიშიდან ლიდერქარდზე (ოპერაცია ხორციელდება</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომხმარებლის მიერ შპს &bdquo;ენ ბი სი&ldquo;-სგან ვერიფიცირებულ მობილურის ნომერზე მიღებული</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ერთჯერადი კოდის (OTP) შესაბამის ველში მითითებით)</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; შეამოწმოს ტრანზაქციების ისტორია</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; მართოს ლიდერქარდის ბარათი: შეცვალო ბარათის პინი (ოპერაცია ხორციელდება მომხმარებლის მიერ შპს &bdquo;ენ ბი სი&ldquo;-სგან ვერიფიცირებულ მობილურის ნომერზე მიღებული ერთჯერადი კოდის (OTP) შესაბამის ველში მითითებით)</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull; მუდმივად თვალი ადევნოს ლიდერქარდის სიახლეებს.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >აპლიკაციით სარგებლობისთვის მომხმარებელმა საჭიროა გაიაროს ავტორიზაცია, შეავსოს ველები:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >მომხმარებელი და პაროლი. ლიდერქარდის მობილური აპლიკაციის გამოყენება მხოლოდ</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ავტორიზაციის შედეგად არის შესაძლებელი.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >&bull;მომხმარებელმა უნდა გაითვალისწინოს, რომ პროვაიდერი თავისი ინიციატივით არასდროს</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დაუკავშირდება მომხმარებელს ბარათის სრული ნომრის, პინ-კოდის ან სხვა კოდების</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >დასაზუსტებლად.</span></span></span></p>
        
        
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >45. ზემოაღნიშნული მოთხოვნების შესრულება დაეხმარება მომხმარებელს უსაფრთხოდ გამოიყენოსპლასტიკური ბარათი. ნებისმიერი შეკითხვის შემთხვევაში, მომხმარებელს შეუძლია დაუკავშირდეს</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >ცხელ ხაზს: *+995 032 2 193293 (10:00 საათიდან 22:00 საათამდე) ან <a href="mailto:info@nbc.ge" style="color:#954f72; text-decoration:underline">info@nbc.ge</a> &nbsp;შეტყობინებით; ასევედეტალური ინფორმაცია შეგიძლიათ იხილოთ პროვაიდერის ოფიციალურ ვებგვერდზე:</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span ><a href="http://www.leadercard.ge/" style="color:#954f72; text-decoration:underline">www.leadercard.ge</a> &nbsp;.</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >45.1. პლასტიკური ბარათის დაბლოკვა ხდება ბარათის დაკარგვის, მოპარვის შემთხვევაში და ასევე თუ პერსონალური იდენტიფიკაციის ნებისმიერი მონაცემი პინ-კოდი და სხვა ინფორმაცია ცნობილი გახდა მესამე პირთათვის. მომხმარებელი ვალდებულია დაუყოვნებლივ მიმართოს პროვაიდერს და მიაწოდოს ინფორმაცია ბარათის დაბლოკვის მიზნით. ყოველი ზეპირი განაცხადი უნდა დადასტურდეს პროვაიდერის მომსახურების პუნქტში მისვლით და წერილობითი განცხადებით (წერილობითი განცხადება პროვაიდერთან უნდა დაფიქსირდეს ზეპირი განცხადებიდან 1 (ერთი) სამუშაო დღის განმავლობაში;</span></span></span></p>
        
        <p style="text-align:justify"><span style="font-size:11pt"><span style="font-family:Calibri,sans-serif"><span >45.2. მომხმარებლის მიერ ბარათთან და/ან ბარათით შესრულებულ ოპერაციებთან დაკავშირებითპროვაიდერის მიერ დადგენილი უსაფრთხოების ვალდებულებების, რეკომენდაციების და/ან მითითებების შეუსრულებლობის შემთხვევაში, მომხმარებლის ან მესამე პირების მიმართ დამდგარ ნებისმიერ ზიანზე სრულადაა პასუხისმგებელი თავად მომხმარებელი.</span></span></span></p>
        `
    }
}