const locations = [
    {
        "id": 1,
        "position": {
            "lat": "41.725265",
            "lng": "44.746200"
        },
        "city": {
            Geo: "თბილისი",
            Eng: "Tbilisi",
            Rus: "Тбилиси",
        },
        "address": {
            Geo: "დელისი - ვაჟა ფშაველას გამზ №46",
            Eng: "Delisi - №46 Vazha Pshavela ave.",
            Rus: "Делиси - проспект Важа Пшавела, №46",
        },
        "phone": "032 2 19 55 00 / 032 2 40 11 40",
        "workingHours": "11:00 - 20:00",
    },
    {
        "id": 2,
        "position": {
            "lat": "41.74904590807158",
            "lng": "44.77782299864513"
        },
        "city": {
            Geo: "თბილისი",
            Eng: "Tbilisi",
            Rus: "Тбилиси",
        },
        "address": {
            Geo: "დიდუბე - წერეთლის №141ა (ტრანსპორტის №1)",
            Eng: "Didube - №141a Akaki Tsereteli Street (Transport street №1)",
            Rus: "Дидубе - ул. Акакия Церетели, №141а (Транспортная ул. №1)",
        },
        "phone": "032 2 19 55 00 / 032 2 40 11 40",
        "workingHours": "11:00 - 20:00",
    },
    {
        "id": 3,
        "position": {
            "lat": "41.565049",
            "lng": "44.976075"
        },
        "city": {
            Geo: "რუსთავი",
            Eng: "Rustavi",
            Rus: "Рустави",
        },
        "address": {
            Geo: "ჟიული  შარტავას გამზ №7",
            Eng: "№7 Shartava ave.",
            Rus: "№7 проспект Шартава",
        },
        "phone": "032 2 19 55 00 / 032 2 40 11 40",
        "workingHours": "10:00 - 19:00",
    },
    {
        "id": 4,
        "position": {
            "lat": "41.46811448433321",
            "lng": "45.086843373444395"
        },
        "city": {
            Geo: "გარდაბანი",
            Eng: "Gardabani",
            Rus: "Гардабани",
        },
        "address": {
            Geo: "დავით აღმაშენებლის ქუჩა №46",
            Eng: "D. Agmashenebeli str. №46",
            Rus: "Д. Агмашенебели №46"
        },
        "phone": "032 2 19 55 00 / 032 2 40 11 40",
        "workingHours": "11:00 - 18:20",
    },
    {
        "id": 5,
        "position": {
            "lat": "41.982218",
            "lng": "44.1117"
        },
        "city": {
            Geo: "გორი",
            Eng: "Gori",
            Rus: "Гори",
        },
        "address": {
            Geo: "ჭავჭავაძის გამზ. №24",
            Eng: "№24 Chavchavadze ave.",
            Rus: "№24 проспект Чавчавадзе",
        },
        "phone": "032 2 19 55 00 / 032 2 40 11 40",
        "workingHours": "11:00 - 19:00",
    },
    {
        "id": 6,
        "position": {
            "lat": "41.993296",
            "lng": "43.598208"
        },
        "city": {
            Geo: "ხაშური",
            Eng: "Khashuri",
            Rus: "Хашури",
        },
        "address": {
            Geo: "რუსთაველის ქ. №42",
            Eng: "№42 Rustaveli str.",
            Rus: "№42 ул. Руставели",
        },
        "phone": "032 2 19 55 00 / 032 2 40 11 40",
        "workingHours": "11:00 - 19:00",
    },
    {
        "id": 7,
        "position": {
            "lat": "42.333908",
            "lng": "43.404629"
        },
        "city": {
            Geo: "საჩხერე",
            Eng: "Sachkhere",
            Rus: "Сачхере",
        },
        "address": {
            Geo: "ივ. გომართელის №2",
            Eng: "№2 Gomarteli str.",
            Rus: "N2 ул. Гомартели",
        },
        "phone": "032 2 19 55 00 / 032 2 40 11 40",
        "workingHours": "11:00 - 20:00",
    },
    {
        "id": 8,
        "position": {
            "lat": "42.256362",
            "lng": "42.674343"
        },
        "city": {
            Geo: "ქუთაისი",
            Eng: "Kutaisi",
            Rus: "Кутаиси",
        },
        "address": {
            Geo: "ჭავჭავაძის გამზ. №67",
            Eng: "№67 Chavchavadze ave.",
            Rus: "Пр. Чавчавадзе №67",
        },
        "phone": "032 2 19 55 00 / 032 2 40 11 40",
        "workingHours": "11:00 - 19:00",
    },
    {
        "id": 9,
        "position": {
            "lat": "42.161204",
            "lng": "42.341941"
        },
        "city": {
            Geo: "სამტრედია",
            Eng: "Samtredia",
            Rus: "Самтредиа",
        },
        "address": {
            Geo: "თამარ მეფის ქუჩა №1, ფართი №1,№2",
            Eng: "№1 Tamar Mepe str.",
            Rus: "№1 ул. Тамар Мепе",
        },
        "phone": "032 2 19 55 00 / 032 2 40 11 40",
        "workingHours": "11:00 - 19:00",
    },
    {
        "id": 10,
        "position": {
            "lat": "42.269327212225264",
            "lng": "42.06417587244063"
        },
        "city": {
            Geo: "სენაკი",
            Eng: "Senaki",
            Rus: "Сенаки",
        },
        "address": {
            Geo: "რუსთაველის  ქ. №166",
            Eng: "Rustaveli str. №166",
            Rus: "Руставели ул. №166",
        },
        "phone": "032 2 19 55 00 / 032 2 40 11 40",
        "workingHours": "11:00 - 19:00",
    },
    {
        "id": 11,
        "position": {
            "lat": "42.507553",
            "lng": "41.868576"
        },
        "city": {
            Geo: "ზუგდიდი",
            Eng: "Zugdidi",
            Rus: "Зугдиди",
        },
        "address": {
            Geo: "რუსთაველის ქ. №93",
            Eng: "№93 Rustaveli str.",
            Rus: "№93 ул. Руставели"
        },
        "phone": "032 2 19 55 00 / 032 2 40 11 40",
        "workingHours": "11:00 - 19:00",
    },
    {
        "id": 12,
        "position": {
            "lat": "42.14469360550007",
            "lng": "41.676417562945815"
        },
        "city": {
            Geo: "ფოთი",
            Eng: "Poti",
            Rus: "Поти",
        },
        "address": {
            Geo: "ჭანტურიას №3",
            Eng: "№3 Giorgi Chanturia Street",
            Rus: "Ул. Георгия Чантурия, №3",
        },
        "phone": "032 2 19 55 00 / 032 2 40 11 40",
        "workingHours": "11:00 - 19:00",
    },
    {
        "id": 13,
        "position": {
            "lat": "41.646812",
            "lng": "41.642590"
        },
        "city": {
            Geo: "ბათუმი",
            Eng: "Batumi",
            Rus: "Батуми",
        },
        "address": {
            Geo: "ჭავჭავაძის ქ. №18",
            Eng: "№18 Chavchavadze ave.",
            Rus: "Проспект Чавчавадзе №18",
        },
        "phone": "032 2 19 55 00 / 032 2 40 11 40",
        "workingHours": "11:00 - 20:00",
    },
    {
        "id": 14,
        "position": {
            "lat": "41.7930415085971",
            "lng": "44.81635332944769"
        },
        "city": {
            Geo: "თბილისი",
            Eng: "Tbilisi",
            Rus: "Тбилиси",
        },
        "address": {
            Geo: "გლდანი - ხიზანიშვილის №30",
            Eng: "Gldani – №30 Khizanishvili street",
            Rus: "Глдани - улица Хизанишвили №30",
        },
        "phone": "032 2 19 55 00 / 032 2 40 11 40",
        "workingHours": "11:00 - 20:00",
    },
    {
        "id": 15,
        "position": {
            "lat": "41.81683566319025",
            "lng": "44.77257185581184"
        },
        "city": {
            Geo: "თბილისი",
            Eng: "Tbilisi",
            Rus: "Тбилиси",
        },
        "address": {
            Geo: "თბილისი მოლი - 16 კმ აღმაშენებლის ხეივანი",
            Eng: "Tbilisi Mall 16 km from Agmashenebeli Avenue",
            Rus: "Tbilisi Mall 16 км от проспекта Агмашенебели",
        },
        "phone": "032 2 19 32 93",
        "workingHours": "12:00 - 20:00",
    },
    {
        "id": 16,
        "position": {
            "lat": "41.6944691",
            "lng": "44.8989083"
        },
        "city": {
            Geo: "თბილისი",
            Eng: "Tbilisi",
            Rus: "Тбилиси",
        },
        "address": {
            Geo: "ისთ ფოინთი - ა. თვალჭრელიძის ქუჩა №2",
            Eng: "East Point Al. Tvalchrelidze №2",
            Rus: "Ист-Пойнт Ал. Твалчрелидзе №2",
        },
        "phone": "032 2 19 32 93",
        "workingHours": "12:00 - 20:00",
    },
    {
        "id": 17,
        "position": {
            "lat": "41.72323733986361",
            "lng": "44.794609134574536"
        },
        "city": {
            Geo: "თბილისი",
            Eng: "Tbilisi",
            Rus: "Тбилиси",
        },
        "address": {
            Geo: "ვაგზალი - გ. ცაბაძის №19ა",
            Eng: "Vagzali №19 G. Tsabadze str.",
            Rus: "Вагзали №19 ул. Г. Цабадзе",
        },
        "phone": "032 2 19 32 93",
        "workingHours": "10:00 - 22:00",
    },
    {
        "id": 18,
        "position": {
            "lat": "41.69081748605083",
            "lng": "44.870288257036826"
        },
        "city": {
            Geo: "თბილისი",
            Eng: "Tbilisi",
            Rus: "Тбилиси",
        },
        "address": {
            Geo: "ვარკეთილი  ჯავახეთის №39 (საქართველოს ბანკის გვერდით)",
            Eng: "Varketili – N39 Javakheti str.",
            Rus: "Варкетили - ул. Джавахети №39.",
        },
        "phone": "032 2 19 55 00 / 032 2 40 11 40",
        "workingHours": "11:00 - 20:00",
    },
    {
        "id": 19,
        "position": {
            "lat": "41.72400342306595",
            "lng": "44.73780105234443"
        },
        "city": {
            Geo: "თბილისი",
            Eng: "Tbilisi",
            Rus: "Тбилиси",
        },
        "address": {
            Geo: "სითი მოლი - პეტრე ქავთარაძის ქ. №1",
            Eng: "City Mall Saburtalo  №1 Petre Kavtaradze str.",
            Rus: "Сити Молл Сабуртало ул. Петре Кавтарадзе, №1",
        },
        "phone": "032 2 19 32 93",
        "workingHours": "12:00 - 20:00",
    },
]


export default locations;