import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	contentContainerGrid: {
		width: "100%",
		margin: "auto",
		marginTop: 86,
		[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
			minHeight: 'max(calc(100vh - 296px), 700px)',
		}
	},
	welcomeBannerContainer: {
		height: 400,
		marginBottom: 100,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			height: 'auto'
		},
	},
	featuresContainer: {
		// marginBottom: 20,
		height: '100%',
		margin: '0 auto',
	}
}));

export default useStyles;
