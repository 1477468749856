import React, { useMemo, useState, useCallback, useContext } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import useStyles from "../styles/components/ChangePasswordStyles";
import TextFieldPassword from "./TextFieldPassword";
import useLanguage from "../hooks/useLanguage";
import SMSModal from "./Modals/SMSModal";
import useAuth from "../hooks/services/useAuth";
import {UserContext} from "../contexts/UserContext";
import StatusModal from "./Modals/StatusModal";
import SuccessStatusSVGR from "../smart-assets/SVGRs/SuccessStatusSVGR";

function ChangePassword(props) {
    const classes = useStyles(props);
    const { pages: { dashboard: { profile: { resetPassword: STRINGS } } } } = useLanguage();

    const { user: { personal_id: personalId } } = useContext(UserContext);
    console.log("pid",personalId);

    const { requestPasswordRecoverySMS, authPasswordRecovery, firstPasswordChange} = useAuth();

    // const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [currentlyRequesting, setCurrentlyRequesting] = useState('');

    const [showStatusModal, setShowStatusModal] = useState(false);

    const [SMSModalOpen, setSMSModalOpen] = useState(false);
    const [smsCode, setSMSCode] = useState('');
    const [smsCodeStatus, setSMSCodeStatus] = useState('');
    const [smsCodeTimeoutKey, setSMSCodeTimeoutKey] = useState(null);
    const [smsCodeError, setSMSCodeErorr] = useState('');
    const [sentSMSAt, setSentSMSAt] = useState(null);
    const [smsTimeout, setSMSTimeout] = useState(0);

    const _sendSMS = useCallback(async (physicalID) => {
        console.log(physicalID)
        if (!sentSMSAt || (new Date().getTime() - sentSMSAt.getTime()) / 1000 > smsTimeout) {
            const data = await requestPasswordRecoverySMS(physicalID);
            console.log('data', data);
            if (data.status === 200) {
                setSentSMSAt(new Date());
            }
            setSMSTimeout(data.data.data);
            if (smsCodeTimeoutKey) {
                clearTimeout(smsCodeTimeoutKey)
            }
            const key = setTimeout(() => {
                setSentSMSAt(false)
            }, data.data.data * 1000);
            setSMSCodeTimeoutKey(key);
        }
    }, [requestPasswordRecoverySMS, sentSMSAt, smsCodeTimeoutKey, smsTimeout])

    const changeUserPassword = () => {
        setCurrentlyRequesting(true);
        // request here
        setSMSModalOpen(true);
        _sendSMS(personalId);
        setCurrentlyRequesting(false);
    }

    const fields = useMemo(() => [
        // {
        //     backendName: 'currentPassword',
        //     component: TextFieldPassword,
        //     label: STRINGS.currentPassword,
        //     value: currentPassword,
        //     setValue: setCurrentPassword,
        //     inputProps: { minLength: 6 },
        // },
        {
            backendName: 'newPassword',
            component: TextFieldPassword,
            label: STRINGS.newPassword,
            validate: () => {},
            value: newPassword,
            setValue: setNewPassword,
            inputProps: { minLength: 6 },
        },
        {
            backendName: 'repeatNewPassword',
            component: TextFieldPassword,
            label: STRINGS.repeatNewPassword,
            validate: () => {},
            value: repeatNewPassword,
            setValue: setRepeatNewPassword,
            inputProps: { minLength: 6 },
        },
    ], [newPassword, setNewPassword, repeatNewPassword, setRepeatNewPassword, STRINGS]);

    return (
        <Grid item container xs={12} className={classes.container} alignItems="flex-start">
            <Grid item xs={12} className={classes.informationLine}>
                <hr />
                <Typography variant="body1" className={classes.informationLineText}>
                    {STRINGS.title}
                </Typography>
                <hr />
            </Grid >

            {fields.map(field => (
                <Grid key={field.backendName} xs={12} item className={classes.textFieldContainer}>
                    <field.component {...field} />
                    {field.bottom}
                </Grid>
            ))}

            <Grid item xs={12} className={classes.changePasswordButtonContainer}>
                <Button 
                // disabled={!(currentPassword.length >= 6 && currentPassword !== newPassword && newPassword === repeatNewPassword) || currentlyRequesting} onClick={changeUserPassword} className={classes.setPasswordButton}>
                disabled={!((newPassword.length >= 6 && newPassword === repeatNewPassword) || currentlyRequesting)} onClick={changeUserPassword} className={classes.setPasswordButton}>
                    <Typography className={classes.setPasswrodButtonText}>
                        {STRINGS.buttonText}
                    </Typography>
                </Button>
            </Grid>

            <StatusModal text={STRINGS.statusModal.text}
                buttonText={STRINGS.statusModal.buttonText}
                onButtonPress={() => {
                    setNewPassword('');
                    setRepeatNewPassword('');
                    setShowStatusModal(false);
                }}
                SVGR={SuccessStatusSVGR}
                isOpen={showStatusModal}
                onClose={() => {
                    setNewPassword('');
                    setRepeatNewPassword('');
                    setShowStatusModal(false);
                }} />

            <SMSModal isOpen={SMSModalOpen}
                smsCodeStatus={smsCodeStatus}
                setSMSCodeStatus={setSMSCodeStatus}
                smsCode={smsCode}
                setSMSCode={setSMSCode}
                smsSentAt={sentSMSAt}
                setSentSMSAt={setSentSMSAt}
                smsTimeout={smsTimeout}
                setSMSCodeErorr={setSMSCodeErorr}
                smsCodeError={smsCodeError}
                _sendSMS={() => _sendSMS(personalId)}
                STRINGS={STRINGS.SMSModal}
                onClose={(val) => {
                    console.log('val', val)
                    setSMSModalOpen(false)
                }}
                onButtonPress={ async () => {
                    const authRes = await authPasswordRecovery(personalId, smsCode);
                    console.log('authRes', authRes)
                    if(authRes.status === 0){
                        const changeRres = await firstPasswordChange(newPassword, repeatNewPassword);
                        console.log('changeRes', changeRres) 
                        setSMSModalOpen(false)
                        setShowStatusModal(true);
                    } else if (authRes.error === "SMS_VERIFY_ERROR") {
                        setSMSCodeErorr(STRINGS.SMSModal.wrongSMSCode)
                    }
                }}
            />
        </Grid>
    );
}

export default ChangePassword;
