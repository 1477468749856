import * as React from "react"

function RadioSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={20}
            height={20}
            viewBox="0 0 20 20"
            {...props}
        >
            <circle data-name="Ellipse 28" cx={10} cy={10} r={10} fill="#26314e" />
        </svg>
    )
}

export default RadioSVGR
