import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    customCheckbox: {
        '& .MuiSvgIcon-root ': {
            borderRadius: '100%',
            background: '#26314e',
        },
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
            backgroundColor: 'transparent',
        }
    }
}));

export default useStyles;
