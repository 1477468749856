import React, { useMemo } from "react";
import { Box, Select, FormControl, InputLabel, Typography, Button } from "@material-ui/core";
import useStyles from "../styles/components/AtmLocationsListStyles";
import CityNotSelected from "../smart-assets/SVGRs/CityNotSelectedSVGR";
import useLanguage from "../hooks/useLanguage";

const AtmLocationsList = (props) => {
    const classes = useStyles(props);
    const { points, onSelect, onClick, selectedCity, zoomIsDefault } = props;
    const sortedPoints = useMemo(() => ([...points].filter((v, i, a) => a.findIndex(t => (t.properties.city === v.properties.city)) === i).sort((a, b) => a.properties.city < b.properties.city ? -1 : 1)), [points])
    const { pages: { atms: { atmLocationsList: STRINGS } } } = useLanguage();

    return (
        <Box className={classes.container}>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">{STRINGS.city}</InputLabel>
                <Select
                    native
                    value={selectedCity}
                    onChange={(val) => {
                        const selected = points.find(city => city.properties.city === val.target.value);
                        onSelect(selected, 'city');
                    }
                    }
                    label={STRINGS.city}
                >
                    {!selectedCity ? <option aria-label="None" value="" className={classes.option} /> : null}
                    {sortedPoints.map(({ id, properties: { city } }) => (
                        <option value={city} key={id} className={classes.option}>{city}</option>
                    ))}
                </Select>
            </FormControl>
            {!zoomIsDefault || selectedCity ?
                <Button className={classes.clearFilterButton} onClick={() => {
                    onSelect(null)
                }}>
                    {STRINGS.clearFilter}
                </Button>
                : null
            }

            {
                selectedCity ?
                    <Box className={classes.list}>
                        {points.filter(location => location.properties.city.includes(selectedCity)).map(({ id, properties: { address, city, phone, workingHours } }) => (
                            <Box key={id} xs={12} className={classes.location} onClick={() => onClick(points.find(point => point.id === id), 'address')}>
                                <Typography variant="body2" className={classes.text}>
                                    {city}
                                    <br />
                                    {address}
                                    <br />
                                    {phone}
                                    <br />
                                    {workingHours}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    :
                    <Box className={classes.cityNotSelected}>
                        <CityNotSelected />
                        <Typography variant="body2" className={classes.cityNotSelectedText}>
                            {STRINGS.pickCity}
                            <br />
                            {STRINGS.toViewAddresses}
                        </Typography>
                    </Box>
            }

        </Box>
    );
};

export default AtmLocationsList;
