import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	contentContainerGrid: {
		width: "100%",
		margin: "auto",
		[theme.breakpoints.up(theme.breakpoints.values.xl)]: {
			maxWidth: '95rem',
			margin: '0 auto',
		},
		[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
			minHeight: 'max(calc(100vh - 210px), 700px)',
		},
		// [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
		marginBottom: 40,
		// }
	},
	news: {
		boxSizing: "border-box",
		borderRadius: 6,
		background: "#181f2e",
		boxShadow: "0px 3px 2px #161c2a",
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			background: 'transparent',
			boxShadow: 'none'
		}
	},
	imageContainer: {
		borderRadius: "6px 6px 0px 0px",
		width: "100%",
		position: "relative",
		"&:before": {
			content: "' '",
			backgroundColor: "#151B284D",
			borderRadius: "6px 6px 0px 0px",
			backgroundPosition: "left",
			position: "absolute",
			left: 0,
			zIndex: 1,
			width: "100%" /*width of the css background*/,
			height: "100%",
		},
	},
	image: {
		height: "100%",
		width: "100%",
		objectFit: "cover",
		borderRadius: "6px 6px 0px 0px",
	},
	contents: {
		boxSizing: "border-box",
		padding: "15px",
	},
	date: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 12,
		lineHeight: "22px",
		textAlign: "left",
		color: "#6b7a9e",
		marginTop: -5,
	},
	title: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 24,
		textAlign: "left",
		color: "#c7d0da",
	},
	description: {
		fontFamily: "var(--font-family-2)",
		fontWeight: "normal",
		fontSize: 14,
		lineHeight: "27px",
		textAlign: "left",
		color: "#6b7a9e",
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			fontSize: 16,
			lineHeight: '28px',
			color: ' #8f9bb8',
		},
		whiteSpace: ' pre-wrap',
	},
	verticalDivider: {
		width: 2,
		background: "#1a2030",
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			display: 'none'
		}
	},
	newsHeader: {
		marginBottom: 20,
		"& svg": {
			height: 20,
			width: 20,
		},
		display: "flex",
		alignItems: "flex-start",
	},
	homeSVGRContainer: {
		marginRight: 16,
		minWidth: 50,
		minHeight: 50,
		maxWidth: 50,
		maxHeight: 50,
		background: "#1c2539",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 100,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			display: 'none'
		},
		cursor: "pointer",
	},
	latestNewsContainer: {
		[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
			marginTop: 50,
		},
		[theme.breakpoints.up(theme.breakpoints.values.lg)]: {
			'& > div > div:first-of-type': {
				marginBottom: 40,
			},
			'& > div > div:not(:first-of-type):not(:last-of-type) > div': {
				margin: '18px 10px',
			}
		},
	},
}));

export default useStyles;
