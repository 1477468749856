import * as React from "react"

function AddSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15}
            height={15}
            viewBox="0 0 15 15"
            {...props}
        >
            <path
                d="M0 7.499a.937.937 0 00.938.938h5.469a.155.155 0 01.156.156v5.469a.938.938 0 001.875 0V8.593a.155.155 0 01.156-.156h5.469a.938.938 0 000-1.875H8.594a.157.157 0 01-.156-.158V.937a.938.938 0 00-1.875 0v5.467a.157.157 0 01-.156.158H.938A.937.937 0 000 7.499z"
                fill="#a6b5d9"
                data-name="Interface-Essential / Remove/Add / add"
            />
        </svg>
    )
}

export default AddSVGR
