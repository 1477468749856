import Georgian from "./languages/Georgian";
import English from "./languages/English";
import Russian from "./languages/Russian";

const languages = [
    Georgian,
    English,
    Russian
];

export default languages;