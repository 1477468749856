import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	contentContainerGrid: {
		width: "auto",
		margin: "auto",
		"& > div": {
			marginTop: 26,
		},
		"& > div:first-of-type": {
			margin: 0,
		},
		[theme.breakpoints.up("md")]: {
			maxWidth: '56.25rem',
		},
	},
	title: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 16,
		lineHeight: "24px",
		textAlign: "left",
		color: "#677aa7",
		marginBottom: 18,
		// "&:first-of-type": {
		// 	background: "red",
		// },
	},
	paragraph: {
		fontFamily: "var(--font-family-2)",
		fontWeight: "normal",
		fontSize: 14,
		lineHeight: "20px",
		textAlign: "left",
		color: "#a6b5d9",
	},
	list: {
		padding: 0,
		paddingLeft: 15,
		margin: 0,
	},
	preview: {
		height: 140,
		borderRadius: 6,
		background: 'transparent',
		border: '2px solid #1c2539',
		[theme.breakpoints.down("sm")]: {
			height: 90,
			background: '#121725',
			border: 'none',
			borderRadius: 4,
		},
	},
	NBC: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 16,
		lineHeight: '37px',
		textAlign: 'left',
		color: '#3c4766',
		[theme.breakpoints.down("sm")]: {
			fontSize: 13,
		}
	},
	balance: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 40,
		lineHeight: '37px',
		textAlign: 'left',
		color: '#c1c9df',
		marginRight: 2,
		[theme.breakpoints.down("sm")]: {
			fontSize: 30,
		}
	},
	currency: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 20,
		lineHeight: '23px',
		textAlign: 'left',
		color: '#c1c9df',
		[theme.breakpoints.down("sm")]: {
			fontSize: 16,
		}
	},
	balanceVisibilityButton: {
		width: 65,
		height: 65,
		background: '#1c2539',
		borderRadius: 100,
		marginRight: 24,
		"&:hover": {
			background: '#1c2539'
		},
		[theme.breakpoints.down("sm")]: {
			marginLeft: 'auto',
			marginRight: 0,
			padding: 0,
			background: 'transparent',
			"&:hover": {
				background: 'transparent',
			},
		}
	},
	eyeOpenSVGR: {
		width: 26,
		height: 17,
	},
	eyeCrossedSVGR: {
		width: 35,
		height: 25,
	},
	balanceAndCurreny: {
		// backgroundColor: 'red',
		display: 'flex',
		alignItems: 'center',
		paddingLeft: 30,
		width: '100%',
		[theme.breakpoints.down("sm")]: {
			justifyContent: 'space-between',
			paddingLeft: 20,
			paddingRight: 20,
			boxSizing: "border-box",
		}
	},
	balanceAndCurrenyFlexRow: {
		display: 'flex',
		alignItems: 'flex-end',
	},
	balanceAndCurrenyInnerWrapper: {
		marginTop: -10,
	},
	transferToLiderbetContainer: {
		// backgroundColor: 'red',
		[theme.breakpoints.down("sm")]: {
			display: 'none'
		},
	},
	transferToLiderbetButton: {
		maxWidth: 320,
		width: '100%',
		marginLeft: 30,
		height: 60,
		borderRadius: 4,
		background: '#15639f',
		"&:hover": {
			background: '#15639f',
		},
	},
	transferToLiderbetButtonText: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 16,
		lineHeight: '25px',
		textAlign: 'left',
		color: '#c1c9df',
	},
	transactionsWrapper: {
		// width: 860,
		marginTop: -20,
		minHight: 560,
		borderRadius: 6,
		background: 'transparent',
		border: '2px solid #1c2539',
		boxSizing: 'border-box',
		padding: 24,
		alignContent: 'flex-start',
		[theme.breakpoints.down("sm")]: {
			display: 'none',
		},
	},
	informationLine: {
		marginTop: -2,
		display: 'flex',
		alignItems: 'center',
		'& hr': {
			backgroundColor: '#1c2539',
			height: 2,
			borderRadius: 1,
			border: 'none',
		},
		'& hr:first-of-type': {
			width: 30,
		},
		'& p': {
			margin: '0 8px',
		},
		'& hr:last-of-type': {
			flex: 1
		},
		marginBottom: 30,
	},
	informationLineText: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 18,
		lineHeight: '28px',
		textAlign: 'left',
		color: '#a8b4d3',
		[theme.breakpoints.down("sm")]: {
			color: '#677aa7',

		},
	},
	button: {
		width: '81.5%',
		height: 56,
		borderRadius: 6,
		background: ' #1c2539',
		'&:hover': {
			background: ' #1c2539',
		},
		'&:disabled': {
			opacity: 0.4,
		},
		'&:first-of-type': {
			marginRight: 12,
		},
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		// fontSize: 13,
		// lineHeight: "22px",
		textAlign: "center",
		color: "#405178",
	},
	activeButton: {
		background: '#f1c157',
		'&:hover': {
			background: ' #f1c157',
		},
		'&  > span': {
			color: '#fff',
		}
	},
	textField1: {
		width: '100%',
		textAlign: 'center',
		borderRadius: 6,
		background: "#1c2539",
		'&:first-of-type': {
			marginRight: 12,
		},
		'&:nth-of-type(3)': {
			marginRight: 12,
		},
		"& label": {
			fontFamily: "var(--font-family-1)",
			fontWeight: 500,
			// fontSize: 13,
			// lineHeight: "22px",
			textAlign: "center",
			color: "#405178",
			width: 'calc(100% - 30px)',
		},
		"&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
			borderColor: "#1c2539",
			borderRadius: 6,
		},
		"& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
			color: "#405178",
			fontFamily: "var(--font-family-1)",
			textAlign: 'center',
		},
		"& .MuiInputLabel-outlined.Mui-focused": {
			color: "#405178",
		},
		"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
			borderColor: "#1c2539",
			borderRadius: 6,
		},
		"& .MuiOutlinedInput-root": {
			borderRadius: 6,
		},
		"& .MuiInputLabel-shrink": {
			// backgroundColor: 'red',
			// width: 'calc(108%)',
			// display: 'flex',
			// alignItems: 'center',
			display: 'none',
		},
	},
	transactionsFiltersWrapper: {
		// backgroundColor: 'red',
		marginBottom: 37,
	},
	transactionsFiltersContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	transactionsCalendarSVGR: {
		minWidth: 24,
		minHeight: 24,
		width: 24,
		height: 24,
		background: 'transparent',
		marginLeft: 8,
		marginRight: 16,
	},
	transactionsSwapSVGR: {
		minWidth: 26,
		minHeight: 16,
		width: 26,
		height: 16,
		background: 'transparent',
		margin: '0px 30px',
	},
	transactionsRestoreButton: {
		minWidth: 56,
		minHeight: 56,
		width: 56,
		height: 56,
		borderRadius: 4,
		background: '#1c2539',
		marginLeft: 16,
		"&:hover": {
			background: '#1c2539',
		},
	},
	transactionContainer: {
		width: '100%',
		display: 'flex',
		height: 40,
		marginBottom: 20,
		boxSizing: "border-box",
		padding: '0 8px',
		alignItems: 'center',
	},
	transactionTitle: {
		fontFamily: "var(--font-family-2)",
		fontWeight: 500,
		fontSize: 16,
		// lineHeight: '37px',
		textAlign: 'left',
		color: '#a6b5d9',
		width: 252,
		marginRight: 40,
		// backgroundColor: 'blue',
	},
	transactionDate: {
		fontFamily: "var(--font-family-2)",
		fontWeight: 500,
		fontSize: 16,
		// lineHeight: '37px',
		textAlign: 'left',
		color: '#343f5a',
		width: 188,
		marginRight: 40,
		// backgroundColor: 'green',
	},
	transactionDirection: {
		fontFamily: "var(--font-family-2)",
		fontWeight: 500,
		fontSize: 16,
		// lineHeight: '37px',
		textAlign: 'left',
		color: '#a6b5d9',
		width: 90,
		marginRight: 20,
		// backgroundColor: 'purple',
	},
	transactionAmount: {
		fontFamily: "var(--font-family-2)",
		fontWeight: 500,
		fontSize: 16,
		textAlign: 'left',
		color: '#a6b5d9',
		// lineHeight: '37px',
		minWidth: 100,
		// backgroundColor: 'orange',
		[theme.breakpoints.down("sm")]: {
			marginLeft: 'auto',
			display: 'block',
			minWidth: 'auto',
			whiteSpace: 'nowrap'
		},
	},
	transactionAmountIncome: {
		color: '#197163',
	},
	transactionDirectionIndicatorSVGR: {
		width: 40,
		height: 40,
		minWidth: 40,
		minHeight: 40,
		marginRight: 20,
	},

	// MOBILE
	totalBalanceDesktop: {
		[theme.breakpoints.down("sm")]: {
			display: 'none'
		},
	},
	totalBalanceMobile: {
		[theme.breakpoints.up("md")]: {
			display: 'none'
		},
	},
	informationLineMobile: {
		// marginTop: -2,
		display: 'flex',
		alignItems: 'center',
		'& hr': {
			backgroundColor: '#1c2539',
			height: 2,
			borderRadius: 1,
			border: 'none',
		},
		'& hr:first-of-type': {
			width: 30,
		},
		'& p': {
			margin: '0 8px',
		},
		'& hr:last-of-type': {
			flex: 1
		},
		[theme.breakpoints.up("md")]: {
			display: 'none',
		},
		marginBottom: 24,
		marginTop: '32px !important',
	},
	transactionToLiderBetFormContainer: {
		marginBottom: 30,
		marginTop: '0 !important',
		[theme.breakpoints.up("md")]: {
			display: 'none'
		},
	},
	transactionsSectionContainerMobile: {
		[theme.breakpoints.up("md")]: {
			display: 'none',
		},
		marginBottom: 12,
		marginTop: '0 !important',
	},
	transactionSectionToggleButton: {
		width: '100%',
		height: 60,
		borderRadius: 4,
		background: 'transparent',
		border: '2px solid #1f283c',
		disaply: 'flex',
		justifyContent: 'space-between',
		padding: 16,
		boxSizing: 'border-box',
	},
	transactionSectionTitle: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 16,
		textAlign: 'left',
		color: '#a6b5d9',
	},
	transactionSectionToggleSVGR: {
		// width: 6,
		// height: 10,
		// minWidth: 6,
		// minHeight: 10,
	},
	transactionSectionToggleSVGROpen: {
		transform: 'rotate(180deg)'
	},
	transactionsFilterMobile: {
		marginTop: 20,
		marginBottom: 32,
		display: 'flex',
		alignItems: 'center',
	},
	transactionMobileContainer: {
		// backgroundColor: 'red',
		width: '100%',
		display: 'flex',
		height: 40,
		marginBottom: 20,
		boxSizing: "border-box",
		padding: '0 8px',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	transactionTitleMobile: {
		fontFamily: "var(--font-family-2)",
		fontWeight: 500,
		fontSize: 14,
		textAlign: 'left',
		color: '#a6b5d9',
		marginRight: 20,
		marginBottom: 6,
		display: '-webkit-box',
		'-webkit-line-clamp': 1,
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		wordBreak: 'break-all',
	},
	transactionTimeMobile: {
		fontFamily: "var(--font-family-2)",
		fontWeight: 500,
		fontSize: 12,
		textAlign: 'left',
		color: '#343f5a',
		marginRight: 20,
	},
	transactionTitleAndTime: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		marginRight: 'auto'
	}
}));

export default useStyles;
