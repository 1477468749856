import * as React from "react"

function LocationPinSVGR(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={27}
      height={26}
      viewBox="0 0 27 26"
      {...props}
    >
      <path
        d="M18.817 19.391a1.133 1.133 0 00-1.215 1.055 1.147 1.147 0 001.026 1.249 32.606 32.606 0 014.248.6.29.29 0 010 .564 53.83 53.83 0 01-18.75 0 .29.29 0 010-.564 32.333 32.333 0 014.2-.6 1.15 1.15 0 00.995-1.248 1.136 1.136 0 00-1.186-1.056C0 20.102 0 21.836 0 22.576c0 3.085 9.357 3.419 13.5 3.419S27 25.666 27 22.576c0-.74 0-2.474-8.183-3.185z"
        fill="#161c2a"
      />
      <path
        d="M14.404 22.728a1.074 1.074 0 01-1.811 0c-2.105-3.291-7-11.327-7-14.821a7.904 7.904 0 1115.809 0c.001 3.495-4.893 11.529-6.998 14.821zm-.9-18.963a3.763 3.763 0 103.761 3.763 3.762 3.762 0 00-3.766-3.763z"
        fill="#161c2a"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default LocationPinSVGR
