import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        width: 350,
        minWidth: 300,
        maxWidth: '30%',
        right: 12,
        top: 12,
        height: 'calc(100% - 24px)',
        borderRadius: 5,
        backgroundColor: '#161c2a',
        opacity: 0.97,
        boxSizing: 'border-box',
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
            height: 550,
            minWidth: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            marginTop: 650,
            padding: 0,
            backgroundColor: 'transparent',
        },
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            width: 460,
        },
    },
    formControl: {
        borderRadius: 6,
        background: '#242d42',
        marginBottom: 10,
        width: '100%',
        '& select': {
            fontFamily: "var(--font-family-1)",
            fontWeight: 500,
            textAlign: 'left',
            color: '#c1c9df',
        },
        "& label": {
            fontFamily: "var(--font-family-1)",
            fontWeight: 500,
            textAlign: "left",
            color: "#405178",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#242d42",
            borderRadius: 6,
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#405178",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#242d42",
            borderRadius: 6,
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: 6,
        },
        '& .MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOutlined': {
            color: '#C1C9DF'
        }
    },
    list: {
        overflowY: 'auto',
        maxHeight: '100%',
        paddingRight: 5,
        width: 'calc(100% + 5px)'
    },
    location: {
        borderRadius: 6,
        border: '2px solid #273044',
        boxSizing: "border-box",
        margin: '15px 0',
        padding: 20,
        cursor: 'pointer',
    },
    text: {
        fontFamily: "var(--font-family-2)",
        fontWeight: 'normal',
        fontSize: 14,
        lineHeight: '24px',
        textAlign: 'left',
        color: '#a6b5d9',
    },
    cityNotSelected: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "center",
        flexDirection: 'column',
        textAlign: 'center',
        margin: 'auto',
        [theme.breakpoints.up(theme.breakpoints.values.md)]: {
            justifyContent: "center",
            // marginTop: 225,
        },
    },
    cityNotSelectedText: {
        marginTop: 35,
        fontFamily: "var(--font-family-4)",
        fontWeight: 'normal',
        fontSize: 22,
        lineHeight: '34px',
        textAlign: 'center',
        color: '#6b7a9e',
    },
    option: {
        color: '#161c2a',
    },
    clearFilterButton: {
        minHeight: 50,
        maxHeight: 50,
        backgroundColor: '#242d42',
        "&:hover": {
            backgroundColor: '#242d42',
        },
        color: "#6b7a9e",
        fontSize: 18,
        fontFamily: "var(--font-family-1)",
        '& + $cityNotSelected': {
            marginTop: -50,
        }
    }
}));

export default useStyles;
