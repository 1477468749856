import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	headerStyles: {
		height: 112,
		backgroundColor: "transparent",
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			height: 130
		},
		boxShadow: 'none'
	},
	containerGrid: {
		// width: '100%',
		margin: '0 auto',
	},
	buttonStyles: {
		height: 60,
		minWidth: 114,
		borderRadius: 30,
		background: "#f1c157",
		boxShadow: "0px 0px 20px rgba(239, 197, 103, 0)",
		fontSize: 16,
		marginLeft: 25,
		"&:hover": {
			backgroundColor: "#f1c157",
		},
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		textAlign: "left",
		color: "#31343e",
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			display: 'none',
		},
	},
	toolBarStyles: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		width: '100%',
		height: "100%",
		minHeight: 110,
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			flexDirection: 'column',
			justifyContent: "flex-start",
		},
	},
	gutterStyles: {
		// padding: "0 100px",
		padding: 0,
		[theme.breakpoints.down("md")]: {
			// padding: "0 50px",
		},
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			padding: "0",
		},
	},
	boxStyles: {
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			width: '100%',
			justifyContent: "center",
			background: '#19202F',
			height: 50,
			boxSizing: "border-box",
			padding: '0 15px',
			'& > div': {
				justifyContent: 'space-between',
				overflowX: 'auto',
				flexWrap: 'nowrap',
				width: '100%',
				margin: 0,
				display: 'flex'
			},
			'& $userLFNames': {
				display: 'none',
			}
		},
	},
	mobileTopPartContainer: {
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			height: 80,
			display: 'flex',
			width: '100%',
			alignItems: 'center',
			justifyContent: 'space-between',
			boxSizing: "border-box",
			// padding: '0 15px',
		},
	},
	mobileTopPartContainerWrapper: {
		[theme.breakpoints.down(400)]: {
			maxWidth: '95%',
		}
	},
	logo: {
		width: 171,
		height: 37,
		cursor: "pointer",
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			// marginLeft: 5,
			width: 146,
			height: 30,
		}
	},
	UserSVGR: {
		display: 'none',
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			display: 'block',
			cursor: 'pointer',
		}
	},
	userLFNames: {
		marginLeft: 30,
		minWidth: 60,
		minHeight: 60,
		width: 60,
		height: 60,
		borderRadius: 30,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: '#f1c157',
		boxShadow: '0px 0px 20px rgba(239, 197, 103, 0)',
		[theme.breakpoints.down(400)]: {
			marginLeft: 10
		}
	},
	userLFNamesText: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 'bold',
		fontSize: 20,
		textAlign: 'left',
		color: '#31343e',
		cursor: 'pointer',
	},
	logoutSVGR: {
		minWidth: 33,
		minHeight: 24,
		marginLeft: 10,
		marginRight: 6,
		cursor: 'pointer',
		[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
			display: 'none',
		}
	},
	logoutSVGR2: {
		display: 'unset',
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			display: 'none',
		}
	},
	userLFNamesMobile: {
		[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
			display: 'none',
		}
	},
	changeLanguageContainer: {
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			display: 'none !important',
		},
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	changeLanguageContainer2: {
		[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
			display: 'none !important',
		}
	},
	buttonAndChangeLanguage: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
}));

export default useStyles;
