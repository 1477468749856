import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	contentContainerGrid: {
		width: "100%",
		margin: "auto",
		marginBottom: 15,
	},
	mapContainer: {
		width: "100%",
		height: '65vh',
		minHeight: 550,
		maxHeight: 650,
		position: 'relative',
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			height: 550,
			marginBottom: 650,
		},
		[theme.breakpoints.up(theme.breakpoints.values.md)]: {
			height: 'calc(100vh - 118px)',
			maxHeight: 855,
			maxWidth: '93.75rem',
		},
	},
	addressesText: {
		width: '100%',
		height: 60,
		borderRadius: 4,
		background: 'transparent',
		border: '2px solid #1f283c',
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 18,
		textAlign: 'center',
		color: '#a6b5d9',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 20,
		[theme.breakpoints.up(theme.breakpoints.values.sm)]: {
			display: 'none',
		}
	}
}));

export default useStyles;
