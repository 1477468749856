import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 450,
        borderRadius: 6,
        background: '#171f32',
        padding: '37px 32px',
        outline: 'none',
        [theme.breakpoints.down(500)]: {
            width: 'calc(100% - 20px)'
        },
    },
    closeSVGR: {
        position: 'absolute',
        top: 20,
        right: 20,
        cursor: 'pointer',
        '& path': {
            transition: 'fill 250ms ease-in-out',
        },
        "&:hover path": {
            fill: '#c7d0da',
        }
    },
    title: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 'normal',
        fontSize: 21,
        lineHeight: '39px',
        textAlign: 'left',
        color: '#c7d0da',
    },
    titleContainer: {
        marginBottom: 30,
    },
    statusText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '22px',
        textAlign: 'center',
        color: '#197163',
        maxWidth: '90%',
        margin: 'auto',
    },
    statusVGR: {
        margin: 'auto',
        marginBottom: 20,
    },
    contentContainer: {
        marginTop: 35,
        marginBottom: 56
    },
    buttonText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        textDecoration: 'underline',
        fontSize: 14,
        textAlign: 'center',
        color: '#a6b5d9',
        cursor: 'pointer'
    },
    textFieldContainer: {
        marginBottom: 20,
    },
    submitButton: {
        marginTop: 5,
        marginBottom: -20,
        width: '100%',
        height: 50,
        borderRadius: 6,
        background: ' #15639f',
        '&:hover': {
            background: ' #15639f',
        },
        '&:disabled': {
            background: '#1B2234'
        }
    },
    submitButtonText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'left',
        color: '#c7d0da',
    },
    textField1: {
        width: '100%',
        textAlign: 'center',
        borderRadius: 6,
        background: "#1c2539",
        '&:first-of-type': {
            marginRight: 12,
        },
        '&:nth-of-type(3)': {
            marginRight: 12,
        },
        "& label": {
            fontFamily: "var(--font-family-1)",
            fontWeight: 500,
            // fontSize: 13,
            // lineHeight: "22px",
            textAlign: "left",
            color: "#405178",
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1c2539",
            borderRadius: 6,
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
            color: "#405178",
            fontFamily: "var(--font-family-1)",
            // textShadow: '0 0 0 red',
            // color: 'transparent',
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
            color: "#405178",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#1c2539",
            borderRadius: 6,
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: 6,
        },
    },
    smsCodeButton: {
        minWidth: 70,
        marginRight: -13,
        height: 55,
        borderRadius: '0px 6px 6px 0px',
        background: '#15639f',
        "&:hover": {
            background: '#15639f',
        },
    },
    smsCodeButtonText: {
        fontFamily: "var(--font-family-1)",
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'left',
        color: '#c7d0da',
    },
    error: {
        marginTop: 12,
        padding: '0 5px',
        boxSizing: 'border-box',
        fontFamily: "var(--font-family-2)",
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '18px',
        textAlign: 'left',
        color: '#cb3352',
    }
}));

export default useStyles;
