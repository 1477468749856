import React from "react";
import useStyles from "../../styles/components/Modals/SMSModalStyles";
import ModalCloseSVGR from "../../smart-assets/SVGRs/ModalCloseSVGR";
import { Grid, Typography, Button } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import SMSInputWithStatus from "../SMSInputWithStatus"

const SMSModal = (props) => {
    const classes = useStyles(props);
    const { isOpen, onClose, onButtonPress, smsCodeError, setSMSCodeErorr, _sendSMS, smsTimeout, setSentSMSAt, smsCode, setSMSCode, smsCodeStatus, setSMSCodeStatus, smsSentAt, STRINGS } = props;

    console.log('timeout', smsTimeout, smsSentAt)

    return (
        <Modal
            open={isOpen}
        >
            <Grid container className={classes.container} alignItems="baseline">
                <Grid xs={12} item className={classes.titleContainer}>
                    <Typography variant="h2" className={classes.title}>
                        {STRINGS.title}
                    </Typography>
                </Grid>
                <ModalCloseSVGR className={classes.closeSVGR} onClick={onClose} />
                <Grid xs={12} item className={classes.textFieldContainer}>
                    <SMSInputWithStatus
                        label={STRINGS.smsCode}
                        validate={(val) => {
                            if (smsCodeError) {
                                setSMSCodeErorr('');
                            }
                            let restrictedVal = val.replace(/[^0-9]/g, '');
                            setSMSCode(restrictedVal);
                        }}
                        value={smsCode}
                        error={smsCodeError}
                        status={smsCodeStatus}
                        setValue={setSMSCode}
                        setStatus={setSMSCodeStatus}
                        sentSMSAt={smsSentAt}
                        setCanSendSMS={setSentSMSAt}
                        sendSMS={_sendSMS}
                        timeoutSecs={smsTimeout}
                        inputProps={{ maxLength: 4 }}
                    />
                    {
                        smsCodeError ? <Typography className={classes.error}>
                            {smsCodeError}
                        </Typography> : null
                    }
                </Grid>
                <Grid xs={12} item className={classes.textFieldContainer}>
                    <Button disabled={!(smsCode && smsCode.length === 4)} className={classes.submitButton} onClick={onButtonPress}>
                        <Typography className={classes.submitButtonText}>
                            {STRINGS.buttonText}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default SMSModal;
