import * as React from "react"

function DiscountSVGR(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={38}
      viewBox="0 0 36 38"
      {...props}
    >
      <g data-name="Group 90">
        <g data-name="Group 89">
          <path
            data-name="Path 30"
            d="M34.264 19.492a1.126 1.126 0 010-.984l1.4-2.9a3.319 3.319 0 00-1.405-4.381l-2.818-1.511a1.113 1.113 0 01-.571-.8l-.55-3.179a3.263 3.263 0 00-3.679-2.704l-3.155.452a1.091 1.091 0 01-.924-.3L20.274.937a3.227 3.227 0 00-4.548 0l-2.285 2.244a1.091 1.091 0 01-.924.3l-3.155-.452a3.262 3.262 0 00-3.679 2.707l-.55 3.179a1.113 1.113 0 01-.571.8l-2.821 1.513a3.319 3.319 0 00-1.4 4.381l1.4 2.9a1.126 1.126 0 010 .984l-1.4 2.9a3.319 3.319 0 001.4 4.379l2.815 1.513a1.113 1.113 0 01.571.8l.55 3.179a3.267 3.267 0 003.212 2.738 3.3 3.3 0 00.469-.034l3.155-.452a1.09 1.09 0 01.924.3l2.29 2.244a3.227 3.227 0 004.548 0l2.29-2.244a1.092 1.092 0 01.924-.3l3.152.451a3.262 3.262 0 003.679-2.707l.551-3.179a1.113 1.113 0 01.571-.8l2.815-1.513a3.319 3.319 0 001.405-4.381zm-1.023 5.344l-2.815 1.513a3.289 3.289 0 00-1.685 2.353l-.55 3.179a1.1 1.1 0 01-1.25.921l-3.155-.452a3.223 3.223 0 00-2.732.9l-2.29 2.244a1.092 1.092 0 01-1.539 0l-2.284-2.25a3.225 3.225 0 00-2.266-.932 3.28 3.28 0 00-.466.033l-3.155.452a1.1 1.1 0 01-1.245-.916l-.55-3.179a3.288 3.288 0 00-1.688-2.354l-2.815-1.513a1.123 1.123 0 01-.475-1.482l1.4-2.9a3.329 3.329 0 000-2.909l-1.4-2.9a1.123 1.123 0 01.475-1.482l2.815-1.513a3.289 3.289 0 001.688-2.347l.55-3.179a1.1 1.1 0 011.245-.921l3.155.452a3.224 3.224 0 002.732-.9l2.29-2.244a1.092 1.092 0 011.539 0l2.29 2.244a3.223 3.223 0 002.732.9l3.149-.452a1.1 1.1 0 011.245.916l.555 3.184a3.288 3.288 0 001.688 2.354l2.812 1.508a1.123 1.123 0 01.475 1.482l-1.4 2.9a3.329 3.329 0 000 2.909l1.4 2.9a1.123 1.123 0 01-.475 1.481z"
            fill="#5e6f9b"
          />
        </g>
      </g>
      <g data-name="Group 92">
        <g data-name="Group 91">
          <path
            data-name="Path 31"
            d="M26.007 10.993a1.1 1.1 0 00-1.55 0L9.993 25.457a1.1 1.1 0 101.55 1.55l14.464-14.464a1.1 1.1 0 000-1.55z"
            fill="#5e6f9b"
          />
        </g>
      </g>
      <g data-name="Group 94">
        <g data-name="Group 93">
          <path
            data-name="Path 32"
            d="M13.688 9.138a4.018 4.018 0 104.018 4.018 4.023 4.023 0 00-4.018-4.018zm0 5.844a1.826 1.826 0 111.826-1.826 1.828 1.828 0 01-1.826 1.826z"
            fill="#5e6f9b"
          />
        </g>
      </g>
      <g data-name="Group 96">
        <g data-name="Group 95">
          <path
            data-name="Path 33"
            d="M22.312 20.826a4.018 4.018 0 104.018 4.018 4.023 4.023 0 00-4.018-4.018zm0 5.844a1.826 1.826 0 111.826-1.826 1.828 1.828 0 01-1.826 1.826z"
            fill="#5e6f9b"
          />
        </g>
      </g>
    </svg>
  )
}

export default DiscountSVGR
