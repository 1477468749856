import React, { useContext, useState } from "react";
import { Grid, Box } from "@material-ui/core";
import useStyles from "../styles/components/ChangeLanguageStyles";
// import DropdownSVGR from "../smart-assets/SVGRs/DropdownSVGR";
// import classNames from "classnames";
import { LanguageContext } from "../contexts/LanguageContext";

const ChangeLanguage = (props) => {
    const classes = useStyles(props);
    const { languages, selectedLanguage, setLanguage } = useContext(LanguageContext);

    const [showSelector, setShowSelector] = useState(false);

    return (
        <Grid item container className={classes.container} alignItems="center" justify="flex-end" onClick={() => setShowSelector(!showSelector)} tabIndex="1" onBlur={() => setShowSelector(false)}>
            {showSelector ?
                <Box className={classes.languageSelector}>
                    {languages.filter(language => language.key !== selectedLanguage.key).map(({ displayText, key, FlagSVGR }) => (
                        <Box className={classes.language} key={key} onClick={() => {
                            setShowSelector(false);
                            setLanguage(key);
                        }}>
                            <FlagSVGR className={classes.flag} />
                            {/* <Typography variant="body2" className={classes.languageText}>
                                {displayText}
                            </Typography> */}
                        </Box>
                    ))}
                </Box>
                : null
            }

            <Grid item className={classes.selectedLanguage}>
                <selectedLanguage.FlagSVGR />
                {/* <Typography variant="body2" className={classes.languageText}>
                    {selectedLanguage.displayText}
                </Typography> */}
                {/* <div className={classes.svgContainer}>
                    <DropdownSVGR className={classNames(classes.picker, classes.rotated)} />
                    <DropdownSVGR className={classNames(classes.picker)} />
                </div> */}
            </Grid>
        </Grid>
    );
};

export default ChangeLanguage;
