import React from "react";
import { Grid } from "@material-ui/core";
import WelcomeBanner from "../components/WelcomeBanner";
import useStyles from "../styles/pages/HomeStyles";
import Features from '../components/Features';

function Home(props) {
	const classes = useStyles(props);
	return (
		<Grid item container xs={12} sm={11} xl={10} className={classes.contentContainerGrid}>
			<Grid item xs={12} className={classes.welcomeBannerContainer}>
				<WelcomeBanner />
			</Grid>
			<Grid item xs={11} sm={12} className={classes.featuresContainer}>
				<Features />
			</Grid>
		</Grid>
	);
}

export default Home;
