import React, { useState, useMemo, useEffect, useCallback } from "react";
import languages from "../data/languages";

const LanguageContext = React.createContext();

const localStorageKey = "langKey";

if (!localStorage.getItem("langKey")) {
  localStorage.setItem("langKey", "Geo");
}

const selectedUserLanguage =
  languages.find(
    (e) => e.routeKey === document.location.pathname.split("/")[1]
  ) || languages.find((e) => e.key === localStorage.getItem(localStorageKey));

if (
  selectedUserLanguage &&
  localStorage.getItem(localStorageKey) !== selectedUserLanguage.key
) {
  localStorage.setItem(localStorageKey, selectedUserLanguage.key);
}

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(selectedUserLanguage.key);

  const selectedLanguage = useMemo(() => {
    return languages.find((entry) => entry.key === language);
  }, [language]);

  const _setLanguage = useCallback(
    (key) => {
      let newSelected = languages.find((entry) => entry.key === key);
      if (
        newSelected &&
        newSelected.routeKey !== selectedUserLanguage.routeKey
      ) {
        localStorage.setItem(localStorageKey, key);
        setLanguage(key);

        document.location.replace(
          `${document.location.origin}${document.location.href
            .replace(document.location.origin, "")
            .replace(selectedUserLanguage.routeKey, newSelected.routeKey)}`
        );
      }
    },
    [setLanguage]
  );

  useEffect(() => {
    const STORED_LANG_KEY = localStorage.getItem(localStorageKey);
    if (STORED_LANG_KEY) {
      _setLanguage(STORED_LANG_KEY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLanguage: _setLanguage,
        selectedLanguage,
        languages,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider, LanguageContext };
