import * as React from "react"

function ChatSVGR(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        d="M8 0C3.667 0 0 3.391 0 7.406a7.638 7.638 0 0010.667 6.884l3.983 1.682a.592.592 0 00.636-.189.694.694 0 00.116-.693l-.927-3.5a6.383 6.383 0 001.526-4.181C16 3.388 12.333 0 8 0z"
        fill="#f1c157"
      />
    </svg>
  )
}

export default ChatSVGR
