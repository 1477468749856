import * as React from "react"

function SubtractSVGR(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15}
            height={2}
            viewBox="0 0 15 2"
            {...props}
        >
            <path
                d="M.938 2h13.125a1 1 0 000-2H.938a1 1 0 000 2z"
                fill="#a6b5d9"
                data-name="Interface-Essential / Remove/Add / subtract"
            />
        </svg>
    )
}

export default SubtractSVGR
