import * as React from "react"

function FlagGeorgianSVGR(props) {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={30}
        height={30}
        viewBox="0 0 30 30"
        {...props}
      >
        <g transform="translate(0 .673)">
          <circle
            data-name="Ellipse 116"
            cx={15}
            cy={15}
            r={15}
            transform="translate(0 -.673)"
            fill="#f0f0f0"
          />
          <g data-name="Group 1186" fill="#d80027">
            <path
              data-name="Path 72"
              d="M29.082 12.702H16.507V.126a14.737 14.737 0 00-3.809 0v12.576H.124a14.737 14.737 0 000 3.809H12.7v12.573a14.737 14.737 0 003.809 0V16.509h12.573a14.737 14.737 0 000-3.809z"
            />
            <path
              data-name="Path 73"
              d="M9.065 7.032V4.997H7.03v2.035H4.995v2.035H7.03v2.035h2.035V9.067H11.1V7.032z"
            />
            <path
              data-name="Path 74"
              d="M22.175 7.032V4.997H20.14v2.035h-2.035v2.035h2.035v2.035h2.035V9.067h2.035V7.032z"
            />
            <path
              data-name="Path 75"
              d="M9.065 20.142v-2.035H7.03v2.035H4.995v2.035H7.03v2.035h2.035v-2.035H11.1v-2.035z"
            />
            <path
              data-name="Path 76"
              d="M22.175 20.142v-2.035H20.14v2.035h-2.035v2.035h2.035v2.035h2.035v-2.035h2.035v-2.035z"
            />
          </g>
        </g>
      </svg>
    )
}

export default FlagGeorgianSVGR
