import React, { useEffect, useCallback } from "react";
import useStyles from "../styles/components/TextFieldWithStatusStyles";
import { Typography, TextField, InputAdornment, Box } from "@material-ui/core";
import generateInputStatuses from "../generators/generateInputStatuses";
import classNames from "classnames";
import useLanguage from "../hooks/useLanguage";

const EndAdornment = ({ classes, inputStatuses, status, error, showError, success }) => {
    const { globalComponents: { loginModal: STRINGS } } = useLanguage();
    let SVGR = inputStatuses[status]?.SVGR;
    if (SVGR) {
        return (
            <InputAdornment position="end" >
                <Box className={classes.endAdormentContainer}>
                    {SVGR ? <SVGR /> : null}
                    <Box className={classes.endAdormentTooltip}>
                        <Box className={classes.toopTipArrow} />
                        <Typography className={classes.endAdormentTooltipText}>
                            {status === "loading" ? STRINGS.loading : status === "success" ? success : showError ?
                                error : null
                            }
                        </Typography>
                    </Box>
                </Box>
            </InputAdornment>
        )
    }
    else {
        return null;
    }
}


const TextFieldWithStatus = (props) => {
    const classes = useStyles(props);
    const { label, value, validate, textFieldProps, inputProps, setValue, setStatus, status, error, success } = props;
    const inputStatuses = generateInputStatuses(label);

    const validation = useCallback(async (val) => {
        if (val) {
            // setStatus(() => 'loading');
            let status = await validate(val);
            setStatus(() => status);
        }
        else {
            setStatus(() => null);
        }
    }, [validate, setStatus])

    useEffect(() => {
        validation(value);
        // disabling eslint because the solutions for this warning will cause infinite or unoptimized loops
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [value])

    return (
        <TextField className={classNames(classes.textField, ["success", null, "loading", undefined].includes(status) ? null : classes.errored)} label={label} variant="outlined"
            value={value}
            onChange={(event) => setValue(() => event.target.value)}
            spellCheck="false"
            maxLength="5"
            InputProps={{
                endAdornment: <EndAdornment classes={classes} inputStatuses={inputStatuses} status={status} error={error} showError={!["success", null, "loading", undefined].includes(status)} success={success} />,
            }}
            inputProps={inputProps}
            {...textFieldProps}
        />
    );
};

export default TextFieldWithStatus;
