import React, { useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../styles/components/PrivateInformationStyles";
import { UserContext } from "../contexts/UserContext";
import useLanguage from "../hooks/useLanguage";

function PrivateInformation(props) {
    const classes = useStyles(props);
    const { user } = useContext(UserContext);
    const { pages: { dashboard: { profile: { privateInformation: STRINGS } } } } = useLanguage();


    return (
        <Grid item container xs={12} className={classes.container} alignItems="flex-start">
            <Grid item xs={12} className={classes.informationLine}>
                <hr />
                <Typography variant="body1" className={classes.informationLineText}>
                    {STRINGS.title}
                </Typography>
                <hr />
            </Grid >
            <Grid item xs={6} sm={3} className={classes.labelAndText}>
                <Typography variant="body1" className={classes.label}>
                    {STRINGS.name}
                </Typography>
                <Typography variant="body1" className={classes.text}>
                    {user.name.split(' ')[0]}
                </Typography>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.labelAndText}>
                <Typography variant="body1" className={classes.label}>
                    {STRINGS.lastName}
                </Typography>
                <Typography variant="body1" className={classes.text}>
                    {user.last_name}
                </Typography>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.labelAndText}>
                <Typography variant="body1" className={classes.label}>
                    {STRINGS.personalId}
                </Typography>
                <Typography variant="body1" className={classes.text}>
                    {user.personal_id}
                </Typography>
            </Grid>
            <Grid item xs={6} sm={3} className={classes.labelAndText}>
                <Typography variant="body1" className={classes.label}>
                    {STRINGS.phoneNumber}
                </Typography>
                <Typography variant="body1" className={classes.text}>
                    {user.mobile}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default PrivateInformation;
