import React from "react";
import useStyles from "../../styles/components/Modals/TransactionModalStyles";
import ModalCloseSVGR from "../../smart-assets/SVGRs/ModalCloseSVGR";
import { Grid, Typography } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';
import TransferToLiderBetForm from "../TransferToLiderbetForm";
import useLanguage from "../../hooks/useLanguage";

const Header = (props) => {
    const classes = useStyles(props);
    const { isOpen, onClose, refetch } = props;
    const { pages: { dashboard: { balance: { transactionModal: STRINGS } } } } = useLanguage();

    return (
        <Modal
            open={isOpen}
        >
            <Grid container className={classes.container} alignItems="baseline">
                <ModalCloseSVGR className={classes.closeSVGR} onClick={onClose} />
                <Grid xs={12} item className={classes.titleContainer}>
                    <Typography variant="h2" className={classes.title}>
                        {STRINGS.title}
                    </Typography>
                </Grid>
                <Grid xs={12} item className={classes.formContainer}>
                    <TransferToLiderBetForm onClose={onClose} refetch={refetch} />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default Header;
