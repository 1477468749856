import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	contentContainerGrid: {
		width: "100%",
		margin: "auto",
		[theme.breakpoints.up("sm")]: {
			'& > div:first-of-type': {
				// paddingRight: 10,
				paddingBottom: 20,
			},
			'& > div:nth-of-type(2)': {
				paddingRight: 10,
			}
		},
	},
	title: {
		fontFamily: "var(--font-family-1)",
		fontWeight: 500,
		fontSize: 16,
		lineHeight: "24px",
		textAlign: "left",
		color: "#677aa7",
		marginBottom: 18,
	},
	privateInformation: {
		height: 200,
		// backgroundColor: 'red',
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			height: 300,
		},
	},
	changePassword: {
		height: 350,
		// backgroundColor: 'green'
	},
	pinRecovery: {
		height: 280,
		// backgroundColor: 'blue'
	}
}));

export default useStyles;
