import * as React from "react"

function HomeSVGR(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      {...props}
    >
      <g fill="#a6b5d9" data-name="Interface-Essential / Home / house-4">
        <path d="M2.033 8.831l7.7-6.24a.418.418 0 01.524 0l7.7 6.24a1.251 1.251 0 101.573-1.945l-7.7-6.24a2.931 2.931 0 00-3.671 0l-7.7 6.24a1.25 1.25 0 001.569 1.945z" />
        <path d="M18.775 18.332v-5.834a1.839 1.839 0 00-.626-1.326l-7.086-5.837a1.678 1.678 0 00-2.129 0l-7.086 5.842a1.837 1.837 0 00-.627 1.321v5.837a1.67 1.67 0 001.672 1.663h4.6a.835.835 0 00.836-.834V14.58a.836.836 0 01.836-.834h1.672a.836.836 0 01.836.834v4.586a.835.835 0 00.829.832h4.6a1.67 1.67 0 001.673-1.666z" />
      </g>
    </svg>
  )
}

export default HomeSVGR
